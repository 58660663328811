"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var ChartJs_vue_1 = require("@/components/ChartJs.vue");
var BasicList_vue_1 = require("@/components/common/BasicList.vue");
var filters_1 = require("@/filters");
var utils_1 = require("./utils");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ItemsDistributionByPeriod',
    props: {
        data: {
            type: Object,
            required: true,
        },
        jsonData: {
            type: Object,
            default: null,
        },
        periodOptions: {
            type: Object,
            default: function () { return ({
                periodValue: null,
            }); },
        },
    },
    setup: function (__props) {
        var props = __props;
        var baseData = (0, vue_2.computed)(function () { var _a; return ((_a = props.jsonData) === null || _a === void 0 ? void 0 : _a.TodoStatsByWeekDayByPeriod) || {}; });
        var days = [
            /**
             * `iso_week_day` from backend takes an integer value representing
             * the day of the week from 1 (Monday) to 7 (Sunday).
             * https://docs.djangoproject.com/en/4.1/ref/models/querysets/#iso-week-day
             */
            "",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
        ];
        var hasData = (0, vue_2.computed)(function () { return Object.keys(baseData.value).length > 0; });
        var labels = (0, vue_2.computed)(function () { return (0, utils_1.getFirstLevelKeys)(baseData.value); });
        var columns = Array(7)
            .fill(0)
            .map(function (e, i) { return (i + 1).toString(); });
        var tableColumns = (0, vue_2.computed)(function () {
            return (0, utils_1.getTableColumns)(columns, function (column) { return days[parseInt(column)]; }, function (field, data) { return (0, filters_1.numberFormat)(data === null || data === void 0 ? void 0 : data[field]); }, true);
        });
        var tableData = (0, vue_2.computed)(function () {
            return (0, utils_1.getTableData)(labels.value, columns, function (label, column) { var _a; return ((_a = baseData.value[label][column]) === null || _a === void 0 ? void 0 : _a.todo_total) || 0; });
        });
        var chartDatasets = (0, vue_2.computed)(function () {
            return (0, utils_1.getChartDatasets)(columns, // using columns as labels
            labels.value, // using columns as labels
            {}, undefined, function (label, column) { var _a; return ((_a = baseData.value[column][label]) === null || _a === void 0 ? void 0 : _a.todo_total) || 0; });
        });
        var chartData = (0, vue_2.computed)(function () { return ({
            labels: columns.map(function (column) { return days[parseInt(column)]; }), // using columns as labels
            datasets: chartDatasets.value,
        }); });
        return { __sfc: true, props: props, baseData: baseData, days: days, hasData: hasData, labels: labels, columns: columns, tableColumns: tableColumns, tableData: tableData, chartDatasets: chartDatasets, chartData: chartData, ChartJs: ChartJs_vue_1.default, BasicList: BasicList_vue_1.default };
    }
});
