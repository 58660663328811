"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var ChartJs_vue_1 = require("@/components/ChartJs.vue");
var BasicList_vue_1 = require("@/components/common/BasicList.vue");
var filters_1 = require("@/filters");
var utils_1 = require("./utils");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ItemsBySuspiciousTermByPeriod',
    props: {
        data: {
            type: Object,
            required: true,
        },
        jsonData: {
            type: Object,
            default: null,
        },
        periodOptions: {
            type: Object,
            default: function () { return ({
                periodValue: null,
            }); },
        },
    },
    setup: function (__props) {
        var props = __props;
        var baseData = (0, vue_2.computed)(function () { var _a; return ((_a = props.jsonData) === null || _a === void 0 ? void 0 : _a.TodoStatsBySuspiciousTermByPeriod) || {}; });
        var hasData = (0, vue_2.computed)(function () { return Object.keys(baseData.value).length > 0; });
        var labels = (0, vue_2.computed)(function () { return (0, utils_1.getFirstLevelKeys)(baseData.value); });
        var columns = (0, vue_2.computed)(function () { return (0, utils_1.getSecondLevelKeys)(baseData.value); });
        var tableColumns = (0, vue_2.computed)(function () {
            return (0, utils_1.getTableColumns)(columns.value, function (column) { return column || "(empty)"; }, function (field, data) { return (0, filters_1.numberFormat)(data === null || data === void 0 ? void 0 : data[field]); }, true);
        });
        var tableData = (0, vue_2.computed)(function () {
            return (0, utils_1.getTableData)(labels.value, columns.value, function (label, column) { var _a; return ((_a = baseData.value[label][column]) === null || _a === void 0 ? void 0 : _a.todo_total) || 0; });
        });
        var chartDatasets = (0, vue_2.computed)(function () {
            return (0, utils_1.getChartDatasets)(labels.value, columns.value, { fill: true }, function (column) { return column || "(empty)"; }, function (label, column) { var _a; return ((_a = baseData.value[label][column]) === null || _a === void 0 ? void 0 : _a.todo_total) || 0; });
        });
        var chartData = (0, vue_2.computed)(function () { return ({
            labels: labels.value,
            datasets: chartDatasets.value,
        }); });
        return { __sfc: true, props: props, baseData: baseData, hasData: hasData, labels: labels, columns: columns, tableColumns: tableColumns, tableData: tableData, chartDatasets: chartDatasets, chartData: chartData, ChartJs: ChartJs_vue_1.default, BasicList: BasicList_vue_1.default };
    }
});
