<!--
  Changes in this template must be reflected
  in `exportQueryCellInfo` of the `DataTable.vue` component
 -->

<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Unredact Reason</v-card-title>

        <v-card-text>
          <v-text-field label="Reason" v-model="reason" required ref="input"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" :disabled="!reason.trim()" text @click="submitUnredact">
            Unredact
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters align="center" class="custom-row" v-if="data.id">
      <v-col>
        <div class="my-title">Actions</div>
      </v-col>
      <v-col class="my-content">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on" @click="showMessageDialog(data)">
              <v-icon>mdi-file-document-outline</v-icon>
            </v-btn>
          </template>
          <span>Open this document for preview</span>
        </v-tooltip>
        <v-tooltip v-if="canShowConversationSearch" bottom>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on" @click="openConversationSearch">
              <v-icon>mdi-comment-search-outline</v-icon>
            </v-btn>
          </template>
          <span>Search for conversations related to this item</span>
        </v-tooltip>
        <v-tooltip v-if="canShowRedaction && isRedacted" bottom>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on" @click="handleUnredactDialog">
              <v-icon>mdi-sort-variant-lock-open</v-icon>
            </v-btn>
          </template>
          <span>Unredact summary informations</span>
        </v-tooltip>
        <v-tooltip v-if="data.group_total > 1" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="displaySimilarItems(data.group_id)"
            >
              <v-icon>mdi-group</v-icon>
            </v-btn>
          </template>
          <span>View {{ data.group_total }} individual documents within this group</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row no-gutters class="custom-row" v-if="data.fingerprint_type">
      <v-col><div class="my-title">Type</div></v-col>
      <v-col class="my-content">{{ data.fingerprint_type }}</v-col>
    </v-row>

    <v-row
      no-gutters
      class="custom-row"
      v-if="data.to && !data.to.includes('undisclosed-recipients')"
    >
      <v-col><div class="my-title">To</div></v-col>
      <v-col class="my-content">
        <div v-obfuscate :title="redactItem(data.to)">
          {{ truncate(redactItem(data.to), 100, true) }}
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="custom-row" v-else-if="data.bcc">
      <v-col><div class="my-title">BCC</div></v-col>
      <v-col class="my-content">
        <div v-obfuscate :title="redactItem(data.bcc)">
          {{ truncate(redactItem(data.bcc), 100, true) }}
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="custom-row" v-if="data.from_person_display">
      <v-col>
        <div class="my-title">
          <v-tooltip bottom v-if="!(canShowRedaction && isRedacted)">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                x-small
                v-on="on"
                :to="{
                  name: 'TriageAutoIgnoreRulesEdit',
                  query: { field_name: 'from_', field_values: data.from_person_display },
                }"
              >
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <span>Create Auto Ignore Rule with this address</span>
          </v-tooltip>
          From
        </div>
      </v-col>
      <v-col class="my-content">
        <div v-obfuscate :title="redactItem(data.from_person_display)">
          {{ truncate(redactItem(data.from_person_display), 100, true) }}
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="custom-row" v-if="data.cc">
      <v-col><div class="my-title">CC</div></v-col>
      <v-col class="my-content">
        <div v-obfuscate :title="redactItem(data.cc)">
          {{ truncate(redactItem(data.cc), 100, true) }}
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="custom-row" v-if="data.subject">
      <v-col><div class="my-title">Subject</div></v-col>
      <v-col class="my-content">
        <div v-obfuscate :title="redactItem(data.subject)">
          {{ truncate(redactItem(data.subject), 100, true) }}
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="custom-row" v-if="data.fingerprint_time">
      <v-col><div class="my-title">Date</div></v-col>
      <v-col class="my-content">{{ fullDateTimeFormat(data.fingerprint_time) }}</v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import EventBus from "@/eventBus";
import { fullDateTimeFormat, redactPII } from "@/filters";
import { logUnredactMessage } from "@/repositories/logs";
import { useAuthStore } from "@/stores/auth";
import { useStore } from "@/stores/main";

export default {
  name: "SummaryColumn",
  data() {
    return {
      data: {},
      isRedacted: true,
      dialog: false,
      reason: "",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canShowConversationSearch", "canShowRedaction"]),
  },
  methods: {
    ...mapActions(useStore, ["showMessageDialog"]),
    fullDateTimeFormat,
    truncate(str, n, useWordBoundary) {
      if (!str || str.length <= n) {
        return str;
      }
      const subString = str.substr(0, n - 1);
      return (
        (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) +
        " ... (hover to see more) ..."
      );
    },
    openConversationSearch() {
      this.$router.push({
        name: "ConversationSearch",
        query: {
          data_types: [this.data.fingerprint_type],
          person1: this.data.from_person,
          person2: this.data.to,
          dt_from: this.data.fingerprint_time,
          dt_to: this.data.fingerprint_time,
          restrict_to_firms: [this.data.fingerprint_firm],
        },
      });
    },
    redactItem(item) {
      if (this.canShowRedaction && this.isRedacted) {
        return redactPII(item);
      }
      return item;
    },
    async handleUnredactDialog() {
      this.dialog = true;
      await this.$nextTick();
      this.$refs.input.focus();
    },
    async submitUnredact() {
      await logUnredactMessage(this.data, "summary", this.reason);
      this.reason = "";
      this.isRedacted = false;
      this.dialog = false;
    },
    displaySimilarItems(groupId) {
      EventBus.$emit("displaySimilarItems", groupId);
    },
  },
};
</script>

<style scoped>
.my-title {
  font-weight: bold;
  text-align: right;
  width: 60px;
  padding-right: 5px;
}
.my-content {
  flex-grow: 11;
  overflow-wrap: normal;
  overflow: hidden;
}

.custom-row {
  flex-wrap: nowrap;
}
</style>
