"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAccount = getAccount;
exports.saveAccount = saveAccount;
var axios_1 = require("@/interceptors/axios");
function getAccount(id) {
    return axios_1.default.get("accounts/".concat(id, "/"));
}
function saveAccount(payload) {
    if (payload.id) {
        return axios_1.default.patch("accounts/".concat(payload.id, "/"), payload);
    }
    else {
        return axios_1.default.post("accounts/", payload);
    }
}
