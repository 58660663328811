"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var composables_1 = require("vue-router/composables");
var firm_1 = require("@/repositories/firm");
var reporting_1 = require("@/repositories/reporting");
var auth_1 = require("@/stores/auth");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DistributionListForm',
    props: {
        id: null
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var router = (0, composables_1.useRouter)();
        var authStore = (0, auth_1.useAuthStore)();
        var _a = (0, pinia_1.storeToRefs)(authStore), canAddReportDistributionList = _a.canAddReportDistributionList, canChangeReportDistributionList = _a.canChangeReportDistributionList, firmLabelObject = _a.firmLabelObject;
        var form = (0, vue_2.ref)(null); // html ref="form"
        var firms = (0, vue_2.ref)([]);
        var reportDistribution = (0, vue_2.ref)(null);
        var displayForm = (0, vue_2.ref)(false);
        var formObj = (0, vue_2.ref)({ external_email_recipients: [] });
        var formValid = (0, vue_2.ref)(false);
        var newRecipient = (0, vue_2.ref)("");
        var rules = {
            name: [
                /* istanbul ignore next */
                function (v) { return !!v || "This field is mandatory"; },
            ],
            emailRules: [
                function (v) {
                    return !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid";
                },
            ],
        };
        var isNew = (0, vue_2.computed)(function () { return props.id === "new"; });
        function handleSave() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!form.value.validate()) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, (0, reporting_1.saveReportDistributionList)(__assign({}, formObj.value))];
                        case 1:
                            _a.sent();
                            router.push({ name: "ReportingDistributionLists" });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleAddRecipient() {
            if (newRecipient.value && formValid.value) {
                formObj.value.external_email_recipients.push(newRecipient.value);
                newRecipient.value = "";
            }
        }
        function handleRemoveRecipient(email) {
            formObj.value.external_email_recipients = formObj.value.external_email_recipients.filter(function (v) { return v !== email; });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, response;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = firms;
                        return [4 /*yield*/, (0, firm_1.getFirms)()];
                    case 1:
                        _a.value = _c.sent();
                        if (!isNew.value) return [3 /*break*/, 2];
                        formObj.value.firm_group = (_b = authStore.selectedFirmGroup) === null || _b === void 0 ? void 0 : _b.id;
                        if (firms.value.length === 1) {
                            formObj.value.fingerprint_firm = firms.value[0].id;
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, (0, reporting_1.getReportDistributionList)(props.id).catch(function () { return null; })];
                    case 3:
                        response = _c.sent();
                        reportDistribution.value = (response === null || response === void 0 ? void 0 : response.data) || {};
                        formObj.value = __assign({}, (reportDistribution.value || {}));
                        _c.label = 4;
                    case 4:
                        displayForm.value = true;
                        return [2 /*return*/];
                }
            });
        }); });
        return { __sfc: true, router: router, authStore: authStore, canAddReportDistributionList: canAddReportDistributionList, canChangeReportDistributionList: canChangeReportDistributionList, firmLabelObject: firmLabelObject, props: props, form: form, firms: firms, reportDistribution: reportDistribution, displayForm: displayForm, formObj: formObj, formValid: formValid, newRecipient: newRecipient, rules: rules, isNew: isNew, handleSave: handleSave, handleAddRecipient: handleAddRecipient, handleRemoveRecipient: handleRemoveRecipient };
    }
});
