import PolicyDocument from "@/components/policy/PolicyDocument.vue";

const routes = [
  {
    path: "/policy-document/",
    name: "PolicyDocument",
    component: PolicyDocument,
    meta: { checkAccountFeatures: (authStore) => authStore.canShowPolicy },
  },
];

export default routes;
