import { Translations } from "@aws-amplify/ui-components";

Translations.CONFIRM_TOTP_CODE = "Enter code from your Authenticator App";
Translations.TOTP_ISSUER = "Fingerprint Supervision";
if (process.env.VUE_APP_TOTP_NAME_SUFFIX) {
  Translations.TOTP_ISSUER += ` (${process.env.VUE_APP_TOTP_NAME_SUFFIX})`;
}
Translations.TOTP_HEADER_TEXT =
  "Scan the QR code with your Authenticator App, then enter the verification code.";
Translations.USERNAME_LABEL = "Username or email *";
Translations.USERNAME_PLACEHOLDER = "Enter your username or your email";
