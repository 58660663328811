<template>
  <div>
    <v-dialog v-model="dialogEdit" max-width="80%" data-html2canvas-ignore="true">
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogEditTitle }}
        </v-card-title>
        <v-card-text v-if="dialogEdit">
          <v-text-field
            v-if="dialogEditType === 'text-field'"
            v-model="dialogEditValue"
            :label="dialogEditTitle"
            autofocus
            :prepend-icon="dialogEditField ? 'mdi-history' : undefined"
            @click:prepend="
              dialogEditField &&
                openDisplayFieldHistoryDialog(
                  'report',
                  report.id,
                  dialogEditField,
                  (value) => (dialogEditValue = value),
                )
            "
          />
          <v-textarea
            v-if="dialogEditType === 'textarea'"
            v-model="dialogEditValue"
            :label="dialogEditTitle"
            autofocus
            :prepend-icon="dialogEditField ? 'mdi-history' : undefined"
            @click:prepend="
              dialogEditField &&
                openDisplayFieldHistoryDialog(
                  'report',
                  report.id,
                  dialogEditField,
                  (value) => (dialogEditValue = value),
                )
            "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogEdit = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="dialogEditConfirm"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditReportItem" max-width="80%" data-html2canvas-ignore="true">
      <v-card>
        <v-card-title>
          Update report item details
          <v-spacer />
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  v-model="dialogEditReportItemItem.is_printable"
                  :label="`Click to ${
                    dialogEditReportItemItem.is_printable ? 'hide from' : 'print to'
                  } PDF`"
                  hide-details
                />
              </span>
            </template>
            <span>Printable on PDF</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text v-if="dialogEditReportItem">
          <v-text-field
            v-model="dialogEditReportItemItem.title"
            label="Title"
            autofocus
            :rules="[(v) => !!v || 'This field is required']"
          />
          <v-textarea
            v-model="dialogEditReportItemItem.commentary_pre"
            label="Commentary pre"
            prepend-icon="mdi-history"
            @click:prepend="
              openDisplayFieldHistoryDialog(
                'item',
                dialogEditReportItemItem.id,
                'commentary_pre',
                (value) => (dialogEditReportItemItem.commentary_pre = value),
              )
            "
          />
          <v-textarea
            v-model="dialogEditReportItemItem.commentary_post"
            label="Commentary post"
            prepend-icon="mdi-history"
            @click:prepend="
              openDisplayFieldHistoryDialog(
                'item',
                dialogEditReportItemItem.id,
                'commentary_post',
                (value) => (dialogEditReportItemItem.commentary_post = value),
              )
            "
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="dialogEditReportItemItem.row"
                label="Display on row"
                type="number"
                min="1"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="dialogEditReportItemItem.col"
                label="Display on column"
                type="number"
                min="1"
                readonly
                disabled
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogEditReportItem = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="dialogEditReportItemConfirm"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <FieldHistoryListDialog
      v-model="dialogDisplayFieldHistory"
      :model="fieldHistoryModel"
      :model-id="fieldHistoryId"
      :field="fieldHistoryField"
      @selected-value="handleSelectedValue"
    />

    <v-dialog v-model="dialogConfirmPublish" max-width="350" persistent>
      <v-card>
        <v-card-title> Confirm Report Publishing? </v-card-title>
        <v-card-text>
          This report will be published to all subscribers and marked as 'Published'.
          <br />
          Are you sure?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogConfirmPublish = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="handlePublish"> Confirm Publish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmUnpublish" max-width="350" persistent>
      <v-card>
        <v-card-title> Undo Report Publishing? </v-card-title>
        <v-card-text>
          This report will be un-published, you can make changes to it and the publish it again.
          <br />
          This action will be logged, are you sure?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogConfirmUnpublish = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="handleUnpublish"> Confirm Unpublish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-toolbar data-html2canvas-ignore="true" color="transparent" flat>
      <v-text-field :value="periodType" label="Period type" hide-details readonly />
      <v-text-field v-model="periodValue" label="Period value" hide-details class="ml-3" readonly />
      <v-spacer />
      <v-btn class="mx-2" :disabled="publishingReport" @click="handlePdfExport"> Print </v-btn>
      <v-btn
        v-if="canPublishReport && !isPublished"
        class="mx-2"
        color="secondary"
        :disabled="publishingReport"
        @click="dialogConfirmPublish = true"
      >
        Publish{{ publishingReport ? "ing..." : "" }}
      </v-btn>
      <v-btn
        v-if="canUnpublishReport && isPublished"
        class="mx-2"
        color="warning"
        @click="dialogConfirmUnpublish = true"
      >
        Undo Publish
      </v-btn>
    </v-toolbar>

    <div class="pa-5">
      <div v-if="report" ref="elementToPrint" :style="`width:${width};`">
        <v-row no-gutters>
          <v-col>
            <h1 class="text-h3" style="margin-top: 0">
              {{ report.title || "Report title" }}
              <v-tooltip v-if="!isPublished" data-html2canvas-ignore="true" right>
                <template #activator="{ on, attrs }">
                  <v-btn
                    data-html2canvas-ignore="true"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      openDialogEdit(
                        'text-field',
                        'Change report title',
                        report.title,
                        handleSaveReportField('title'),
                        '', // 'title',
                      )
                    "
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </h1>
            <div class="text-subtitle-1">
              <span>Account: {{ account?.account_name }}</span>
              <span v-if="firm">, Firm: {{ firm?.firm_name }}</span>
            </div>
            <div class="text-subtitle-1">
              <div>
                Modified<span v-if="report.modified_by">
                  by: {{ report.modified_by.full_name }},</span
                >
                on {{ fullDateTimeFormat(report.modified_on) }}
              </div>
            </div>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-img
              v-if="reportLogoBase64"
              :src="`data:image/png;base64,${reportLogoBase64}`"
              contain
              max-height="150"
              max-width="150"
            />
          </v-col>
        </v-row>

        <div v-if="report.report_config.default_banner">
          <v-divider />
          <div class="text-body-2">
            {{ report.report_config.default_banner }}
          </div>
        </div>

        <div :data-html2canvas-ignore="report.summary_pre ? undefined : 'true'">
          <v-divider />
          <h3 class="text-h6">
            Summary
            <v-tooltip v-if="!isPublished" data-html2canvas-ignore="true" right>
              <template #activator="{ on, attrs }">
                <v-btn
                  data-html2canvas-ignore="true"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    openDialogEdit(
                      'textarea',
                      'Change summary text',
                      report.summary_pre,
                      handleSaveReportField('summary_pre'),
                      'summary_pre',
                    )
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </h3>
          <div class="text-body-2">
            {{ report.summary_pre }}
          </div>
        </div>

        <section
          v-for="(item, index) of sortedReportItems"
          :key="index"
          :class="{
            greyed: !item.is_printable,
            'report-item': true,
          }"
          :data-html2canvas-ignore="!item.is_printable"
        >
          <StoredReportItem
            :is-published="isPublished"
            :report-item="item"
            :json-data="item.jsonData"
            :period-options="
              canShowPeriodOptions(item) && {
                periodType: report.default_item_period_type,
                periodValue: report.default_item_period_value,
              }
            "
            @change-item-values="openDialogEditReportItem(item, handleSaveReportItemFields(item))"
            @change-report-item-parameters="handleChangeReportItemParameters(item, $event)"
          />
        </section>

        <!-- <v-row
          v-for="(row, rowIndex) of reportItemsTable"
          :key="rowIndex"
          class="items-row"
        >
          <v-col
            v-for="(col, colIndex) of row"
            :key="`${rowIndex}${colIndex}`"
            :cols="12 / row.length"
            class="items-col"
          >
            <StoredReportItem
              :report-item="col"
              :json-data="col.jsonData"
              @change-item-values="openDialogEditReportItem(
                col,
                handleSaveReportItemFields(col),
              )"
            />
          </v-col>
        </v-row> -->

        <v-divider />
        <h3 class="text-h6" :data-html2canvas-ignore="report.summary_post ? undefined : 'true'">
          Final comments
          <v-tooltip v-if="!isPublished" data-html2canvas-ignore="true" right>
            <template #activator="{ on, attrs }">
              <v-btn
                data-html2canvas-ignore="true"
                icon
                v-bind="attrs"
                v-on="on"
                @click="
                  openDialogEdit(
                    'textarea',
                    'Change final comments',
                    report.summary_post,
                    handleSaveReportField('summary_post'),
                    'summary_post',
                  )
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </h3>
        <div class="text-body-2">
          {{ report.summary_post }}
        </div>

        <div v-if="report.report_config.default_signoff">
          <v-divider />
          <div class="text-body-2">
            {{ report.report_config.default_signoff }}
          </div>
        </div>

        <div v-if="report.report_config.marketing_text">
          <v-divider />
          <div class="text-body-2">
            {{ report.report_config.marketing_text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import { mapState } from "pinia";
import EventBus from "@/eventBus";
import { fullDateTimeFormat } from "@/filters";
import { getAccount } from "@/repositories/account";
import { getFirm } from "@/repositories/firm";
import {
  getReportingReport,
  getReportSettingsLogoBase64,
  patchReportingReport,
  patchReportingReportItem,
  publishReport,
  unpublishReport,
} from "@/repositories/reporting";
import { useAuthStore } from "@/stores/auth";
// import { createReportingTableFromList } from '@/utils';
import FieldHistoryListDialog from "./FieldHistoryListDialog.vue";
import StoredReportItem from "./StoredReportItem.vue";

const PDF_MARGIN_BOTTOM = 104.03;
const PDF_MARGIN_LEFT = 53.85;
const PDF_MARGIN_RIGHT = 37.42;
const PDF_MARGIN_TOP = 53.85;

export default {
  name: "StoredReportDetail",
  components: {
    FieldHistoryListDialog,
    StoredReportItem,
  },
  data() {
    return {
      dialogEdit: false,
      dialogEditType: "",
      dialogEditTitle: "",
      dialogEditValue: "",
      dialogEditHandler: null,
      dialogEditField: "",
      dialogEditReportItem: false,
      dialogEditReportItemItem: null,
      dialogEditReportItemHandler: null,
      dialogDisplayFieldHistory: false,
      dialogDisplayFieldHistoryHandler: null,
      dialogConfirmPublish: false,
      dialogConfirmUnpublish: false,
      fieldHistoryModel: "",
      fieldHistoryId: "",
      fieldHistoryField: "",
      width: "504.01pt",
      report: null,
      reportLogoBase64: null,
      reportItems: [],
      periodType: "all",
      periodValue: null,
      publishingReport: false,
      fixOriginalStyles: [],
      fixIgnoredElements: [],
      account: null,
      firm: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canPublishReport", "canUnpublishReport"]),
    isPublished() {
      return this.report?.report_status_display === "Published";
    },
    sortedReportItems() {
      return [...this.reportItems].sort((a, b) => {
        if (!a.row) {
          return 1;
        }
        if (!b.row) {
          return -1;
        }
        return a.row < b.row ? -1 : 1;
      });
    },
    // reportItemsTable() {
    //   return createReportingTableFromList(this.reportItems);
    // },
  },
  mounted() {
    this.loadReport();
  },
  methods: {
    fullDateTimeFormat,
    canShowPeriodOptions(item) {
      return (
        [
          "TodosMetricsByPeriod",
          "ItemsByChannelByPeriod",
          "ItemsByFirmByPeriod",
          "ItemsBySuspiciousTermByPeriod",
          "ItemsDistributionByPeriod",
          "ProcessedItemsByUserByPeriod",
          "CaseCategoriesForPeriod",
          "CasesOpenedClosedByPeriod",
          // "ChannelVNoActionReasonByPeriod",
          // "TodoByNoActionReasonByPeriod",
          // "CategoryVNoActionReasonByPeriod",
          "ItemsBurndownByPeriod",
          "SearchActivityByUserByPeriod",
          "ItemProcessedOutcomeByPeriod",
          "TimeBeforeItemReviewByPeriod",
          "CaseWorkflowProgress",
        ].includes(item.report_item_type) || undefined
      );
    },
    async loadReport() {
      try {
        let response = await getReportingReport(this.$route.params.id);
        this.report = { ...response.data };
        this.periodType = this.report.default_item_period_type;
        this.periodValue = this.report.default_item_period_value;
        if (this.report.report_config.logo_url) {
          this.reportLogoBase64 = await getReportSettingsLogoBase64(this.report.report_config.id);
        }

        this.reportItems = this.report.items.map((item) => {
          const jsonData = item?.data?.data;
          return {
            ...item,
            jsonData,
          };
        });

        response = await getAccount(this.report.account);
        this.account = response.data;
        if (this.report.fingerprint_firm) {
          response = await getFirm(this.report.fingerprint_firm);
          this.firm = response.data;
        }
      } catch {
        this.report = null;
      }
    },
    fixOriginalStylesPre() {
      this.originalStyles = [];
      this.ignoredElements = document.querySelectorAll("[data-html2canvas-ignore]");
      // saving original style.display
      this.ignoredElements.forEach((el) => {
        this.originalStyles.push(el.style.display);
        el.style.display = "none";
      });
    },
    fixOriginalStylesPost() {
      // restoring original style.display
      this.ignoredElements.forEach((el, i) => {
        el.style.display = this.originalStyles[i];
      });
    },
    getPdfWorker() {
      /**
       * A4 page is (210x297)mm, (595.28x841.89)pt including margins
       * Standard margins are:
       * - top: 19mm, 53.85pt
       * - right: 13.2mm, 37.42pt
       * - bottom: 36.7mm, 104.03pt
       * - left: 19mm, 53.85pt
       * Computed width is: 210 - 13.2 - 19 = 177.8mm = 595.28 - 37.42 - 53.85 = 504.01pt
       */
      const html2PdfBase = html2pdf()
        .set({
          margin: [PDF_MARGIN_TOP, PDF_MARGIN_LEFT, PDF_MARGIN_BOTTOM, PDF_MARGIN_RIGHT],
          filename: "myfile.pdf",
          image: { type: "jpeg", quality: 0.98 },
          enableLinks: false,
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        })
        .from(this.$refs.elementToPrint);
      // creating file from html2PdfBase output
      // you can upload with using FormData
      // https://github.com/eKoopmans/html2pdf.js/issues/271
      const worker = html2PdfBase
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            this.printPdfHeadersFooters(pdf, i, totalPages);
          }
          return pdf;
        });

      return worker;
    },
    async handlePdfExport() {
      this.fixOriginalStylesPre();
      const worker = this.getPdfWorker();
      await worker.save();
      this.fixOriginalStylesPost();
    },
    async handlePublish() {
      this.dialogConfirmPublish = false;
      this.publishingReport = true;
      this.fixOriginalStylesPre();
      const worker = this.getPdfWorker();
      const pdf = await worker;

      // using blob output to upload generated pdf to a webservice
      const blob = pdf.output("blob");
      const file = new File([blob], "myfile.pdf", { type: "application/pdf" });
      try {
        await publishReport(this.report.id, file);
        await this.loadReport();
      } catch (error) {
        EventBus.$emit("notify", "error", error);
      }
      this.publishingReport = false;
      this.fixOriginalStylesPost();
    },
    async handleUnpublish() {
      this.dialogConfirmUnpublish = false;
      try {
        await unpublishReport(this.report.id);
        this.loadReport();
      } catch (error) {
        EventBus.$emit("notify", "error", error);
      }
    },
    printPdfHeadersFooters(pdf, page, totalPages) {
      [
        {
          x: PDF_MARGIN_LEFT,
          y: PDF_MARGIN_TOP * 0.7,
          align: "left",
          text: this.report.report_config.header_left,
        },
        {
          x: pdf.internal.pageSize.getWidth() / 2,
          y: PDF_MARGIN_TOP * 0.7,
          align: "center",
          text: this.report.report_config.header_centre,
        },
        {
          x: pdf.internal.pageSize.getWidth() - PDF_MARGIN_RIGHT,
          y: PDF_MARGIN_TOP * 0.7,
          align: "right",
          text: this.report.report_config.header_right,
        },
        {
          x: PDF_MARGIN_LEFT,
          y: pdf.internal.pageSize.getHeight() - PDF_MARGIN_BOTTOM / 2,
          align: "left",
          text: this.report.report_config.footer_left,
        },
        {
          x: pdf.internal.pageSize.getWidth() / 2,
          y: pdf.internal.pageSize.getHeight() - PDF_MARGIN_BOTTOM / 2,
          align: "center",
          text: this.report.report_config.footer_centre,
        },
        {
          x: pdf.internal.pageSize.getWidth() - PDF_MARGIN_RIGHT,
          y: pdf.internal.pageSize.getHeight() - PDF_MARGIN_BOTTOM / 2,
          align: "right",
          text: this.report.report_config.footer_right,
        },
      ].forEach((item) => {
        pdf.text(this.getPredefinedText(item.text, page, totalPages), item.x, item.y, {
          align: item.align,
        });
      });
    },
    getPredefinedText(text, page, totalPages) {
      const date = new Date();
      return text
        .replace(/{{date}}/g, date.toLocaleDateString())
        .replace(/{{time}}/g, date.toLocaleTimeString())
        .replace(/{{pageNumber}}/g, page)
        .replace(/{{totalPages}}/g, totalPages);
    },
    openDialogEdit(type, title, destination, handler, field) {
      this.dialogEditType = type;
      this.dialogEditTitle = title;
      this.dialogEditValue = destination;
      this.dialogEditHandler = handler;
      this.dialogEditField = field;
      this.dialogEdit = true;
    },
    openDialogEditReportItem(item, handler) {
      this.dialogEditReportItemItem = {
        id: item.id,
        is_printable: item.is_printable,
        title: item.title,
        commentary_pre: item.commentary_pre,
        commentary_post: item.commentary_post,
        row: item.row,
        col: item.col,
      };
      this.dialogEditReportItemHandler = handler;
      this.dialogEditReportItem = true;
    },
    async handleChangeReportItemParameters(item, parameters) {
      const patchedItem = {
        id: item.id,
        parameters,
      };
      try {
        const newItem = await this.innerPatchReportingReportItem(patchedItem);
        Object.assign(item, newItem);
      } catch (e) {
        /* empty */
      }
    },
    async innerPatchReportingReportItem(reportItem) {
      try {
        const { data: patched } = await patchReportingReportItem(reportItem);
        return patched;
      } catch (err) {
        EventBus.$emit("notify", "warn", err);
        return null;
      }
    },
    async dialogEditConfirm() {
      await this.dialogEditHandler();
      this.dialogEdit = false;
    },
    async dialogEditReportItemConfirm() {
      await this.dialogEditReportItemHandler();
      this.dialogEditReportItem = false;
    },
    handleSaveReportField(field) {
      return async () => {
        try {
          await patchReportingReport({
            id: this.report.id,
            [field]: this.dialogEditValue,
          });
          this.report[field] = this.dialogEditValue;
        } catch (err) {
          EventBus.$emit("notify", "warn", err);
        }
      };
    },
    handleSaveReportItemFields(item) {
      return async () => {
        const patchedItem = {
          id: item.id,
          ...this.dialogEditReportItemItem,
          row: this.dialogEditReportItemItem.row
            ? parseInt(this.dialogEditReportItemItem.row, 10)
            : null,
          col: this.dialogEditReportItemItem.col
            ? parseInt(this.dialogEditReportItemItem.col, 10)
            : null,
        };
        const newItem = await this.innerPatchReportingReportItem(patchedItem);
        Object.assign(item, newItem);
      };
    },
    openDisplayFieldHistoryDialog(model, id, field, handler) {
      this.fieldHistoryModel = model;
      this.fieldHistoryId = id;
      this.fieldHistoryField = field;
      this.dialogDisplayFieldHistory = true;
      this.dialogDisplayFieldHistoryHandler = handler;
    },
    handleSelectedValue(value) {
      this.dialogDisplayFieldHistoryHandler && this.dialogDisplayFieldHistoryHandler(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.greyed {
  opacity: 0.5;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
