<template>
  <div>
    <PageFrame>
      <template #title>
        <template v-if="pk">
          {{ query.name }}
        </template>
        <template v-else-if="caseObj"> Adhoc Search </template>
        <template v-else-if="randomSearch">Random Sample</template>
        <template v-else> New Search </template>
      </template>

      <template #crumb>
        <template v-if="pk">
          <router-link :to="{ name: 'SearchList' }"> Saved Search </router-link>
        </template>
        <template v-else-if="caseObj">
          <router-link :to="{ name: 'CaseDetail', params: { pk: caseObj.id } }">
            {{ caseObj.name }}
          </router-link>
        </template>
      </template>

      <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
        <v-textarea
          v-if="pk"
          v-model="query.name"
          auto-grow
          rows="1"
          label="Query Name"
          hint="Edit here the query name"
          prepend-icon="mdi-text-short"
          :rules="rules.name"
          name="name"
        >
          <template #prepend>
            <v-icon small> mdi-text-short </v-icon>
          </template>
        </v-textarea>

        <v-textarea
          v-if="pk"
          v-model="query.description"
          auto-grow
          rows="1"
          label="Query Description"
          hint="Edit here the query description"
          prepend-icon="mdi-text"
          :rules="rules.description"
          name="description"
        >
          <template #prepend>
            <v-icon small> mdi-text </v-icon>
          </template>
        </v-textarea>

        <v-alert v-if="randomSearch" type="info" text dismissible>
          A random search will return a random sample of data from the selected data sources.<br />
          Performing the same search multiple times will return different results.
        </v-alert>
        <v-row v-if="!randomSearch" align="center" dense width="85%">
          <v-col>
            <v-textarea
              v-model="query.query"
              auto-grow
              rows="1"
              label="Search query"
              hide-details
              prepend-icon="mdi-text"
              :rules="rules.query"
              autofocus
              name="query"
              class="search-query"
            >
              <template #prepend>
                <v-icon small> mdi-text </v-icon>
              </template>
            </v-textarea>
          </v-col>
          <v-col cols="auto">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              <template #default>
                Enter your specific search term or phrase here, for example:
                <blockquote>&nbsp;&nbsp;&nbsp;&nbsp; Vodafone or "Vodafone deal"</blockquote>
                <br />
                You can also enter a boolean expression, for example:
                <blockquote>
                  &nbsp;&nbsp;&nbsp;&nbsp; Vodafone OR VODA, (Vodafone OR VODA) AND (deal OR
                  agreement) NOT announced
                </blockquote>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>

        <div>
          <v-row dense class="mt-2">
            <v-col cols="auto">
              <v-input v-model="query" prepend-icon="mdi-calendar-start" :rules="rules.date_range">
                <template #prepend>
                  <v-icon small>mdi-calendar</v-icon>
                </template>
                <v-label class="mr-2" style="font-size: 14px">Date range</v-label>
                <DateTimeRangeSelect
                  :range-type="query.searchType"
                  :from-value="query.dt_from"
                  :to-value="query.dt_to"
                  :offset-type="OFFSET_TYPE_FRONTEND_MAPPING[query.dt_offset_type]"
                  :offset-value="query.dt_offset_count"
                  :limit-quick-select-end-now="true"
                  @update:rangeType="query = { ...query, searchType: $event }"
                  @update:fromValue="query = { ...query, dt_from: $event }"
                  @update:toValue="query = { ...query, dt_to: $event }"
                  @update:offsetType="
                    query = {
                      ...query,
                      dt_offset_type: OFFSET_TYPE_BACKEND_MAPPING[$event] || null,
                    }
                  "
                  @update:offsetValue="query = { ...query, dt_offset_count: $event }"
                />
              </v-input>
            </v-col>
          </v-row>
        </div>

        <v-select
          v-model="query.data_types"
          :items="dataTypes"
          multiple
          label="Data types"
          hint="Include or exclude different data sources to search across"
          persistent-hint
          small-chips
          deletable-chips
          item-value="id"
          item-text="name"
          prepend-icon="mdi-hexagon-multiple-outline"
          :rules="rules.dataTypes"
          class="tour-data-type"
        >
          <template #prepend>
            <v-icon small> mdi-hexagon-multiple-outline </v-icon>
          </template>
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAllDataTypes">
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
          <template #item="{ item, attrs }">
            <v-checkbox v-model="attrs.inputValue" />
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ item.name }}
          </template>
          <template #selection="{ item, parent }">
            <v-chip small close @click:close="parent.onChipInput(item)">
              <v-icon left small>
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>

        <v-select
          v-model="query.restrict_to_firms"
          :items="firmList"
          multiple
          :label="
            caseId
              ? `You can search only on the same ${firmLabelObject.singularLowercase} as the one selected in the Case`
              : firmLabelObject.pluralUppercase
          "
          :hint="`Select one or more ${firmLabelObject.pluralLowercase}`"
          small-chips
          deletable-chips
          item-value="id"
          item-text="firm_name"
          prepend-icon="mdi-office-building"
          :rules="rules.restrict_to_firms"
          class="tour-firms"
          :disabled="!!caseId"
        >
          <template #prepend>
            <v-icon small> mdi-office-building </v-icon>
          </template>
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAllFirms">
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>

        <v-expansion-panels v-model="advancedSearchExpandedPanelIndex">
          <v-expansion-panel class="transparent-expansion-panel">
            <v-expansion-panel-header>Advanced search</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center" dense>
                <v-col>
                  <v-select
                    v-model="query.fields"
                    :items="searchableFields"
                    multiple
                    label="Search specific Fields"
                    hint="Select one or more fields"
                    small-chips
                    deletable-chips
                    item-value="key"
                    item-text="label"
                    prepend-icon="mdi-tag-text-outline"
                    class="tour-fields"
                  >
                    <template #prepend>
                      <v-icon small>mdi-tag-text-outline</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <template #default>
                      Limits the search to specific fields. If no fields are selected, the search is
                      performed on all fields.
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row align="center" dense>
                <v-col>
                  <v-select
                    v-model="query.weighting_method"
                    :items="searchWeightingMethods"
                    label="Weighting Method"
                    item-value="key"
                    item-text="label"
                    prepend-icon="mdi-weight"
                    class="tour-weighting-method"
                  >
                    <template #prepend>
                      <v-icon small>mdi-weight</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <template #default>
                      <!-- https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-multi-match-query.html#multi-match-types -->
                      <ul>
                        <li>
                          Best Fields (Default)<br />
                          (default) Finds documents which match any field, but uses the _score from
                          the best field.
                        </li>
                        <li>
                          Bool Prefix<br />
                          Creates a match_bool_prefix query on each field and combines the _score
                          from each field.
                        </li>
                        <li>
                          Cross Fields<br />
                          Treats fields with the same analyzer as though they were one big field.
                          Looks for each word in any field.
                        </li>
                        <li>
                          Most Fields<br />
                          Finds documents which match any field and combines the _score from each
                          field.
                        </li>
                        <li>
                          Phrase<br />
                          Runs a match_phrase query on each field and uses the _score from the best
                          field.
                        </li>
                        <li>
                          Phrase Prefix<br />
                          Runs a match_phrase_prefix query on each field and uses the _score from
                          the best field.
                        </li>
                      </ul>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row align="center" dense>
                <v-col>
                  <v-select
                    v-model="query.analyser"
                    :items="searchAnalyzers"
                    label="Analyzer"
                    item-value="key"
                    item-text="label"
                    prepend-icon="mdi-text-search-variant"
                    class="tour-analyzer"
                  >
                    <template #prepend>
                      <v-icon small>mdi-text-search-variant</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="auto">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <template #default>
                      <!-- https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-analyzers.html -->
                      <ul>
                        <li>
                          Standard Analyzer<br />
                          Divides text into terms on word boundaries, as defined by the Unicode Text
                          Segmentation algorithm. It removes most punctuation, lowercases terms, and
                          supports removing stop words.
                        </li>
                        <li>
                          Simple Analyzer<br />
                          Divides text into terms whenever it encounters a character which is not a
                          letter. It lowercases all terms.
                        </li>
                        <li>
                          Whitespace Analyzer<br />
                          Divides text into terms whenever it encounters any whitespace character.
                          It does not lowercase terms.
                        </li>
                        <li>
                          Stop Analyzer<br />
                          Is like the simple analyzer, but also supports removal of stop words.
                        </li>
                        <li>
                          Keyword Analyzer<br />
                          Is a “noop” analyzer that accepts whatever text it is given and outputs
                          the exact same text as a single term.
                        </li>
                        <li>
                          Pattern Analyzer<br />
                          Uses a regular expression to split the text into terms. It supports
                          lower-casing and stop words.
                        </li>
                        <li>
                          Fingerprint Analyzer<br />
                          Is a specialist analyzer which creates a fingerprint which can be used for
                          duplicate detection.
                        </li>
                      </ul>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn type="submit" :disabled="!valid" class="mt-4 ml-5" color="primary">Search</v-btn>
        <v-btn
          v-if="pk"
          type="button"
          :disabled="!valid || !unsavedChanges"
          class="mt-4 ml-2"
          color="warning"
          @click="saveSearch"
        >
          Update
        </v-btn>
      </v-form>
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import DateTimeRangeSelect from "@/components/datetime-range/DateTimeRangeSelect.vue";
import {
  OFFSET_TYPE_BACKEND_MAPPING,
  OFFSET_TYPE_FRONTEND_MAPPING,
} from "@/components/datetime-range/types";
import EventBus from "@/eventBus";
import { getFirms } from "@/repositories/firm";
import searchRepository from "@/repositories/search";
import {
  getDataTypes,
  getSearchableFields,
  getSearchAnalyzers,
  getSearchWeightingMethods,
} from "@/repositories/settings";
import { getCase } from "@/repositories/case";
import { useAuthStore } from "@/stores/auth";
import { useStore } from "@/stores/main";
import PageFrame from "../PageFrame.vue";

const defaultWeightingMethod = "best_fields";
const defaultAnalyzer = "standard";

export default {
  name: "SearchForm",
  components: {
    PageFrame,
    DateTimeRangeSelect,
  },
  props: {
    randomSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      OFFSET_TYPE_BACKEND_MAPPING,
      OFFSET_TYPE_FRONTEND_MAPPING,
      advancedSearchExpandedPanelIndex: null,
      formObj: null,
      dataTypes: [],
      firmList: [{}],
      searchableFields: [],
      searchAnalyzers: [],
      searchWeightingMethods: [],
      searchData: null,
      caseObj: null,
      query: {
        query: "",
        searchType: "absolute",
        dt_from: new Date(new Date().setDate(new Date().getDate() - 5)),
        dt_to: new Date(),
        dt_offset_count: null,
        dt_offset_type: null,
        data_types: [],
        restrict_to_firms: [],
        fields: [],
        weighting_method: defaultWeightingMethod,
        analyser: defaultAnalyzer,
      },
      valid: true,
      searchType: "absolute", // used for validation only
      isAbsoluteSearch: true,
      validationEnabled: true,
      dataPeriods: [
        { id: "m", name: "Minutes" },
        { id: "h", name: "Hours" },
        { id: "d", name: "Days" },
        { id: "w", name: "Weeks" },
        { id: "M", name: "Months" },
        { id: "y", name: "Years" },
      ],
      periodFields: { text: "name", value: "id" },
      firmFields: { text: "firm_name", value: "id" },
      toolbarSettings: {
        items: ["moveTo", "moveFrom", "moveAllTo", "moveAllFrom"],
      },
      noRecordsTemplate: "",
      unsavedChanges: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["selectedFirmGroup", "firmLabelObject"]),
    pk() {
      return this.$route.params.pk;
    },
    caseId() {
      return this.$route.params.caseId;
    },
    rules() {
      return {
        name: [(v) => !!v || "Please enter a name for this query"],
        query: [(v) => !!v || "Please enter a query"],
        date_range: [
          ({ searchType, dt_from, dt_offset_count, dt_offset_type }) =>
            (searchType === "relative" && !!dt_offset_count && !!dt_offset_type) ||
            (searchType === "absolute" && !!dt_from) ||
            "Please enter a valid date range",
          ({ searchType, dt_from }) =>
            searchType === "relative" ||
            (searchType === "absolute" && !!dt_from) ||
            "Please enter a valid start date",
          ({ searchType, dt_from }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_from) <= new Date() ||
            "The start date is in the future",
          ({ searchType, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && !!dt_to) ||
            "Please enter a valid end date",
          ({ searchType, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_to <= new Date()) ||
            "The end date is in the future",
          ({ searchType, dt_from, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_from <= dt_to) ||
            "The start date is greater than the end date",
        ],
        dataTypes: [(v) => !!v.length || "Please select at least one data type"],
        restrict_to_firms: [
          (v) =>
            !!v.length || `Please select at least one ${this.firmLabelObject.singularLowercase}`,
          (v) =>
            v.length <= 10 ||
            `You can't select more than 10 ${this.firmLabelObject.pluralLowercase}`,
        ],
      };
    },
    firmSelected() {
      const firms = this.query.restrict_to_firms || [];
      return this.firmList.filter((item) => firms.includes(item.id));
    },
  },
  watch: {
    query: {
      deep: true,
      handler() {
        if (this.query.query) {
          this.query.query = this.query.query
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"');
        }
      },
    },
  },
  async mounted() {
    const [
      dataTypes,
      searchableFields,
      searchAnalyzers,
      searchWeightingMethods,
      firmList,
      searchData,
    ] = await Promise.all([
      getDataTypes(),
      getSearchableFields(),
      getSearchAnalyzers(),
      getSearchWeightingMethods(),
      this.getFirmList(),
      this.getSearchData(),
    ]);
    this.dataTypes = dataTypes;
    this.searchableFields = searchableFields;
    this.searchAnalyzers = searchAnalyzers;
    this.searchWeightingMethods = searchWeightingMethods;
    this.firmList = firmList;
    this.searchData = searchData;
    this.query = {
      ...this.query,
      data_types: this.dataTypes.map((dt) => dt.id),
      ...this.handleRouteQuery(),
    };
    // if only a single firm, automatically selects the firm
    if (this.firmList.length === 1) {
      this.query.restrict_to_firms = this.firmList.map((f) => f.id);
    }
    // if advanced search fields selected, then expand the "advanced search" panel
    if (
      this.query.fields?.length ||
      this.query.weighting_method !== defaultWeightingMethod ||
      this.query.analyser !== defaultAnalyzer
    ) {
      this.advancedSearchExpandedPanelIndex = 0; // `null` equals to "not expanded"
    }

    if (this.caseId) {
      this.caseObj = await this.getCase();
      const mainStore = useStore();
      mainStore.setCase(this.caseObj);
      this.query.restrict_to_firms = [this.caseObj.fingerprint_firm];
    }
    this.$watch(
      "query",
      () => {
        this.unsavedChanges = true;
      },
      { deep: true },
    );

    this.$refs.form.validate();
  },
  methods: {
    toggleSelectAllDataTypes() {
      if (this.query.data_types.length === this.dataTypes.length) {
        this.query.data_types = [];
      } else {
        this.query.data_types = this.dataTypes.map((f) => f.id);
      }
    },
    toggleSelectAllFirms() {
      if (this.query.restrict_to_firms.length === this.firmList.length) {
        this.query.restrict_to_firms = [];
      } else {
        this.query.restrict_to_firms = this.firmList.map((f) => f.id);
      }
    },
    async getFirmList() {
      try {
        const firms = await getFirms();
        return firms;
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    async getSearchData() {
      if (this.pk) {
        try {
          const r = await searchRepository.search(this.pk);
          return r.data;
        } catch (error) {
          EventBus.$emit("notify", "warn", error);
        }
      }
    },
    async getCase() {
      try {
        const r = await getCase(this.caseId);
        return r.data;
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    handleRouteQuery() {
      if (this.searchData) {
        return {
          name: this.searchData.name,
          description: this.searchData.description,
          query: this.searchData.query,
          dt_from: new Date(this.searchData.dt_from),
          dt_to: new Date(this.searchData.dt_to),
          dt_offset_count: this.searchData.dt_offset_count,
          dt_offset_type: this.searchData.dt_offset_type,
          data_types: this.searchData.data_types,
          restrict_to_firms: this.searchData.restrict_to_firms,
          searchType: this.searchData.dt_offset_type ? "relative" : "absolute",
          fields: this.searchData.fields,
          weighting_method: this.searchData.weighting_method,
          analyser: this.searchData.analyser,
        };
      } else if (this.$route.query && Object.keys(this.$route.query).length > 0) {
        return {
          ...this.$route.query,
          dt_offset_count:
            this.$route.query.dt_offset_count && parseInt(this.$route.query.dt_offset_count),
          dt_from: new Date(this.$route.query.dt_from),
          dt_to: new Date(this.$route.query.dt_to),
          data_types: [].concat(this.$route.query.data_types || []),
          restrict_to_firms: [].concat(this.$route.query.restrict_to_firms || []),
        };
      }
      return {};
    },
    inputHandler(args) {
      args.event.currentTarget.style.height = "19px";
      args.event.currentTarget.style.height = `${args.event.currentTarget.scrollHeight}px`;
    },
    handleSubmit() {
      let destRouteName = "SearchResults";
      if (this.caseObj) {
        destRouteName = "SearchResultsCase";
      } else if (this.randomSearch) {
        destRouteName = "RandomSearchResults";
      }
      return this.$router.push({
        name: destRouteName,
        query: {
          ...this.query,
          query: (!this.randomSearch && this.query.query) || undefined,
          firm_groups: [this.selectedFirmGroup?.id],
          case_id: this.caseObj ? this.caseObj.id : undefined,
          dt_from: this.query.searchType === "absolute" ? this.query.dt_from.toJSON() : undefined,
          dt_to: this.query.searchType === "absolute" ? this.query.dt_to.toJSON() : undefined,
          dt_offset_count:
            this.query.searchType === "relative" ? this.query.dt_offset_count : undefined,
          dt_offset_type:
            this.query.searchType === "relative" ? this.query.dt_offset_type : undefined,
        },
      });
    },
    async saveSearch() {
      try {
        await searchRepository.searchSave(this.query, this.pk);
        this.unsavedChanges = false;
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-query {
  &.v-input {
    font-size: large;

    ::v-deep(.v-label) {
      font-size: large;
    }
  }
}

.transparent-expansion-panel {
  background-color: transparent !important;
}

.control-label {
  padding-top: 7px;
  text-align: right;
  font-weight: 700;
}

::v-deep(.e-list-item) {
  height: 24px !important;
  line-height: 24px !important;
}

.e-btn-group,
.e-listboxtool-wrapper {
  margin-bottom: 6px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

label.e-btn {
  text-transform: capitalize;
  margin-right: 2px;
}

.e-btn-group input:checked + label.e-btn {
  background-color: #394bff;
  color: white;
}

.ico {
  font-family: "e-icons" !important;
  padding-right: 4px;
}

.ico-absolute:before {
  content: "\e421";
}

.ico-relative:before {
  content: "\ec08";
}

.ico-email:before {
  content: "\e194";
}

.ico-bbgmsg:before {
  content: "\e424";
}

.ico-bbgim:before {
  content: "\ec10";
}

.ico-voice:before {
  content: "\ec10";
}

.ico-slack:before {
  content: "\ec10";
}

.ico-teamspersonal:before {
  content: "\ec10";
}

.ico-teamscorporate:before {
  content: "\ec10";
}

.ico-question:before {
  content: "\e607";
}

.multiline textarea {
  height: 27px;
}
</style>
