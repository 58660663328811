import httpClient from "@/interceptors/axios";
import { withFirmGroup } from "./utils";

export async function getStatsSettings() {
  return httpClient.get("stats/usersettings/");
}

export async function getPeriodValues(period_type) {
  return httpClient.get("stats/periods/", { params: withFirmGroup({ period_type }) });
}

export async function getStatsTotals(payload, periodType = "all", periodValue = "all") {
  return httpClient.get(`stats/totals/`, {
    params: withFirmGroup({
      period_type: periodType,
      period_value: periodValue,
      ...payload,
    }),
  });
}

export async function getStatsTodoTotals(payload, periodType = "all", periodValue = "all") {
  return httpClient.get(`stats/totals_todo/`, {
    params: withFirmGroup({
      period_type: periodType,
      period_value: periodValue,
      ...payload,
    }),
  });
}

export async function getStatsCaseTotals(payload, periodType = "all", periodValue = "all") {
  return httpClient.get(`stats/totals_case/`, {
    params: withFirmGroup({
      period_type: periodType,
      period_value: periodValue,
      ...payload,
    }),
  });
}
