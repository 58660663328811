<template>
  <div>
    <h1>Risk Term History</h1>
    <v-timeline dense>
      <v-timeline-item v-for="(historyItem, index) of historyDiff" :key="index">
        <v-card class="mx-auto">
          <v-card-subtitle>
            {{ fullDateTimeFormat(historyItem.revision.date_created) }}
            <span v-if="historyItem.revision.user">
              - {{ historyItem.revision.user.full_name }}
            </span>
          </v-card-subtitle>
          <v-card-text class="text--primary">
            <span v-if="historyItem.old === null">
              <h3>Item created</h3>
              <p>
                Name: {{ historyItem.current.name }}
                <br />
                Query: {{ historyItem.current.query }}
                <br />
                Search weight: {{ searchWeightsComputed[historyItem.current.weighting] }}
                <br />
                Enabled: {{ !historyItem.current.is_deleted }}
              </p>
            </span>
            <span v-if="historyItem.old !== null">
              <h3>Item changed</h3>
              <p>
                <span v-if="historyItem.current.name !== historyItem.old.name">
                  Name:
                  <span class="striked">{{ historyItem.old.name }}</span>
                  --&gt; {{ historyItem.current.name }}
                  <br />
                </span>
                <span v-if="historyItem.current.query !== historyItem.old.query">
                  Query:
                  <span class="striked">{{ historyItem.old.query }}</span>
                  --&gt; {{ historyItem.current.query }}
                  <br />
                </span>
                <span v-if="historyItem.current.weighting !== historyItem.old.weighting">
                  Search weight:
                  <span class="striked">{{
                    searchWeightsComputed[historyItem.old.weighting]
                  }}</span>
                  --&gt; {{ searchWeightsComputed[historyItem.current.weighting] }}
                  <br />
                </span>
                <span v-if="historyItem.current.is_public !== historyItem.old.is_public">
                  Is Public:
                  <span class="striked">{{ historyItem.old.is_public }}</span>
                  --&gt; {{ historyItem.current.is_public }}
                  <br />
                </span>
                <span v-if="historyItem.current.is_deleted !== historyItem.old.is_deleted">
                  Enabled:
                  <span class="striked">{{ !historyItem.old.is_deleted }}</span>
                  --&gt; {{ !historyItem.current.is_deleted }}
                </span>
              </p>
            </span>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { fullDateTimeFormat } from "@/filters";
import searchRepository from "@/repositories/search";
import { getSearchWeightList } from "@/repositories/settings";

export default {
  name: "SuspiciousTermHistory",
  data() {
    return {
      history: [],
      searchWeights: [],
    };
  },
  computed: {
    historyDiff() {
      return this.history.map((h, i, arr) => ({
        revision: h.revision,
        current: { ...h.field_dict },
        old: arr[i + 1]?.field_dict ? { ...arr[i + 1]?.field_dict } : null,
      }));
    },
    searchWeightsComputed() {
      return this.searchWeights.reduce(
        (acc, w) => ({
          ...acc,
          [w.id]: w.name,
        }),
        {},
      );
    },
  },
  async mounted() {
    const [response1, response2] = await Promise.all([
      getSearchWeightList(),
      searchRepository.getTermHistory(this.$route.params.id),
    ]);
    this.searchWeights = response1;
    this.history = response2.data;
  },
  methods: {
    fullDateTimeFormat,
  },
};
</script>

<style scoped>
.striked {
  text-decoration: line-through;
}
</style>
