<template>
  <div>
    <v-dialog v-model="deleteConfirmationDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Confirm Delete</v-card-title>
        <v-card-text>
          <div>Are you sure you want to delete this item?</div>
          <div>Field Name: &quot;{{ itemToDelete?.field_name }}&quot;</div>
          <div>Field Values: &quot;{{ itemToDelete?.field_values.join(", ") }}&quot;</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelDeleteItem">Cancel</v-btn>
          <v-btn color="error" @click="confirmDeleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PageFrame>
      <template #title>Auto Ignore Rules</template>

      <template #actions>
        <v-btn v-if="canAddTriageRules" :to="{ name: 'TriageAutoIgnoreRulesEdit' }">
          <v-icon left>mdi-plus</v-icon>
          Add New
        </v-btn>
      </template>

      <v-data-table
        :loading="showLoading"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
        }"
        @update:options="handleTableUpdateOptions"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom v-if="item.permissions.change">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                icon
                small
                v-on="on"
                :to="{ name: 'TriageAutoIgnoreRulesEdit', params: { id: item.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.permissions.delete">
            <template v-slot:activator="{ on }">
              <v-btn :disabled="showLoading" icon small v-on="on" @click="handleDeleteClick(item)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:item.field_name="{ item }">
          {{ fieldNames.find((f) => f.id === item.field_name)?.name }}
        </template>
        <template v-slot:item.field_values="{ item }">
          {{ item.field_values.join(", ") }}
        </template>
        <template v-slot:item.created_by="{ item }">
          {{ item.created_by.full_name }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import PageFrame from "@/components/PageFrame.vue";
import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { getFieldList } from "@/repositories/settings";
import { useAuthStore } from "@/stores/auth";
import { vuetifyGridOptionsToQueryParams } from "@/utils";

export default {
  name: "TodoRuleList",
  components: { PageFrame },
  data() {
    return {
      showLoading: false,
      lastFilterOptions: {},
      headers: [
        { value: "id", text: "Actions", sortable: false },
        { value: "field_name", text: "Field Name" },
        { value: "field_values", text: "Field Values" },
        { value: "created_by", text: "Owner" },
      ],
      items: [],
      itemsCount: 0,
      fieldNames: [],
      itemToDelete: null,
      deleteConfirmationDialog: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canAddTriageRules"]),
  },
  async mounted() {
    try {
      this.fieldNames = await getFieldList();
    } catch (error) {
      console.error("loadFieldNames error", error);
      EventBus.$emit("notify", "warn", error);
    }
  },
  methods: {
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      if (options) {
        this.lastFilterOptions = options;
      }
      const state = vuetifyGridOptionsToQueryParams(this.lastFilterOptions);
      const { data } = await caseRepository.todoRuleList({
        ...state,
        source: "user",
      });
      this.items = data.results;
      this.itemsCount = data.count;
      this.showLoading = false;
    },
    handleDeleteClick(item) {
      this.itemToDelete = item;
      this.deleteConfirmationDialog = true;
    },
    async confirmDeleteItem() {
      await caseRepository.todoRuleDelete(this.itemToDelete.id);
      this.cancelDeleteItem();
      this.handleTableUpdateOptions();
    },
    cancelDeleteItem() {
      this.itemToDelete = null;
      this.deleteConfirmationDialog = false;
    },
  },
};
</script>
