<template>
  <div>
    <v-card flat>
      <v-card-title>Risk Terms</v-card-title>
      <v-card-subtitle>
        <v-checkbox
          v-model="includeDisabled"
          label="Include disabled items"
          @change="handleDataStateChange()"
        />
      </v-card-subtitle>
    </v-card>
    <BasicList
      v-if="columns.length"
      :columns="columns"
      :data-source="dataSource"
      :grid-params="{
        allowPaging: true,
        pageSettings: pageSettings,
        allowSorting: true,
        allowResizing: true,
        autoFitColumns: true,
        selectionSettings: { enableToggle: false },
        allowEditing: true,
        editSettings: editSettings,
        toolbar: ['Add', 'Edit', 'ExcelExport', 'CsvExport', 'Print'],
        gridLines: 'Both',
        dataStateChange: handleDataStateChange,
        rowSelected: handleRowSelected,
        actionBegin: handleActionBegin, // used for saving data
        actionComplete: handleActionComplete, // used for resizing edit dialog
        loadingIndicator: {
          indicatorType: 'Shimmer',
        },
      }"
    />
  </div>
</template>

<script>
import { DataManager } from "@syncfusion/ej2-data";
import { MultiSelect } from "@syncfusion/ej2-vue-dropdowns";
import { TextBox } from "@syncfusion/ej2-inputs";
import BasicList from "@/components/common/BasicList.vue";
import searchRepository, { getTerms } from "@/repositories/search";
import SuspiciousTermActions from "./columns/SuspiciousTermActions.vue";
import {
  getSearchableFields,
  getSearchAnalyzers,
  getSearchWeightingMethods,
} from "@/repositories/settings";
import { getSearchWeightList } from "@/repositories/settings";
import { getSuspiciousTermCategories } from "@/repositories/todo";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";
import { ejsGridArgsToQueryParams } from "@/utils";

export default {
  name: "SuspiciousTermList",
  components: { BasicList },
  data() {
    return {
      basePermissions: {},
      lastFilterArgs: {},
      includeDisabled: false,
      columns: [],
      lastState: {},
      dataSource: { result: [], count: 0 },
      pageSettings: {
        currentPage: 1,
        pageSize: 20,
        enableQueryString: false,
        pageSizes: [10, 20, 50, 100, "All"],
      },
      editSettings: {
        allowAdding: true,
        allowEditing: false,
        mode: "Dialog",
      },
    };
  },
  async mounted() {
    let searchWeights = [];
    let searchableFields = [];
    let searchAnalyzers = [];
    let searchWeightingMethods = [];
    let suspiciousTermCategories = [];
    try {
      const [
        _suspiciousTermCategories,
        searchWeightsResponse,
        permissionsResponse,
        _searchableFields,
        _searchAnalyzers,
        _searchWeightingMethods,
      ] = await Promise.all([
        getSuspiciousTermCategories(),
        getSearchWeightList(),
        searchRepository.searchPermission(),
        getSearchableFields(),
        getSearchAnalyzers(),
        getSearchWeightingMethods(),
      ]);
      suspiciousTermCategories = _suspiciousTermCategories;
      searchWeights = searchWeightsResponse;
      searchableFields = _searchableFields;
      searchAnalyzers = _searchAnalyzers;
      searchWeightingMethods = _searchWeightingMethods;
      this.basePermissions = permissionsResponse.data;
      this.editSettings = {
        ...this.editSettings,
        allowAdding: this.basePermissions.add,
        allowEditing: this.basePermissions.edit,
        allowEditOnDblClick: this.basePermissions.edit,
      };
    } catch (error) {
      EventBus.$emit("notify", "warn", error);
    }
    let elemQuery;
    let textEditorQuery;
    let elemFields;
    let multiSelectFields;
    this.columns = [
      {
        field: "actions",
        headerText: "Actions",
        allowEditing: false,
        template: () => ({
          template: SuspiciousTermActions,
        }),
      },
      {
        field: "id",
        headerText: "ID",
        isPrimaryKey: true,
        visible: false,
      },
      {
        field: "suspicious_term_category",
        headerText: "Category",
        dataSource: new DataManager(suspiciousTermCategories),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        validationRules: { required: true },
      },
      {
        field: "name",
        headerText: "Name",
        validationRules: { required: true },
      },
      {
        field: "query",
        headerText: "Query",
        allowSorting: false,
        validationRules: { required: true },
        edit: {
          create: () => {
            elemQuery = document.createElement("textarea");
            return elemQuery;
          },
          read: () => {
            return textEditorQuery.value;
          },
          destroy: () => {
            textEditorQuery.destroy();
          },
          write: (args) => {
            textEditorQuery = new TextBox({
              multiline: true,
              value: args.rowData[args.column.field],
              floatLabelType: "Always",
              placeholder: "Query",
            });
            textEditorQuery.appendTo(elemQuery);
          },
        },
      },
      {
        field: "weighting",
        headerText: "Search Weight",
        dataSource: new DataManager(searchWeights),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        validationRules: { required: true },
      },
      {
        field: "fields",
        headerText: "Search specific Fields",
        allowSorting: false,
        valueAccessor: (_, data) => data.fields?.join(", "),
        edit: {
          create: function () {
            elemFields = document.createElement("input");
            return elemFields;
          },
          read: () => {
            return multiSelectFields.value;
          },
          destroy: () => {
            multiSelectFields.destroy();
          },
          write: (args) => {
            var data = args.rowData.fields;
            multiSelectFields = new MultiSelect({
              floatLabelType: "Always",
              placeholder: "Search specific Fields",
              value: typeof data === "string" ? args.rowData.fields.split() : data,
              fields: { text: "label", value: "key" },
              dataSource: new DataManager(searchableFields),
            });
            multiSelectFields.appendTo(elemFields);
          },
        },
      },
      {
        field: "weighting_method",
        headerText: "Weighting Method",
        dataSource: new DataManager(searchWeightingMethods),
        foreignKeyField: "key",
        foreignKeyValue: "label",
      },
      {
        field: "analyser",
        headerText: "Analyzer",
        dataSource: new DataManager(searchAnalyzers),
        foreignKeyField: "key",
        foreignKeyValue: "label",
      },
      {
        field: "created_by",
        headerText: "Owner",
        valueAccessor: (_, data) => data.created_by?.full_name,
        allowEditing: false,
      },
      {
        field: "is_deleted",
        headerText: "Disabled",
        editType: "booleanedit",
        type: "boolean",
        displayAsCheckBox: true,
      },
    ];
    this.handleDataStateChange();
  },
  methods: {
    async handleDataStateChange(args) {
      if (args) {
        this.lastFilterArgs = args;
      }
      const state = ejsGridArgsToQueryParams(this.lastFilterArgs);
      if (state.ordering) {
        state.ordering = state.ordering.replace(
          "suspicious_term_category",
          "suspicious_term_category__name",
        );
      }
      const { data } = await getTerms({
        ...state,
        is_deleted: this.includeDisabled ? undefined : false,
        source: "user",
      });
      this.dataSource = { count: data.count, result: data.results };
    },
    handleRowSelected(args) {
      this.editSettings = {
        ...this.editSettings,
        allowEditing: this.basePermissions.change && args.data.permissions.change,
        allowEditOnDblClick: this.basePermissions.change && args.data.permissions.change,
      };
    },
    async handleActionBegin(args) {
      if (args.requestType === "save" && ["add", "edit"].includes(args.action)) {
        args.cancel = true;
        const authStore = useAuthStore();
        const selectedGroupId = authStore.selectedFirmGroup?.id;
        await searchRepository.saveSuspiciousCategory(
          {
            name: args.data.name,
            query: args.data.query,
            suspicious_term_category: args.data.suspicious_term_category || undefined,
            weighting: args.data.weighting || undefined,
            is_deleted: args.data.is_deleted,
            fields: args.data.fields,
            weighting_method: args.data.weighting_method || undefined,
            analyser: args.data.analyser || undefined,
            firm_groups: args.data.firm_groups || (selectedGroupId && [selectedGroupId]),
          },
          args.data.id,
        );
        args.dialog && args.dialog.close();
        await this.handleDataStateChange(args.state);
      }
    },
    handleActionComplete(args) {
      // check for add/edit action and resizes the dialog width
      if (["add", "beginEdit"].includes(args.requestType) && args.dialog) {
        args.dialog.width = "85%";
      }
    },
  },
};
</script>

<style scoped>
::v-deep(.e-disable::before) {
  content: "\e825";
}

::v-deep(.e-enable::before) {
  content: "\e7ff";
}
</style>
