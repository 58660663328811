<template>
  <div>
    <div v-if="isLoading" class="d-flex d-flex justify-center">
      <v-progress-circular indeterminate size="24" />
    </div>
    <div v-else-if="dataError">
      <v-alert icon="mdi-alert" type="warning">
        {{ dataError }}
      </v-alert>
    </div>
    <v-timeline v-else-if="data.count > 0" class="my-timeline">
      <v-timeline-item
        v-for="(item, i) of data.results"
        :ref="`id_${item.id}`"
        :key="item.id"
        :icon="item.position === 'RIGHT' ? 'mdi-chevron-left' : 'mdi-chevron-right'"
        :left="item.position === 'LEFT'"
        :right="item.position === 'RIGHT'"
        fill-dot
        large
      >
        <template #icon>
          <v-icon color="background" large>
            {{ item.position === "RIGHT" ? "mdi-chevron-left" : "mdi-chevron-right" }}
          </v-icon>
        </template>
        <template #opposite>
          <div :class="{ 'text-right': item.position === 'RIGHT' }">
            <div>{{ fullDateTimeFormat(item.fingerprint_time) }}</div>
          </div>
        </template>
        <v-card :class="item.id === esId ? 'active-card' : ''">
          <v-card-title>
            <h4 v-obfuscate>
              {{ item.from_person }}
            </h4>
          </v-card-title>
          <v-card-subtitle>
            <small v-obfuscate>To: {{ item.to }}</small>
          </v-card-subtitle>
          <v-card-text>
            <small>
              <TranslateSelect
                v-if="languages && canShowTranslateChatTimeline"
                v-model="translateOptions"
                :languages="languages"
                :translated="item.translated_body !== undefined"
                @translate="translate(item, i)"
                @reset="handleResetTranslation(item, i)"
              />
              <!-- eslint-disable vue/no-v-html -->
              <p v-obfuscate class="black-text" v-html="redactItem(item)" />
              <v-btn v-if="isRedacted(item)" small @click="handleUnredact(item)">Un-redact</v-btn>
            </small>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <div>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="getTimeline"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import Vue from "vue";
import TranslateSelect from "@/components/common/TranslateSelect.vue";
import { logUnredactMessage } from "@/repositories/logs";
import { getChat, translate } from "@/repositories/search";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";
import { fullDateTimeFormat, redactPII } from "@/filters";

export default {
  name: "WhatsappTimeline",
  components: { TranslateSelect },
  props: {
    esId: {
      type: String,
      default: null,
    },
    conversationId: {
      type: String,
      default: null,
    },
    fingerprintClient: {
      type: String,
      default: null,
    },
    fingerprintTime: {
      type: String,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
    fromPerson: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      translateOptions: {
        fromLang: "en",
        toLang: "de",
      },
      isLoading: true,
      data: {
        count: 0,
        results: [],
      },
      dataError: null,
      page: 1,
      pageLength: 100,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canShowRedaction", "canShowTranslateChatTimeline"]),
    pageCount() {
      return Math.ceil(this.data.count / this.pageLength);
    },
  },
  async mounted() {
    await this.getTimeline();
    this.scrollToID();
  },
  methods: {
    fullDateTimeFormat,
    isRedacted(item) {
      return (
        this.canShowRedaction &&
        item.unredact === undefined &&
        redactPII(item.translated_body || item.body) !== (item.translated_body || item.body)
      );
    },
    redactItem(item) {
      const body = item.translated_body || item.body;
      if (this.canShowRedaction && item.unredact === undefined) {
        return redactPII(body);
      }
      return body;
    },
    handleUnredact(item) {
      Vue.set(item, "unredact", true);
      logUnredactMessage(item);
    },
    async getTimeline() {
      this.isLoading = true;
      this.dataError = null;
      try {
        const { data } = await getChat(
          "whatsapp",
          this.conversationId,
          this.fingerprintClient,
          this.fingerprintTime,
          this.page,
          this.pageLength,
        );
        this.data = {
          count: data.count,
          results: data.results.map((item) => {
            return {
              ...item,
              position: item.from_person === this.fromPerson ? "LEFT" : "RIGHT",
            };
          }),
        };
      } catch (error) {
        this.dataError =
          error?.response?.data?.detail || error?.response?.data || error?.message || error;
        console.error("getTimeline error", error);
      } finally {
        this.isLoading = false;
      }
    },
    async scrollToID() {
      await this.$nextTick();
      setTimeout(() => {
        if (this.$refs[`id_${this.esId}`]) {
          this.$refs[`id_${this.esId}`][0].$el.scrollIntoView();
        }
      }, 500);
    },
    async translate(item, index) {
      try {
        let r = await translate(
          "translation_chat_timeline",
          item.body,
          this.translateOptions.fromLang,
          this.translateOptions.toLang,
        );
        Vue.set(item, "translated_body", r.data.to_text);

        this.translateOtherItems(index);
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    async translateOtherItems(index) {
      const indexStart = Math.max(index - 5, 0);
      const indexEnd = Math.min(index + 5, this.data.results.length - 1);
      const range = [...Array(indexEnd - indexStart + 1).keys()]
        .map((i) => i + indexStart)
        .filter((i) => i !== index);
      for (let i of range) {
        try {
          let r = await translate(
            "translation_chat_timeline",
            this.data.results[i].body,
            this.translateOptions.fromLang,
            this.translateOptions.toLang,
          );
          Vue.set(this.data.results[i], "translated_body", r.data.to_text);
        } catch {
          /**
           * don't log other translation errors because they are in background
           */
        }
      }
    },
    handleResetTranslation(item, index) {
      Vue.set(item, "translated_body", undefined);
      this.resetOtherItemsTranslation(index);
    },
    resetOtherItemsTranslation(index) {
      const indexStart = Math.max(index - 5, 0);
      const indexEnd = Math.min(index + 5, this.data.results.length - 1);
      const range = [...Array(indexEnd - indexStart + 1).keys()]
        .map((i) => i + indexStart)
        .filter((i) => i !== index);
      for (let i of range) {
        Vue.set(this.data.results[i], "translated_body", undefined);
      }
    },
  },
};
</script>

<style scoped>
.v-timeline:before {
  left: 50%;
}
.my-timeline {
  min-width: 950px; /* less than this value, the button "translate" is displayed on a new line */
}
.black-text {
  color: black;
}
.active-card {
  background-color: #f2dede;
}
</style>
