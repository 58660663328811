<template>
  <div>
    <v-tabs>
      <v-tab :to="{ name: 'ReportingReports' }">
        <v-icon small class="mr-2"> mdi-folder-open-outline </v-icon>
        Pre-published reports
      </v-tab>
      <v-tab :to="{ name: 'ReportingReports', params: { status: 'published' } }">
        <v-icon small class="mr-2"> mdi-folder-outline </v-icon>
        Published reports
      </v-tab>
      <v-tab disabled>
        <v-progress-circular v-if="showLoading" indeterminate size="24" />
      </v-tab>
    </v-tabs>
    <v-data-table
      v-if="headers.length"
      :loading="showLoading"
      :headers="headers"
      :items="items"
      :items-per-page="25"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
      }"
      sort-by="modified_on"
      :sort-desc="true"
      :server-items-length="itemsCount"
      @update:options="handleTableUpdateOptions"
    >
      <template v-slot:item.actions="{ item }">
        <StoredReportsActions :inner-data="item" />
      </template>
      <template v-slot:item.report_schedule="{ item }">
        {{ item.report_schedule?.name }}
      </template>
      <template v-slot:item.report_status="{ item }">
        {{ item.report_status_display }}
      </template>
      <template v-slot:item.account__account_name="{ item }">
        {{ accounts.find((account) => account.id === item.account)?.account_name }}
      </template>
      <template v-slot:item.fingerprint_firm__firm_name="{ item }">
        {{ firms.find((firm) => firm.id === item.fingerprint_firm)?.firm_name }}
      </template>
      <template v-slot:item.created_on="{ item }">
        {{ mediumDateTimeFormat(item.created_on) }}
      </template>
      <template v-slot:item.modified_on="{ item }">
        {{ mediumDateTimeFormat(item.modified_on) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";

import { mediumDateTimeFormat } from "@/filters";
import { getFirms } from "@/repositories/firm";
import { getReportingReports } from "@/repositories/reporting";
import { useAccountsStore } from "@/stores/accounts";
import { useAuthStore } from "@/stores/auth";
import { vuetifyGridOptionsToQueryParams } from "@/utils";
import StoredReportsActions from "./columns/StoredReportsActions.vue";

export default {
  name: "StoredReports",
  components: {
    StoredReportsActions,
  },
  props: {
    published: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      firms: [],
      showLoading: false,
      headers: [],
      items: [],
      itemsCount: 0,
    };
  },
  computed: {
    ...mapState(useAccountsStore, ["accounts"]),
  },
  watch: {
    published() {
      this.handleTableUpdateOptions();
    },
  },
  async mounted() {
    const authStore = useAuthStore();
    const [, firms] = await Promise.all([this.getAccounts(), getFirms()]);
    this.firms = firms;
    this.headers = [
      {
        value: "actions",
        text: "Actions",
        sortable: false,
      },
      {
        value: "report_schedule",
        text: "Source Schedule",
        sortable: false,
      },
      {
        value: "report_status",
        text: "Report Status",
      },
      {
        value: "account__account_name",
        text: "Account",
      },
      {
        value: "fingerprint_firm__firm_name",
        text: authStore.firmLabelObject.singularUppercase,
      },
      {
        value: "default_item_period_type",
        text: "Period Type",
      },
      {
        value: "default_item_period_value",
        text: "Period Value",
      },
      {
        value: "created_on",
        text: "Created on",
      },
      {
        value: "modified_on",
        text: "Modified on",
      },
    ];
  },
  methods: {
    mediumDateTimeFormat,
    ...mapActions(useAccountsStore, ["getAccounts"]),
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      const state = vuetifyGridOptionsToQueryParams(options, 25, "-modified_on");
      try {
        const { data } = await getReportingReports({
          ...state,
          source: "user",
          published: this.published,
        });
        this.items = data.results;
        this.itemsCount = data.count;
      } catch (error) {
        this.items = [];
        this.itemsCount = 0;
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
