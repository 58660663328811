"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleSocketMessage = exports.closeWebsocketConnection = exports.initWebsocketConnection = void 0;
var aws_amplify_1 = require("aws-amplify");
var cases_1 = require("@/stores/cases");
var notifications_1 = require("@/stores/notifications");
var utils_1 = require("@/utils");
var ws = null;
var fibonacciGenerator = (0, utils_1.createFibonacciGenerator)();
var initWebsocketConnection = function () { return __awaiter(void 0, void 0, Promise, function () {
    var wsUrl, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = process.env.VUE_APP_WSS;
                _b = "?token=".concat;
                return [4 /*yield*/, aws_amplify_1.Auth.currentSession()];
            case 1:
                wsUrl = _a +
                    _b.apply("?token=", [(_c.sent()).getAccessToken().getJwtToken()]);
                (0, exports.closeWebsocketConnection)();
                ws = new WebSocket(wsUrl);
                ws.onopen = function () {
                    // eslint-disable-next-line no-console
                    console.info("WebSocket connection established");
                    // resets the fibonacci generator
                    fibonacciGenerator = (0, utils_1.createFibonacciGenerator)();
                };
                ws.onmessage = exports.handleSocketMessage;
                ws.onerror = function (e) {
                    console.error("WebSocket error observed:", e);
                };
                ws.onclose = function (e /*CloseEvent */) {
                    var delaySeconds = fibonacciGenerator.next().value || 1;
                    var delayMilliseconds = Math.min(delaySeconds, 60) * 1000;
                    // eslint-disable-next-line no-console
                    console.info("Socket is closed. Reconnect will be attempted in ".concat(delaySeconds, " seconds. Reason: ").concat(e.reason), e);
                    setTimeout(exports.initWebsocketConnection, delayMilliseconds);
                };
                return [2 /*return*/, ws];
        }
    });
}); };
exports.initWebsocketConnection = initWebsocketConnection;
var closeWebsocketConnection = function (code, reason) {
    /**
     * If the WebSocket connection is closed before the connection is established,
     * the WebSocket connection will be closed and a warning will be displayed.
     * This is a known issue and can be ignored.
     * ```
     * WebSocket connection to 'wss://...' failed:
     * WebSocket is closed before the connection is established.
     * ```
     */
    return ws && ws.close(code, reason);
};
exports.closeWebsocketConnection = closeWebsocketConnection;
var handleSocketMessage = function (ev) {
    var _a;
    var casesStore = (0, cases_1.useCasesStore)();
    var notificationsStore = (0, notifications_1.useNotificationsStore)();
    var data = JSON.parse(ev.data);
    switch (data === null || data === void 0 ? void 0 : data.source_name) {
        case "workflow":
            notificationsStore.newWorkflowNotification("Workflow update", data.message, ((_a = data.source_details) === null || _a === void 0 ? void 0 : _a.case_id) && {
                name: "CaseDetail",
                params: { pk: data.source_details.case_id },
            });
            casesStore.getCaseNotifications();
            break;
    }
};
exports.handleSocketMessage = handleSocketMessage;
