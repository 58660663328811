<template>
  <div>
    <v-dialog v-model="removeItemsFromCaseDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text>
          You are removing selected items from case<br />
          {{ selectedCase.name }}
        </v-card-text>
        <v-card-actions>
          {{ apiLoadingMessage }}
          <v-spacer />
          <v-btn
            :disabled="apiLoadingMessage !== ''"
            text
            @click="removeItemsFromCaseDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn :disabled="apiLoadingMessage !== ''" color="error" text @click="removeItems">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip v-if="canDeleteTodoItem" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          color="error"
          v-bind="attrs"
          v-on="on"
          @click="removeItemsFromCaseDialog = true"
        >
          <v-icon left>mdi-minus</v-icon>
          Batch Remove
        </v-btn>
      </template>
      <span>Remove selected items from case {{ selectedCase.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "pinia";
import EventBus from "@/eventBus";
import { useAuthStore } from "@/stores/auth";
import { useStore } from "@/stores/main";
import { resetTodoItems } from "@/repositories/todo";

export default {
  name: "BatchActionsCaseDetail",
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      removeItemsFromCaseDialog: false,
      data: {},
      apiLoadingMessage: "",
    };
  },
  computed: {
    ...mapState(useStore, {
      selectedCase: (state) => state.case,
    }),
    ...mapState(useAuthStore, ["canDeleteTodoItem"]),
  },
  methods: {
    async removeItems() {
      this.apiLoadingMessage = "Removing...";
      try {
        await resetTodoItems(this.selectedRows.map((row) => row.django_id));
      } catch (error) {
        console.error("removeItems error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.apiLoadingMessage = "";
        EventBus.$emit("loadData");
      }
    },
  },
};
</script>
