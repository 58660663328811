<template>
  <div class="mb-2">
    <v-tooltip v-if="canDeleteIgnoreItem && areAllItemsActioned" bottom>
      <template #activator="{ on, attrs }">
        <v-btn class="ma-1" color="error" v-bind="attrs" v-on="on" @click="unignoreItems">
          <v-icon left>mdi-undo</v-icon>
          Batch Undo
        </v-btn>
      </template>
      <span>Move selected items back into the 'Pending' list</span>
    </v-tooltip>

    <div v-if="areAllItemsNotActioned">
      <v-tooltip bottom v-if="canAddIgnoreItem">
        <template #activator="{ on, attrs }">
          <v-btn class="ma-1" color="error" v-bind="attrs" v-on="on" @click="ignoreItemsDialog">
            <v-icon left>mdi-redo</v-icon>
            Batch Ignore
          </v-btn>
        </template>
        <span>Move selected items to the 'Reviewed - No Action' list</span>
      </v-tooltip>
      <v-tooltip bottom v-if="canAddCaseItem && canShowAddButton">
        <template #activator="{ on, attrs }">
          <v-btn class="ma-1" color="secondary" v-bind="attrs" v-on="on" @click="addItemsDialog">
            <v-icon left>mdi-plus</v-icon>
            Batch Add
          </v-btn>
        </template>
        <span>Add selected items to a case</span>
      </v-tooltip>
      <span v-if="!canShowAddButton" class="mx-2">
        Only items with the same Client can be added to a case.
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import EventBus from "@/eventBus";
import {
  addItemsToCase,
  addItemGroupsToCase,
  ignoreTodoItems,
  ignoreTodoItemGroups,
  resetTodoItems,
  resetTodoItemGroups,
} from "@/repositories/todo";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "BatchActionsTodo",
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    showCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "canAddCaseItem",
      "canAddIgnoreItem",
      "canDeleteIgnoreItem",
      "defaultIgnoreReasonCategoryID",
    ]),
    areAllItemsActioned() {
      return this.selectedRows.every((selectedRow) => selectedRow.actioned);
    },
    areAllItemsNotActioned() {
      return this.selectedRows.every((selectedRow) => !selectedRow.actioned);
    },
    canShowAddButton() {
      const firstRowClient = this.selectedRows[0]?.fingerprint_client;
      return this.selectedRows.every(
        (selectedRow) => selectedRow.fingerprint_client === firstRowClient,
      );
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["updateDefaultIgnoreReasonCategoryID"]),
    async addItemsDialog() {
      EventBus.$emit("caseSelect", this.selectedRows, (caseObj) => this.addItems(caseObj));
    },
    async addItems(caseObj) {
      const ids = this.selectedRows.map((row) => row.django_id);
      const group_ids = this.selectedRows.map((row) => row.group_id);
      try {
        if (this.showCount) {
          await addItemGroupsToCase(group_ids, caseObj.case_id || caseObj.id);
        } else {
          await addItemsToCase(ids, caseObj.case_id || caseObj.id);
        }
      } catch (error) {
        console.error("addItems error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
    async ignoreItemsDialog() {
      if (this.defaultIgnoreReasonCategoryID) {
        this.ignoreItemHandler(this.defaultIgnoreReasonCategoryID);
      } else {
        EventBus.$emit("setIgnoreReason", this.ignoreItemHandler);
      }
    },
    async ignoreItemHandler(ignoreCategoryId, ignoreReason, useCategoryAsDefault) {
      await this.ignoreItems(ignoreCategoryId, ignoreReason);
      if (useCategoryAsDefault) {
        this.updateDefaultIgnoreReasonCategoryID(ignoreCategoryId);
      }
    },
    async ignoreItems(ignoreCategoryId, ignoreReason) {
      const ids = this.selectedRows.map((row) => row.django_id);
      const group_ids = this.selectedRows.map((row) => row.group_id);
      try {
        if (this.showCount) {
          await ignoreTodoItemGroups(group_ids, ignoreCategoryId, ignoreReason);
        } else {
          await ignoreTodoItems(ids, ignoreCategoryId, ignoreReason);
        }
      } catch (error) {
        console.error("ignoreItems error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
    async unignoreItems() {
      const ids = this.selectedRows.map((row) => row.django_id);
      const group_ids = this.selectedRows.map((row) => row.group_id);
      try {
        if (this.showCount) {
          await resetTodoItemGroups(group_ids);
        } else {
          await resetTodoItems(ids);
        }
      } catch (error) {
        console.error("unignoreItems error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
  },
};
</script>
