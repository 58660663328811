import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"800px"},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.handleSave.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VTextField,{attrs:{"rules":_vm.nameRules,"autofocus":"","label":"Name","required":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.name"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){_vm.showEditDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","disabled":!_vm.formValid},on:{"click":_vm.handleSave}},[_vm._v("Save")])],1)],1)],1),_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Case Categories")]},proxy:true},{key:"actions",fn:function(){return [(_vm.permissions.add)?_c(VBtn,{on:{"click":_vm.handleAdd}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New ")],1):_vm._e()]},proxy:true}])},[_c(VCheckbox,{attrs:{"label":"Include disabled items"},on:{"change":_vm.handleTableUpdateOptions},model:{value:(_vm.includeDeleted),callback:function ($$v) {_vm.includeDeleted=$$v},expression:"includeDeleted"}}),_c(VDataTable,{attrs:{"loading":_vm.showLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsCount,"items-per-page":25,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
      }},on:{"update:options":_vm.handleTableUpdateOptions},scopedSlots:_vm._u([{key:"item.is_deleted",fn:function({ item }){return [(item.permissions.change)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"icon":"","small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(item.is_deleted ? item.permissions.change : item.permissions.delete)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"color":item.is_deleted ? 'warning' : 'primary',"icon":"","small":""},on:{"click":function($event){return _vm.handleToggleItem(item)}}},on),[_c(VIcon,[_vm._v(_vm._s(item.is_deleted ? "mdi-toggle-switch-off" : "mdi-toggle-switch"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_deleted ? "Enable" : "Disable"))])]):_vm._e()]}},{key:"item.cases",fn:function({ item }){return [_c('ul',_vm._l((item.cases),function(c){return _c('li',{key:c.id},[_c('router-link',{attrs:{"to":{ name: 'CaseDetail', params: { pk: c.id } }}},[_vm._v(" "+_vm._s(c.name)+" ")])],1)}),0)]}},{key:"item.created_by",fn:function({ item }){return [_vm._v(" "+_vm._s(item.created_by.full_name)+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }