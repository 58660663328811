import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VTabs,[_c(VTab,{attrs:{"to":{ name: 'ReportingReports' }}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-folder-open-outline ")]),_vm._v(" Pre-published reports ")],1),_c(VTab,{attrs:{"to":{ name: 'ReportingReports', params: { status: 'published' } }}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-folder-outline ")]),_vm._v(" Published reports ")],1),_c(VTab,{attrs:{"disabled":""}},[(_vm.showLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24"}}):_vm._e()],1)],1),(_vm.headers.length)?_c(VDataTable,{attrs:{"loading":_vm.showLoading,"headers":_vm.headers,"items":_vm.items,"items-per-page":25,"footer-props":{
      'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
    },"sort-by":"modified_on","sort-desc":true,"server-items-length":_vm.itemsCount},on:{"update:options":_vm.handleTableUpdateOptions},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('StoredReportsActions',{attrs:{"inner-data":item}})]}},{key:"item.report_schedule",fn:function({ item }){return [_vm._v(" "+_vm._s(item.report_schedule?.name)+" ")]}},{key:"item.report_status",fn:function({ item }){return [_vm._v(" "+_vm._s(item.report_status_display)+" ")]}},{key:"item.account__account_name",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.accounts.find((account) => account.id === item.account)?.account_name)+" ")]}},{key:"item.fingerprint_firm__firm_name",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.firms.find((firm) => firm.id === item.fingerprint_firm)?.firm_name)+" ")]}},{key:"item.created_on",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(item.created_on))+" ")]}},{key:"item.modified_on",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(item.modified_on))+" ")]}}],null,false,1410355986)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }