<template>
  <div class="outer-space">
    <div ref="html2pdf" class="html2pdf-a4">
      <section class="pdf-content start-page">
        <v-row data-html2canvas-ignore="true">
          <v-spacer />
          <v-btn color="secondary" @click="handlePrintPDF">Save / Print</v-btn>
        </v-row>
        <h1>
          Fingerprint Report {{ humanText }} ({{ selectedPeriodType }} {{ selectedPeriodValue }})
        </h1>

        <section class="pdf-item">
          <h2>Executive summary</h2>
          <p>
            The information below represents a summary of the suspicious activity discovered via the
            Fingerprint platform and the number of cases and case actions generated as part of work
            carried out to investigate that activity {{ humanText }}.
          </p>
        </section>

        <section class="pdf-item" v-if="bar_chart_data?.datasets?.length > 0">
          <h2>
            Case Items by Category by
            {{ selectedPeriodType.charAt(0).toUpperCase() + selectedPeriodType.substring(1) }}
          </h2>
          <p>
            The figure below shows the number of case items created by {{ selectedPeriodType }} by
            suspicious search term.
          </p>
          <ChartJs
            v-if="bar_chart_data"
            height="400px"
            type="bar"
            :data="bar_chart_data"
            :options="bar_chart_options"
          />
          <div v-else>
            <v-progress-circular indeterminate />
          </div>
        </section>

        <div class="html2pdf__page-break" v-if="bar_chart_data?.datasets?.length > 0" />

        <section
          v-if="dataTypeList.length > 0"
          class="pdf-item"
          :class="{ 'start-page': bar_chart_data?.datasets?.length > 0 }"
        >
          <h2>Triage Items by Message Type</h2>
          <div
            v-for="dataType in dataTypeList"
            :key="dataType.value"
            style="width: 32%; float: left"
            class="mx-1"
          >
            <h4>{{ dataType.title }}</h4>
            <Metric
              :loading="loading_totals"
              :val="`${countTriagedItems(data_todo_time_type, dataType.value)} of ${countIngestedItems(data_totals, dataType.value)}`"
              :perc="percTodos(data_todo_time_type, data_totals, dataType.value)"
            />
            <MetricList
              v-if="data_todo_time_type_search"
              :loading="loading_totals"
              :period-data="data_todo_time_type_search[dataType.value]"
              :field-name="'todo_total'"
            />
          </div>
        </section>

        <section class="pdf-item">
          <h2>Breakdown of Triage Items</h2>
          <h4>By Status</h4>
          <TodoMetric :loading="loading_todo_time_action" :period-data="data_todo_time_action" />
          <h4>By Risk Categ.</h4>
          <TodoMetricList
            :loading="loading_todo_time_search_action"
            :period-data="data_todo_time_search_action"
          />
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item start-page">
          <h4>Avg Time to Action</h4>
          <Metric
            :loading="loading_todo_time"
            :val="
              !isEmpty(data_todo_time?.actioned_avg)
                ? parseInt(data_todo_time.actioned_avg) + ' hours'
                : 'N/A'
            "
          />
          <h4>Avg Time to Action by Risk Categ.</h4>
          <MetricList
            :loading="loading_todo_time_search"
            :period-data="data_todo_time_search"
            :field-name="'actioned_avg'"
          />
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item start-page">
          <h2>Breakdown of Cases</h2>
          <h4>Case Categories for {{ selectedPeriodValue }}</h4>
          <div style="width: 32%; float: left">
            <ChartJs
              v-if="pie_chart_data"
              type="pie"
              :data="pie_chart_data"
              :options="pie_chart_options"
            />
          </div>
        </section>

        <section class="pdf-item">
          <h4>Case Opened</h4>
          <Metric
            :loading="loading_case_timecreated_status"
            :val="data_case_timecreated_status?.case_total || 0"
          />
          <MetricList
            :loading="loading_case_timecreated_status_category"
            :period-data="data_case_timecreated_status_category"
            :field-name="'case_total'"
          />

          <h4>Case Closed</h4>
          <Metric
            :loading="loading_case_timeclosed_status"
            :val="data_case_timeclosed_status?.case_total || 0"
          />
          <MetricList
            :loading="loading_case_timeclosed_status_category"
            :period-data="data_case_timeclosed_status_category"
            :field-name="'case_total'"
          />
        </section>

        <div class="html2pdf__page-break" />

        <div
          v-if="
            pie_todo_time_actionreason ||
            data_todo_time_type_actionreason ||
            data_todo_time_search_actionreason
          "
          class="start-page"
        />
        <h2
          v-if="
            pie_todo_time_actionreason ||
            data_todo_time_type_actionreason ||
            data_todo_time_search_actionreason
          "
        >
          Breakdown of Triage Items when 'No Action' Taken
        </h2>

        <section v-if="pie_todo_time_actionreason" class="pdf-item">
          <h4>Reasons</h4>
          <div style="width: 50%; float: left">
            <ChartJs
              v-if="pie_todo_time_actionreason"
              type="pie"
              :data="pie_todo_time_actionreason"
              :options="pie_chart_options"
            />
          </div>
        </section>

        <section v-if="data_todo_time_type_actionreason" class="pdf-item">
          <h4>Reasons by Channel</h4>
          <v-simple-table v-if="data_todo_time_type_actionreason">
            <thead>
              <tr>
                <th />
                <th>Email</th>
                <th>BBG Msg</th>
                <th>BBG IM</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cat_name, cat_index) in data_todo_time_type_actionreason"
                :key="cat_index"
              >
                <template v-if="cat_index !== 'None' && cat_index !== 'Added via Adhoc Search'">
                  <td>{{ cat_index }}</td>
                  <td>
                    {{ cat_name["email"] ? cat_name["email"]["todo_total"] : 0 }}
                  </td>
                  <td>
                    {{ cat_name["bbg.msg"] ? cat_name["bbg.msg"]["todo_total"] : 0 }}
                  </td>
                  <td>
                    {{ cat_name["bbg.im"] ? cat_name["bbg.im"]["todo_total"] : 0 }}
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </section>

        <div v-if="data_todo_time_search_actionreason" class="html2pdf__page-break" />

        <section v-if="data_todo_time_search_actionreason" class="pdf-item start-page">
          <h4>Reasons V Categories</h4>
          <v-simple-table v-if="data_todo_time_search_actionreason" style="font-size: 0.5em">
            <thead>
              <tr v-if="results_todo_search">
                <th />
                <th
                  v-for="(search_name, search_index) in results_todo_search['True']"
                  :key="search_index"
                >
                  {{ search_index }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(cat_name, cat_index) in data_todo_time_search_actionreason"
                :key="cat_index"
              >
                <template
                  v-if="
                    results_todo_search &&
                    cat_index !== 'None' &&
                    cat_index !== 'Added via Adhoc Search'
                  "
                >
                  <td>{{ cat_index }}</td>
                  <td
                    v-for="(search_name, search_index) in results_todo_search['True']"
                    :key="search_index"
                  >
                    {{ cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0 }}
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { DateTime } from "luxon";
import html2pdf from "html2pdf.js";
import Metric from "./Metric.vue";
import MetricList from "./MetricList.vue";
import TodoMetric from "./TodoMetric.vue";
import TodoMetricList from "./TodoMetricList.vue";
import ChartJs from "@/components/ChartJs.vue";
import { fillTodoTimeData, fillCategoryPieData, fillReasonPieData } from "./utils.js";
import { getDataTypes } from "@/repositories/settings";
import { getStatsCaseTotals, getStatsTodoTotals, getStatsTotals } from "@/repositories/stats";
import EventBus from "@/eventBus";
import { isEmpty } from "@/utils";

export default {
  name: "AppMetrics",
  components: {
    Metric,
    MetricList,
    TodoMetric,
    TodoMetricList,
    ChartJs,
  },
  props: {
    periodType: {
      type: String,
      default: "month",
    },
    periodValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      periodTypes: ["week", "month", "quarter", "year"],
      periodValues: null,
      dataTypes: [],
      selectedPeriodType: "month",
      selectedPeriodValue: null,
      caseStatus: "1",
      htmlToPdfOptions: {
        margin: [
          0, // top, default 0
          0, // left, default 0
          0, // bottom, default 0
          0, // right, default 0
        ],
      },

      loading: true,
      // loading_settings: true,
      loading_totals: true,
      loading_todo: true,
      loading_todo_user: true,
      loading_todo_search: true,
      loading_todo_actionreason: true,
      loading_todo_type_actionreason: true,
      loading_todo_search_actionreason: true,
      loading_todo_time: true,
      loading_todo_time_type: true,
      loading_todo_time_search: true,
      loading_todo_time_type_search: true,
      loading_todo_time_action: true,
      loading_todo_time_search_action: true,
      loading_todo_time_actionreason: true,
      loading_todo_time_type_actionreason: true,
      loading_todo_time_search_actionreason: true,
      loading_case_timecreated_status: true,
      loading_case_timeclosed_status: true,
      loading_case_name: true,
      loading_case_name_category: true,
      loading_case_status: true,
      loading_case_status_category: true,
      loading_case_timeclosed_status_category: true,
      loading_case_timecreated_status_category: true,
      // loading_case_status_stage: true,
      // loading_case_category_stage: true,
      // loading_case_category_stage_status: true,

      // results_settings: null,
      results_totals: null,
      results_todo: {},
      results_todo_user: null,
      results_todo_search: {},
      results_todo_actionreason: null,
      results_todo_type_actionreason: null,
      results_todo_search_actionreason: null,
      results_todo_time: null,
      results_todo_time_type: null,
      results_todo_time_search: null,
      results_todo_time_type_search: null,
      results_todo_time_action: null,
      results_todo_time_search_action: null,
      results_todo_time_actionreason: null,
      results_todo_time_type_actionreason: null,
      results_todo_time_search_actionreason: null,
      results_case_timecreated_status: null,
      results_case_timeclosed_status: null,
      results_case_name: null,
      results_case_name_category: null,
      results_case_status: null,
      results_case_status_category: null,
      // results_case_status_stage: null,
      // results_case_category_stage: null,
      // results_case_category_stage_status: null,

      data_totals: {},
      data_todo_time: {},
      data_todo_time_type: {},
      data_todo_time_search: {},
      data_todo_time_type_search: {},
      data_todo_time_action: {},
      data_todo_time_search_action: {},
      data_todo_time_actionreason: {},
      data_todo_time_type_actionreason: null,
      data_todo_time_search_actionreason: null,
      data_case_timecreated_status: {},
      data_case_timecreated_status_category: {},
      data_case_timeclosed_status: {},
      data_case_timeclosed_status_category: {},

      line_chart_data: null,
      line_chart_options: {},
      bar_chart_data: null,
      bar_chart_options: {},
      pie_chart_data: {},
      pie_chart_options: {},
      pie_todo_actionreason: {},
      pie_todo_time_actionreason: null,
    };
  },
  computed: {
    canShowBbgIm() {
      return this.dataTypes.some((dt) => dt.id === "bbg.im");
    },
    canShowBbgMsg() {
      return this.dataTypes.some((dt) => dt.id === "bbg.msg");
    },
    canShowEmail() {
      return this.dataTypes.some((dt) => dt.id === "email");
    },
    canShowVoice() {
      return this.dataTypes.some((dt) => dt.id === "voice");
    },
    canShowSlack() {
      return this.dataTypes.some((dt) => dt.id === "slack");
    },
    canShowTeamsPersonal() {
      return this.dataTypes.some((dt) => dt.id === "teams.personal");
    },
    canShowTeamsCorporate() {
      return this.dataTypes.some((dt) => dt.id === "teams.corporate");
    },
    canShowWhatsapp() {
      return this.dataTypes.some((dt) => dt.id === "whatsapp");
    },
    canShowZoomCall() {
      return this.dataTypes.some((dt) => dt.id === "zoom.call");
    },
    canShowZoomMeet() {
      return this.dataTypes.some((dt) => dt.id === "zoom.meet");
    },
    dataTypeList() {
      return [
        {
          canShow: this.canShowBbgIm,
          value: "bbg.im",
          title: "Bloomberg IM",
        },
        {
          canShow: this.canShowBbgMsg,
          value: "bbg.msg",
          title: "Bloomberg Msg",
        },
        {
          canShow: this.canShowEmail,
          value: "email",
          title: "Email",
        },
        {
          canShow: this.canShowVoice,
          value: "voice",
          title: "Voice",
        },
        {
          canShow: this.canShowSlack,
          value: "slack",
          title: "Slack",
        },
        {
          canShow: this.canShowTeamsPersonal,
          value: "teams.personal",
          title: "Teams Personal",
        },
        {
          canShow: this.canShowTeamsCorporate,
          value: "teams.corporate",
          title: "Teams Corporate",
        },
        {
          canShow: this.canShowWhatsapp,
          value: "whatsapp",
          title: "Whatsapp",
        },
        {
          canShow: this.canShowZoomCall,
          value: "zoom.call",
          title: "Triage: Zoom Call",
        },
        {
          canShow: this.canShowZoomMeet,
          value: "zoom.meet",
          title: "Triage: Zoom Meet",
        },
      ].filter((item) => item.canShow);
    },
    humanText() {
      let p_year;
      let p_num;
      try {
        [p_year, p_num] = this.selectedPeriodValue.split("-");
      } catch {
        p_year = this.selectedPeriodValue;
        p_num = null;
      }
      if (this.selectedPeriodType === "day") {
        let first = DateTime.fromFormat(this.selectedPeriodValue, "YYYY-MM-DD");
        return `for ${first.toLocaleString(DateTime.DATE_MED)}`;
      } else if (this.selectedPeriodType === "week") {
        let first = DateTime.fromISO(this.selectedPeriodValue);
        let last = first.plus({ days: 6, hours: 23 });
        return `from ${first.toLocaleString(DateTime.DATE_MED)} to ${last.toLocaleString(
          DateTime.DATE_MED,
        )}`;
      } else if (this.selectedPeriodType === "month") {
        return `for  ${DateTime.fromFormat(this.selectedPeriodValue, "yyyy-MM").toFormat(
          "MMM yyyy",
        )}`;
      } else if (this.selectedPeriodType === "quarter") {
        return `for Q${p_num} ${p_year}`;
      } else if (this.selectedPeriodType === "year") {
        return `for ${p_year}`;
      }
      return null;
    },
  },
  watch: {
    selectedPeriodType() {
      this.fetchAll();
    },
    loading() {
      if (!this.loading) {
        this.offsetDataToSelectedPeriod();
      }
    },
    selectedPeriodValue() {
      if (!this.loading) {
        this.offsetDataToSelectedPeriod();
      }
    },
  },
  async beforeMount() {
    this.selectedPeriodType = this.periodType;
    this.selectedPeriodValue = this.periodValue;
    this.fetchAll();
  },
  methods: {
    isEmpty,
    // async fetchSettings() {
    //   this.loading_settings = true;
    //   try {
    //     const r = await getStatsSettings();
    //     this.results_settings = r.data;
    //   } catch (error) {
    //     console.error("fetchSettings error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_settings = false;
    // },
    async fetchAllTotalsByTime() {
      this.loading_totals = true;
      try {
        const r = await getStatsTotals(
          {
            data_types: this.dataTypeList.map((item) => item.value),
            group_by: "data_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_totals = r.data;
      } catch (error) {
        console.error("fetchAllTotalsByTime error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_totals = false;
    },
    async fetchTodoStats(actioned = "False") {
      this.loading_todo = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: actioned.toLowerCase(),
          priority: "priority",
          auto_actioned: "false",
          exclude_assigned_items: "true",
          group_by: "actioned",
        });
        Vue.set(this.results_todo, actioned, r.data.all?.[actioned] || {});
      } catch (error) {
        console.error("fetchTodoStats error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo = false;
    },
    async fetchTodoStatsBySearch(actioned = "False") {
      this.loading_todo_search = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: actioned.toLowerCase(),
          priority: "priority",
          auto_actioned: "false",
          exclude_assigned_items: "true",
          group_by: "actioned,suspicious_term__suspicious_term_category__name",
        });
        Vue.set(this.results_todo_search, actioned, r.data.all?.[actioned]);
      } catch (error) {
        console.error("fetchTodoStatsBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_search = false;
    },
    async fetchTodoStatsByActionReason() {
      this.loading_todo_actionreason = true;
      try {
        const r = await getStatsTodoTotals({ group_by: "case,action_reason" });
        this.results_todo_actionreason = r.data ? r.data["None"] : null;
        this.pie_todo_actionreason = fillReasonPieData(this.results_todo_actionreason);
      } catch (error) {
        console.error("fetchTodoStatsByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_actionreason = false;
    },
    async fetchTodoStatsByTypeByActionReason() {
      this.loading_todo_type_actionreason = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: "true",
          auto_actioned: "false",
          exclude_assigned_items: "false",
          group_by: "case,action_reason,document__fingerprint_type",
        });
        this.results_todo_type_actionreason = r.data?.all ? r.data.all["None"] : null;
      } catch (error) {
        console.error("fetchTodoStatsByTypeByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_type_actionreason = false;
    },
    async fetchTodoStatsBySearchByActionReason() {
      this.loading_todo_search_actionreason = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: "true",
          auto_actioned: "false",
          exclude_assigned_items: "false",
          group_by: "case,action_reason,suspicious_term__suspicious_term_category__name",
        });
        this.results_todo_search_actionreason = r.data?.all ? r.data.all["None"] : null;
      } catch (error) {
        console.error("fetchTodoStatsBySearchByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_search_actionreason = false;
    },
    async fetchTodoStatsByTime() {
      this.loading_todo_time = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time = r.data;
        this.periodValues = Object.keys(r.data).sort();
      } catch (error) {
        console.error("fetchTodoStatsByTime error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time = false;
    },
    async fetchTodoStatsByTimeByType() {
      this.loading_todo_time_type = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "document__fingerprint_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByType error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type = false;
    },
    async fetchTodoStatsByTimeBySearch() {
      this.loading_todo_time_search = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search = false;
    },
    async fetchTodoStatsByTimeByTypeBySearch() {
      this.loading_todo_time_type_search = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "document__fingerprint_type,suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type_search = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByTypeBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type_search = false;
    },
    async fetchTodoStatsByTimeAction() {
      this.loading_todo_time_action = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "actioned",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_action = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeAction error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_action = false;
    },
    async fetchTodoStatsByTimeBySearchByAction() {
      this.loading_todo_time_search_action = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "suspicious_term__suspicious_term_category__name,actioned",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search_action = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearchByAction error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search_action = false;
    },

    async fetchTodoStatsByTimeByActionReason() {
      this.loading_todo_time_actionreason = true;
      try {
        const r = await getStatsTodoTotals(
          {
            actioned: "true",
            auto_actioned: "false",
            exclude_assigned_items: "false",
            group_by: "case,action_reason",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_actionreason = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_actionreason = false;
    },
    async fetchTodoStatsByTimeByTypeByActionReason() {
      this.loading_todo_time_type_actionreason = true;
      try {
        const r = await getStatsTodoTotals(
          {
            actioned: "true",
            auto_actioned: "false",
            exclude_assigned_items: "false",
            group_by: "case,action_reason,document__fingerprint_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type_actionreason = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByTypeByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type_actionreason = false;
    },
    async fetchTodoStatsByTimeBySearchByActionReason() {
      this.loading_todo_time_search_actionreason = true;
      try {
        const r = await getStatsTodoTotals(
          {
            actioned: "true",
            auto_actioned: "false",
            exclude_assigned_items: "false",
            group_by: "case,action_reason,suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search_actionreason = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearchByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search_actionreason = false;
    },
    async fetchCaseStatsByTimeCreatedByStatus() {
      this.loading_case_timecreated_status = true;
      try {
        const r = await getStatsCaseTotals(
          {
            time_field: "created_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timecreated_status = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeCreatedByStatus error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timecreated_status = false;
    },
    async fetchCaseStatsByTimeCreatedByStatusByCategory() {
      this.loading_case_timecreated_status_category = true;
      try {
        const r = await getStatsCaseTotals(
          {
            group_by: "category__name",
            time_field: "created_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timecreated_status_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeCreatedByStatusByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timecreated_status_category = false;
    },
    async fetchCaseStatsByTimeClosedByStatus() {
      this.loading_case_timeclosed_status = true;
      try {
        const r = await getStatsCaseTotals(
          {
            time_field: "closed_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timeclosed_status = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeClosedByStatus error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timeclosed_status = false;
    },
    async fetchCaseStatsByTimeClosedByStatusByCategory() {
      this.loading_case_timeclosed_status_category = true;
      try {
        const r = await getStatsCaseTotals(
          {
            group_by: "category__name",
            time_field: "closed_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timeclosed_status_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeClosedByStatusByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timeclosed_status_category = false;
    },
    async fetchCaseStatsByName() {
      this.loading_case_name = true;
      try {
        const r = await getStatsCaseTotals({ group_by: "name" });
        this.results_case_name = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByName error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_name = false;
    },
    async fetchCaseStatsByNameByCategory() {
      this.loading_case_name_category = true;
      try {
        const r = await getStatsCaseTotals({ group_by: "name,category__name" });
        this.results_case_name_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByNameByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_name_category = false;
    },
    async fetchCaseStatsByStatus() {
      this.loading_case_status = true;
      try {
        const r = await getStatsCaseTotals({ group_by: "case_status" });
        this.results_case_status = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByStatus error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_status = false;
    },
    async fetchCaseStatsByStatusByCategory() {
      this.loading_case_status_category = true;
      try {
        const r = await getStatsCaseTotals({ group_by: "case_status,category__name" });
        this.results_case_status_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByStatusByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_status_category = false;
    },
    // async fetchCaseStatsByStatusByStage() {
    //   this.loading_case_status_stage = true;
    //   try {
    //     const r = await getStatsCaseTotals({ group_by: "case_status,stage__name" });
    //     this.results_case_status_stage = r.data;
    //   } catch (error) {
    //     console.error("fetchCaseStatsByStatusByStage error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_case_status_stage = false;
    // },
    // async fetchCaseStatsByCategoryByStage() {
    //   this.loading_case_category_stage = true;
    //   try {
    //     const r = await getStatsCaseTotals({ group_by: "category__name,stage__name" });
    //     this.results_case_category_stage = r.data;
    //   } catch (error) {
    //     console.error("fetchCaseStatsByCategoryByStage error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_case_category_stage = false;
    // },
    // async fetchCaseStatsByCategoryByStageStatus() {
    //   this.loading_case_category_stage_status = true;
    //   try {
    //     const r = await getStatsCaseTotals({ group_by: "category__name,stage__name,case_status" });
    //     this.results_case_category_stage_status = r.data;
    //   } catch (error) {
    //     console.error("fetchCaseStatsByCategoryByStageStatus error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_case_category_stage_status = false;
    // },
    fetchAll() {
      this.loading = true;
      Promise.all([
        getDataTypes().then((dataTypes) => {
          this.dataTypes = dataTypes;
        }),
        // this.fetchSettings(),
        this.fetchAllTotalsByTime(),
        // this.fetchTodoStats('False'),
        // this.fetchTodoStats('True'),
        this.fetchTodoStatsBySearch("False"),
        this.fetchTodoStatsBySearch("True"),
        // this.fetchTodoStatsByActionReason(),
        // this.fetchTodoStatsByTypeByActionReason(),
        // this.fetchTodoStatsBySearchByActionReason(),
        this.fetchTodoStatsByTime(),
        this.fetchTodoStatsByTimeByType(),
        this.fetchTodoStatsByTimeBySearch(),
        this.fetchTodoStatsByTimeByTypeBySearch(),
        this.fetchTodoStatsByTimeAction(),
        this.fetchTodoStatsByTimeBySearchByAction(),
        this.fetchTodoStatsByTimeByActionReason(),
        this.fetchTodoStatsByTimeByTypeByActionReason(),
        this.fetchTodoStatsByTimeBySearchByActionReason(),
        this.fetchCaseStatsByTimeCreatedByStatus(),
        this.fetchCaseStatsByTimeCreatedByStatusByCategory(),
        // this.fetchCaseStatsByName(),
        // this.fetchCaseStatsByNameByCategory(),
        // this.fetchCaseStatsByStatus(),
        // this.fetchCaseStatsByStatusByCategory(),
        // this.fetchCaseStatsByStatusByStage(),
        // this.fetchCaseStatsByCategoryByStage(),
        // this.fetchCaseStatsByCategoryByStageStatus(),
        this.fetchCaseStatsByTimeClosedByStatus(),
        this.fetchCaseStatsByTimeClosedByStatusByCategory(),
      ]).then(() => {
        this.loading = false;
      });
    },
    offsetDataToSelectedPeriod() {
      if (!this.loading_totals) {
        this.data_totals = this.results_totals?.[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time) {
        this.data_todo_time = this.results_todo_time[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_type) {
        this.data_todo_time_type = this.results_todo_time_type[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_search) {
        this.data_todo_time_search = this.results_todo_time_search[this.selectedPeriodValue];
        [this.bar_chart_data, this.bar_chart_options] = fillTodoTimeData(
          this.results_todo_time_search,
          this.periodValues,
          0,
          true,
        );
      }
      if (!this.loading_todo_time_type_search) {
        this.data_todo_time_type_search =
          this.results_todo_time_type_search[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_action) {
        this.data_todo_time_action = this.results_todo_time_action[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_search_action) {
        this.data_todo_time_search_action =
          this.results_todo_time_search_action[this.selectedPeriodValue];
      }
      if (
        !this.loading_todo_time_actionreason &&
        this.data_todo_time_actionreason &&
        Object.keys(this.data_todo_time_actionreason).length !== 0
      ) {
        this.data_todo_time_actionreason = this.results_todo_time_actionreason[
          this.selectedPeriodValue
        ]
          ? this.results_todo_time_actionreason[this.selectedPeriodValue]["None"]
          : null;
        this.pie_todo_time_actionreason = fillReasonPieData(this.data_todo_time_actionreason);
      }
      if (
        !this.loading_todo_time_type_actionreason &&
        this.results_todo_time_type_actionreason &&
        Object.keys(this.results_todo_time_type_actionreason).length !== 0
      ) {
        this.data_todo_time_type_actionreason =
          this.results_todo_time_type_actionreason?.[this.selectedPeriodValue]?.["None"];
      }
      if (
        !this.loading_todo_time_search_actionreason &&
        this.results_todo_time_search_actionreason &&
        Object.keys(this.results_todo_time_search_actionreason).length !== 0
      ) {
        this.data_todo_time_search_actionreason =
          this.results_todo_time_search_actionreason?.[this.selectedPeriodValue]?.["None"];
      }
      if (!this.loading_case_timecreated_status) {
        this.data_case_timecreated_status =
          this.results_case_timecreated_status[this.selectedPeriodValue];
      }
      if (!this.loading_case_timecreated_status_category) {
        this.data_case_timecreated_status_category =
          this.results_case_timecreated_status_category[this.selectedPeriodValue];
        this.pie_chart_data = fillCategoryPieData(this.data_case_timecreated_status_category);
      }
      if (!this.loading_case_timeclosed_status) {
        this.data_case_timeclosed_status =
          this.results_case_timeclosed_status[this.selectedPeriodValue];
      }
      if (!this.loading_case_timeclosed_status_category) {
        this.data_case_timeclosed_status_category =
          this.results_case_timeclosed_status_category[this.selectedPeriodValue];
      }
    },
    countTodos(periodData) {
      if (!periodData) return 0;
      return (periodData["todo_total"] || 0).toLocaleString();
    },
    // countTotals(periodData) {
    //     if(!periodData) return 0;
    //     return (periodData['total'] || 0).toLocaleString();
    // },
    percTodos(periodData, periodDataTotal, dataType) {
      const data = periodData?.[dataType];
      const total = periodDataTotal?.[dataType]?.total || 0;
      if (data) {
        if (total) {
          return `${parseFloat(((100 * data) / total).toFixed(4)) || 0}`;
        }
        return null;
      }
      return "0";
    },
    countTriagedItems(periodData, dataType) {
      return periodData?.[dataType]?.todo_total || 0;
    },
    countIngestedItems(periodDataTotal, dataType) {
      return periodDataTotal?.[dataType]?.total || 0;
    },
    async handlePrintPDF() {
      await html2pdf()
        .set({
          margin: 1,
          filename: "summary.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .from(this.$refs.html2pdf)
        .save();
    },
  },
};
</script>

<style scoped>
/*#app_metrics{*/
/*    overflow: hidden;*/
/*}*/
/*.date-select{*/
/*    padding-bottom: 5px;*/
/*}*/
.outer-space {
  background: white;
  padding: 10px 20px;
}
.pdf-content {
  margin-bottom: 0.75in;
  margin-left: 0.75in;
  margin-right: 0.75in;
}
.start-page {
  margin-top: 0.75in;
}
.pdf-item {
  clear: both;
}
h1 {
  text-transform: capitalize;
}
h2 {
  padding: 20px 0 10px 0;
}
h4 {
  padding: 10px 0 5px 0;
  text-align: left;
}
textarea {
  width: 100%;
  border: 1px solid #cccccc;
  padding: 5px;
  font-family: Times, "Times New Roman", serif;
  font-size: 16px;
  background: #fff;
  margin-left: 10px;
}
</style>
