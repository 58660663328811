<template>
  <div>
    <v-dialog :value="showDialog" persistent max-width="500">
      <v-form v-model="valid">
        <v-card>
          <v-card-title> Enter a Reason for Closing the Case </v-card-title>
          <v-card-text>
            <v-textarea v-model="actionReason" label="Explanation" :rules="actionReasonRules" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="$emit('cancel')"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="handleSubmit"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      actionReason: "",
      actionReasonRules: [
        /* istanbul ignore next */
        (v) => !!v || "An explanation is required",
      ],
    };
  },
  methods: {
    async handleSubmit() {
      this.$emit("confirm", {
        actionReason: this.actionReason,
      });
    },
  },
};
</script>
