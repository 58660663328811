import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Scheduled Searches")]},proxy:true},{key:"actions",fn:function(){return [(_vm.permissions.add)?_c(VBtn,{attrs:{"to":{ name: 'ReportingScheduleForm', params: { id: 'new' } }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New ")],1):_vm._e()]},proxy:true}])},[_c(VCheckbox,{attrs:{"label":"Include disabled items"},on:{"change":_vm.handleTableUpdateOptions},model:{value:(_vm.includeDeleted),callback:function ($$v) {_vm.includeDeleted=$$v},expression:"includeDeleted"}}),_c(VDataTable,{attrs:{"loading":_vm.showLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsCount,"items-per-page":25,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
      }},on:{"update:options":_vm.handleTableUpdateOptions},scopedSlots:_vm._u([{key:"item.is_deleted",fn:function({ item }){return [(item.permissions.change)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"icon":"","small":"","to":{ name: 'WatchForm', params: { pk: item.id } }}},on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(item.is_deleted ? item.permissions.change : item.permissions.delete)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"color":item.is_deleted ? 'warning' : 'primary',"icon":"","small":""},on:{"click":function($event){return _vm.handleToggleItem(item)}}},on),[_c(VIcon,[_vm._v(_vm._s(item.is_deleted ? "mdi-toggle-switch-off" : "mdi-toggle-switch"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_deleted ? "Enable" : "Disable"))])]):_vm._e()]}},{key:"item.schedule",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getCronDescription(item.schedule))+" ")]}},{key:"item.search_objs",fn:function({ item }){return [_c('ul',_vm._l((item.search_objs),function(search){return _c('li',{key:search.id},[_c('router-link',{attrs:{"to":{ name: 'SavedSearch', params: { pk: search.id } }}},[_vm._v(" "+_vm._s(search.name)+" ")])],1)}),0)]}},{key:"item.created_by",fn:function({ item }){return [_vm._v(" "+_vm._s(item.created_by.full_name)+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }