"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountStatus = exports.CategoryType = void 0;
var CategoryType;
(function (CategoryType) {
    CategoryType[CategoryType["TODO"] = 1] = "TODO";
    CategoryType[CategoryType["CASE"] = 2] = "CASE";
})(CategoryType || (exports.CategoryType = CategoryType = {}));
var AccountStatus;
(function (AccountStatus) {
    AccountStatus[AccountStatus["OFFLINE"] = 0] = "OFFLINE";
    AccountStatus[AccountStatus["ONLINE"] = 1] = "ONLINE";
    AccountStatus[AccountStatus["SUSPENDED"] = 9] = "SUSPENDED";
})(AccountStatus || (exports.AccountStatus = AccountStatus = {}));
