"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var ChartJs_vue_1 = require("@/components/ChartJs.vue");
var BasicList_vue_1 = require("@/components/common/BasicList.vue");
var utils_1 = require("./utils");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ItemsBurndownByPeriod',
    props: {
        data: {
            type: Object,
            required: true,
        },
        jsonData: {
            type: Object,
            default: null,
        },
        periodOptions: {
            type: Object,
            default: function () { return ({
                periodValue: null,
            }); },
        },
    },
    setup: function (__props) {
        var props = __props;
        var createdItemsByPeriod = (0, vue_2.computed)(function () { var _a; return ((_a = props.jsonData) === null || _a === void 0 ? void 0 : _a.CreatedItemsByPeriod) || {}; });
        var modifiedItemsByPeriod = (0, vue_2.computed)(function () { var _a; return ((_a = props.jsonData) === null || _a === void 0 ? void 0 : _a.ModifiedItemsByPeriod) || {}; });
        var labels = (0, vue_2.computed)(function () {
            var labelsSet = new Set(__spreadArray(__spreadArray([], (0, utils_1.getFirstLevelKeys)(createdItemsByPeriod.value), true), (0, utils_1.getFirstLevelKeys)(modifiedItemsByPeriod.value), true));
            return Array.from(labelsSet).sort();
        });
        var hasData = (0, vue_2.computed)(function () { return labels.value.length > 0; });
        var tableColumns = [
            { field: "label", headerText: "" },
            { field: "processedItems", headerText: "Processed Items", type: "number", textAlign: "Right" },
            { field: "generatedItems", headerText: "Generated Items", type: "number", textAlign: "Right" },
            { field: "delta", headerText: "Delta", type: "number", textAlign: "Right" },
            { field: "open", headerText: "open", type: "number", textAlign: "Right", visible: false },
            { field: "close", headerText: "close", type: "number", textAlign: "Right", visible: false },
        ];
        var tableData = (0, vue_2.computed)(function () {
            var cumulativeStart = 0;
            var cumulativeStop = 0;
            return labels.value.map(function (label) {
                var _a, _b;
                var generatedItems = ((_a = createdItemsByPeriod.value[label]) === null || _a === void 0 ? void 0 : _a.todo_total) || 0;
                var processedItems = ((_b = modifiedItemsByPeriod.value[label]) === null || _b === void 0 ? void 0 : _b.todo_total) || 0;
                var delta = processedItems - generatedItems;
                cumulativeStart = cumulativeStop;
                cumulativeStop = cumulativeStop + delta;
                return {
                    label: label,
                    generatedItems: generatedItems,
                    processedItems: processedItems,
                    delta: delta,
                    open: cumulativeStart,
                    close: cumulativeStop,
                };
            });
        });
        var chartData = {
            labels: labels.value,
            datasets: [
                {
                    label: "delta",
                    data: tableData.value.map(function (row) { return [row.open, row.close]; }),
                    backgroundColor: tableData.value.map(function (row) {
                        return row.delta >= 0 ? "rgb(54, 162, 235)" : "rgb(255, 159, 64)";
                    }),
                    tooltip: {
                        callbacks: {
                            label: function (context) { return [
                                "Delta: ".concat(tableData.value[context.dataIndex].delta),
                                "Processed Items: ".concat(tableData.value[context.dataIndex].processedItems),
                                "Generated Items: ".concat(tableData.value[context.dataIndex].generatedItems),
                            ]; },
                        },
                    },
                },
            ],
        };
        /**
         * 1. extract CreatedItemsByPeriod and ModifiedItemsByPeriod
         * 2. extract period list from both and join them
         * 3. get data for table/chart
         */
        return { __sfc: true, props: props, createdItemsByPeriod: createdItemsByPeriod, modifiedItemsByPeriod: modifiedItemsByPeriod, labels: labels, hasData: hasData, tableColumns: tableColumns, tableData: tableData, chartData: chartData, ChartJs: ChartJs_vue_1.default, BasicList: BasicList_vue_1.default };
    }
});
