<template>
  <div>
    <v-row no-gutters>
      <h3>Case Totals</h3>
    </v-row>
    <v-row>
      <v-col>
        <MetricBox title="Open Cases" :show-accordion="!!totalCasesByStatusAndCatList.open.length">
          <template #metric>
            <Metric :val="totalCasesByStatusList.open.case_total || 'N/A'" />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :period-data-list="totalCasesByStatusAndCatList.open"
              key-name="cat"
              field-name="case_total"
            />
            <MetricList
              title="by Stage"
              :period-data-list="totalOpenCasesByStageList"
              key-name="stage"
              field-name="case_total"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Closed Cases"
          :show-accordion="!!totalCasesByStatusAndCatList.closed.length"
        >
          <template #metric>
            <Metric :val="totalCasesByStatusList.closed.case_total" />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :period-data-list="totalCasesByStatusAndCatList.closed"
              key-name="cat"
              field-name="case_total"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Avg Time to Close Case"
          :show-accordion="!!totalCasesByStatusAndCatList.closed.length"
        >
          <template #metric>
            <Metric
              :val="
                totalCasesByStatusList.closed.duration_avg
                  ? parseInt(totalCasesByStatusList.closed.duration_avg) + ' hours'
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :period-data-list="totalCasesByStatusAndCatList.closed"
              key-name="cat"
              field-name="duration_avg"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";
import Metric from "@/components/metrics/Metric.vue";
import MetricList from "@/components/metrics/MetricList.vue";

const reduceCategories = (obj) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) =>
      acc.concat({
        cat: k,
        ...v,
      }),
    [],
  );

const summarizeCategories = (list) => {
  let case_total = 0,
    todoitem_total = 0,
    casenote_total = 0;
  let total_duration = null;
  list.forEach((elem) => {
    case_total += elem.case_total;
    todoitem_total += elem.todoitem_total;
    casenote_total += elem.casenote_total;
    if (elem.duration_avg !== null) {
      total_duration = (total_duration || 0) + elem.duration_avg * elem.case_total;
    }
  });
  return {
    case_total,
    todoitem_total,
    casenote_total,
    duration_avg: total_duration && case_total && total_duration / case_total,
  };
};

export default {
  name: "CaseTotals",
  components: {
    Metric,
    MetricBox,
    MetricList,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    caseStatsByStatusByCategory() {
      return this.jsonData?.CaseStatsByStatusByCategory?.all || {};
    },
    caseStatsByStatusByStage() {
      return this.jsonData?.CaseStatsByStatusByStage?.all || {};
    },

    totalCasesByStatusAndCatList() {
      const keyMap = { 1: "open", 2: "closed" };
      return Object.entries(this.caseStatsByStatusByCategory).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [keyMap[k]]: reduceCategories(v),
        }),
        { open: [], closed: [] },
      );
    },
    totalCasesByStatusList() {
      return Object.entries(this.totalCasesByStatusAndCatList).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: summarizeCategories(v),
        }),
        { open: {}, closed: {} },
      );
    },
    totalOpenCasesByStageList() {
      return Object.entries(this.caseStatsByStatusByStage).reduce(
        (acc, [k, v]) =>
          acc.concat({
            stage: k,
            ...v,
          }),
        [],
      );
    },
  },
};
</script>
