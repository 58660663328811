"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'IpsFooter',
    setup: function (__props, _a) {
        var emit = _a.emit;
        var version = (0, vue_2.ref)("");
        var build = (0, vue_2.ref)("");
        var currentYear = new Date().getFullYear();
        var dialog = (0, vue_2.ref)(false);
        (0, vue_2.onMounted)(function () {
            version.value = process.env.VUE_APP_VERSION || "";
            var scripts = document.getElementsByTagName("script");
            var appScriptSrc = scripts[1].src;
            var appScriptName = appScriptSrc.slice(appScriptSrc.lastIndexOf("/") + 1);
            var hash = appScriptName.split(".").at(-2);
            build.value = hash || "";
        });
        function getDebugInfo() {
            return {
                appVersion: version.value,
                appBuild: build.value,
                cookieEnabled: navigator.cookieEnabled,
                language: navigator.language,
                languages: navigator.languages,
                onLine: navigator.onLine,
                userAgent: navigator.userAgent,
                consoleLogs: __spreadArray([], (console.everything || []), true), // eslint-disable-line no-console
            };
        }
        function handleSupportEmail() {
            var subject = encodeURIComponent("Support request");
            var debugInfo = getDebugInfo();
            var jsonDebug = JSON.stringify(debugInfo);
            // navigator.clipboard.writeText(jsonDebug);
            var body = encodeURIComponent("\n\n----------\nPlease do not delete the message below. The text contains essential information to help our team diagnose and resolve your issue\n----------\n".concat(jsonDebug));
            var href = "mailto:support@fingerprint-supervision.com" + "?subject=".concat(subject) + "&body=".concat(body);
            // limit href length otherwise some browsers could return errors
            window.location.assign(href.substring(0, 500000));
        }
        return { __sfc: true, version: version, build: build, currentYear: currentYear, dialog: dialog, emit: emit, getDebugInfo: getDebugInfo, handleSupportEmail: handleSupportEmail };
    }
});
