"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var luxon_1 = require("luxon");
var vue_2 = require("vue");
var filters_1 = require("@/filters");
var MenuDateSelect_vue_1 = require("./MenuDateSelect.vue");
var MenuQuickSelect_vue_1 = require("./MenuQuickSelect.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DateTimeRangeSelect',
    props: {
        rangeType: { default: "absolute" },
        fromValue: null,
        toValue: null,
        offsetType: null,
        offsetValue: null,
        limitQuickSelectEndNow: { type: Boolean }
    },
    emits: ["update:rangeType", "update:fromValue", "update:toValue", "update:offsetType", "update:offsetValue"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var quickSelectMenu = (0, vue_2.ref)(false);
        var dateFromMenu = (0, vue_2.ref)(false);
        var dateToMenu = (0, vue_2.ref)(false);
        var rangeSelection = (0, vue_2.computed)(function () {
            return ({
                rangeType: props.rangeType,
                fromValue: props.fromValue && luxon_1.DateTime.fromJSDate(props.fromValue),
                toValue: props.toValue && luxon_1.DateTime.fromJSDate(props.toValue),
                offsetType: props.offsetType,
                offsetValue: props.offsetValue,
            });
        });
        function updateRangeType(e) {
            emit("update:rangeType", e);
            if (e === "absolute") {
                updateFromValue(luxon_1.DateTime.now().startOf("day"));
                updateToValue(luxon_1.DateTime.now());
                updateOffsetType(null);
                updateOffsetValue(null);
            }
            else if (e === "relative") {
                updateFromValue(null);
                updateToValue(null);
                updateOffsetType("days");
                updateOffsetValue(2);
            }
        }
        function updateTimeRange(e) {
            updateFromValue(e.fromValue);
            updateToValue(e.toValue);
            updateOffsetType(e.offsetType);
            updateOffsetValue(e.offsetValue);
            quickSelectMenu.value = false;
        }
        function updateFromValue(e) {
            emit("update:fromValue", e && e.toJSDate());
        }
        function updateToValue(e) {
            emit("update:toValue", e && e.toJSDate());
        }
        function updateOffsetType(e) {
            emit("update:offsetType", e);
        }
        function updateOffsetValue(e) {
            emit("update:offsetValue", e);
        }
        return { __sfc: true, props: props, emit: emit, quickSelectMenu: quickSelectMenu, dateFromMenu: dateFromMenu, dateToMenu: dateToMenu, rangeSelection: rangeSelection, updateRangeType: updateRangeType, updateTimeRange: updateTimeRange, updateFromValue: updateFromValue, updateToValue: updateToValue, updateOffsetType: updateOffsetType, updateOffsetValue: updateOffsetValue, shortDateTimeFormat: filters_1.shortDateTimeFormat, MenuDateSelect: MenuDateSelect_vue_1.default, MenuQuickSelect: MenuQuickSelect_vue_1.default };
    }
});
