import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.hasData)?_c(VRow,[_c(VCol,[_c(_setup.BasicList,{attrs:{"columns":_setup.tableColumns,"data-source":_setup.tableData,"grid-params":{
          allowResizing: true,
          autoFitColumns: false,
        }}})],1)],1):_c(VRow,[_c(VCol,[_vm._v("No data")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }