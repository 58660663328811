import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Metrics For All Time")]),_c('MetricBox',{attrs:{"title":"Triage: Pending","show-accordion":!!Object.keys((_vm.results_todo_search || {}).False || {}).length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.loading_todo,"val":!_vm.isEmpty(_vm.results_todo?.False?.todo_total) ? _vm.results_todo.False.todo_total : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Risk Categ.","loading":_vm.loading_todo_search,"period-data":_vm.results_todo_search ? _vm.results_todo_search.False : null,"field-name":'todo_total'}})]},proxy:true}])}),_c('MetricBox',{attrs:{"title":"Triage: Actioned","show-accordion":!!Object.keys((_vm.results_todo_search || {}).True || {}).length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.loading_todo,"val":!_vm.isEmpty(_vm.results_todo?.True?.todo_total) ? _vm.results_todo.True.todo_total : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Risk Categ.","loading":_vm.loading_todo_search,"period-data":_vm.results_todo_search ? _vm.results_todo_search.True : null,"field-name":'todo_total'}})]},proxy:true}])}),_c('MetricBox',{attrs:{"title":"Triage: Avg Time to Action","show-accordion":!!Object.keys((_vm.results_todo_search || {}).True || {}).length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.loading_todo,"val":!_vm.isEmpty(_vm.results_todo?.True?.actioned_avg)
                ? parseInt(_vm.results_todo.True.actioned_avg) + ' hours'
                : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Risk Categ.","loading":_vm.loading_todo_search,"period-data":_vm.results_todo_search ? _vm.results_todo_search.True : null,"field-name":'actioned_avg'}})]},proxy:true}])})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Case Totals")]),_c('MetricBox',{attrs:{"title":"Open Cases","show-accordion":!!_vm.totalCasesByStatusAndCatList?.open?.length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.totalCasesByStatusLoading,"val":!_vm.isEmpty(_vm.totalCasesByStatusList?.open?.case_total)
                ? _vm.totalCasesByStatusList.open.case_total
                : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Category","loading":_vm.totalCasesByStatusLoading,"period-data-list":_vm.totalCasesByStatusAndCatList?.open,"key-name":"cat","field-name":"case_total"}})]},proxy:true}])}),_c('MetricBox',{attrs:{"title":"Closed Cases","show-accordion":!!_vm.totalCasesByStatusAndCatList?.closed?.length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.totalCasesByStatusLoading,"val":!_vm.isEmpty(_vm.totalCasesByStatusList?.closed?.case_total)
                ? _vm.totalCasesByStatusList.closed.case_total
                : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Category","loading":_vm.totalCasesByStatusLoading,"period-data-list":_vm.totalCasesByStatusAndCatList?.closed,"key-name":"cat","field-name":"case_total"}})]},proxy:true}])}),_c('MetricBox',{attrs:{"title":"Avg Time to Close Case","show-accordion":!!_vm.totalCasesByStatusAndCatList?.closed.length},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c('Metric',{attrs:{"loading":_vm.totalCasesByStatusLoading,"val":!_vm.isEmpty(_vm.totalCasesByStatusList?.closed?.duration_avg)
                ? parseInt(_vm.totalCasesByStatusList.closed.duration_avg) + ' hours'
                : 'N/A'}})]},proxy:true},{key:"metric-detail",fn:function(){return [_c('MetricList',{attrs:{"title":"by Category","loading":_vm.totalCasesByStatusLoading,"period-data-list":_vm.totalCasesByStatusAndCatList.closed,"key-name":"cat","field-name":"duration_avg"}})]},proxy:true}])})],1)],1),_c(VRow,[_c(VCol,[(_vm.results_case_name_chart_data.data_todoitem_total.length)?_c(VCard,[_c(VCardTitle,[_c('h5',[_vm._v("Case Items by Case")])]),_c(VCardText,[_c('ChartJs',{attrs:{"height":"500px","type":"bar","data":{
              datasets: [
                {
                  data: _vm.results_case_name_chart_data.data_todoitem_total,
                  backgroundColor: _vm.results_case_name_chart_data.backgroundColor,
                },
              ],
              labels: _vm.results_case_name_chart_data.labels,
            },"options":{
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  padding: 2,
                },
                y: {
                  ticks: {
                    stepSize: 1,
                  },
                },
              },
            }}})],1)],1):_vm._e()],1),_c(VCol,[(_vm.results_case_name_chart_data.data_casenote_total.length)?_c(VCard,[_c(VCardTitle,[_c('h5',[_vm._v("Case Notes by Case")])]),_c(VCardText,[_c('ChartJs',{attrs:{"height":"500px","type":"bar","data":{
              datasets: [
                {
                  data: _vm.results_case_name_chart_data.data_casenote_total,
                  backgroundColor: _vm.results_case_name_chart_data.backgroundColor,
                },
              ],
              labels: _vm.results_case_name_chart_data.labels,
            },"options":{
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: '',
                },
              },
              scales: {
                x: {
                  padding: 2,
                },
                y: {
                  ticks: {
                    stepSize: 1,
                  },
                },
              },
            }}})],1)],1):_vm._e()],1)],1),_c(VRow,[_c(VCol,[(_vm.results_todo_type_actionreason)?_c('MetricBox',{attrs:{"title":"Channel V No Action Reason","show-accordion":false},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Email")]),_c('th',[_vm._v("BBG Msg")]),_c('th',[_vm._v("BBG IM")])])]),_c('tbody',_vm._l((_vm.results_todo_type_actionreason),function(cat_name,cat_index){return _c('tr',{key:cat_index},[(cat_index !== 'None' && cat_index !== 'Added via Adhoc Search')?[_c('td',[_vm._v(" "+_vm._s(cat_index)+" ")]),_c('td',[_vm._v(" "+_vm._s(cat_name["email"] ? cat_name["email"]["todo_total"] : 0)+" ")]),_c('td',[_vm._v(" "+_vm._s(cat_name["bbg.msg"] ? cat_name["bbg.msg"]["todo_total"] : 0)+" ")]),_c('td',[_vm._v(" "+_vm._s(cat_name["bbg.im"] ? cat_name["bbg.im"]["todo_total"] : 0)+" ")])]:_vm._e()],2)}),0)])]},proxy:true}],null,false,1429540458)}):_vm._e()],1)],1),_c(VRow,[_c(VCol,[(_vm.results_todo_search_actionreason && _vm.results_todo_search['True'])?_c('MetricBox',{staticClass:"scroll-box",attrs:{"title":"Category V No Action Reason","show-accordion":false},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c(VSimpleTable,[_c('thead',[(_vm.results_todo_search)?_c('tr',[_c('th'),_vm._l((_vm.results_todo_search.True),function(search_name,search_index){return _c('th',{key:search_index},[_vm._v(" "+_vm._s(search_index)+" ")])})],2):_vm._e()]),_c('tbody',_vm._l((_vm.results_todo_search_actionreason),function(cat_name,cat_index){return _c('tr',{key:cat_index},[(
                    _vm.results_todo_search &&
                    cat_index !== 'None' &&
                    cat_index !== 'Added via Adhoc Search'
                  )?[_c('td',[_vm._v(" "+_vm._s(cat_index)+" ")]),_vm._l((_vm.results_todo_search.True),function(search_name,search_index){return _c('td',{key:search_index},[_vm._v(" "+_vm._s(cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0)+" ")])})]:_vm._e()],2)}),0)])]},proxy:true}],null,false,1916050702)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }