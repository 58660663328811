import { render, staticRenderFns } from "./CaseDataTable.vue?vue&type=template&id=490e3903&scoped=true"
import script from "./CaseDataTable.vue?vue&type=script&lang=js"
export * from "./CaseDataTable.vue?vue&type=script&lang=js"
import style0 from "./CaseDataTable.vue?vue&type=style&index=0&id=490e3903&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490e3903",
  null
  
)

export default component.exports