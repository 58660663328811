<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="loadingData">Loading data...</div>
  <v-timeline v-else dense>
    <v-timeline-item v-for="(historyItem, index) of historyDiff" :key="index">
      <v-card class="mx-auto">
        <v-card-subtitle>
          {{ fullDateTimeFormat(historyItem.revision.date_created) }}
          <span v-if="historyItem.revision.user">
            - {{ historyItem.revision.user.full_name }}
          </span>
        </v-card-subtitle>
        <v-card-text class="text--primary">
          <span v-if="historyItem.old === null">
            <h3>Item created</h3>
            <p>
              Summary: {{ historyItem.current.summary }}
              <br />
              Note: <span v-html="historyItem.current.note" />
              <span v-if="historyItem.current.link">
                Link:
                <a :href="historyItem.current.link" target="_blank">
                  {{ historyItem.current.link }}
                </a>
              </span>
            </p>
          </span>
          <span v-if="historyItem.old !== null">
            <h3>Item changed</h3>
            <p>
              <span v-if="historyItem.current.summary !== historyItem.old.summary">
                Summary:
                <span class="striked">{{ historyItem.old.summary }}</span>
                --&gt; {{ historyItem.current.summary }}
                <br />
              </span>
              <span v-if="historyItem.current.note !== historyItem.old.note">
                Note:
                <div class="striked ml-2" v-html="historyItem.old.note" />
                <small>changed to</small>
                <div class="ml-2" v-html="historyItem.current.note" />
              </span>
              <span v-if="historyItem.current.link !== historyItem.old.link">
                Link:
                <span class="striked">
                  <a :href="historyItem.old.link" target="_blank">
                    {{ historyItem.old.link }}
                  </a>
                </span>
                --&gt;
                <a :href="historyItem.current.link" target="_blank">
                  {{ historyItem.current.link }}
                </a>
                <br />
              </span>
              <span>
                <small>Changes to attached file are not tracked</small>
              </span>
            </p>
          </span>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { fullDateTimeFormat } from "@/filters";
import caseRepository from "@/repositories/case";

export default {
  name: "CaseNoteHistory",
  props: {
    noteId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      loadingData: true,
    };
  },
  computed: {
    historyDiff() {
      return this.history.map((h, i, arr) => ({
        revision: h.revision,
        current: { ...h.field_dict },
        old: arr[i + 1]?.field_dict ? { ...arr[i + 1]?.field_dict } : null,
      }));
    },
  },
  async mounted() {
    const response = await caseRepository.noteHistory(this.noteId);
    this.history = response.data;
    this.loadingData = false;
  },
  methods: {
    fullDateTimeFormat,
  },
};
</script>

<style scoped>
.striked {
  text-decoration: line-through;
}
</style>
