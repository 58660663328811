import WatchList from "@/components/crud/WatchList.vue";
import WatchForm from "@/components/watch/WatchForm.vue";
import ExportList from "@/components/crud/ExportList.vue";

const routes = [
  {
    path: "/watch/saved/create/",
    redirect: { name: "NewWatch" },
    meta: {
      checkAccountFeatures: ({ canAddWatch }) => canAddWatch,
    },
  },
  {
    path: "/watch/:search_id?/create/",
    name: "NewWatch",
    component: WatchForm,
    meta: {
      checkAccountFeatures: ({ canAddWatch }) => canAddWatch,
      refreshTo: { name: "WatchList" },
    },
  },
  {
    path: "/watch/saved/:pk/update/",
    name: "WatchForm",
    component: WatchForm,
    meta: {
      checkAccountFeatures: ({ canEditWatch }) => canEditWatch,
      refreshTo: { name: "WatchList" },
    },
  },
  {
    path: "/watch/saved/",
    name: "WatchList",
    component: WatchList,
    meta: {
      checkAccountFeatures: ({ canShowWatch }) => canShowWatch,
    },
  },
  {
    path: "/watch/reports/",
    name: "WatchReports",
    component: ExportList,
    meta: {
      checkAccountFeatures: ({ canShowWatch }) => canShowWatch,
    },
  },
];

export default routes;
