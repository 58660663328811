import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Event Log")]},proxy:true}])},[_c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"chips":"","clearable":"","deletable-chips":"","items":_vm.actions,"item-value":"key","item-text":"label","label":"Filter Actions","multiple":"","hide-details":""},on:{"change":_vm.handleTableUpdateOptions},model:{value:(_vm.selectedActions),callback:function ($$v) {_vm.selectedActions=$$v},expression:"selectedActions"}})],1),_c(VCol,[_c(VAutocomplete,{attrs:{"chips":"","clearable":"","deletable-chips":"","items":_vm.users,"item-value":"id","item-text":"name","label":"Filter Users","multiple":"","hide-details":""},on:{"change":_vm.handleTableUpdateOptions},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),(_vm.firms.length > 1)?_c(VCol,[_c(VAutocomplete,{attrs:{"chips":"","clearable":"","deletable-chips":"","items":_vm.firms,"item-value":"id","item-text":"firm_name","label":`Filter ${_vm.firmLabelObject.pluralUppercase}`,"multiple":"","hide-details":""},on:{"change":_vm.handleTableUpdateOptions},model:{value:(_vm.selectedFirms),callback:function ($$v) {_vm.selectedFirms=$$v},expression:"selectedFirms"}})],1):_vm._e()],1),_c(VDataTable,{attrs:{"loading":_vm.showLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsCount,"items-per-page":25,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"sort-by":"event.created","sort-desc":true,"show-expand":""},on:{"update:options":_vm.handleTableUpdateOptions},scopedSlots:_vm._u([{key:"item.event.action.keyword",fn:function({ item }){return [_vm._v(" "+_vm._s(item.event.action)+" ")]}},{key:"item.django.user_id",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.users.find((u) => u.id === item.django.user_id)?.full_name)+" ")]}},{key:"item.django.firm_id",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.firms.find((f) => f.id === item.django.firm_id)?.firm_name)+" ")]}},{key:"item.event.created",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(item.event.created))+" ")]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c(VContainer,{attrs:{"fluid":""}},[_c('Log',{attrs:{"data":item}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }