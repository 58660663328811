<template>
  <div v-if="!displayForm">
    <h1>
      <v-progress-circular indeterminate color="primary" />
      Loading data...
    </h1>
  </div>
  <div v-else>
    <h1>{{ isNew ? "Add" : "Edit" }} Report Schedule</h1>

    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-text-field
        v-model="formObj.name"
        placeholder="Name"
        hint="Short name for this schedule, for quick reference later"
        :rules="rules.name"
      />

      <v-select
        v-model="formObj.fingerprint_firm"
        :label="firmLabelObject.singularUppercase"
        :items="firms"
        item-value="id"
        item-text="firm_name"
        clearable
      />

      <v-select
        v-model="formObj.report_template"
        :rules="rules.report_template"
        label="Template"
        :items="templates"
        item-value="id"
        item-text="name"
      />

      <v-select
        v-model="formObj.distribution_list"
        label="Distribution list"
        :items="distributionLists"
        item-value="id"
        item-text="name"
        clearable
      />

      <CronEditor v-model="formObj.schedule" :periods="['year', 'month', 'week', 'day']" />
      <!-- <v-text-field
            v-model="cronstrued"
            placeholder="Select a schedule from the editor above"
            readonly
            :rules="rules.schedule"
          /> -->
      <br />

      <v-select
        v-model="formObj.default_item_period_type"
        :rules="rules.default_item_period_type"
        clearable
        label="Period type"
        :items="reportTimePeriods"
        item-value="key"
        item-text="label"
      />

      <v-select
        v-model="formObj.notify_by"
        label="Notification"
        hint="Enter a notification type for this report schedule"
        :items="notifyTypes"
        item-text="name"
        item-value="id"
        multiple
        small-chips
        deletable-chips
      />

      <v-btn class="mx-1" :to="{ name: 'ReportingSchedule' }"> Cancel </v-btn>
      <v-btn color="primary" class="mx-1" :disabled="!formValid" @click="handleSave">
        {{ isNew ? "Save" : "Update" }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import cronstrue from "cronstrue";
import { mapState } from "pinia";

import CronEditor from "@/components/common/CronEditor.vue";
import { getFirms } from "@/repositories/firm";
import {
  createReportingSchedule,
  getReportDistributionLists,
  getReportingSchedule,
  getReportingTemplates,
  updateReportingSchedule,
} from "@/repositories/reporting";
import { getReportNotifyTypes, getReportTimePeriods } from "@/repositories/settings";
import { getUserList } from "@/repositories/user";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "ScheduleForm",
  components: {
    CronEditor,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      firms: [],
      users: [],
      templates: [],
      distributionLists: [],
      notifyTypes: [],
      schedule: null,
      displayForm: false,
      formObj: {
        schedule: "0 0 * * *",
      },
      formValid: false,
      rules: {
        name: [
          /* istanbul ignore next */
          (v) => !!v || "This field is mandatory",
        ],
        report_template: [
          /* istanbul ignore next */
          (v) => !!v || "This field is mandatory",
        ],
        default_item_period_type: [
          /* istanbul ignore next */
          (v) => !!v || "This field is mandatory",
        ],
      },
      reportTimePeriods: [],
    };
  },
  computed: {
    ...mapState(useAuthStore, ["firmLabelObject"]),
    cronstrued() {
      return this.formObj.schedule && cronstrue.toString(this.formObj.schedule);
    },
    isNew() {
      return this.id === "new";
    },
  },
  async mounted() {
    const [
      firms,
      users,
      reportTimePeriods,
      { data: templates },
      { data: distributionLists },
      notifyTypes,
    ] = await Promise.all([
      getFirms(),
      getUserList({ expand: undefined }),
      getReportTimePeriods(),
      getReportingTemplates(),
      getReportDistributionLists(),
      getReportNotifyTypes(),
    ]);
    this.firms = firms;
    this.users = users;
    this.reportTimePeriods = reportTimePeriods;
    this.templates = templates;
    this.distributionLists = distributionLists;
    this.notifyTypes = notifyTypes;
    if (this.isNew) {
      if (this.firms.length === 1) {
        this.formObj.fingerprint_firm = this.firms[0].id;
      }
      if (this.templates.length === 1) {
        this.formObj.report_template = this.templates[0].id;
      }
    } else {
      const { data } = await getReportingSchedule(this.id).catch(() => null);
      this.schedule = data || {};
      this.formObj = {
        ...(this.schedule || {}),
      };
    }
    this.displayForm = true;
  },
  methods: {
    async handleSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const saveFn = this.isNew ? createReportingSchedule : updateReportingSchedule;
      await saveFn({
        ...this.formObj,
      });
      this.$router.push({ name: "ReportingSchedule" });
    },
  },
};
</script>
