<template>
  <div>
    <PageFrame>
      <template #title>Saved Searches</template>

      <template #actions>
        <v-btn v-if="permissions.add" :to="{ name: 'NewSearch' }">
          <v-icon left>mdi-plus</v-icon>
          Add New
        </v-btn>
      </template>

      <v-checkbox
        v-model="includeDeleted"
        label="Include disabled items"
        @change="handleTableUpdateOptions()"
      />
      <v-data-table
        :loading="showLoading"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
        }"
        @update:options="handleTableUpdateOptions"
      >
        <template v-slot:item.is_deleted="{ item }">
          <v-tooltip bottom v-if="item.permissions.change">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                icon
                v-on="on"
                :to="{ name: 'SavedSearch', params: { pk: item.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="item.is_deleted ? item.permissions.change : item.permissions.delete"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                :color="item.is_deleted ? 'warning' : 'primary'"
                icon
                v-on="on"
                @click="handleToggleItem(item)"
              >
                <v-icon>{{
                  item.is_deleted ? "mdi-toggle-switch-off" : "mdi-toggle-switch"
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.is_deleted ? "Enable" : "Disable" }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_deleted">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                color="primary"
                icon
                :to="{ name: 'SearchResults', params: { pk: item.id } }"
                v-on="on"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.is_deleted">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                color="warning"
                icon
                :to="{ name: 'NewWatch', params: { search_id: item.id } }"
                v-on="on"
              >
                <v-icon>mdi-clock-outline</v-icon>
              </v-btn>
            </template>
            <span>Schedule</span>
          </v-tooltip>
        </template>
        <template v-slot:item.from="{ item }">
          <span v-if="item.type === 'Relative'">
            Last {{ item.dt_offset_count }} {{ item.dt_offset_type_human }}
          </span>
          <span v-else>
            {{ mediumDateTimeFormat(item.dt_from) }}
          </span>
        </template>
        <template v-slot:item.to="{ item }">
          <span v-if="item.type === 'Relative'">Now</span>
          <span v-else>
            {{ mediumDateTimeFormat(item.dt_to) }}
          </span>
        </template>
        <template v-slot:item.data_types="{ item }">
          <v-chip v-for="dt in item.data_types" :key="dt" class="ma-1" label outlined small>
            {{ dt }}
          </v-chip>
        </template>
        <template v-slot:item.created_by="{ item }">
          {{ item.created_by.full_name }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import PageFrame from "@/components/PageFrame.vue";
import { mediumDateTimeFormat } from "@/filters";
import searchRepository from "@/repositories/search";
import { vuetifyGridOptionsToQueryParams } from "@/utils";

export default {
  components: { PageFrame },
  data() {
    return {
      permissions: {},
      lastFilterOptions: {},
      includeDeleted: false,
      showLoading: false,
      headers: [
        { value: "is_deleted", text: "Actions", width: "110px" },
        { value: "name", text: "Name" },
        { value: "type", text: "Type" },
        { value: "description", text: "Description" },
        { value: "query", text: "Query" },
        { value: "from", text: "From", sortable: false },
        { value: "to", text: "To", sortable: false },
        { value: "data_types", text: "Data Types" },
        { value: "created_by", text: "Owner" },
      ],
      items: [],
      itemsCount: 0,
    };
  },
  async mounted() {
    const { data } = await searchRepository.searchPermission();
    this.permissions = data;
  },
  methods: {
    mediumDateTimeFormat,
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      if (options) {
        this.lastFilterOptions = options;
      }
      const state = vuetifyGridOptionsToQueryParams(this.lastFilterOptions);
      const { data } = await searchRepository.searchList({
        ...state,
        is_deleted: this.includeDeleted ? undefined : false,
        source: "user",
      });
      this.items = data.results;
      this.itemsCount = data.count;
      this.showLoading = false;
    },
    async handleToggleItem(item) {
      this.showLoading = true;
      const callFn = item.is_deleted
        ? searchRepository.searchRestore
        : searchRepository.searchDelete;
      try {
        await callFn(item.id);
      } finally {
        this.showLoading = false;
        this.handleTableUpdateOptions();
      }
    },
  },
};
</script>
