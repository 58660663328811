<template>
  <div>
    <v-row v-if="canShowCount" no-gutters>
      <v-col cols="12">
        <v-chip color="warning" class="my-1">Group of {{ data.group_total }} items</v-chip>
      </v-col>
    </v-row>
    <v-row v-if="canChangeTriageItem && data.actioned" no-gutters>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn class="ma-1" color="error" v-bind="attrs" v-on="on" @click="unignoreItem">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span
            >Move {{ canShowCount ? `${data.group_total} items` : "this item" }} back into the
            'Pending' list</span
          >
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="canAddCaseItem && !data.actioned" no-gutters>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn class="ma-1" color="error" v-bind="attrs" v-on="on" @click="ignoreItemDialog">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <span>
            Move {{ canShowCount ? `${data.group_total} items` : "this item" }} to the 'Reviewed -
            No Action' list
          </span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn class="ma-1" color="secondary" v-bind="attrs" v-on="on" @click="addItemDialog">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add {{ canShowCount ? `${data.group_total} items` : "this item" }} to a case</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";

import EventBus from "@/eventBus";
import {
  addItemsToCase,
  addItemGroupsToCase,
  ignoreTodoItems,
  ignoreTodoItemGroups,
  resetTodoItems,
  resetTodoItemGroups,
} from "@/repositories/todo";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "ActionsTodo",
  props: {
    showCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "canAddCaseItem",
      "canChangeTriageItem",
      "defaultIgnoreReasonCategoryID",
    ]),
    canShowCount() {
      return this.showCount && this.data.group_total > 1;
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["updateDefaultIgnoreReasonCategoryID"]),
    ...mapActions(useStore, ["showMessageDialog"]),
    async addItemDialog() {
      EventBus.$emit("caseSelect", [this.data], (caseObj) => this.addItem(caseObj));
    },
    async addItem(caseObj) {
      try {
        if (this.showCount) {
          await addItemGroupsToCase([this.data.group_id], caseObj.case_id || caseObj.id);
        } else {
          await addItemsToCase([this.data.django_id], caseObj.case_id || caseObj.id);
        }
      } catch (error) {
        console.error("addItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
    async ignoreItemDialog() {
      if (this.defaultIgnoreReasonCategoryID) {
        this.ignoreItemHandler(this.defaultIgnoreReasonCategoryID);
      } else {
        EventBus.$emit("setIgnoreReason", this.ignoreItemHandler);
      }
    },
    async ignoreItemHandler(ignoreCategoryId, ignoreReason, useCategoryAsDefault) {
      await this.ignoreItem(ignoreCategoryId, ignoreReason);
      if (useCategoryAsDefault) {
        this.updateDefaultIgnoreReasonCategoryID(ignoreCategoryId);
      }
    },
    async ignoreItem(ignoreCategoryId, ignoreReason) {
      try {
        if (this.showCount) {
          await ignoreTodoItemGroups([this.data.group_id], ignoreCategoryId, ignoreReason);
        } else {
          await ignoreTodoItems([this.data.django_id], ignoreCategoryId, ignoreReason);
        }
      } catch (error) {
        console.error("ignoreItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
    async unignoreItem() {
      try {
        if (this.showCount) {
          await resetTodoItemGroups([this.data.group_id]);
        } else {
          await resetTodoItems([this.data.django_id]);
        }
      } catch (error) {
        console.error("unignoreItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
