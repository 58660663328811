<template>
  <v-card>
    <v-card-title>
      <span v-if="noteId">Edit Case Note</span>
      <span v-else>Add Case Note</span>
    </v-card-title>
    <v-card-text>
      <div v-if="loadingData">Loading data...</div>
      <v-form v-show="!loadingData">
        <v-text-field
          v-model="params.summary"
          label="Summary"
          :rules="[(v) => !!v || 'Please enter a summary.']"
        />

        <label class="control-label">Note</label>
        <div>
          <ejs-richtexteditor
            id="note"
            ref="noteObj"
            v-model="params.note"
            name="note"
            :height="350"
            :toolbar-settings="toolbarSettings"
            :enable-auto-url="false"
          />
        </div>

        <div v-if="params.file_obj && params.file_obj.url" class>
          <a :href="params.file_obj.url" target="_blank">{{ params.file_obj.name }}</a>
          <v-btn small class="mx-2 mt-2" color="primary" @click="clearFile"> Clear </v-btn>
        </div>
        <v-file-input v-else v-model="params.file_obj" label="Upload File" show-size />

        <v-text-field
          type="url"
          v-model="params.link"
          label="URL Link"
          name="link"
          maxlength="200"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="$emit('close-dialog', false)"> Cancel </v-btn>
      <v-btn color="warning" text @click="save">
        {{ noteId ? "Update" : "Save" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
} from "@syncfusion/ej2-vue-richtexteditor";

import caseRepository, { getCase } from "@/repositories/case";
import EventBus from "@/eventBus";

export default {
  name: "CaseNoteForm",
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar],
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    todoItemId: {
      type: String,
      required: false,
      default: null,
    },
    noteId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      savedData: null,
      params: {
        summary: null,
        note: null,
        file_obj: null,
        link: null,
      },
      toolbarSettings: {
        type: "Expand",
        items: [
          "Bold",
          "Italic",
          "Underline",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "|",
          "Formats",
          "OrderedList",
          "UnorderedList",
          "|",
          "CreateLink",
          "|",
          "Undo",
          "Redo",
        ],
      },
      loadingData: true,
    };
  },
  async mounted() {
    if (this.noteId) {
      try {
        const { data } = await caseRepository.note(this.noteId);
        this.savedData = data;
        this.params = {
          ...this.params,
          ...data,
        };
      } catch (error) {
        console.error("getSavedData error", error);
        EventBus.$emit("notify", "warn", error);
        this.$emit("close-dialog", true);
        return;
      }
    }
    this.loadingData = false;
    setTimeout(() => this.$refs.noteObj && this.$refs.noteObj.refreshUI(), 500);
  },
  methods: {
    async getCase() {
      try {
        const r = await getCase(this.caseId);
        return r.data;
      } catch (error) {
        console.error("getCase error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    clearFile() {
      this.params.file_obj = null;
    },
    getFormData() {
      const formData = new FormData();
      formData.append("id", this.params.id);
      formData.append("summary", this.params.summary);
      formData.append("note", this.params.note);
      formData.append("file", this.params.file_obj || "");
      formData.append("link", this.params.link || "");
      formData.append("case", this.caseId);
      formData.append("todo_item", this.todoItemId || "");
      return formData;
    },
    async save() {
      try {
        await caseRepository.noteSave(this.getFormData(), this.noteId);
        this.$emit("close-dialog", true);
      } catch (error) {
        console.error("save error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-inputs/styles/material3.css";
@import "@syncfusion/ej2-lists/styles/material3.css";
@import "@syncfusion/ej2-popups/styles/material3.css";
@import "@syncfusion/ej2-buttons/styles/material3.css";
@import "@syncfusion/ej2-navigations/styles/material3.css";
@import "@syncfusion/ej2-splitbuttons/styles/material3.css";
@import "@syncfusion/ej2-vue-richtexteditor/styles/material3.css";
</style>
