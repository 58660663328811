import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(" Suspicious Term \""+_vm._s(_vm.$props.name)+"\" "),(_setup.isSaving)?_c(VProgressCircular,{attrs:{"dense":"","indeterminate":""}}):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _setup.emit('close')}}},[_c(VIcon,[_vm._v("mdi-close-thick")])],1)],1),_c(VCardText,{staticClass:"text-primary"},[_c(VRow,[_c(VCol,[_vm._v(" You can boost the score of each channel by moving right the slider."),_c('br'),_vm._v(" The default value is 1.0, the score can be increased/decreased by 0.25 between 0.25 and 3.0. ")])],1),_vm._l((_setup.boostData),function(categoryBoost){return _c(VRow,{key:categoryBoost.data_type},[_c(VCol,{attrs:{"cols":"4","align-self":"center"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(categoryBoost.dataTypeIcon)+" ")]),_vm._v(" "+_vm._s(categoryBoost.dataTypeName)+" ")],1),_c(VCol,[_c(VSlider,{attrs:{"min":"-100","max":"200","step":"25","ticks":"always","tick-size":"4","persistent-hint":"","disabled":categoryBoost.boost === -100,"hint":`Score set: ${1 + (1 * categoryBoost.boost) / 100}`},on:{"change":function($event){return _setup.handleBoostUpdate(categoryBoost)}},model:{value:(categoryBoost.boost),callback:function ($$v) {_vm.$set(categoryBoost, "boost", $$v)},expression:"categoryBoost.boost"}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","link":"","small":""},on:{"click":function($event){return _setup.handleBoostUpdate(categoryBoost, 0)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-undo")])],1)]}}],null,true)},[_c('span',[_vm._v("Reset default score")])])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }