<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h5>Case Categories for {{ periodOptions.periodValue }}</h5>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-2" v-on="on">mdi-information</v-icon>
              </template>
              <div>Cases created within the time period, by category</div>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <ChartJs
              v-if="data_case_timecreated_status_category !== undefined"
              height="300px"
              type="pie"
              :data="pie_chart_data"
              :options="pie_chart_options"
            />
            <span v-else>No data available for this period</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartJs from "@/components/ChartJs.vue";
import { fillCategoryPieData } from "@/components/metrics/utils";

export default {
  name: "CaseCategoriesForPeriod",
  components: {
    ChartJs,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
    periodOptions: {
      type: Object,
      default: () => ({
        periodValue: null,
      }),
    },
  },
  data() {
    return {
      pie_chart_options: {},
    };
  },
  computed: {
    data_case_timecreated_status_category() {
      return this.jsonData?.CaseStatsByTimeCreatedByStatusByCategory?.[
        this.periodOptions.periodValue
      ];
    },
    pie_chart_data() {
      return fillCategoryPieData(this.data_case_timecreated_status_category) || {};
    },
  },
};
</script>
