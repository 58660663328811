import { getCachedOrFetch } from "@/cache";
import httpClient from "@/interceptors/axios";
import { withFirmGroups } from "./utils";

export async function getFirms(params = {}) {
  const _params = withFirmGroups({
    take: "all",
    ...params,
  });
  return getCachedOrFetch(
    `firms__${btoa(JSON.stringify(_params))}`,
    async () => {
      try {
        const { data } = await httpClient.get("firms/", {
          params: _params,
          paramsSerializer: { indexes: null }, // https://github.com/axios/axios#request-config
        });
        return data;
      } catch (err) {
        return [];
      }
    },
    { ttl: 1000 * 60 * 5 /* 5 minutes */ },
  );
}

export function getFirm(id) {
  return httpClient.get(`firms/${id}/`);
}
