import httpClient from "@/interceptors/axios";

export async function getUserList(params) {
  try {
    const { data } = await httpClient.get("users/", {
      params: {
        expand: "account,groups,firm_groups",
        ...params,
        take: "all",
      },
    });
    return data;
  } catch {
    return [];
  }
}

export function saveUser(id, payload) {
  return httpClient.patch(`users/${id}/`, payload);
}

export function setUserObfuscation(id, obfuscation_level = 0) {
  return saveUser(id, { obfuscation_level });
}

export function setUserSettings(id, settings = {}) {
  return saveUser(id, { settings });
}
