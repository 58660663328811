import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"350"},model:{value:(_setup.dialogDelete),callback:function ($$v) {_setup.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Do you really want to delete the selected distribution list? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_setup.dialogDelete = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_setup.handleConfirmDelete}},[_vm._v("Delete")])],1)],1)],1),_c(_setup.PageFrame,{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Report distribution lists")]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"to":{ name: 'ReportingDistributionListDetail', params: { id: 'new' } }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New ")],1)]},proxy:true}])},[_c(VDataTable,{attrs:{"loading":_setup.isLoading,"headers":_setup.headers,"items":_setup.items,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(_setup.DistributionListsActions,{attrs:{"data":item},on:{"delete":_setup.handleDeleteClick}})]}},{key:"item.fingerprint_firm__firm_name",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.firms.find((firm) => firm.id === item.fingerprint_firm)?.firm_name)+" ")]}},{key:"item.external_email_recipients",fn:function({ item }){return [_vm._v(" "+_vm._s(item.external_email_recipients.join(", "))+" ")]}},{key:"item.created_on",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(item.created_on))+" ")]}},{key:"item.modified_on",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(item.modified_on))+" ")]}},{key:"footer",fn:function(){return undefined},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }