"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.responseErrorInterceptor = exports.requestInterceptor = void 0;
var aws_amplify_1 = require("aws-amplify");
var axios_1 = require("axios");
var auth_1 = require("@/stores/auth");
var httpClient = axios_1.default.create({
    baseURL: "".concat(process.env.VUE_APP_API, "/api/v1/"),
});
var requestInterceptor = function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var requestUri, slicedUrl, currentSession, accessToken, jwtToken, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                requestUri = httpClient.getUri(request);
                if (request.method === "get" && requestUri.length > 2048) {
                    slicedUrl = requestUri.slice(0, 2048);
                    slicedUrl = slicedUrl.slice(0, slicedUrl.lastIndexOf("&"));
                    request.params = {};
                    request.url = slicedUrl.replace(request.baseURL || "", "");
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                if (!requestUri.startsWith(process.env.VUE_APP_API)) return [3 /*break*/, 3];
                return [4 /*yield*/, aws_amplify_1.Auth.currentSession()];
            case 2:
                currentSession = _a.sent();
                accessToken = currentSession.getAccessToken();
                jwtToken = accessToken.getJwtToken();
                request.headers.Authorization = "Bearer ".concat(jwtToken);
                request.headers["Cache-Control"] = "no-cache";
                _a.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                error_1 = _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/, request];
        }
    });
}); };
exports.requestInterceptor = requestInterceptor;
var responseErrorInterceptor = function (err) {
    var _a;
    if (!err.response) {
        // Server connection lost
        throw Error("Something has gone wrong on this page, please refresh the page or try again later, if the problem persists contact support.");
    }
    var data = ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data) || {};
    /**
     * error received from server, manage error message with
     * `error.response.data`
     * `error.response.status`
     * `error.response.headers`
     * displaying a generic error for now
     */
    var authStore = (0, auth_1.useAuthStore)();
    if (err.response.status === 401 && ["No matching user found."].includes(data.detail)) {
        authStore.setAuthErrorMessage(data.detail);
        authStore.awsLogout();
    }
    else if (err.response.status === 401 && err.message === "Request failed with status code 401") {
        throw new Error("You cannot access this URL without logging in");
    }
    else if (err.response.status === 403 && data.error == "ip-blocked") {
        var errorMsg = "Your IP Address (".concat(data.address, ") does not have access to the service") +
            " for the Account \"".concat(data.account, "\", User \"").concat(data.user, "\", Email \"").concat(data.email, "\".") +
            " Please contact your administrator to add it to the whitelist.";
        authStore.setAuthErrorMessage(errorMsg);
        authStore.awsLogout();
    }
    throw err;
};
exports.responseErrorInterceptor = responseErrorInterceptor;
httpClient.interceptors.request.use(exports.requestInterceptor);
httpClient.interceptors.response.use(undefined, exports.responseErrorInterceptor);
exports.default = httpClient;
