<template>
  <div>
    <PageFrame>
      <template #title>Format Report</template>

      <v-form ref="form" v-model="formValid" @submit.prevent="handleSubmit">
        <h1>Header</h1>
        <v-row>
          <v-col>
            <v-select
              v-model="formData.header_left"
              :rules="rules.header_left"
              :items="positionalItems"
              label="Header Left"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="formData.header_centre"
              :rules="rules.header_centre"
              :items="positionalItems"
              label="Header Centre"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="formData.header_right"
              :rules="rules.header_right"
              :items="positionalItems"
              label="Header Right"
            />
          </v-col>
        </v-row>

        <h1>Footer</h1>
        <v-row>
          <v-col>
            <v-select
              v-model="formData.footer_left"
              :rules="rules.footer_left"
              :items="positionalItems"
              label="Footer Left"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="formData.footer_centre"
              :rules="rules.footer_centre"
              :items="positionalItems"
              label="Footer Centre"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="formData.footer_right"
              :rules="rules.footer_right"
              :items="positionalItems"
              label="Footer Right"
            />
          </v-col>
        </v-row>

        <h1>Logo</h1>
        <v-row align="center">
          <v-col>
            <v-file-input
              v-model="formData.logo"
              :rules="rules.logo"
              accept="image/*"
              :label="`${formData.logo_url ? 'Change' : 'Add'} Logo`"
              show-size
              @change="handleLogoChange"
            />
            <v-btn v-if="formData.logo_url" color="warning" @click="handleLogoRemove">
              Remove logo
            </v-btn>
          </v-col>
          <v-col v-if="previewUrl || formData.logo_url">
            <v-img
              :src="previewUrl || formData.logo_url"
              contain
              max-height="150"
              max-width="250"
            />
          </v-col>
        </v-row>

        <h1>Default Banner text</h1>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.default_banner"
              :rules="rules.default_banner"
              auto-grow
              rows="2"
              label="Banner Text"
              clearable
            />
          </v-col>
        </v-row>

        <h1>Default Sign-off text</h1>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.default_signoff"
              :rules="rules.default_signoff"
              auto-grow
              rows="2"
              label="Sign-off Text"
              clearable
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              v-if="(formData.id && canChangeReportSettings) || canAddReportSettings"
              color="primary"
              type="submit"
              :disabled="!formValid"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" bottom color="info" timeout="2000">
          {{ snackbarText }}
        </v-snackbar>
      </v-form>
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import PageFrame from "@/components/PageFrame.vue";
import { getReportingSettings, saveReportingSettings } from "@/repositories/reporting";
import { useAuthStore } from "@/stores/auth";

const MAX_UPLOAD_SIZE_KB = 600;

export default {
  name: "ReportingCustomise",
  components: { PageFrame },
  data() {
    return {
      settings: {},
      formValid: false,
      formData: {},
      rules: {
        logo: [
          /* istanbul ignore next */
          (v) =>
            !v ||
            v.size < MAX_UPLOAD_SIZE_KB * 1024 ||
            `Max allowed size is ${MAX_UPLOAD_SIZE_KB} KB`,
        ],
      },
      positionalItems: [
        { value: "", text: "- none -" },
        { value: "{{date}}", text: "Date" },
        { value: "{{time}}", text: "Time" },
        { value: "{{date}} {{time}}", text: "Date & Time" },
        { value: "{{pageNumber}}/{{totalPages}}", text: "Page n/total" },
        { value: "Page {{pageNumber}} of {{totalPages}}", text: "Page n of total" },
      ],
      previewUrl: null,
      snackbar: false,
      snackbarText: "Settings saved",
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canAddReportSettings", "canChangeReportSettings"]),
  },
  async mounted() {
    await this.loadData();
    this.$refs.form.validate();
  },
  methods: {
    handleLogoChange(file) {
      if (file) {
        this.previewUrl = URL.createObjectURL(file);
      } else {
        this.previewUrl = null;
      }
    },
    async handleLogoRemove() {
      this.previewUrl = null;
      this.formData.logo = null;
      this.formData.logo_url = undefined;
    },
    async loadData() {
      try {
        const { data } = await getReportingSettings({ source: "user" });
        this.settings = data?.[0] || {};
      } catch {
        this.settings = {};
      }
      const formData = {};
      [
        "id",
        "header_left",
        "header_centre",
        "header_right",
        "footer_left",
        "footer_centre",
        "footer_right",
        "default_banner",
        "default_signoff",
        "logo",
        "logo_url",
      ].forEach((key) => {
        formData[key] = this.settings[key];
      });
      this.formData = formData;
      this.previewUrl = null;
    },
    async handleSubmit() {
      try {
        await saveReportingSettings(this.formData);
      } catch {
        /* empty */
      }
      this.snackbar = true;
      this.loadData();
    },
  },
};
</script>
