<template>
  <v-card class="mb-5">
    <v-expansion-panels flat :readonly="!enableExpansion">
      <v-expansion-panel>
        <v-expansion-panel-header :hide-actions="!enableExpansion">
          <div>
            <span>{{ title }}</span>

            <v-tooltip bottom v-if="$slots.info">
              <template v-slot:activator="{ on }">
                <v-icon class="ml-2" v-on="on">mdi-information</v-icon>
              </template>
              <div>
                <slot name="info"> </slot>
              </div>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <slot name="metric-detail" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <slot name="metric" />
  </v-card>
</template>

<script>
export default {
  name: "MetricBox",
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    showAccordion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    enableExpansion() {
      return this.$slots["metric-detail"] && this.showAccordion;
    },
  },
};
</script>
