import FaqHome from "@/views/faq/FaqHome.vue";

const routes = [
  {
    path: "/faq/",
    name: "FaqHome",
    component: FaqHome,
    meta: {
      checkAccountFeatures: ({ canShowFaq }) => canShowFaq,
    },
  },
];

export default routes;
