import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1):_c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_vm._v("Pending")]),_c(VCol,{attrs:{"cols":"auto"}},[_c('small',[_vm._v(_vm._s(_vm.todoPending(_vm.periodData))+" of "+_vm._s(_vm.todoTotal(_vm.periodData)))])])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VProgressLinear,{attrs:{"value":_vm.percPending(_vm.periodData),"color":"accent"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_vm._v("Reviewed - No Action")]),_c(VCol,{attrs:{"cols":"auto"}},[_c('small',[_vm._v(_vm._s(_vm.todoIgnored(_vm.periodData))+" of "+_vm._s(_vm.todoTotal(_vm.periodData)))])])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VProgressLinear,{attrs:{"value":_vm.percIgnored(_vm.periodData),"color":"warning"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_vm._v("Assigned")]),_c(VCol,{attrs:{"cols":"auto"}},[_c('small',[_vm._v(_vm._s(_vm.todoAssigned(_vm.periodData))+" of "+_vm._s(_vm.todoTotal(_vm.periodData)))])])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VProgressLinear,{attrs:{"value":_vm.percAssigned(_vm.periodData)}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }