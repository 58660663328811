<template>
  <ul>
    <li v-for="savedCase in savedCases" :key="savedCase.id">
      <router-link v-if="canShowCases" :to="{ name: 'CaseDetail', params: { pk: savedCase.id } }">
        {{ savedCase.name }}
      </router-link>
      <span v-else>{{ savedCase.name }}</span>
    </li>
  </ul>
</template>

<script>
import { mapState } from "pinia";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "SavedCasesColumn",
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canShowCases"]),
    savedCases() {
      return (this.data.saved_cases || []).reduce(
        (acc, savedCase) => ({ ...acc, [savedCase.id]: savedCase }),
        {},
      );
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0;
}
</style>
