import { useAuthStore } from "@/stores/auth";

const lorem_words = [
  "enim",
  "consequat",
  "velit",
  "non",
  "amet",
  "est",
  "nostrud",
  "nulla",
  "culpa",
  "sint",
  "tempor",
  "deserunt",
  "officia",
  "reprehenderit",
  "commodo",
  "exercitation",
  "lorem",
  "sunt",
  "qui",
  "cupidatat",
  "magna",
  "incididunt",
  "aliquip",
  "quis",
  "fugiat",
  "mollit",
  "sit",
  "eiusmod",
  "adipiscing",
  "cillum",
  "excepteur",
  "aliqua",
  "veniam",
  "duis",
  "anim",
  "proident",
  "pariatur",
  "minim",
  "voluptate",
  "laborum",
  "consectetur",
  "labore",
  "nisi",
  "irure",
  "esse",
  "laboris",
  "ipsum",
  "aute",
  "elit",
  "sed",
  "dolor",
  "occaecat",
  "dolore",
  "ullamco",
];

String.prototype.adler32 = function () {
  let a = 1,
    b = 0,
    L = this.length,
    M = 0,
    c = 0,
    d = 0;
  for (let i = 0; i < L; ) {
    M = Math.min(L - i, 3850);
    while (M > 0) {
      c = this.charCodeAt(i++);
      if (c < 0x80) {
        a += c;
      } else if (c < 0x800) {
        a += 192 | ((c >> 6) & 31);
        b += a;
        --M;
        a += 128 | (c & 63);
      } else if (c >= 0xd800 && c < 0xe000) {
        c = (c & 1023) + 64;
        d = this.charCodeAt(i++) & 1023;
        a += 240 | ((c >> 8) & 7);
        b += a;
        --M;
        a += 128 | ((c >> 2) & 63);
        b += a;
        --M;
        a += 128 | ((d >> 6) & 15) | ((c & 3) << 4);
        b += a;
        --M;
        a += 128 | (d & 63);
      } else {
        a += 224 | ((c >> 12) & 15);
        b += a;
        --M;
        a += 128 | ((c >> 6) & 63);
        b += a;
        --M;
        a += 128 | (c & 63);
      }
      b += a;
      --M;
    }
    a = 15 * (a >>> 16) + (a & 65535);
    b = 15 * (b >>> 16) + (b & 65535);
  }
  return (b % 65521 << 16) | a % 65521;
};

export const obfuscate = {
  obfuscate_replace(text) {
    if (this.level() === 1) {
      return text.replace(/&amp;/g, "&").replace(/[\b\w+\b][^%<b>%</b>][\b\w+\b]+/g, (match) => {
        return lorem_words[match.adler32() % lorem_words.length];
      });
    } else {
      return text.replace(/[a-zA-Z0-9]/g, "x");
    }
  },
  async toggle() {
    const authStore = useAuthStore();
    await authStore.toggleObfuscation();
  },

  level() {
    const authStore = useAuthStore();
    return authStore.obfuscationLevel;
  },
  obfuscate_element(el) {
    const obfuscationLevel = this.level();
    if (obfuscationLevel > 0 && el) {
      if (obfuscationLevel === 3) {
        el.style =
          "color: transparent !important; text-shadow: 0 0 10px rgba(0,0,0,0.5) !important;";
      } else {
        el.innerText = this.obfuscate_replace(el.innerText);
      }
    }
  },
  obfuscate(el) {
    const obfuscationLevel = this.level();
    if (obfuscationLevel > 0 && el) {
      const items = el.querySelectorAll(".obfuscate");
      if (items.length === 0) {
        this.obfuscate_element(el);
      } else {
        for (let i = 0; i < items.length; i++) {
          this.obfuscate_element(items[i]);
        }
      }
    }
  },
  obfuscateTextBox(el) {
    const obfuscationLevel = this.level();
    if (obfuscationLevel > 0 && el) {
      el.textContent = this.obfuscate_replace(el.textContent);
    }
  },
};

export const obfuscateDirective = {
  inserted: function (el) {
    obfuscate.obfuscate(el);
  },
  update: function (el) {
    obfuscate.obfuscate(el);
  },
};
