var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Fingerprint User List")]},proxy:true}])},[(_vm.columns.length)?_c('BasicList',{attrs:{"columns":_vm.columns,"data-source":_vm.columns && _vm.users,"grid-params":{
        toolbar: ['Edit', 'Print'],
        allowSorting: true,
        allowPaging: true,
        allowResizing: true,
        editSettings: {
          allowAdding: false,
          allowEditing: true,
          allowEditOnDblClick: true,
          allowDeleting: false,
          mode: 'Dialog',
          showDeleteConfirmDialog: true,
        },
        pageSettings: _vm.pageSettings,
        filterSettings: { type: 'Menu' },
        actionBegin: _vm.handleActionBegin,
        gridLines: 'Both',
        height: '600',
        autoFitColumns: true,
      }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }