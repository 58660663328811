<template>
  <div>
    <v-dialog v-model="showEditDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="formValid" @submit.prevent="handleSave">
            <v-text-field
              v-model.trim="editedItem.name"
              :rules="nameRules"
              autofocus
              label="Name"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showEditDialog = false">Cancel</v-btn>
          <v-btn type="submit" color="primary" :disabled="!formValid" @click="handleSave"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <PageFrame>
      <template #title>Case Categories</template>

      <template #actions>
        <v-btn v-if="permissions.add" @click="handleAdd">
          <v-icon left>mdi-plus</v-icon>
          Add New
        </v-btn>
      </template>

      <v-checkbox
        v-model="includeDeleted"
        label="Include disabled items"
        @change="handleTableUpdateOptions"
      />
      <v-data-table
        :loading="showLoading"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
        }"
        @update:options="handleTableUpdateOptions"
      >
        <template v-slot:item.is_deleted="{ item }">
          <v-tooltip bottom v-if="item.permissions.change">
            <template v-slot:activator="{ on }">
              <v-btn :disabled="showLoading" icon small v-on="on" @click="handleEdit(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="item.is_deleted ? item.permissions.change : item.permissions.delete"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                :color="item.is_deleted ? 'warning' : 'primary'"
                icon
                small
                v-on="on"
                @click="handleToggleItem(item)"
              >
                <v-icon>{{
                  item.is_deleted ? "mdi-toggle-switch-off" : "mdi-toggle-switch"
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.is_deleted ? "Enable" : "Disable" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.cases="{ item }">
          <ul>
            <li v-for="c in item.cases" :key="c.id">
              <router-link :to="{ name: 'CaseDetail', params: { pk: c.id } }">
                {{ c.name }}
              </router-link>
            </li>
          </ul>
        </template>
        <template v-slot:item.created_by="{ item }">
          {{ item.created_by.full_name }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import PageFrame from "@/components/PageFrame.vue";
import caseRepository from "@/repositories/case";
import { vuetifyGridOptionsToQueryParams } from "@/utils";

export default {
  components: { PageFrame },
  data() {
    return {
      permissions: {},
      showEditDialog: false,
      editedItem: {},
      dialogTitle: "",
      formValid: false,
      nameRules: [(v) => !!v || "Name is required"],
      lastFilterState: {},
      includeDeleted: false,
      showLoading: false,
      lastFilterOptions: {},
      headers: [
        { value: "is_deleted", text: "Actions" },
        { value: "name", text: "Name" },
        { value: "cases", text: "Cases", sortable: false },
        { value: "created_by", text: "Owner", sortable: false },
      ],
      items: [],
      itemsCount: 0,
    };
  },
  async mounted() {
    const { data } = await caseRepository.caseCategoryPermission();
    this.permissions = data;
  },
  methods: {
    handleAdd() {
      this.editedItem = {};
      this.dialogTitle = "Add New Case Category";
      this.showEditDialog = true;
    },
    handleEdit(item) {
      this.editedItem = { ...item };
      this.dialogTitle = "Edit Case Category";
      this.showEditDialog = true;
    },
    async handleSave() {
      try {
        await caseRepository.caseCategorySave(this.editedItem);
        this.showEditDialog = false;
        this.handleTableUpdateOptions();
      } catch {
        /* empty */
      }
    },
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      if (options) {
        this.lastFilterOptions = options;
      }
      const state = vuetifyGridOptionsToQueryParams(this.lastFilterOptions);
      const { data } = await caseRepository.caseCategoryList({
        ...state,
        is_deleted: this.includeDeleted ? undefined : false,
        case__is_deleted: false,
        case__case_status: "1",
        source: "user",
      });
      this.items = data.results;
      this.itemsCount = data.count;
      this.showLoading = false;
    },
    async handleToggleItem(item) {
      this.showLoading = true;
      const callFn = item.is_deleted
        ? caseRepository.caseCategoryRestore
        : caseRepository.caseCategoryDelete;
      try {
        await callFn(item.id);
      } finally {
        this.showLoading = false;
        this.handleTableUpdateOptions();
      }
    },
  },
};
</script>

<style scoped>
:deep(ul) {
  list-style-type: none;
  padding: 0;
}
</style>
