<script setup>
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted } from "vue";
import { mediumDateTimeFormat } from "@/filters";
import { useAuthStore } from "@/stores/auth";
import { useCasesStore } from "@/stores/cases";

const authStore = useAuthStore();
const {
  isAuthenticated,
  userProfile,
  canChangeUserInfo,
  canShowWorkflows,
  selectedFirmGroup,
  firmLabelObject,
  obfuscationLevel,
} = storeToRefs(authStore);
const { awsLogout } = authStore;

const casesStore = useCasesStore();
const {
  caseActivityLoading,
  caseNotesLoading,
  caseNotifications,
  caseNotificationsCount,
  caseNotificationsLoading,
  caseObjLoading,
  caseWorkflowLoading,
} = storeToRefs(casesStore);
const { getCaseNotifications } = casesStore;
async function handleLogin() {}
async function handleLogout() {
  await awsLogout();
}
let _caseNotificationsInterval = null;
onMounted(() => {
  if (canShowWorkflows.value) {
    // different intervals for case notifications
    _caseNotificationsInterval = setInterval(() => {
      // getting caseNotifications every 60 seconds, but only for the active tab
      !document.hidden && isAuthenticated.value && getCaseNotifications();
    }, 60000);
  }
  isAuthenticated.value && canShowWorkflows.value && getCaseNotifications();
});
onUnmounted(() => {
  if (_caseNotificationsInterval) {
    clearInterval(_caseNotificationsInterval);
  }
});
</script>

<template>
  <v-app-bar absolute app dense flat>
    <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')" />
    <h2>{{ userProfile?.account?.account_name }}</h2>
    <v-spacer />
    <v-menu v-if="canShowWorkflows && caseNotifications.length" offset-y>
      <template #activator="menu">
        <v-tooltip bottom>
          <template #activator="tooltip">
            <v-btn
              :disabled="
                caseObjLoading ||
                caseActivityLoading ||
                caseNotesLoading ||
                caseNotificationsLoading ||
                caseWorkflowLoading
              "
              class="mx-3 text-capitalize"
              small
              text
              v-on="{ ...menu.on, ...tooltip.on }"
            >
              <v-badge bordered overlap :content="caseNotifications.length" color="warning">
                <v-icon left> mdi-file-document-multiple </v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Cases waiting for activity</span>
        </v-tooltip>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in caseNotifications"
          :key="index"
          :to="{ name: 'CaseDetail', params: { pk: item.case.id } }"
        >
          <v-list-item-title>{{ item.case.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ mediumDateTimeFormat(item.started) }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item :to="{ name: 'CaseWorkflowList' }">
          <v-list-item-title>
            <span v-if="caseNotificationsCount > caseNotifications.length"> ... </span>
            <span v-else> All pending workflows </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-if="userProfile" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark small text v-bind="attrs" v-on="on">
          <v-icon left>mdi-account</v-icon>
          <span v-obfuscate>{{ userProfile.first_name || userProfile.name }}</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="pb-0 justify-center">
          <span v-obfuscate>{{ userProfile.name }}</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-obfuscate>{{ userProfile.email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-obfuscate>
                  {{ userProfile.account && userProfile.account.account_name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="selectedFirmGroup">
              <v-list-item-icon>
                <v-icon>mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ firmLabelObject.singularUppercase }} Group:
                  <span v-obfuscate>
                    {{ selectedFirmGroup.group_name }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-ip-network</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-obfuscate>
                  {{ userProfile.ip_address }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-btn v-if="canChangeUserInfo" small text @click="$emit('toggle-obfuscation')">
                <v-icon left>
                  {{ obfuscationLevel > 0 ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
                {{ obfuscationLevel > 0 ? "Show" : "Hide" }} private info
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small text :to="{ name: 'UserSettings' }">
                <v-icon left>mdi-cog</v-icon>
                Settings
              </v-btn>
            </v-row>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn small text @click="isAuthenticated ? handleLogout() : handleLogin()">
                <v-icon left>
                  {{ isAuthenticated ? "mdi-logout" : "mdi-login" }}
                </v-icon>
                Log {{ isAuthenticated ? "Out" : "In" }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<style lang="scss" scoped>
@import "vuetify/src/styles/styles.sass";

.v-sheet,
.theme--light.v-btn {
  color: var(--v-primary-base);
}

::v-deep(.theme--light.v-badge .v-badge__badge::after) {
  border-color: var(--v-background-base);
}

select {
  color: var(--v-primary-base);
}
</style>
