<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          v-model="cutoffValue"
          label="Cut-off value"
          type="number"
          min="0"
          @change="handleChangeCutoffValue"
        />
        <ChartJs
          v-if="chartData.data.length"
          height="500px"
          type="bar"
          :data="{
            datasets: [
              {
                data: chartData.data,
                backgroundColor: chartData.backgroundColor,
              },
            ],
            labels: chartData.labels,
          }"
          :options="{
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                padding: 2,
              },
              y: {
                ticks: {
                  stepSize: 1,
                },
              },
            },
          }"
        />
        <span v-else>No data available for this period</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartJs from "@/components/ChartJs.vue";
import { selectColour } from "@/components/metrics/utils";

export default {
  name: "CaseItemsByCase",
  components: {
    ChartJs,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cutoffValue: 0,
    };
  },
  computed: {
    results_case_name() {
      return this.jsonData?.CaseStatsByName?.all;
    },
    chartData() {
      const entries = Object.entries(this.results_case_name || {}).filter(
        ([, value]) => value.todoitem_total > this.cutoffValue,
      );
      const labels = entries.map(([key]) => key);
      const data = entries.map(([, value]) => value.todoitem_total);
      const backgroundColor = data.map((_, i) => selectColour(i + 1, data.length));
      return {
        backgroundColor,
        data,
        labels,
      };
    },
  },
  mounted() {
    this.cutoffValue = this.data.parameters?.cutoffValue || 0;
  },
  methods: {
    handleChangeCutoffValue(value) {
      this.$emit("change-report-item-parameters", {
        ...(this.data.parameters || {}),
        cutoffValue: parseInt(value, 10),
      });
    },
  },
};
</script>
