import { getRedactor } from "./redactor";

export const adaptiveRelativeTimeFormat = (milliseconds) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat
  const units = [
    { unit: "year", value: 31536000000 }, // 365 * 24 * 60 * 60 * 1000
    { unit: "quarter", value: 7884000000 }, // 3 * 30 * 24 * 60 * 60 * 1000
    { unit: "month", value: 2628000000 }, // 30 * 24 * 60 * 60 * 1000
    { unit: "week", value: 604800000 }, // 7 * 24 * 60 * 60 * 1000
    { unit: "day", value: 86400000 }, // 24 * 60 * 60 * 1000
    { unit: "hour", value: 3600000 }, // 60 * 60 * 1000
    { unit: "minute", value: 60000 }, // 60 * 1000
    // { unit: 'second', value: 1000 },
  ];
  const millisecondsAbs = Math.abs(milliseconds);
  const rtf = new Intl.RelativeTimeFormat(navigator.language, { numeric: "auto" });
  for (let unit of units) {
    if (millisecondsAbs > unit.value) {
      const diff = Math.trunc(milliseconds / unit.value);
      return rtf.format(diff, unit.unit);
    }
  }
  const diff = Math.trunc(milliseconds / 1000);
  return rtf.format(diff, "second");
};

export const fixedRelativeTimeFormat = (milliseconds, unit) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat
  const unitConversion = {
    second: 1000,
    minute: 60000,
    hour: 3600000,
    day: 86400000,
    week: 604800000,
    month: 2628000000,
    quarter: 7884000000,
    year: 31536000000,
  };
  if (unit && Object.prototype.hasOwnProperty.call(unitConversion, unit)) {
    const rtf = new Intl.RelativeTimeFormat(navigator.language, { numeric: "auto" });
    const diff = Math.trunc(milliseconds / unitConversion[unit]);
    return rtf.format(diff, unit);
  } else {
    throw new Error("Invalid unit");
  }
};

export const fullDateFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        dateStyle: "full",
      })
      .replace("\u202F", " ")
  );
};

export const fullDateTimeFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        dateStyle: "full",
        timeStyle: "short",
      })
      .replace("\u202F", " ")
  );
};

export const mediumDateTimeFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        dateStyle: "medium",
        timeStyle: "short",
      })
      .replace("\u202F", " ")
  );
};

export const shortDateTimeFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        dateStyle: "short",
        timeStyle: "short",
      })
      .replace("\u202F", " ")
  );
};

export const mediumDateFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        dateStyle: "medium",
      })
      .replace("\u202F", " ")
  );
};

export const mediumTimeFormat = (value) => {
  return (
    value &&
    new Date(value)
      .toLocaleString(navigator.language, {
        timeStyle: "short",
      })
      .replace("\u202F", " ")
  );
};

export function numberFormat(value) {
  if (value === null || value === undefined || isNaN(value)) {
    return value;
  }
  const rtf = new Intl.NumberFormat(navigator.language);
  return rtf.format(value);
}

export const redactPII = (value) => {
  return value && getRedactor().redact(value);
};

export const composeRelativeDateTimeFormat = (value, elementsToWrite = 2) => {
  elementsToWrite = elementsToWrite || 2;

  const units = [
    // commenting year, quarter and months because they are all approximations
    // { unit: 'year', value: 31536000000 }, // 365 * 24 * 60 * 60 * 1000
    // { unit: 'quarter', value: 7884000000 }, // 3 * 30 * 24 * 60 * 60 * 1000
    // { unit: 'month', value: 2628000000 }, // 30 * 24 * 60 * 60 * 1000
    { unit: "week", value: 604800000 }, // 7 * 24 * 60 * 60 * 1000
    { unit: "day", value: 86400000 }, // 24 * 60 * 60 * 1000
    { unit: "hour", value: 3600000 }, // 60 * 60 * 1000
    { unit: "minute", value: 60000 }, // 60 * 1000
    { unit: "second", value: 1000 },
  ];

  let writtenElements = 0;
  let retVal = "";
  let nextValue = Math.abs(value);

  for (let unit of units) {
    let v = Math.floor(nextValue / unit.value);
    if (writtenElements < elementsToWrite && v > 0) {
      retVal += `${writtenElements ? " " : ""}${v} ${unit.unit}${v > 1 ? "s" : ""}`;
      writtenElements++;
    }
    nextValue = nextValue % unit.value;
  }
  return retVal;
};
