<template>
  <div>
    <PageFrame>
      <template #title>Triage List</template>

      <v-row no-gutters align="center" class="mb-1">
        <v-col cols="auto">
          <v-btn
            v-for="item in statusTabs"
            :key="item.value"
            text
            :input-value="statusTabValue === item.value"
            @click="handleStatusChange(item.value)"
          >
            <v-icon small left>{{ item.icon }}</v-icon>
            {{ item.text }}
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-checkbox
            v-model="groupSimilarItems"
            label="Group similar items"
            hide-details
            dense
            class="mb-2"
            @change="showTodo(true)"
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn text @click="showTodo(true)">
            <v-icon small left>mdi-refresh</v-icon>
            Reload data
          </v-btn>
        </v-col>
      </v-row>

      <v-alert v-if="statusTabValue === 'pending' && defaultIgnoreReasonCategoryID" type="warning">
        <v-select
          :value="defaultIgnoreReasonCategoryID"
          :items="actionReasonCategories"
          clearable
          hint="You can remove this default Reason Category by clicking on the 'x' icon on the right."
          persistent-hint
          item-value="id"
          item-text="name"
          label="This default Reason Category will be used when ignoring items."
          @change="updateDefaultIgnoreReasonCategoryID"
        />
      </v-alert>

      <v-row no-gutters align="center" class="mb-1">
        <v-col cols="auto" v-if="firms.length >= 1">
          <v-select
            v-model="firmIds"
            class="mx-1 mt-3 mb-1"
            :items="firms"
            clearable
            dense
            hide-details
            multiple
            :label="`${firmLabelObject.singularUppercase} filter`"
            item-value="id"
            item-text="firm_name"
            small-chips
            deletable-chips
            @change="showTodo(false)"
          />
        </v-col>

        <v-col cols="auto" v-if="dataTypes.length >= 1">
          <v-select
            v-model="dataTypeIds"
            class="mx-1 mt-3 mb-1"
            :items="dataTypes"
            clearable
            dense
            hide-details
            multiple
            :label="`Data Type filter`"
            item-value="id"
            item-text="name"
            small-chips
            deletable-chips
            @change="showTodo(false)"
          />
        </v-col>

        <v-col cols="auto">
          <v-select
            v-model="riskBuckets"
            class="mx-1 mt-3 mb-1"
            :items="riskBucketList"
            clearable
            dense
            hide-details
            label="Risk Buckets filter"
            item-value="value"
            item-text="name"
            multiple
            small-chips
            deletable-chips
            @change="showTodo(false)"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-icon>
                  <v-icon>
                    {{
                      riskBuckets.includes(item.value)
                        ? "mdi-checkbox-marked"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-left">
                    <v-chip small :color="item.color">
                      {{ item.name }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <v-chip small :color="item.color">{{ item.value }}</v-chip>
            </template>
          </v-select>
        </v-col>

        <v-col cols="auto" v-if="suspiciousTermCategories.length > 1">
          <v-select
            v-model="suspiciousTermCategoryIds"
            class="mx-1 mt-3 mb-1"
            :items="suspiciousTermCategories"
            clearable
            dense
            hide-details
            label="Risk Category filter"
            item-value="id"
            item-text="name"
            multiple
            small-chips
            deletable-chips
            @change="showTodo(false)"
          />
        </v-col>

        <v-col cols="auto" v-if="statusTabValue === 'ignored'">
          <v-select
            v-model="itemIgnored"
            class="mx-1 mt-3 mb-1"
            :items="[
              {
                value: 'user',
                text: 'User Ignored',
              },
              {
                value: 'auto',
                text: 'Auto Ignored',
              },
            ]"
            dense
            hide-details
            label="Reviewed Type"
            @change="showTodo(false)"
          />
        </v-col>
        <v-col cols="auto" v-if="statusTabValue === 'ignored'">
          <v-select
            v-model="actionReasonCategoryIds"
            class="mx-1 mt-3 mb-1"
            :items="actionReasonCategories"
            item-text="name"
            item-value="id"
            multiple
            small-chips
            deletable-chips
            clearable
            dense
            hide-details
            label="Ignored Categories"
            @change="showTodo(false)"
          />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                :input-value="showTableToolbar"
                @click="showTableToolbar = !showTableToolbar"
              >
                <v-icon small>mdi-table-large</v-icon>
              </v-btn>
            </template>
            <span>{{ showTableToolbar ? "Hide" : "Show" }} advanced table tools</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-alert
        v-if="filterItemGroupId"
        class="mb-1"
        dismissible
        type="warning"
        @input="displaySimilarItems({ detail: [null] })"
      >
        Displaying similar items, click the 'x' icon on the right to clear the filter.
      </v-alert>

      <DataTable
        v-if="isMounted"
        grid-id="TriageDataTable"
        :data-function="dataFunction"
        :show-toolbar="showTableToolbar"
        :show-created="true"
        :show-risk-bucket="true"
        :show-action-reason="statusTabValue === 'ignored'"
        :action-reason-categ-data-source="actionReasonCategories"
        :show-susp-term-columns="true"
        :susp-term-categ-data-source="suspiciousTermCategories"
        :show-modified-on-column="statusTabValue === 'ignored'"
        :default-get-params="{ actioned: false }"
        :firms-data-source="firms"
        :enable-fingerprint-time-sort="true"
        :show-count-column="shouldGroupSimilarItems"
      >
        <template #footer-count="{ count }">
          <v-alert v-if="count && count > 1000" dense icon="mdi-alert" type="warning">
            As you have more than 1000 results, the total count of items above is approximate.
          </v-alert>
        </template>
      </DataTable>
    </PageFrame>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import DataTable from "./DataTable.vue";
import EventBus from "@/eventBus";
import PageFrame from "@/components/PageFrame.vue";
import { CategoryType } from "@/models/enums";
import caseRepository from "@/repositories/case";
import { getFirms } from "@/repositories/firm";
import { getDataTypes } from "@/repositories/settings";
import { getActionReasonCategories, getSuspiciousTermCategories } from "@/repositories/todo";
import { useAuthStore } from "@/stores/auth";
import { useCasesStore } from "@/stores/cases";
import ActionsTodo from "./columns/ActionsTodo.vue";
import BatchActionsTodo from "./columns/BatchActionsTodo.vue";

export default {
  name: "TriageDataTable",
  components: {
    DataTable,
    PageFrame,
  },
  provide: {
    actions: ActionsTodo,
    batchActions: BatchActionsTodo,
  },
  data() {
    return {
      isMounted: false,
      dataLoadingTimeout: null,
      statusTabValue: "pending",
      statusTabs: [
        {
          value: "pending",
          text: "Pending Items",
          icon: "mdi-message-alert-outline",
        },
        {
          value: "ignored",
          text: "Reviewed Items",
          icon: "mdi-message-bookmark-outline",
        },
      ],
      riskBuckets: [],
      itemIgnored: "user",
      showTableToolbar: false,
      firms: [],
      firmIds: [],
      dataTypes: [],
      dataTypeIds: [],
      actionReasonCategories: [],
      suspiciousTermCategories: [],
      suspiciousTermCategoryIds: [],
      actionReasonCategoryIds: [],
      selectedActionReasonCategory: null,
      filterItemGroupId: null,
      groupSimilarItems: false,
    };
  },
  async mounted() {
    EventBus.$on("displaySimilarItems", this.displaySimilarItems);
    try {
      // populating risk buckets
      await this.getAccountRiskConfig();

      const firms = await getFirms();
      this.firms = firms.filter((f) => !f.is_deleted);
      this.dataTypes = await getDataTypes();

      this.actionReasonCategories = await getActionReasonCategories(CategoryType.TODO);
      this.suspiciousTermCategories = await getSuspiciousTermCategories();
    } catch {
      this.firms = [];
    } finally {
      this.isMounted = true;
    }
  },
  beforeDestroy() {
    EventBus.$off("displaySimilarItems", this.displaySimilarItems);
  },
  computed: {
    ...mapState(useAuthStore, ["firmLabelObject", "defaultIgnoreReasonCategoryID"]),
    ...mapState(useCasesStore, ["riskBucketList"]),
    shouldGroupSimilarItems() {
      return this.groupSimilarItems;
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["updateDefaultIgnoreReasonCategoryID"]),
    ...mapActions(useCasesStore, ["getAccountRiskConfig"]),
    dataFunction(state) {
      return new Promise((resolve) => {
        if (this.dataLoadingTimeout) {
          clearTimeout(this.dataLoadingTimeout);
        }
        this.dataLoadingTimeout = setTimeout(() => {
          resolve(
            caseRepository.getTodoItems({ ...state, source: "user" }, this.shouldGroupSimilarItems),
          );
        }, 200);
      });
    },
    handleStatusChange(value) {
      this.statusTabValue = value;
      this.showTodo();
    },
    showTodo(forceReload = false) {
      if (this.statusTabValue !== "ignored") {
        this.itemIgnored = null;
      }
      EventBus.$emit("setGridParam", {
        actioned: this.statusTabValue !== "pending",
        auto_actioned: this.itemIgnored === "auto" || undefined,
        has_case: false,
        risk_buckets: (this.riskBuckets.length && this.riskBuckets) || undefined,
        firm_ids: this.firmIds,
        data_type_ids: this.dataTypeIds,
        suspicious_term_categories:
          (this.suspiciousTermCategoryIds.length && this.suspiciousTermCategoryIds) || undefined,
        action_categories:
          (this.actionReasonCategoryIds.length && this.actionReasonCategoryIds) || undefined,
        forceReload: forceReload ? new Date().getTime() : undefined,
        group_ids: this.filterItemGroupId ? [this.filterItemGroupId] : undefined,
      });
    },
    displaySimilarItems({ detail: [groupId] }) {
      this.filterItemGroupId = groupId;
      this.groupSimilarItems = !groupId;
      this.showTodo();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-select) {
  min-width: 200px;
}
::v-deep(.v-select__selections) {
  > input {
    width: 15px;
    min-width: 15px;
  }
}

::v-deep(.v-autocomplete.v-select.v-input--is-focused input) {
  min-width: 15px;
}
</style>
