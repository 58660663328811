import { defineStore } from "pinia";
import { getBucketsDefaultConfig } from "@/models/case";
import {
  getAccountRiskConfig,
  getCaseNotifications as repoGetCaseNotifications,
} from "@/repositories/case";

export const defaultState = {
  accountRiskBucket: null,
  accountRiskBucketLoading: false,
  accountRiskBucketLoadingError: null,
  caseActivityLoading: false,
  caseNotesLoading: false,
  caseNotifications: [],
  caseNotificationsCount: 0,
  caseNotificationsLoading: false,
  caseNotificationsLoadingError: null,
  caseObjLoading: false,
  caseWorkflowLoading: false,
};

export const getters = {
  riskBucketList({ accountRiskBucket }) {
    const bucketDefaultConfig = getBucketsDefaultConfig(accountRiskBucket?.num_buckets || 0);
    return bucketDefaultConfig.map((defaultConfig, index) => ({
      value: index + 1,
      name: accountRiskBucket?.bucket_names[index] || defaultConfig.name,
      decayDays: accountRiskBucket?.decay_days[index],
      scoreThresholds: accountRiskBucket?.score_thresholds[index],
      color: defaultConfig.color,
    }));
  },
};

export const actions = {
  /**
   * @returns { import("@/models/case").RiskConfig } the risk configuration for the account
   */
  async getAccountRiskConfig() {
    this.accountRiskBucketLoading = true;
    this.accountRiskBucketLoadingError = null;
    try {
      this.accountRiskBucket = await getAccountRiskConfig();
    } catch (error) {
      this.accountRiskBucketLoadingError = error;
      this.accountRiskBucket = null;
    } finally {
      this.accountRiskBucketLoading = false;
    }
  },
  async getCaseNotifications(params) {
    this.caseNotificationsLoading = true;
    this.caseNotificationsLoadingError = null;
    try {
      const { data } = await repoGetCaseNotifications(params);
      this.caseNotifications = data.results || data;
      this.caseNotificationsCount = data.count || data.length;
    } catch (error) {
      this.caseNotificationsLoadingError = error;
      this.caseNotifications = [];
      this.caseNotificationsCount = 0;
    } finally {
      this.caseNotificationsLoading = false;
    }
  },
};

export const useCasesStore = defineStore("cases", {
  state: () => defaultState,
  getters,
  actions,
});
