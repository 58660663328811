import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=44d0ab2b&scoped=true"
import script from "./Message.vue?vue&type=script&lang=js"
export * from "./Message.vue?vue&type=script&lang=js"
import style0 from "./Message.vue?vue&type=style&index=0&id=44d0ab2b&prod&lang=css"
import style1 from "./Message.vue?vue&type=style&index=1&id=44d0ab2b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d0ab2b",
  null
  
)

export default component.exports