import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Cases")]},proxy:true}])},[_c(VRow,{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c(VBtn,{attrs:{"text":"","to":{ name: 'CaseList', params: { status: '1' } }}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-folder-open-outline")]),_vm._v(" Open Cases ")],1),_c(VBtn,{attrs:{"text":"","to":{ name: 'CaseList', params: { status: '2' } }}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-folder-outline")]),_vm._v(" Closed Cases ")],1),_c(VCheckbox,{staticClass:"mx-2 mt-0 pt-0",attrs:{"label":"Include deleted items","hide-details":""},on:{"change":function($event){return _vm.handleDataStateChange()}},model:{value:(_vm.includeDeleted),callback:function ($$v) {_vm.includeDeleted=$$v},expression:"includeDeleted"}}),(_vm.showLoading)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24"}}):_vm._e()],1),(_vm.columns.length)?_c('BasicList',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"grid-params":{
        allowPaging: true,
        pageSettings: _vm.pageSettings,
        allowSorting: true,
        filterSettings: {
          type: 'Menu',
        },
        allowResizing: true,
        autoFitColumns: true,
        selectionSettings: { enableToggle: false },
        allowEditing: true,
        editSettings: _vm.editSettings,
        toolbar: ['Add', 'Edit', 'ExcelExport', 'CsvExport', 'Print'],
        gridLines: 'Both',
        dataStateChange: _vm.handleDataStateChange,
        actionBegin: _vm.handleActionBegin, // used for saving data
        actionComplete: _vm.handleActionComplete, // used for resizing edit dialog
        loadingIndicator: {
          indicatorType: 'Shimmer',
        },
      }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }