<template>
  <div>
    <span v-if="canAddTriageItem">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            color="secondary"
            :disabled="isAdded"
            v-bind="attrs"
            v-on="on"
            @click="addItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add this item to case {{ selectedCase.name }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
import { mapState } from "pinia";

import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { getPayload } from "../utils";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "ActionsCaseSearch",
  data() {
    return {
      data: {},
      innerIsAdded: false,
    };
  },
  computed: {
    ...mapState(useStore, {
      selectedCase: (state) => state.case,
    }),
    ...mapState(useAuthStore, ["canAddTriageItem"]),
    isAdded() {
      return this.innerIsAdded || this.isChecked(this.data.saved_cases);
    },
  },
  methods: {
    async addItem() {
      const payload = getPayload(this.data, this.selectedCase);
      payload.search_params = this.$route.query;
      try {
        await caseRepository.todoItemAdd(payload);
        this.innerIsAdded = true;
      } catch (error) {
        console.error("addItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    isChecked(arr) {
      return arr.some((item) => this.selectedCase.id && this.selectedCase.id === item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
