<template>
  <div>
    <v-dialog v-model="dialogDisableSchedule" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h6">
          Do you really want to {{ dialogDisableScheduleType }} the selected schedule?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogDisableSchedule = false"> Cancel </v-btn>
          <v-btn color="primary" text @click="handleConfirmDisableSchedule"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PageFrame>
      <template #title>Report Schedules</template>

      <template #actions>
        <v-btn :to="{ name: 'ReportingScheduleForm', params: { id: 'new' } }">
          <v-icon left>mdi-plus</v-icon>
          Add New
        </v-btn>
      </template>

      <v-data-table :headers="headers" :items="schedules" class="elevation-1">
        <template v-slot:item.status="{ item }">
          <ScheduleActions
            :data="item"
            :isLoadingTriggerButton="reportTriggerRunningId === item.id"
            @disable-report-schedule="handleDisableScheduleClick"
            @enable-report-schedule="handleEnableScheduleClick"
            @schedule-trigger="handleReportScheduleTrigger"
          />
        </template>
        <template v-slot:item.schedule="{ item }">
          {{ getScheduleValue(item.schedule) }}
        </template>
        <template v-slot:item.fingerprint_firm="{ item }">
          {{ firms.find((firm) => firm.id === item.fingerprint_firm)?.firm_name }}
        </template>
        <template v-slot:item.last_run="{ item }">
          {{ mediumDateTimeFormat(item.last_run) }}
        </template>
        <template v-slot:item.modified_on="{ item }">
          {{ mediumDateTimeFormat(item.modified_on) }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import cronstrue from "cronstrue";

import PageFrame from "@/components/PageFrame.vue";
import { mediumDateTimeFormat } from "@/filters";
import { getFirms } from "@/repositories/firm";
import {
  deleteReportingSchedule,
  getReportingSchedules,
  patchReportingSchedule,
  triggerSchedule,
} from "@/repositories/reporting";
import ScheduleActions from "./columns/ScheduleActions.vue";

export default {
  name: "ReportingSchedule",
  components: {
    PageFrame,
    ScheduleActions,
  },
  data() {
    return {
      schedules: [],
      schedulesLoading: false,
      dialogDisableSchedule: false,
      dialogDisableScheduleId: null,
      dialogDisableScheduleType: "disable",
      reportTriggerRunningId: "",
      firms: [],
      headers: [
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Schedule",
          value: "schedule",
        },
        {
          text: "Firm",
          value: "fingerprint_firm",
        },
        {
          text: "Period type",
          value: "default_item_period_type",
        },
        {
          text: "Last run",
          value: "last_run",
        },
        {
          text: "Modified on",
          value: "modified_on",
        },
      ],
    };
  },
  async mounted() {
    this.firms = await getFirms();
    this.loadData();
  },
  methods: {
    mediumDateTimeFormat,
    async loadData() {
      this.schedulesLoading = true;
      try {
        const { data } = await getReportingSchedules({ source: "user" });
        this.schedules = data;
      } catch {
        this.schedules = [];
      } finally {
        this.schedulesLoading = false;
      }
    },
    getScheduleValue(schedule) {
      return cronstrue.toString(schedule);
    },
    handleDisableScheduleClick(id) {
      this.dialogDisableScheduleType = "disable";
      this.dialogDisableSchedule = true;
      this.dialogDisableScheduleId = id;
    },
    handleEnableScheduleClick(id) {
      this.dialogDisableScheduleType = "enable";
      this.dialogDisableSchedule = true;
      this.dialogDisableScheduleId = id;
    },
    async handleConfirmDisableSchedule() {
      if (this.dialogDisableScheduleType === "disable") {
        await deleteReportingSchedule(this.dialogDisableScheduleId);
      } else {
        await patchReportingSchedule({
          id: this.dialogDisableScheduleId,
          is_deleted: false,
        });
      }
      await this.loadData();
      this.dialogDisableSchedule = false;
      this.dialogDisableScheduleId = null;
    },
    async handleReportScheduleTrigger(id) {
      this.reportTriggerRunningId = id;
      try {
        await triggerSchedule(id);
      } finally {
        this.reportTriggerRunningId = "";
      }
      this.$router.push({ name: "ReportingReports" });
    },
  },
};
</script>
