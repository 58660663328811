"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.policyReviewFrequencies = exports.reportingFrequencies = exports.triageSampleFrequencies = exports.triageReviewFrequencies = exports.reviewFrequencies = void 0;
exports.getReviewFrequencyText = getReviewFrequencyText;
exports.getTriageReviewFrequencyText = getTriageReviewFrequencyText;
exports.getTriageSampleFrequencyText = getTriageSampleFrequencyText;
exports.getReportingFrequencyText = getReportingFrequencyText;
exports.getPolicyReviewFrequencyText = getPolicyReviewFrequencyText;
exports.reviewFrequencies = [
    { value: "week", text: "weekly" },
    { value: "month", text: "monthly" },
    { value: "quarter", text: "quarterly" },
    { value: "year", text: "yearly" },
];
function getReviewFrequencyText(value) {
    var _a;
    return (_a = exports.reviewFrequencies.filter(function (freq) { return freq.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.text;
}
exports.triageReviewFrequencies = [
    { value: "day", text: "daily" },
    { value: "week", text: "weekly" },
    { value: "month", text: "monthly" },
    { value: "quarter", text: "quarterly" },
    { value: "year", text: "yearly" },
];
function getTriageReviewFrequencyText(value) {
    var _a;
    return (_a = exports.triageReviewFrequencies.filter(function (freq) { return freq.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.text;
}
exports.triageSampleFrequencies = [
    { value: "month", text: "monthly" },
    { value: "quarter", text: "quarterly" },
    { value: "6months", text: "six months" },
    { value: "year", text: "yearly" },
];
function getTriageSampleFrequencyText(value) {
    var _a;
    return (_a = exports.triageSampleFrequencies.filter(function (freq) { return freq.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.text;
}
exports.reportingFrequencies = [
    { value: "week", text: "weekly" },
    { value: "month", text: "monthly" },
    { value: "quarter", text: "quarterly" },
    { value: "year", text: "yearly" },
];
function getReportingFrequencyText(value) {
    var _a;
    return (_a = exports.reportingFrequencies.filter(function (freq) { return freq.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.text;
}
exports.policyReviewFrequencies = [
    { value: "week", text: "weekly" },
    { value: "month", text: "monthly" },
    { value: "quarter", text: "quarterly" },
    { value: "year", text: "yearly" },
];
function getPolicyReviewFrequencyText(value) {
    var _a;
    return (_a = exports.policyReviewFrequencies.filter(function (freq) { return freq.value === value; })[0]) === null || _a === void 0 ? void 0 : _a.text;
}
