import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[(_vm.results_todo_search_actionreason)?_c('MetricBox',{staticClass:"scroll-box",attrs:{"title":"Category V No Action Reason","show-accordion":false},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c(VSimpleTable,[_c('thead',[(_vm.results_todo_search)?_c('tr',[_c('th'),_vm._l((_vm.results_todo_search),function(search_name,search_index){return _c('th',{key:search_index},[_vm._v(" "+_vm._s(search_index)+" ")])})],2):_vm._e()]),_c('tbody',_vm._l((_vm.results_todo_search_actionreason),function(cat_name,cat_index){return _c('tr',{key:cat_index},[(
                    _vm.results_todo_search &&
                    cat_index !== 'None' &&
                    cat_index !== 'Added via Adhoc Search'
                  )?[_c('td',[_vm._v(" "+_vm._s(cat_index || "-- empty --")+" ")]),_vm._l((_vm.results_todo_search),function(search_name,search_index){return _c('td',{key:search_index},[_vm._v(" "+_vm._s(cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0)+" ")])})]:_vm._e()],2)}),0)])]},proxy:true}],null,false,1624977275)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }