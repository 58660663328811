import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"scrollable":"","width":"90%"},model:{value:(_setup.canShowMessageDialog),callback:function ($$v) {_setup.canShowMessageDialog=$$v},expression:"canShowMessageDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"secondary","dark":""}},[_c(VToolbarTitle,[_vm._v("Message content")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_setup.canShowMessageDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"p-0"},[(_setup.canShowMessageDialog)?_c(VContainer,{attrs:{"fluid":""}},[_c(_setup.Message,{attrs:{"external-data":_setup.messageDialogItem,"languages":_setup.languages}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }