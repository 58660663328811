import LogList from "@/components/logs/LogList.vue";

const routes = [
  {
    path: "/logs/:action?/",
    name: "LogList",
    component: LogList,
    meta: {
      checkAccountFeatures: ({ canShowLogs }) => canShowLogs,
    },
  },
];

export default routes;
