"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var todo_1 = require("@/repositories/todo");
var auth_1 = require("@/stores/auth");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SuspiciousCategoryList',
    setup: function (__props) {
        var _this = this;
        var authStore = (0, auth_1.useAuthStore)();
        var _a = (0, pinia_1.storeToRefs)(authStore), canAddSuspiciousTermCategory = _a.canAddSuspiciousTermCategory, canChangeSuspiciousTermCategory = _a.canChangeSuspiciousTermCategory, canDeleteSuspiciousTermCategory = _a.canDeleteSuspiciousTermCategory;
        var categories = (0, vue_2.ref)([]);
        var newCategoryName = (0, vue_2.ref)("");
        var deleteConfirmationDialog = (0, vue_2.ref)(false);
        var categoryToDelete = (0, vue_2.ref)(null);
        var deleteError = (0, vue_2.ref)(false);
        function loadCategories() {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = categories;
                            return [4 /*yield*/, (0, todo_1.getSuspiciousTermCategories)()];
                        case 1:
                            _a.value = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                loadCategories();
                return [2 /*return*/];
            });
        }); });
        function enableEdit(category) {
            vue_2.default.set(category, "editing", true);
        }
        function addCategory() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, todo_1.createSuspiciousTermCategory)(newCategoryName.value.trim())];
                        case 1:
                            _a.sent();
                            newCategoryName.value = "";
                            loadCategories();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function updateCategory(category) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, todo_1.updateSuspiciousTermCategory)(category.id, category.name.trim())];
                        case 1:
                            _a.sent();
                            vue_2.default.set(category, "editing", false);
                            loadCategories();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function deleteCategory(category) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    categoryToDelete.value = category;
                    deleteConfirmationDialog.value = true;
                    return [2 /*return*/];
                });
            });
        }
        function confirmDeleteCategory() {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!categoryToDelete.value) return [3 /*break*/, 4];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, (0, todo_1.deleteSuspiciousTermCategory)(categoryToDelete.value.id)];
                        case 2:
                            _a.sent();
                            loadCategories();
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            deleteError.value = true;
                            return [3 /*break*/, 4];
                        case 4:
                            cancelDeleteCategory();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function cancelDeleteCategory() {
            deleteConfirmationDialog.value = false;
            categoryToDelete.value = null;
        }
        return { __sfc: true, authStore: authStore, canAddSuspiciousTermCategory: canAddSuspiciousTermCategory, canChangeSuspiciousTermCategory: canChangeSuspiciousTermCategory, canDeleteSuspiciousTermCategory: canDeleteSuspiciousTermCategory, categories: categories, newCategoryName: newCategoryName, deleteConfirmationDialog: deleteConfirmationDialog, categoryToDelete: categoryToDelete, deleteError: deleteError, loadCategories: loadCategories, enableEdit: enableEdit, addCategory: addCategory, updateCategory: updateCategory, deleteCategory: deleteCategory, confirmDeleteCategory: confirmDeleteCategory, cancelDeleteCategory: cancelDeleteCategory };
    }
});
