<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <v-row dense>
        <v-col>Pending</v-col>
        <v-col cols="auto">
          <small>{{ todoPending(periodData) }} of {{ todoTotal(periodData) }}</small>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-progress-linear :value="percPending(periodData)" color="accent" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>Reviewed - No Action</v-col>
        <v-col cols="auto">
          <small>{{ todoIgnored(periodData) }} of {{ todoTotal(periodData) }}</small>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-progress-linear :value="percIgnored(periodData)" color="warning" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>Assigned</v-col>
        <v-col cols="auto">
          <small>{{ todoAssigned(periodData) }} of {{ todoTotal(periodData) }}</small>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-progress-linear :value="percAssigned(periodData)" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "TodoMetric",
  props: {
    periodData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    todoPending(item) {
      if (!item || !item.False) return 0;
      return item.False.todo_total || 0;
    },
    todoActioned(item) {
      if (!item || !item.True) return 0;
      return item.True.todo_total || 0;
    },
    todoAssigned(item) {
      if (!item || !item.True) return 0;
      return item.True.todoitem_total || 0;
    },
    todoIgnored(item) {
      return this.todoActioned(item) - this.todoAssigned(item);
    },
    todoTotal(item) {
      return this.todoPending(item) + this.todoActioned(item);
    },
    percPending(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoPending(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
    percIgnored(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoIgnored(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
    percAssigned(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoAssigned(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.2em;
  padding-bottom: 5px;
}
</style>
