<script setup>
import { computed } from "vue";

const props = defineProps({
  isPrint: {
    type: Boolean,
    required: true,
  },
  dataTypes: {
    type: Array,
    required: false,
    default: () => [],
  },
  channels: {
    type: Array,
    required: false,
    default: () => [],
  },
});
const emit = defineEmits(["update:channels"]);

const dataTypesStruct = computed(() =>
  props.dataTypes.value.reduce(
    (acc, dataType) => ({
      ...acc,
      [dataType.id]: dataType.name,
    }),
    {},
  ),
);
</script>

<template>
  <div>
    <h2>Supervision Channels</h2>

    We analyse the following Channels of communications:
    <ul v-if="isPrint">
      <li v-for="channel in props.channels" :key="channel">
        {{ dataTypesStruct[channel] }}
      </li>
    </ul>
    <ul v-else>
      <li v-for="dataType in props.dataTypes" :key="dataType.id">
        <v-checkbox
          :input-value="props.channels"
          type="checkbox"
          :value="dataType.id"
          :label="dataType.name"
          hide-details
          @change="emit('update:channels', $event)"
        />
      </li>
    </ul>
    Each Channel will be activated in the Fingerprint Supervision platform.
  </div>
</template>
