<template>
  <div>
    <PageFrame>
      <template #title> New Conversation Search </template>

      <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
        <v-textarea
          v-model="query.person1"
          auto-grow
          rows="1"
          label="Person 1"
          hint="Enter the name or email address of person 1"
          prepend-icon="mdi-account-outline"
          :rules="rules.person1"
          autofocus
          name="person1"
        >
          <template #prepend>
            <v-icon small> mdi-account-outline </v-icon>
          </template>
        </v-textarea>
        <v-textarea
          v-model="query.person2"
          auto-grow
          rows="1"
          label="Person 2"
          hint="Enter the name or email address of person 2"
          prepend-icon="mdi-account-outline"
          :rules="rules.person2"
          name="person2"
        >
          <template #prepend>
            <v-icon small> mdi-account-outline </v-icon>
          </template>
        </v-textarea>
        <v-textarea
          v-model="query.subject"
          auto-grow
          rows="1"
          label="Subject"
          hint="Enter words or phrases to search for in the message subject"
          prepend-icon="mdi-text"
          name="subject"
        >
          <template #prepend>
            <v-icon small> mdi-text </v-icon>
          </template>
        </v-textarea>

        <v-autocomplete
          v-if="canShowConversationStockSearch"
          v-model="stockSelected"
          :loading="stockSearchResultsLoading"
          :items="stockSearchResults"
          :search-input.sync="stockSearch"
          cache-items
          clearable
          hide-no-data
          label="Stock search"
          hint="Compare results with stock data timeline (optional)"
          persistent-hint
          prepend-icon="mdi-finance"
          :filter="handleStockFilter"
        >
          <template #prepend>
            <v-icon small> mdi-finance </v-icon>
          </template>
          <template #selection="data">
            {{ data.item.name }} ({{ data.item.symbol }}) - {{ data.item.exchange }}
          </template>
          <template #item="data">
            <v-list-item-title>{{ data.item.name }} ({{ data.item.symbol }})</v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.exchange }}
            </v-list-item-subtitle>
          </template>
        </v-autocomplete>

        <div>
          <v-row dense class="mt-2">
            <v-col cols="auto">
              <v-input v-model="query" prepend-icon="mdi-calendar-start" :rules="rules.date_range">
                <template #prepend>
                  <v-icon small>mdi-calendar</v-icon>
                </template>
                <v-label class="mr-2" style="font-size: 14px">Date range</v-label>
                <DateTimeRangeSelect
                  :range-type="query.searchType"
                  :from-value="query.dt_from"
                  :to-value="query.dt_to"
                  :offset-type="OFFSET_TYPE_FRONTEND_MAPPING[query.dt_offset_type]"
                  :offset-value="query.dt_offset_count"
                  :limit-quick-select-end-now="true"
                  @update:rangeType="query = { ...query, searchType: $event }"
                  @update:fromValue="query = { ...query, dt_from: $event }"
                  @update:toValue="query = { ...query, dt_to: $event }"
                  @update:offsetType="
                    query = {
                      ...query,
                      dt_offset_type: OFFSET_TYPE_BACKEND_MAPPING[$event] || null,
                    }
                  "
                  @update:offsetValue="query = { ...query, dt_offset_count: $event }"
                />
              </v-input>
            </v-col>
          </v-row>
        </div>

        <v-select
          v-model="query.data_types"
          :items="dataTypes"
          multiple
          label="Data types"
          hint="Include or exclude different data sources to search across"
          persistent-hint
          small-chips
          deletable-chips
          item-value="id"
          item-text="name"
          prepend-icon="mdi-hexagon-multiple-outline"
          :rules="rules.dataTypes"
          class="tour-data-type"
        >
          <template #prepend>
            <v-icon small> mdi-hexagon-multiple-outline </v-icon>
          </template>
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAllDataTypes">
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
          <template #item="{ item, attrs }">
            <v-checkbox v-model="attrs.inputValue" />
            <v-icon left>
              {{ item.icon }}
            </v-icon>
            {{ item.name }}
          </template>
          <template #selection="{ item, parent }">
            <v-chip small close @click:close="parent.onChipInput(item)">
              <v-icon left small>
                {{ item.icon }}
              </v-icon>
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>

        <v-select
          v-model="query.restrict_to_firms"
          :items="firmList"
          multiple
          :label="firmLabelObject.pluralUppercase"
          :hint="`Select one or more ${firmLabelObject.pluralLowercase}`"
          small-chips
          deletable-chips
          item-value="id"
          item-text="firm_name"
          prepend-icon="mdi-office-building"
          :rules="rules.restrict_to_firms"
          class="tour-firms"
        >
          <template #prepend>
            <v-icon small> mdi-office-building </v-icon>
          </template>
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAllFirms">
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>

        <v-btn type="submit" :disabled="!valid" class="mt-2 ml-5" color="primary"> Search </v-btn>
      </v-form>
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import DateTimeRangeSelect from "@/components/datetime-range/DateTimeRangeSelect.vue";
import {
  OFFSET_TYPE_BACKEND_MAPPING,
  OFFSET_TYPE_FRONTEND_MAPPING,
} from "@/components/datetime-range/types";
import { getFirms } from "@/repositories/firm";
import { searchStock } from "@/repositories/search";
import { getDataTypes } from "@/repositories/settings";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";
import { getEmailAddressesFromEmailField } from "@/utils";
import PageFrame from "../PageFrame.vue";

export default {
  name: "SearchForm",
  components: {
    PageFrame,
    DateTimeRangeSelect,
  },
  data() {
    return {
      OFFSET_TYPE_BACKEND_MAPPING,
      OFFSET_TYPE_FRONTEND_MAPPING,
      dataTypes: [],
      firmList: [],
      query: {
        person1: "",
        person2: "",
        subject: "",
        searchType: "absolute",
        dt_from: new Date(new Date().setDate(new Date().getDate() - 5)),
        dt_to: new Date(),
        dt_offset_count: 1,
        dt_offset_type: "d",
        data_types: [],
        restrict_to_firms: [],
        stockSymbol: undefined,
        stockName: undefined,
        stockExchange: undefined,
      },
      stockSearchResults: [],
      stockSearchResultsLoading: false,
      stockSelected: null,
      dataPeriods: [
        { id: "m", name: "Minutes" },
        { id: "h", name: "Hours" },
        { id: "d", name: "Days" },
        { id: "w", name: "Weeks" },
        { id: "M", name: "Months" },
        { id: "y", name: "Years" },
      ],
      valid: true,
      stockSearch: null,
      stockSearchTimeout: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "canShowConversationStockSearch",
      "selectedFirmGroup",
      "firmLabelObject",
    ]),
    rules() {
      return {
        person1: [
          (v) => !!v || "Please enter a name or email address for person 1",
          (v) => (v && !v.includes(", ")) || "Please enter only one person / email address",
        ],
        person2: [
          (v) => !!v || "Please enter a name or email address for person 2",
          (v) => (v && !v.includes(", ")) || "Please enter only one person / email address",
        ],
        date_range: [
          ({ searchType, dt_from, dt_offset_count, dt_offset_type }) =>
            (searchType === "relative" && !!dt_offset_count && !!dt_offset_type) ||
            (searchType === "absolute" && !!dt_from) ||
            "Please enter a valid date range",
          ({ searchType, dt_from }) =>
            searchType === "relative" ||
            (searchType === "absolute" && !!dt_from) ||
            "Please enter a valid start date",
          ({ searchType, dt_from }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_from) <= new Date() ||
            "The start date is in the future",
          ({ searchType, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && !!dt_to) ||
            "Please enter a valid end date",
          ({ searchType, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_to <= new Date()) ||
            "The end date is in the future",
          ({ searchType, dt_from, dt_to }) =>
            searchType === "relative" ||
            (searchType === "absolute" && dt_from <= dt_to) ||
            "The start date is greater than the end date",
        ],
        dataTypes: [(v) => !!v.length || "Please select at least one data type"],
        restrict_to_firms: [
          (v) =>
            !!v.length || `Please select at least one ${this.firmLabelObject.singularLowercase}`,
          (v) =>
            v.length <= 10 ||
            `You can't select more than 10 ${this.firmLabelObject.pluralLowercase}`,
        ],
      };
    },
    firmSelected() {
      const firms = this.query.restrict_to_firms || [];
      return this.firmList.filter((item) => firms.includes(item.id));
    },
  },
  watch: {
    // what?
    stockSearch(val) {
      this.stockSearchTimeout && clearTimeout(this.stockSearchTimeout);
      if (val && val !== this.query.stockSymbol) {
        this.stockSearchTimeout = setTimeout(() => {
          this.handleStockSearch(val || "");
        }, 200);
      }
    },
  },
  async mounted() {
    const [dataTypes, firmList] = await Promise.all([getDataTypes(), this.getFirmList()]);
    this.dataTypes = dataTypes;
    this.firmList = firmList;
    this.query = {
      ...this.query,
      data_types: this.dataTypes.map((dt) => dt.id),
      ...this.handleRouteQuery(),
    };
    // if only a single firm, automatically selecting the firm
    if (this.firmList.length === 1) {
      this.query.restrict_to_firms = this.firmList.map((f) => f.id);
    }

    if (this.query.stockSymbol && this.query.stockExchange) {
      await this.handleStockSearch(this.query.stockSymbol);
      this.stockSelected = this.stockSearchResults.filter(
        (s) => s.exchange === this.query.stockExchange,
      )[0];
    }

    this.$refs.form.validate();
  },
  methods: {
    async handleStockSearch(val) {
      this.stockSearchResultsLoading = true;
      try {
        const { data } = await searchStock(val);
        this.stockSearchResults = data;
      } catch {
        this.stockSearchResults = [];
      } finally {
        this.stockSearchResultsLoading = false;
      }
    },
    toggleSelectAllDataTypes() {
      if (this.query.data_types.length === this.dataTypes.length) {
        this.query.data_types = [];
      } else {
        this.query.data_types = this.dataTypes.map((f) => f.id);
      }
    },
    toggleSelectAllFirms() {
      if (this.query.restrict_to_firms.length === this.firmList.length) {
        this.query.restrict_to_firms = [];
      } else {
        this.query.restrict_to_firms = this.firmList.map((f) => f.id);
      }
    },
    async getFirmList() {
      try {
        const firms = await getFirms();
        return firms;
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    handleRouteQuery() {
      if (this.$route.query && Object.keys(this.$route.query).length > 0) {
        let retVal = {
          ...this.$route.query,
          dt_from: new Date(this.$route.query.dt_from),
          dt_to: new Date(this.$route.query.dt_to),
          data_types: [].concat(this.$route.query.data_types || []),
          restrict_to_firms: [].concat(this.$route.query.restrict_to_firms || []),
        };
        if (
          [
            "bbg.im",
            "bbg.msg",
            "email",
            "slack",
            "teams.personal",
            "teams.corporate",
            "whatsapp",
          ].includes(this.$route.query.data_types)
        ) {
          retVal.person1 = getEmailAddressesFromEmailField(retVal.person1);
          retVal.person2 = getEmailAddressesFromEmailField(retVal.person2);
        }
        return retVal;
      }
      return {};
    },
    handleSubmit() {
      return this.$router.push({
        name: "SearchResultsConversation",
        query: {
          ...this.query,
          firm_groups: [this.selectedFirmGroup?.id],
          stockSymbol: this.stockSelected?.symbol,
          stockName: this.stockSelected?.name,
          stockExchange: this.stockSelected?.exchange,
          dt_from: this.query.searchType === "absolute" ? this.query.dt_from.toJSON() : undefined,
          dt_to: this.query.searchType === "absolute" ? this.query.dt_to.toJSON() : undefined,
          dt_offset_count:
            this.query.searchType === "relative" ? this.query.dt_offset_count : undefined,
          dt_offset_type:
            this.query.searchType === "relative" ? this.query.dt_offset_type : undefined,
        },
      });
    },
    handleStockFilter(item, queryText) {
      const lowerQueryText = queryText.toLocaleLowerCase();
      return (
        item.symbol.toLocaleLowerCase().indexOf(lowerQueryText) > -1 ||
        item.name.toLocaleLowerCase().indexOf(lowerQueryText) > -1
      );
    },
  },
};
</script>
