import Vue from "vue";
import Vuetify from "vuetify/lib";
// import colors from 'vuetify/lib/util/colors';
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#140452",
        secondary: "#394bff",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#ff8a39",
        background: "#f3f3f4",
      },
    },
  },
});
