<!--
  Changes in this template must be reflected
  in `exportQueryCellInfo` of the `DataTable.vue` component
 -->

<template>
  <div v-if="data.current_risk_bucket">
    <v-chip :color="bucket.color">{{ bucket.name }}</v-chip>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useCasesStore } from "@/stores/cases";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useCasesStore, ["riskBucketList"]),
    bucket() {
      return this.riskBucketList[this.data?.current_risk_bucket - 1];
    },
  },
};
</script>
