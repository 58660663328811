import { getCachedOrFetch } from "@/cache";
import httpClient from "@/interceptors/axios";
import { withFirmGroup } from "./utils";

export function todoItemsToGridElement(list) {
  return list.map((elem) => ({
    ...(elem.es || elem.document || { id: elem.es_id }),
    django_id: elem.id,
    created_on: elem.created_on.substring(0, 10),
    created_by: elem.created_by,
    action_category: elem.action_category,
    action_reason: elem.action_reason,
    fingerprint_firm: elem.fingerprint_firm,
    initial_risk_bucket: elem.initial_risk_bucket,
    current_risk_bucket: elem.current_risk_bucket,
    modified_on: elem.modified_on,
    modified_by: elem.modified_by,
    is_hidden: elem.is_hidden,
    suspicious_term: elem.suspicious_term,
    actioned: elem.actioned,
    highlight: elem.highlight,
    group_id: elem.group_id,
    group_total: elem.group_total,
  }));
}

export async function getDocuments(state, url) {
  const r = await httpClient.get(url, { params: state });
  const results = "results" in r.data ? r.data.results : r.data;
  const count = "count" in r.data ? r.data.count : results.length;
  return {
    ...r,
    data: {
      result: todoItemsToGridElement(results),
      count,
    },
  };
}

export function getCase(caseId, additionalExpand = []) {
  const defaultExpand = ["category", "created_by"];
  const expand = defaultExpand.concat(additionalExpand);
  return httpClient.get(`case/${caseId}/`, {
    params: withFirmGroup({
      expand: expand.join(","),
    }),
  });
}

export function getCaseActivity(caseId) {
  return httpClient.get(`log/${caseId}/for_case/`, {
    params: {
      take: "all",
    },
  });
}

export function getCaseNotes(caseId) {
  return httpClient.get(`casenote/`, {
    params: {
      case: caseId,
      expand: "created_by",
    },
  });
}

export function getCaseNotifications(params) {
  return httpClient.get(`case/notifications/`, {
    params: withFirmGroup({
      case_status: "1",
      is_deleted: false,
      ...params,
    }),
  });
}

export function getCaseWorkflow(caseId) {
  return httpClient.get(`case/${caseId}/workflow/`);
}

export function caseClose(caseId, categoryId, reason) {
  return httpClient.post(`case/${caseId}/close/`, {
    category_id: categoryId || undefined,
    reason,
  });
}

export function caseReopen(caseId, reason) {
  return httpClient.post(`case/${caseId}/reopen/`, { reason });
}

export async function getCaseCategoriesByReasonClosed() {
  try {
    const { data } = await httpClient.get("case/group-reason-closed-category/");
    return data;
  } catch {
    return [];
  }
}

export function updateCaseCategory(fromCategoryId, toCategoryId, reason) {
  return httpClient.post("case/bulk-update-category/", {
    from_category_id: fromCategoryId,
    to_category_id: toCategoryId,
    reason,
  });
}

/**
 * @returns { import("@/models/case").RiskConfig } the risk configuration for the account
 * @param {boolean} force force fetch from server instead of getting the cached copy
 */
export async function getAccountRiskConfig(force = false) {
  return getCachedOrFetch(
    "accountRiskConfig",
    async () => {
      const { data } = await httpClient.get("riskconfig/", {
        params: {
          take: "all",
        },
      });
      return data[0];
    },
    {
      ttl: 1000 * 60 * 30 /* 30 minutes */,
      force: !!force || undefined,
    },
  );
}

/**
 * @param {RiskConfig} payload object to update
 * @returns { import("@/models/case").RiskConfig } the risk configuration for the account
 */
export async function updateAccountRiskConfig(riskConfig) {
  const { data } = await httpClient.patch(`riskconfig/${riskConfig.id}/`, riskConfig);
  return data;
}

/**
 * @param {number} numBuckets number of buckets to use
 * @param {boolean} isAccountLevel calculates a new set of values based on all the todo items in the account
 * @returns { number[] } default threshold list for the given number of buckets
 */
export async function getDefaultRiskConfigThresholds(numBuckets, isAccountLevel) {
  const { data } = await httpClient.get("todo/score_threshold_auto_suggest/", {
    params: { buckets: numBuckets, is_account_level: isAccountLevel || undefined },
  });
  return data;
}

export default {
  // Case
  caseList(state) {
    let params = withFirmGroup({
      ...state,
      expand: "created_by",
    });
    return httpClient.get("case/", { params });
  },
  caseSave(payload, id = null) {
    let httpClientMethod = httpClient.post;
    let httpClientUrl = "case/";
    if (id) {
      httpClientMethod = httpClient.put;
      httpClientUrl = `case/${id}/`;
    }
    return httpClientMethod(httpClientUrl, withFirmGroup(payload), {
      params: {
        expand: "category,created_by",
      },
    });
  },
  caseDelete(id) {
    return httpClient.delete(`case/${id}/`);
  },
  caseRestore(id) {
    return httpClient.patch(`case/${id}/`, { is_deleted: false });
  },
  caseExport(id, password) {
    return httpClient.post(`case/${id}/compliance_export/`, { password: password });
  },
  casePermission() {
    return httpClient.get("case/permission/");
  },
  note(noteId) {
    return httpClient.get(`casenote/${noteId}/`);
  },
  noteSave(payload, noteId = null) {
    if (noteId) {
      return httpClient.patch(`casenote/${noteId}/`, payload);
    } else {
      return httpClient.post("casenote/", payload);
    }
  },
  noteDelete(noteId) {
    return httpClient.delete(`casenote/${noteId}/`);
  },
  noteRestore(noteId) {
    return httpClient.patch(`casenote/${noteId}/`, { is_deleted: false });
  },
  noteHistory(id) {
    return httpClient.get(`casenote/${id}/history/`, {
      params: { take: "all" },
    });
  },

  // Case Category
  caseCategoryList(state) {
    let params = withFirmGroup({
      ...state,
      expand: "cases,created_by",
    });
    return httpClient.get("casecategory/", { params });
  },
  caseCategorySave(payload) {
    if (payload.id) {
      return httpClient.patch(`casecategory/${payload.id}/`, payload);
    } else {
      return httpClient.post("casecategory/", payload);
    }
  },
  caseCategoryDelete(id) {
    return httpClient.delete(`casecategory/${id}/`);
  },
  caseCategoryRestore(id) {
    return httpClient.patch(`casecategory/${id}/`, { is_deleted: false });
  },
  caseCategoryPermission() {
    return httpClient.get("casecategory/permission/");
  },

  // Case Workflow Actions
  caseStartAction(caseId, actionId) {
    return httpClient.post(`case/${caseId}/workflow/`, {
      event_type: "start",
      action: actionId,
    });
  },
  caseFinishAction(caseId, actionId, actionReason) {
    return httpClient.post(`case/${caseId}/workflow/`, {
      event_type: "finish",
      action: actionId,
      reason: actionReason,
    });
  },
  caseRevertAction(caseId, actionId) {
    return httpClient.post(`case/${caseId}/workflow/`, {
      event_type: "revert",
      action: actionId,
    });
  },

  // Todo Rule
  todoRuleList(params) {
    return httpClient.get("todorule/", { params: withFirmGroup(params) });
  },
  todoRule(id) {
    return httpClient.get(`todorule/${id}/`);
  },
  todoRuleSave(payload) {
    if (payload.id) {
      return httpClient.put(`todorule/${payload.id}/`, withFirmGroup(payload));
    } else {
      return httpClient.post("todorule/", withFirmGroup(payload));
    }
  },
  todoRuleDelete(id) {
    return httpClient.delete(`todorule/${id}/`);
  },

  // Todo Item
  async getTodoItems(state, groupSimilarItems = false) {
    let payload = withFirmGroup({
      ...state,
      expand: "created_by,modified_by,suspicious_term",
    });
    let endpoint = "todo/";
    if (groupSimilarItems) {
      endpoint += "grouped/";
    }
    return await getDocuments(payload, endpoint);
  },
  todoItemAdd(payload) {
    // getting query string leading here and adding it before the rest of the payload
    return httpClient.post("todo/" + location.search, withFirmGroup(payload));
  },
};
