<template>
  <div>
    <v-dialog :value="showDialog" persistent max-width="500">
      <v-form v-model="valid">
        <v-card>
          <v-card-title> Enter a Reason for Closing the Case </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="actionCategoryId"
              label="Reason Category"
              :items="actionReasonCategories"
              item-value="id"
              item-text="name"
              autofocus
              auto-select-first
              :rules="actionCategoryIdRules"
            />
            <v-textarea v-model="actionReason" label="Explanation" :rules="actionReasonRules" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="$emit('cancel')"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="handleSubmit"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { CategoryType } from "@/models/enums";
import { getActionReasonCategories } from "@/repositories/todo";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      actionReasonCategories: [],
      actionCategoryId: null,
      actionCategoryIdRules: [(v) => !!v || v === "" || "Selecting a Category is required"],
      actionReason: "",
    };
  },
  computed: {
    actionReasonRules() {
      const category = this.actionReasonCategories.filter((c) => c.id === this.actionCategoryId)[0];
      if (category && !category.id) {
        return [(v) => !!v || "An explanation is required for the selected category"];
      }
      return [];
    },
  },
  mounted() {
    this.loadActionReasonCategories();
  },
  methods: {
    async loadActionReasonCategories() {
      const actionReasonCategories = await getActionReasonCategories(CategoryType.CASE, false);
      this.actionReasonCategories = actionReasonCategories.concat([
        {
          id: "",
          name: "Other...",
        },
      ]);
    },
    async handleSubmit() {
      this.$emit("confirm", {
        categoryId: this.actionCategoryId,
        actionReason: this.actionReason,
      });
    },
  },
};
</script>
