<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2>Metrics Over Time</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="secondary"
          small
          :to="{
            name: 'StatsReport',
            params: { periodType: selectedPeriodType, periodValue: selectedPeriodValue },
          }"
        >
          View Report for {{ selectedPeriodType }} {{ selectedPeriodValue }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="auto">
        <v-select
          v-model="selectedPeriodType"
          class="ma-1"
          :items="periodTypes"
          dense
          hide-details
          label="Period type"
          outlined
          @change="handleChangeSelectedPeriodType"
        />
      </v-col>
      <v-col cols="auto">
        <v-select
          v-model="selectedPeriodValue"
          class="ma-1"
          :items="periodValues"
          dense
          hide-details
          label="Period value"
          outlined
          @change="fetchAll"
        />
      </v-col>
    </v-row>

    <!-- commented out for now, we can figure this out later -->
    <!-- <v-row>
      <v-col>
        <div
          v-if="bar_chart_data"
          class="ibox"
        >
          <div class="ibox-title">
            <h5>Case Items by Category by {{ selectedPeriodType.charAt(0).toUpperCase() + selectedPeriodType.substring(1) }}</h5>
          </div>
          <div class="ibox-content">
            <ChartJs
              height="400px"
              type="bar"
              :data="bar_chart_data"
              :options="bar_chart_options"
            />
          </div>
        </div>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="6">
        <MetricBox
          v-for="dataType in dataTypeList"
          :key="dataType.value"
          :title="dataType.title"
          :show-accordion="
            !!Object.keys((data_todo_time_type_search || {})[dataType.value] || {}).length
          "
        >
          <template #info>
            <ul>
              <li>
                {{ countTriagedItems(data_todo_time_type, dataType.value) }} items added to the
                triage list
              </li>
              <li>
                {{ countIngestedItems(data_totals, dataType.value) }} items ingested into our
                elastic search cluster<br />
              </li>
              <li>
                This represents {{ percTodos(data_todo_time_type, data_totals, dataType.value) }}%
                of the ingested items ({{
                  countTriagedItems(data_todo_time_type, dataType.value)
                }}/{{ countIngestedItems(data_totals, dataType.value) }}).
              </li>
            </ul>
          </template>
          <template #metric>
            <Metric
              :loading="loading_totals"
              :val="`${countTriagedItems(data_todo_time_type, dataType.value)} of ${countIngestedItems(data_totals, dataType.value)}`"
              :perc="percTodos(data_todo_time_type, data_totals, dataType.value)"
            />
          </template>
          <template #metric-detail>
            <MetricList
              v-if="data_todo_time_type_search"
              title="by Risk Categ."
              :loading="loading_totals"
              :period-data="data_todo_time_type_search[dataType.value]"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Triage by Status"
          :show-accordion="!!Object.keys(data_todo_time_search_action || {}).length"
        >
          <template #info>
            <ul>
              <li>Pending: triage items still in the pending list</li>
              <li>
                Reviewed: items actioned by a user (or auto rule) by moving them to the no action
                list
              </li>
              <li>Assigned: items actioned by a user by adding them to a case</li>
            </ul>
          </template>
          <template #metric>
            <TodoMetric :loading="loading_todo_time_action" :period-data="data_todo_time_action" />
          </template>
          <template #metric-detail>
            <TodoMetricList
              title="by Risk Categ."
              :loading="loading_todo_time_search_action"
              :period-data="data_todo_time_search_action"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Triage: Avg Time to Action"
          :show-accordion="!!Object.keys(data_todo_time_search || {}).length"
        >
          <template #info>
            Hours elapsed on average between a triage item being added to the triage list and it
            being actioned
          </template>
          <template #metric>
            <Metric
              :loading="loading_todo_time"
              :val="
                !isEmpty(data_todo_time?.actioned_avg)
                  ? parseInt(data_todo_time.actioned_avg) + ' hours'
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_todo_time_search"
              :period-data="data_todo_time_search"
              :field-name="'actioned_avg'"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col cols="6">
        <v-card class="mb-5" v-if="pie_chart_data">
          <v-card-title>
            <h5>Case Categories for {{ selectedPeriodValue }}</h5>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="ml-2" v-on="on">mdi-information</v-icon>
              </template>
              <div>Cases created within the time period, by category</div>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <ChartJs type="pie" :data="pie_chart_data" :options="pie_chart_options" />
          </v-card-text>
        </v-card>

        <MetricBox
          title="Case Opened"
          :show-accordion="!!Object.keys(data_case_timecreated_status_category || {}).length"
        >
          <template #info>
            Cases created within the time period and currently in the opened state
          </template>
          <template #metric>
            <Metric
              :loading="loading_case_timecreated_status"
              :val="data_case_timecreated_status?.case_total || 0"
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_case_timecreated_status_category"
              :period-data="data_case_timecreated_status_category"
              :field-name="'case_total'"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Case Closed"
          :show-accordion="!!Object.keys(data_case_timeclosed_status_category || {}).length"
        >
          <template #info>
            Cases created within the time period and currently in the closed state
          </template>
          <template #metric>
            <Metric
              :loading="loading_case_timeclosed_status"
              :val="data_case_timeclosed_status?.case_total || 0"
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_case_timeclosed_status_category"
              :period-data="data_case_timeclosed_status_category"
              :field-name="'case_total'"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MetricBox
          v-if="data_todo_time_type_actionreason"
          :title="
            'Channel V No Action Reason by ' +
            selectedPeriodType.charAt(0).toUpperCase() +
            selectedPeriodType.substring(1)
          "
          :show-accordion="false"
        >
          <template #metric>
            <v-simple-table>
              <thead>
                <tr>
                  <th />
                  <th>Email</th>
                  <th>BBG Msg</th>
                  <th>BBG IM</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat_name, cat_index) in data_todo_time_type_actionreason"
                  :key="cat_index"
                >
                  <template v-if="cat_index !== 'None' && cat_index !== 'Added via Adhoc Search'">
                    <td>
                      {{ cat_index }}
                    </td>
                    <td>
                      {{ cat_name["email"] ? cat_name["email"]["todo_total"] : 0 }}
                    </td>
                    <td>
                      {{ cat_name["bbg.msg"] ? cat_name["bbg.msg"]["todo_total"] : 0 }}
                    </td>
                    <td>
                      {{ cat_name["bbg.im"] ? cat_name["bbg.im"]["todo_total"] : 0 }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </MetricBox>
      </v-col>
    </v-row>

    <!-- commented out for now, we can figure this out later -->
    <!-- <v-row>
      <v-col>
        <div
          v-if="pie_todo_time_actionreason"
          class="ibox"
        >
          <div class="ibox-title">
            <h5>Triage items by No Action Reason by {{ selectedPeriodType.charAt(0).toUpperCase() + selectedPeriodType.substring(1) }}</h5>
          </div>
          <div class="ibox-content">
            <ChartJs
              type="pie"
              :data="pie_todo_time_actionreason"
              :options="pie_chart_options"
            />
          </div>
        </div>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <MetricBox
          v-if="data_todo_time_search_actionreason"
          :title="
            'Category V No Action Reason by ' +
            selectedPeriodType.charAt(0).toUpperCase() +
            selectedPeriodType.substring(1)
          "
          class="scroll-box"
          :show-accordion="false"
        >
          <template #metric>
            <v-simple-table>
              <thead>
                <tr v-if="results_todo_search">
                  <th />
                  <th
                    v-for="(search_name, search_index) in results_todo_search['True']"
                    :key="search_index"
                  >
                    {{ search_index }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat_name, cat_index) in data_todo_time_search_actionreason"
                  :key="cat_index"
                >
                  <template
                    v-if="
                      results_todo_search &&
                      cat_index !== 'None' &&
                      cat_index !== 'Added via Adhoc Search'
                    "
                  >
                    <td>
                      {{ cat_index }}
                    </td>
                    <td
                      v-for="(search_name, search_index) in results_todo_search['True']"
                      :key="search_index"
                    >
                      {{ cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0 }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Category, ColumnSeries, ScrollBar, Tooltip, Zoom } from "@syncfusion/ej2-vue-charts";
import MetricBox from "./MetricBox.vue";
import Metric from "./Metric.vue";
import MetricList from "./MetricList.vue";
import TodoMetric from "./TodoMetric.vue";
import TodoMetricList from "./TodoMetricList.vue";
import ChartJs from "@/components/ChartJs.vue";
import {
  // fillTodoTimeData,
  fillCategoryPieData,
  // fillReasonPieData,
} from "./utils.js";
import { getDataTypes } from "@/repositories/settings";
import {
  getPeriodValues,
  getStatsCaseTotals,
  getStatsTodoTotals,
  getStatsTotals,
} from "@/repositories/stats";
import EventBus from "@/eventBus";
import { isEmpty } from "@/utils";

export default {
  name: "AppMetrics",
  components: {
    MetricBox,
    Metric,
    MetricList,
    TodoMetric,
    TodoMetricList,
    ChartJs,
  },
  provide: {
    chart: [Category, ColumnSeries, ScrollBar, Tooltip, Zoom],
  },
  data() {
    return {
      periodTypes: ["week", "month", "quarter", "year"],
      periodValues: [],
      dataTypes: [],
      selectedPeriodType: "month",
      selectedPeriodValue: "all",
      caseStatus: "1",

      loading: true,
      loading_totals: true,
      loading_todo_search: true,
      loading_todo_time: true,
      loading_todo_time_type: true,
      loading_todo_time_search: true,
      loading_todo_time_type_search: true,
      loading_todo_time_action: true,
      loading_todo_time_search_action: true,
      // loading_todo_time_actionreason: true,
      loading_todo_time_type_actionreason: true,
      loading_todo_time_search_actionreason: true,
      loading_case_timecreated_status: true,
      loading_case_timeclosed_status: true,
      loading_case_timeclosed_status_category: true,
      loading_case_timecreated_status_category: true,

      results_totals: null,
      results_todo_user: null,
      results_todo_search: {},
      results_todo_time: null,
      results_todo_time_type: null,
      results_todo_time_search: null,
      results_todo_time_type_search: null,
      results_todo_time_action: null,
      results_todo_time_search_action: null,
      // results_todo_time_actionreason: null,
      results_todo_time_type_actionreason: null,
      results_todo_time_search_actionreason: null,
      results_case_timecreated_status: null,
      results_case_timeclosed_status: null,

      data_totals: {},
      data_todo_time: {},
      data_todo_time_type: {},
      data_todo_time_search: {},
      data_todo_time_type_search: {},
      data_todo_time_action: {},
      data_todo_time_search_action: {},
      // data_todo_time_actionreason: {},
      data_todo_time_type_actionreason: null,
      data_todo_time_search_actionreason: null,
      data_case_timecreated_status: {},
      data_case_timecreated_status_category: {},
      data_case_timeclosed_status: {},
      data_case_timeclosed_status_category: {},

      // bar_chart_data: null,
      // bar_chart_options: {},
      pie_chart_data: {},
      pie_chart_options: {},
      // pie_todo_time_actionreason: null,
    };
  },
  computed: {
    canShowBbgIm() {
      return this.dataTypes.some((dt) => dt.id === "bbg.im");
    },
    canShowBbgMsg() {
      return this.dataTypes.some((dt) => dt.id === "bbg.msg");
    },
    canShowEmail() {
      return this.dataTypes.some((dt) => dt.id === "email");
    },
    canShowVoice() {
      return this.dataTypes.some((dt) => dt.id === "voice");
    },
    canShowSlack() {
      return this.dataTypes.some((dt) => dt.id === "slack");
    },
    canShowTeamsPersonal() {
      return this.dataTypes.some((dt) => dt.id === "teams.personal");
    },
    canShowTeamsCorporate() {
      return this.dataTypes.some((dt) => dt.id === "teams.corporate");
    },
    canShowWhatsapp() {
      return this.dataTypes.some((dt) => dt.id === "whatsapp");
    },
    canShowZoomCall() {
      return this.dataTypes.some((dt) => dt.id === "zoom.call");
    },
    canShowZoomMeet() {
      return this.dataTypes.some((dt) => dt.id === "zoom.meet");
    },
    dataTypeList() {
      return [
        {
          canShow: this.canShowBbgIm,
          value: "bbg.im",
          title: "Triage: Bloomberg IM",
        },
        {
          canShow: this.canShowBbgMsg,
          value: "bbg.msg",
          title: "Triage: Bloomberg Msg",
        },
        {
          canShow: this.canShowEmail,
          value: "email",
          title: "Triage: Email",
        },
        {
          canShow: this.canShowVoice,
          value: "voice",
          title: "Triage: Voice",
        },
        {
          canShow: this.canShowSlack,
          value: "slack",
          title: "Triage: Slack",
        },
        {
          canShow: this.canShowTeamsPersonal,
          value: "teams.personal",
          title: "Triage: Teams Personal",
        },
        {
          canShow: this.canShowTeamsCorporate,
          value: "teams.corporate",
          title: "Triage: Teams Corporate",
        },
        {
          canShow: this.canShowWhatsapp,
          value: "whatsapp",
          title: "Triage: Whatsapp",
        },
        {
          canShow: this.canShowZoomCall,
          value: "zoom.call",
          title: "Triage: Zoom Call",
        },
        {
          canShow: this.canShowZoomMeet,
          value: "zoom.meet",
          title: "Triage: Zoom Meet",
        },
      ].filter((item) => item.canShow);
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.offsetDataToSelectedPeriod();
      }
    },
    selectedPeriodValue() {
      if (!this.loading) {
        this.offsetDataToSelectedPeriod();
      }
    },
  },
  async mounted() {
    this.dataTypes = await getDataTypes();
    this.handleChangeSelectedPeriodType();
  },
  methods: {
    isEmpty,
    async handleChangeSelectedPeriodType() {
      const { data } = await getPeriodValues(this.selectedPeriodType);
      this.periodValues = data;
      this.selectedPeriodValue = this.periodValues[this.periodValues.length - 1];
      this.fetchAll();
    },
    async fetchAllTotalsByTime() {
      this.loading_totals = true;
      try {
        const r = await getStatsTotals(
          {
            data_types: this.dataTypeList.map((item) => item.value),
            group_by: "data_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_totals = r.data;
      } catch (error) {
        console.error("fetchAllTotalsByTime error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_totals = false;
    },
    async fetchTodoStatsBySearch(actioned = "False") {
      this.loading_todo_search = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: actioned.toLowerCase(),
          priority: "priority",
          auto_actioned: "false",
          exclude_assigned_items: "true",
          group_by: "actioned,suspicious_term__suspicious_term_category__name",
        });
        Vue.set(this.results_todo_search, actioned, r.data?.[actioned]);
      } catch (error) {
        console.error("fetchTodoStatsBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_search = false;
    },
    async fetchTodoStatsByTime() {
      this.loading_todo_time = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTime error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time = false;
    },
    async fetchTodoStatsByTimeByType() {
      this.loading_todo_time_type = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "document__fingerprint_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByType error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type = false;
    },
    async fetchTodoStatsByTimeBySearch() {
      this.loading_todo_time_search = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search = false;
    },
    async fetchTodoStatsByTimeByTypeBySearch() {
      this.loading_todo_time_type_search = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "document__fingerprint_type,suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type_search = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByTypeBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type_search = false;
    },
    async fetchTodoStatsByTimeAction() {
      this.loading_todo_time_action = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "actioned",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_action = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeAction error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_action = false;
    },
    async fetchTodoStatsByTimeBySearchByAction() {
      this.loading_todo_time_search_action = true;
      try {
        const r = await getStatsTodoTotals(
          {
            exclude_assigned_items: "false",
            group_by: "suspicious_term__suspicious_term_category__name,actioned",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search_action = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearchByAction error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search_action = false;
    },
    // async fetchTodoStatsByTimeByActionReason() {
    //   this.loading_todo_time_actionreason = true;
    //   try {
    //     const r = await getStatsTodoTotals({
    //       'actioned': 'true',
    //       'auto_actioned': 'false',
    //       'exclude_assigned_items': 'false',
    //       'group_by': 'case,action_reason'
    //     }, this.selectedPeriodType, this.selectedPeriodValue);
    //     this.results_todo_time_actionreason = r.data;
    //   } catch (error) {
    //     console.error('fetchTodoStatsByTimeByActionReason error', error);
    //     EventBus.$emit('notify', 'warn', error);
    //   }
    //   this.loading_todo_time_actionreason = false;
    // },
    async fetchTodoStatsByTimeByTypeByActionReason() {
      this.loading_todo_time_type_actionreason = true;
      try {
        const r = await getStatsTodoTotals(
          {
            actioned: "true",
            auto_actioned: "false",
            exclude_assigned_items: "false",
            group_by: "case,action_reason,document__fingerprint_type",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_type_actionreason = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeByTypeByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_type_actionreason = false;
    },
    async fetchTodoStatsByTimeBySearchByActionReason() {
      this.loading_todo_time_search_actionreason = true;
      try {
        const r = await getStatsTodoTotals(
          {
            actioned: "true",
            auto_actioned: "false",
            exclude_assigned_items: "false",
            group_by: "case,action_reason,suspicious_term__suspicious_term_category__name",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_todo_time_search_actionreason = r.data;
      } catch (error) {
        console.error("fetchTodoStatsByTimeBySearchByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_time_search_actionreason = false;
    },
    async fetchCaseStatsByTimeCreatedByStatus() {
      this.loading_case_timecreated_status = true;
      try {
        const r = await getStatsCaseTotals(
          {
            time_field: "created_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timecreated_status = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeCreatedByStatus error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timecreated_status = false;
    },
    async fetchCaseStatsByTimeCreatedByStatusByCategory() {
      this.loading_case_timecreated_status_category = true;
      try {
        const r = await getStatsCaseTotals(
          {
            group_by: "category__name",
            time_field: "created_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timecreated_status_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeCreatedByStatusByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timecreated_status_category = false;
    },
    async fetchCaseStatsByTimeClosedByStatus() {
      this.loading_case_timeclosed_status = true;
      try {
        const r = await getStatsCaseTotals(
          {
            time_field: "closed_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timeclosed_status = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeClosedByStatus error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timeclosed_status = false;
    },
    async fetchCaseStatsByTimeClosedByStatusByCategory() {
      this.loading_case_timeclosed_status_category = true;
      try {
        const r = await getStatsCaseTotals(
          {
            group_by: "category__name",
            time_field: "closed_on",
          },
          this.selectedPeriodType,
          this.selectedPeriodValue,
        );
        this.results_case_timeclosed_status_category = r.data;
      } catch (error) {
        console.error("fetchCaseStatsByTimeClosedByStatusByCategory error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_timeclosed_status_category = false;
    },
    fetchAll() {
      this.loading = true;
      Promise.all([
        this.fetchAllTotalsByTime(),
        this.fetchTodoStatsBySearch("False"),
        this.fetchTodoStatsBySearch("True"),
        this.fetchTodoStatsByTime(),
        this.fetchTodoStatsByTimeByType(),
        this.fetchTodoStatsByTimeBySearch(),
        this.fetchTodoStatsByTimeByTypeBySearch(),
        this.fetchTodoStatsByTimeAction(),
        this.fetchTodoStatsByTimeBySearchByAction(),
        // this.fetchTodoStatsByTimeByActionReason(),
        this.fetchTodoStatsByTimeByTypeByActionReason(),
        this.fetchTodoStatsByTimeBySearchByActionReason(),
        this.fetchCaseStatsByTimeCreatedByStatus(),
        this.fetchCaseStatsByTimeCreatedByStatusByCategory(),
        this.fetchCaseStatsByTimeClosedByStatus(),
        this.fetchCaseStatsByTimeClosedByStatusByCategory(),
      ]).then(() => {
        this.loading = false;
      });
    },
    offsetDataToSelectedPeriod() {
      if (!this.loading_totals) {
        this.data_totals = this.results_totals?.[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time) {
        this.data_todo_time = this.results_todo_time[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_type) {
        this.data_todo_time_type = this.results_todo_time_type[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_search) {
        this.data_todo_time_search = this.results_todo_time_search[this.selectedPeriodValue];
        // [this.bar_chart_data, this.bar_chart_options] = fillTodoTimeData(this.results_todo_time_search, this.periodValues, 0, true);
      }
      if (!this.loading_todo_time_type_search) {
        this.data_todo_time_type_search =
          this.results_todo_time_type_search[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_action) {
        this.data_todo_time_action = this.results_todo_time_action[this.selectedPeriodValue];
      }
      if (!this.loading_todo_time_search_action) {
        this.data_todo_time_search_action =
          this.results_todo_time_search_action[this.selectedPeriodValue];
      }
      // if(!this.loading_todo_time_actionreason && this.data_todo_time_actionreason && Object.keys(this.data_todo_time_actionreason).length !== 0) {
      //   this.data_todo_time_actionreason = this.results_todo_time_actionreason[this.selectedPeriodValue] ? this.results_todo_time_actionreason[this.selectedPeriodValue]['None'] : null;
      //   this.pie_todo_time_actionreason = fillReasonPieData(this.data_todo_time_actionreason);
      // }
      if (
        !this.loading_todo_time_type_actionreason &&
        this.results_todo_time_type_actionreason &&
        Object.keys(this.results_todo_time_type_actionreason).length !== 0
      ) {
        this.data_todo_time_type_actionreason =
          this.results_todo_time_type_actionreason?.[this.selectedPeriodValue]?.["None"];
      }
      if (
        !this.loading_todo_time_search_actionreason &&
        this.results_todo_time_search_actionreason &&
        Object.keys(this.results_todo_time_search_actionreason).length !== 0
      ) {
        this.data_todo_time_search_actionreason =
          this.results_todo_time_search_actionreason?.[this.selectedPeriodValue]?.["None"];
      }
      if (!this.loading_case_timecreated_status) {
        this.data_case_timecreated_status =
          this.results_case_timecreated_status[this.selectedPeriodValue];
      }
      if (!this.loading_case_timecreated_status_category) {
        this.data_case_timecreated_status_category =
          this.results_case_timecreated_status_category[this.selectedPeriodValue];
        this.pie_chart_data = fillCategoryPieData(this.data_case_timecreated_status_category);
      }
      if (!this.loading_case_timeclosed_status) {
        this.data_case_timeclosed_status =
          this.results_case_timeclosed_status[this.selectedPeriodValue];
      }
      if (!this.loading_case_timeclosed_status_category) {
        this.data_case_timeclosed_status_category =
          this.results_case_timeclosed_status_category &&
          this.results_case_timeclosed_status_category[this.selectedPeriodValue];
      }
    },
    percTodos(periodData, periodDataTotal, dataType) {
      const data = periodData?.[dataType]?.todo_total;
      const total = periodDataTotal?.[dataType]?.total || 0;
      if (data) {
        if (total) {
          return `${parseFloat(((100 * data) / total).toFixed(2)) || 0}`;
        }
        return null;
      }
      return "0";
    },
    countTriagedItems(periodData, dataType) {
      return periodData?.[dataType]?.todo_total || 0;
    },
    countIngestedItems(periodDataTotal, dataType) {
      return periodDataTotal?.[dataType]?.total || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  font-size: 0.8em;
  overflow-x: auto;
}
</style>
