<template>
  <div>
    <label>From: </label> &nbsp;
    <select
      :value="value.fromLang"
      @change="$emit('input', { ...value, fromLang: $event.target.value })"
    >
      <option disabled>--- Favourites ---</option>
      <option v-for="lang in favouriteLanguages" :key="lang.name" :value="lang.code">
        {{ lang.name }}
      </option>
      <option disabled>--- Others ---</option>
      <option v-for="lang in lessFavouriteLanguages" :key="lang.name" :value="lang.code">
        {{ lang.name }}
      </option>
    </select>
    &nbsp; <label>To: </label> &nbsp;
    <select
      :value="value.toLang"
      @change="$emit('input', { ...value, toLang: $event.target.value })"
    >
      <option disabled>--- Favourites ---</option>
      <option v-for="lang in favouriteLanguages" :key="lang.name" :value="lang.code">
        {{ lang.name }}
      </option>
      <option disabled>--- Others ---</option>
      <option v-for="lang in lessFavouriteLanguages" :key="lang.name" :value="lang.code">
        {{ lang.name }}
      </option>
    </select>
    &nbsp;
    <v-btn v-if="translated" x-small @click="$emit('reset')"> Reset </v-btn>
    <v-btn v-else x-small @click="$emit('translate')"> Translate </v-btn>
  </div>
</template>

<script>
export default {
  name: "TranslateSelect",
  props: {
    value: {
      type: Object,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    translated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    favouriteLanguages() {
      return this.languages.filter((l) => l.is_favourite);
    },
    lessFavouriteLanguages() {
      return this.languages.filter((l) => !l.is_favourite);
    },
  },
};
</script>
