"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AskForReason',
    props: {
        value: { type: Boolean },
        title: { default: "Reason" },
        inputLabel: { default: "Reason" },
        inputHint: { default: "Please provide a reason for this action" }
    },
    emits: ["input", "ok", "cancel"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var reason = (0, vue_2.ref)("");
        function emitOk() {
            emit("ok", reason.value);
            reason.value = "";
        }
        function emitCancel() {
            emit("cancel");
            reason.value = "";
        }
        return { __sfc: true, emit: emit, reason: reason, emitOk: emitOk, emitCancel: emitCancel };
    }
});
