"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActionReasonCategories = getActionReasonCategories;
exports.getCategoryTypes = getCategoryTypes;
exports.getTodoCategoriesByActionReason = getTodoCategoriesByActionReason;
exports.ignoreTodoItems = ignoreTodoItems;
exports.ignoreTodoItemGroups = ignoreTodoItemGroups;
exports.resetTodoItems = resetTodoItems;
exports.resetTodoItemGroups = resetTodoItemGroups;
exports.createActionReasonCategory = createActionReasonCategory;
exports.updateActionReasonCategory = updateActionReasonCategory;
exports.deleteActionReasonCategory = deleteActionReasonCategory;
exports.updateTodoItemsCategory = updateTodoItemsCategory;
exports.hideTodoItem = hideTodoItem;
exports.showTodoItem = showTodoItem;
exports.addItemsToCase = addItemsToCase;
exports.addItemGroupsToCase = addItemGroupsToCase;
exports.getSuspiciousTermCategories = getSuspiciousTermCategories;
exports.getSuspiciousTermCategory = getSuspiciousTermCategory;
exports.createSuspiciousTermCategory = createSuspiciousTermCategory;
exports.updateSuspiciousTermCategory = updateSuspiciousTermCategory;
exports.deleteSuspiciousTermCategory = deleteSuspiciousTermCategory;
var axios_1 = require("@/interceptors/axios");
function getActionReasonCategories(type, isAutoAction, sourceUser) {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("action-reason-categories/", {
                            params: {
                                type: type,
                                is_auto_action: isAutoAction,
                                source: sourceUser ? "user" : undefined,
                                take: "all",
                            },
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getCategoryTypes() {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("action-reason-categories/category-types/")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getTodoCategoriesByActionReason() {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("todo/group-action-reason-category/")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function ignoreTodoItems(ids, categoryId, reason) {
    return axios_1.default.post("todo/ignore/", {
        todo_ids: ids,
        category_id: categoryId || undefined,
        reason: reason,
    });
}
function ignoreTodoItemGroups(groupIds, categoryId, reason) {
    return axios_1.default.post("todo/ignore/", {
        group_ids: groupIds,
        category_id: categoryId || undefined,
        reason: reason,
    });
}
function resetTodoItems(ids) {
    return axios_1.default.post("todo/reset/", {
        todo_ids: ids,
    });
}
function resetTodoItemGroups(groupIds) {
    return axios_1.default.post("todo/reset/", {
        group_ids: groupIds,
    });
}
function createActionReasonCategory(name, type) {
    return axios_1.default.post("action-reason-categories/", {
        name: name.trim(),
        type: type,
    });
}
function updateActionReasonCategory(category) {
    return axios_1.default.patch("action-reason-categories/".concat(category.id, "/"), {
        name: category.name.trim(),
        // type: category.type,  // we don't update the type for now
    });
}
function deleteActionReasonCategory(categoryId) {
    return axios_1.default.delete("action-reason-categories/".concat(categoryId, "/"));
}
function updateTodoItemsCategory(fromCategoryId, toCategoryId, reason) {
    return axios_1.default.post("todo/bulk-update-category/", {
        from_category_id: fromCategoryId,
        to_category_id: toCategoryId,
        reason: reason,
    });
}
function hideTodoItem(id) {
    return axios_1.default.patch("todo/".concat(id, "/hide/"));
}
function showTodoItem(id) {
    return axios_1.default.patch("todo/".concat(id, "/show/"));
}
function addItemsToCase(ids, caseId, actionReason) {
    return axios_1.default.post("todo/assign/", {
        todo_ids: ids,
        case: caseId,
        action_reason: actionReason,
    });
}
function addItemGroupsToCase(groupIds, caseId, actionReason) {
    return axios_1.default.post("todo/assign/", {
        group_ids: groupIds,
        case: caseId,
        action_reason: actionReason,
    });
}
function getSuspiciousTermCategories() {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("suspicioustermcategories/", {
                            params: {
                                ordering: "name",
                                take: "all",
                                expand: "search",
                            },
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getSuspiciousTermCategory(id) {
    return axios_1.default.get("suspicioustermcategories/".concat(id, "/"));
}
function createSuspiciousTermCategory(name) {
    return axios_1.default.post("suspicioustermcategories/", { name: name });
}
function updateSuspiciousTermCategory(id, name) {
    return axios_1.default.patch("suspicioustermcategories/".concat(id, "/"), { id: id, name: name });
}
function deleteSuspiciousTermCategory(id) {
    return axios_1.default.delete("suspicioustermcategories/".concat(id, "/"));
}
