import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":_vm.value,"max-width":"90%","data-html2canvas-ignore":"true","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('input', false)}}},[_c(VCard,[_c(VCardTitle,[_vm._v("Field history")]),_c(VCardText,[_c('BasicList',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"grid-params":{
          allowPaging: true,
          pageSettings: _vm.pageSettings,
          selectionSettings: { type: 'Single' },
          rowSelected: _vm.handleRowSelected,
          dataStateChange: _vm.handleDataStateChange,
        }}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.handleSelectValue}},[_vm._v(" Use selected value ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }