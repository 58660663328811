import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.caseSubmit.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Select Risk Bucket "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialogVisible = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"text--primary"},[_vm._v(" Select the risk bucket in which to move the selected items. "),_c(VSelect,{attrs:{"items":_vm.riskBucketList,"autofocus":"","hide-details":"","item-value":"value","item-text":"name"},on:{"change":_vm.updateLastPreferredRiskBucket},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c(VListItem,_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-left"},[_c(VChip,{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)]}},{key:"selection",fn:function({ item }){return [_c(VChip,{attrs:{"color":item.color}},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedRiskBucket),callback:function ($$v) {_vm.selectedRiskBucket=$$v},expression:"selectedRiskBucket"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","type":"submit","disabled":_vm.formSubmitting}},[_vm._v(" Select Risk Bucket ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }