import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.deleteConfirmationDialog),callback:function ($$v) {_vm.deleteConfirmationDialog=$$v},expression:"deleteConfirmationDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Confirm Delete")]),_c(VCardText,[_c('div',[_vm._v("Are you sure you want to delete this item?")]),_c('div',[_vm._v("Field Name: \""+_vm._s(_vm.itemToDelete?.field_name)+"\"")]),_c('div',[_vm._v("Field Values: \""+_vm._s(_vm.itemToDelete?.field_values.join(", "))+"\"")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.cancelDeleteItem}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.confirmDeleteItem}},[_vm._v("Delete")])],1)],1)],1),_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Auto Ignore Rules")]},proxy:true},{key:"actions",fn:function(){return [(_vm.canAddTriageRules)?_c(VBtn,{attrs:{"to":{ name: 'TriageAutoIgnoreRulesEdit' }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New ")],1):_vm._e()]},proxy:true}])},[_c(VDataTable,{attrs:{"loading":_vm.showLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsCount,"items-per-page":25,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
      }},on:{"update:options":_vm.handleTableUpdateOptions},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [(item.permissions.change)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"icon":"","small":"","to":{ name: 'TriageAutoIgnoreRulesEdit', params: { id: item.id } }}},on),[_c(VIcon,[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(item.permissions.delete)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.showLoading,"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteClick(item)}}},on),[_c(VIcon,[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}},{key:"item.field_name",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.fieldNames.find((f) => f.id === item.field_name)?.name)+" ")]}},{key:"item.field_values",fn:function({ item }){return [_vm._v(" "+_vm._s(item.field_values.join(", "))+" ")]}},{key:"item.created_by",fn:function({ item }){return [_vm._v(" "+_vm._s(item.created_by.full_name)+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }