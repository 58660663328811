import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[(_vm.$slots.title || _vm.$slots.actions)?_c(VCardTitle,[_vm._t("title",function(){return [_vm._v("Fingerprint")]}),_c(VSpacer),(_vm.$slots.actions)?_c('div',[_vm._t("actions")],2):_vm._e()],2):_vm._e(),(_vm.$slots.crumb)?_c(VCardSubtitle,[_c(VBreadcrumbs,{staticClass:"pa-0",attrs:{"divider":"/"}},[_c(VBreadcrumbsItem,[_vm._t("crumb")],2),_c(VBreadcrumbsItem,[_c('strong',[_vm._t("title",function(){return [_vm._v("Fingerprint")]})],2)])],1)],1):_vm._e(),_c(VCardText,{staticClass:"text-primary"},[_c(VRow,{staticClass:"mt-0"},[_c(VCol,{attrs:{"cols":"12"}},[_vm._t("default",function(){return [_vm._v("Content")]})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }