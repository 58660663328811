<template>
  <div class="my-flex">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-switch
            v-model="switchValue"
            hide-details
            readonly
            :disabled="!data.permissions.delete"
            @click="handleToggleStatus"
          />
        </span>
      </template>
      <span>{{ data.is_deleted ? "Disabled, click to enable" : "Enabled, click to disable" }}</span>
    </v-tooltip>
    <v-tooltip v-if="!data.is_deleted && data.permissions.change" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          small
          :to="{ name: 'ReportingScheduleForm', params: { id: data.id } }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit</span>
    </v-tooltip>
    <v-tooltip v-if="!data.is_deleted" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          small
          :loading="isLoadingTriggerButton"
          v-bind="attrs"
          v-on="on"
          @click="handleScheduleTrigger"
        >
          <v-icon>mdi-file-refresh-outline</v-icon>
        </v-btn>
      </template>
      <span>Manually trigger report generation</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ScheduleActions",
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    isLoadingTriggerButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switchValue: false,
    };
  },
  beforeMount() {
    this.switchValue = !this.data.is_deleted;
  },
  beforeUpdate() {
    this.switchValue = !this.data.is_deleted;
  },
  methods: {
    handleToggleStatus() {
      this.$emit(`${this.switchValue ? "disable" : "enable"}-report-schedule`, this.data.id);
    },
    handleScheduleTrigger() {
      this.$emit("schedule-trigger", this.data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
