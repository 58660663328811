"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RISK_BUCKET_SETTINGS = void 0;
exports.getBucketsDefaultConfig = getBucketsDefaultConfig;
exports.RISK_BUCKET_SETTINGS = {
    "3": [
        { name: "Critical", color: "deep-orange" },
        { name: "Moderate", color: "yellow" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "4": [
        { name: "Critical", color: "deep-orange" },
        { name: "High", color: "yellow" },
        { name: "Moderate", color: "light-green" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "5": [
        { name: "Critical", color: "deep-orange" },
        { name: "High", color: "yellow" },
        { name: "Medium", color: "light-green" },
        { name: "Low", color: "cyan" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "6": [
        { name: "Critical", color: "deep-orange" },
        { name: "Very High", color: "orange" },
        { name: "High", color: "yellow" },
        { name: "Medium", color: "light-green" },
        { name: "Low", color: "cyan" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "7": [
        { name: "Critical", color: "deep-orange" },
        { name: "Very High", color: "orange" },
        { name: "High", color: "yellow" },
        { name: "Elevated", color: "light-green" },
        { name: "Medium", color: "teal" },
        { name: "Low", color: "cyan" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "8": [
        { name: "Critical", color: "deep-orange" },
        { name: "Very High", color: "orange" },
        { name: "High", color: "amber" },
        { name: "Significant", color: "yellow" },
        { name: "Elevated", color: "light-green" },
        { name: "Medium", color: "teal" },
        { name: "Low", color: "cyan" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "9": [
        { name: "Critical", color: "deep-orange" },
        { name: "Very High", color: "orange" },
        { name: "High", color: "amber" },
        { name: "Significant", color: "yellow" },
        { name: "Elevated", color: "teal" },
        { name: "Moderate", color: "light-green" },
        { name: "Medium", color: "cyan" },
        { name: "Low", color: "blue" },
        { name: "Auto Ignore", color: "grey" },
    ],
    "10": [
        { name: "Critical", color: "deep-orange" },
        { name: "Very High", color: "orange" },
        { name: "High", color: "amber" },
        { name: "Significant", color: "yellow" },
        { name: "Elevated", color: "teal" },
        { name: "Moderate", color: "light-green" },
        { name: "Medium", color: "cyan" },
        { name: "Low", color: "blue" },
        { name: "Very Low", color: "indigo" },
        { name: "Auto Ignore", color: "grey" },
    ],
};
function getBucketsDefaultConfig(numBuckets) {
    return exports.RISK_BUCKET_SETTINGS[numBuckets] || [];
}
