import { Auth } from "aws-amplify";
import { AuthState } from "@aws-amplify/ui-components";
import httpClient from "@/interceptors/axios";
import { defineStore } from "pinia";
import { initWebsocketConnection } from "@/websocket";
import { initRedactor } from "@/redactor";
import { logLogout } from "@/repositories/logs";
import { setUserObfuscation, setUserSettings } from "@/repositories/user";

export const REDIRECT_KEY_NAME = "REDIRECT_TO";

export const defaultState = {
  authErrorMessage: null,
  awsAuthData: null,
  awsAuthState: null,
  _selectedFirmGroupId: null, // use `selectedFirmGroup` to read the selected firm group
  userProfile: null,
  lastProfileUpdate: null,
  userPermissions: null,
};

export const getters = {
  firmLabelObject({ userProfile }) {
    const firmLabel = userProfile?.account?.firm_label || "firm";
    const labels = {
      firm: {
        pluralLowercase: "firms",
        pluralUppercase: "Firms",
        singularLowercase: "firm",
        singularUppercase: "Firm",
      },
      dept: {
        pluralLowercase: "departments",
        pluralUppercase: "Departments",
        singularLowercase: "department",
        singularUppercase: "Department",
      },
    };
    return labels[firmLabel] || labels.firm;
  },
  isAuthenticated(state) {
    return state.awsAuthState === AuthState.SignedIn;
  },
  defaultIgnoreReasonCategoryID({ userProfile }) {
    return userProfile?.settings?.defaultIgnoreReasonCategoryID;
  },
  caseDataTableTodoItemsTab({ userProfile }) {
    return userProfile?.settings?.caseDataTableTodoItemsTab;
  },
  dataTableVisibleColumns({ userProfile }) {
    return userProfile?.settings?.dataTableVisibleColumns || {};
  },
  todoItemsTimelineShowHidden({ userProfile }) {
    const retVal = userProfile?.settings?.todoItemsTimelineShowHidden;
    if (retVal === undefined) {
      return true;
    }
    return retVal;
  },
  lastPreferredRiskBucket({ userProfile }) {
    return userProfile?.settings?.lastPreferredRiskBucket || 1;
  },
  canShowConversationSearch({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.view_searchdata &&
      userProfile?.account?.features?.conversation_search
    );
  },
  canShowConversationStockSearch(state) {
    return !!state.userProfile?.account?.features?.conversation_graph;
  },
  canShowTranslateBody(state) {
    return !!state.userProfile?.account?.features?.translation_body;
  },
  canShowTranslateAttachments(state) {
    return !!state.userProfile?.account?.features?.translation_attachments;
  },
  canShowTranslateChatTimeline(state) {
    return !!state.userProfile?.account?.features?.translation_chat_timeline;
  },
  canShowRedaction(state) {
    return !!state.userProfile?.account?.features?.redact_items;
  },
  canShowReports({ userPermissions, userProfile }) {
    return !!(userPermissions?.reporting?.view_report && userProfile?.account?.features?.reporting);
  },
  canShowReportDistributionList({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.view_reportdistributionlist &&
      userProfile?.account?.features?.reporting
    );
  },
  canAddReportDistributionList({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.add_reportdistributionlist &&
      userProfile?.account?.features?.reporting
    );
  },
  canChangeReportDistributionList({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.change_reportdistributionlist &&
      userProfile?.account?.features?.reporting
    );
  },
  canDeleteReportDistributionList({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.delete_reportdistributionlist &&
      userProfile?.account?.features?.reporting
    );
  },
  canShowReportSchedule({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.view_reportschedule && userProfile?.account?.features?.reporting
    );
  },
  canShowReportSettings({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.view_reportconfig && userProfile?.account?.features?.reporting
    );
  },
  canAddReportSettings({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.add_reportconfig && userProfile?.account?.features?.reporting
    );
  },
  canChangeReportSettings({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.change_reportconfig && userProfile?.account?.features?.reporting
    );
  },
  canPublishReport({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.publish_report && userProfile?.account?.features?.reporting
    );
  },
  canUnpublishReport({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.reporting?.unpublish_report && userProfile?.account?.features?.reporting
    );
  },
  canShowWorkflows({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.workflow?.view_workflow && userProfile?.account?.features?.workflows
    );
  },
  canShowStats(state) {
    return !!state.userProfile?.account?.features?.stats;
  },
  canShowSuspiciousTerms({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.view_suspiciousterm &&
      userProfile?.account?.features?.suspicious_terms_management
    );
  },
  canShowSuspiciousTermCategory({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.view_suspicioustermcategory &&
      userProfile?.account?.features?.suspicious_terms_management
    );
  },
  canAddSuspiciousTermCategory({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.add_suspicioustermcategory &&
      userProfile?.account?.features?.suspicious_terms_management
    );
  },
  canChangeSuspiciousTermCategory({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.change_suspicioustermcategory &&
      userProfile?.account?.features?.suspicious_terms_management
    );
  },
  canDeleteSuspiciousTermCategory({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.delete_suspicioustermcategory &&
      userProfile?.account?.features?.suspicious_terms_management
    );
  },
  canShowSearch({ userPermissions, userProfile }) {
    return !!(userPermissions?.search?.view_searchdata && userProfile?.account?.features?.search);
  },
  canShowRandomSearch({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.search?.view_searchdata && userProfile?.account?.features?.random_search
    );
  },
  canShowTriage({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_todoitem && userProfile?.account?.features?.todo_management
    );
  },
  canShowTriageRules({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_todorule && userProfile?.account?.features?.todo_management
    );
  },
  canAddTriageRules({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_todorule && userProfile?.account?.features?.todo_management
    );
  },
  canChangeTriageRules({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.change_todorule &&
      userProfile?.account?.features?.todo_management
    );
  },
  canDeleteTriageRules({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.delete_todorule &&
      userProfile?.account?.features?.todo_management
    );
  },
  canShowTriageActionReasonCategories({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_actionreasoncategory &&
      userProfile?.account?.features?.todo_management
    );
  },
  canAddTriageItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_todoitem && userProfile?.account?.features?.todo_management
    );
  },
  canChangeTriageItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.change_todoitem &&
      userProfile?.account?.features?.todo_management
    );
  },
  canShowCases({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_case && userProfile?.account?.features?.case_management
    );
  },
  canShowCaseCategories({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_casecategory &&
      userProfile?.account?.features?.case_management
    );
  },
  canAddCase({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_case && userProfile?.account?.features?.case_management
    );
  },
  canChangeCase({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.change_case && userProfile?.account?.features?.case_management
    );
  },
  canDeleteCase({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.delete_case && userProfile?.account?.features?.case_management
    );
  },
  canAddCaseItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_caseitem && userProfile?.account?.features?.case_management
    );
  },
  canDeleteTodoItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.delete_caseitem &&
      userProfile?.account?.features?.case_management
    );
  },
  canAddIgnoreItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_ignoreitem &&
      userProfile?.account?.features?.case_management
    );
  },
  canDeleteIgnoreItem({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.delete_ignoreitem &&
      userProfile?.account?.features?.case_management
    );
  },
  canShowCaseNote({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_casenote && userProfile?.account?.features?.case_management
    );
  },
  canAddCaseNote({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.add_casenote && userProfile?.account?.features?.case_management
    );
  },
  canChangeCaseNote({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.change_casenote &&
      userProfile?.account?.features?.case_management
    );
  },
  canDeleteCaseNote({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.delete_casenote &&
      userProfile?.account?.features?.case_management
    );
  },
  canShowCaseActivity({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.casemanager?.view_caseactivity &&
      userProfile?.account?.features?.case_management
    );
  },
  canAddWatch({ userPermissions, userProfile }) {
    return !!(userPermissions?.watch?.add_watchdata && userProfile?.account?.features?.watch);
  },
  canEditWatch({ userPermissions, userProfile }) {
    return !!(userPermissions?.watch?.change_watchdata && userProfile?.account?.features?.watch);
  },
  canShowWatch({ userPermissions, userProfile }) {
    return !!(userPermissions?.watch?.view_watchdata && userProfile?.account?.features?.watch);
  },
  canShowConfiguration(state) {
    return !!state.userProfile?.account?.features?.configuration;
  },
  canShowConfigurationAccount({ userPermissions, userProfile }) {
    return !!(userPermissions?.main?.view_account && userProfile?.account?.features?.configuration);
  },
  canChangeAccountConfiguration({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.change_account && userProfile?.account?.features?.configuration
    );
  },
  canShowConfigurationUser({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.view_fingerprintuser && userProfile?.account?.features?.configuration
    );
  },
  canChangeUserInfo({ userPermissions }) {
    // used for obfuscation toggle
    return !!userPermissions?.main?.change_fingerprintuser;
  },
  canShowLogs({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.admin?.view_logentry &&
      userProfile?.account?.features?.logs &&
      (userProfile?.obfuscation_level || 0) === 0
    );
  },
  canShowPolicy({ userProfile }) {
    return !!userProfile?.account?.features?.policy;
  },
  obfuscationLevel({ userProfile }) {
    return userProfile?.obfuscation_level || 0;
  },
  firmGroups({ userProfile }) {
    return userProfile?.firm_groups || [];
  },
  selectedFirmGroup({ userProfile, _selectedFirmGroupId }) {
    const selectedFirmGroupId = _selectedFirmGroupId || userProfile?.settings?.selectedFirmGroupId;
    const firmGroups = userProfile?.firm_groups || [];
    const firmGroup = firmGroups.find((firmGroup) => firmGroup.id === selectedFirmGroupId);
    const selectedFirmGroup = firmGroup || firmGroups[0];
    return selectedFirmGroup;
  },
  canShowConfigurationFirmGroup({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.change_firmgroup && userProfile?.account?.features?.configuration
    );
  },
  canAddFirmGroups({ userPermissions }) {
    return !!userPermissions?.main?.add_firmgroup;
  },
  canChangeFirmGroups({ userPermissions }) {
    return !!userPermissions?.main?.change_firmgroup;
  },
  canDeleteFirmGroups({ userPermissions }) {
    return !!userPermissions?.main?.delete_firmgroup;
  },
  canShowFaq({ userPermissions }) {
    return !!userPermissions?.pagecontent?.view_contentsection;
  },
  canShowRiskConfig({ userPermissions }) {
    return !!userPermissions?.main?.view_riskconfig;
  },
  canChangeRiskConfig({ userPermissions }) {
    return !!userPermissions?.main?.change_riskconfig;
  },
  canShowRedactTerms({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.view_redactterm && userProfile?.account?.features?.redact_items
    );
  },
  canAddRedactTerms({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.add_redactterm && userProfile?.account?.features?.redact_items
    );
  },
  canChangeRedactTerms({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.change_redactterm && userProfile?.account?.features?.redact_items
    );
  },
  canDeleteRedactTerms({ userPermissions, userProfile }) {
    return !!(
      userPermissions?.main?.delete_redactterm && userProfile?.account?.features?.redact_items
    );
  },
  isStaffUser({ userProfile }) {
    return !!userProfile?.is_staff;
  },
};

export const actions = {
  setAuthErrorMessage(message) {
    this.authErrorMessage = message;
  },
  setAwsAuthState({ authState, authData }) {
    this.awsAuthState = authState;
    this.awsAuthData = authData; // DON'T use object spread here!
  },
  async setSelectedFirmGroupId(firmGroupId) {
    this._selectedFirmGroupId = firmGroupId;
    await this.updateUserSettings("selectedFirmGroupId", firmGroupId);
  },
  setUserProfile(userProfile) {
    this.userProfile = userProfile && { ...userProfile };
    // not a real logged-in flag, just for displaying different loading screen msg
    localStorage.setItem("LOADING_SCREEN_LOGGED", userProfile ? "1" : "0");
  },

  async getUserProfile({ onlyAfter60Seconds } = { onlyAfter60Seconds: false }) {
    const now = new Date();
    if (
      onlyAfter60Seconds && // reload userInfo only after 60 seconds
      this.userProfile && // userProfile already set
      this.lastProfileUpdate && // lastProfileUpdate already set
      now - this.lastProfileUpdate < 60000 // lastProfileUpdate more than 60 seconds ago
    ) {
      return this.userProfile;
    }
    try {
      const [{ data: userProfile }, { data: userPermissions }] = await Promise.all([
        httpClient.get("users/me/", {
          params: { expand: "account,firm_groups,account.redact_terms" },
        }),
        httpClient.get("users/me/permissions/"),
      ]);
      this.setUserProfile(userProfile);
      this.lastProfileUpdate = now;
      this.userPermissions = userPermissions;
      return userProfile;
    } catch (err) {
      this.setAuthErrorMessage(err);
      this.setUserProfile(null);
    }
  },
  async updateUserSettings(key, value) {
    await setUserSettings(this.userProfile.id, {
      ...this.userProfile.settings,
      [key]: value,
    });
    this.getUserProfile();
  },
  updateDefaultIgnoreReasonCategoryID(value) {
    return this.updateUserSettings("defaultIgnoreReasonCategoryID", value);
  },
  updateCaseDataTableTodoItemsTab(value) {
    return this.updateUserSettings("caseDataTableTodoItemsTab", value);
  },
  updateDataTableVisibleColumns(tableName, settings) {
    return this.updateUserSettings("dataTableVisibleColumns", {
      ...this.dataTableVisibleColumns,
      [tableName]: settings,
    });
  },
  /**
   * @param {number} value
   */
  updateLastPreferredRiskBucket(value) {
    return this.updateUserSettings("lastPreferredRiskBucket", value);
  },
  /**
   * @param {boolean} boolValue
   */
  updateTodoItemsTimelineShowHidden(value) {
    return this.updateUserSettings("todoItemsTimelineShowHidden", value);
  },
  async handleAwsUiStateChange({ authState, authData }) {
    this.setAwsAuthState({ authState, authData });
    if (authState === AuthState.SignedIn) {
      // user is logged in correctly, getting user profile
      const userProfile = await this.getUserProfile({ onlyAfter60Seconds: true });
      if (userProfile) {
        initRedactor(userProfile.account.redact_terms);
        initWebsocketConnection();
      } else {
        // unable to get userprofile, logging out
        Auth.signOut();
      }
    }
  },
  async awsLogout() {
    await logLogout().catch(() => {});
    await Auth.signOut();
    this.setUserProfile(null);
    location.replace("/");
  },
  async toggleObfuscation() {
    const { data } = await setUserObfuscation(
      this.userProfile.id,
      this.userProfile.obfuscation_level === 0 ? 3 : 0,
    );
    this.setUserProfile({ ...this.userProfile, obfuscation_level: data.obfuscation_level });
  },
};

export const useAuthStore = defineStore("auth", {
  state: () => defaultState,
  getters,
  actions,
});
