<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <v-tabs v-model="tabSelected" @change="handleTabChange" small>
      <v-tab>Text</v-tab>
      <v-tab v-if="!['bbg.msg'].includes(innerData.fingerprint_type)">Raw</v-tab>
      <v-tab
        v-if="
          ['bbg.im', 'teams.personal', 'teams.corporate', 'slack', 'whatsapp'].includes(
            innerData.fingerprint_type,
          )
        "
      >
        Timeline
      </v-tab>
      <v-tab v-if="['voice', 'zoom.call', 'zoom.meet'].includes(innerData.fingerprint_type)">
        Voice/Text
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabSelected">
      <!-- Text tab -->
      <v-tab-item>
        <div>
          <div v-if="loading" class="body-text">Loading ...</div>

          <div v-else-if="bodyRedacted" class="body-text">
            <v-row>
              <v-col>
                <h4>Body Text</h4>
              </v-col>
              <v-col cols="auto">
                <TranslateSelect
                  v-if="canShowTranslateBody"
                  v-model="translateOptions"
                  :languages="languages"
                  :translated="translated_body !== null"
                  @translate="_translate"
                  @reset="handleResetTranslation"
                />
              </v-col>
            </v-row>

            <div v-obfuscate v-html="bodyRedacted" />
            <v-btn v-if="isRedacted" small @click="handleUnredact"> Un-redact </v-btn>
          </div>
          <div v-else>
            <h3>Body text is empty</h3>
          </div>

          <div v-if="innerData.attachments" class="attach-text">
            <h3>Attachments</h3>
            <template v-if="Array.isArray(innerData.attachments)">
              <div
                v-for="(item, index) in innerData.attachments"
                :key="index"
                class="attach-detail"
              >
                <TranslateSelect
                  v-if="canShowTranslateAttachments"
                  v-model="translateOptions"
                  :languages="languages"
                  :translated="item.translated_content !== undefined"
                  @translate="translateAttachment(item)"
                  @reset="handleResetAttachmentTranslation(item)"
                />
                <div class="text-subtitle-1">
                  <strong>Filename:</strong> <span v-obfuscate v-html="item.filename" />
                </div>
                <div>
                  <strong>Content:</strong>
                  <span v-obfuscate v-html="item.translated_content || item.content" />
                </div>
              </div>
            </template>
            <template v-else>
              <TranslateSelect
                v-if="canShowTranslateAttachments"
                v-model="translateOptions"
                :languages="languages"
                :translated="item.translated_content !== undefined"
                @translate="translateAttachment(innerData.attachments)"
                @reset="handleResetAttachmentTranslation(innerData.attachments)"
              />
              <div class="text-subtitle-1">
                <strong>Filename:</strong>
                <span v-obfuscate v-html="innerData.attachments.filename" />
              </div>
              <div>
                <strong>Content:</strong>
                <span
                  v-obfuscate
                  v-html="innerData.attachments.translated_content || innerData.attachments.content"
                />
              </div>
            </template>
          </div>
        </div>
      </v-tab-item>

      <!-- Raw tab -->
      <v-tab-item v-if="!['bbg.msg'].includes(innerData.fingerprint_type)">
        <div>
          <div class="body-text">
            <h3>
              <span> Subject: </span>
              <span v-obfuscate>
                {{ innerData.subject }}
                <span v-if="innerData.fingerprint_type === 'voice'">
                  on {{ mediumDateTimeFormat(innerData.fingerprint_time) }}
                </span>
              </span>
              <span>{{ fullDateTimeFormat(innerData.fingerprint_time) }}</span>
            </h3>
            <h5>
              <span>From: </span>
              <span v-obfuscate>{{ from_person }}</span>
              <br />
              <template v-if="innerData.to">
                <span>To: </span><span v-obfuscate>{{ innerData.to }}</span>
              </template>
              <template v-else-if="innerData.cc">
                <span>Cc: </span><span v-obfuscate>{{ innerData.cc }}</span>
              </template>
              <template v-else-if="innerData.bcc">
                <span>Bcc: </span><span v-obfuscate>{{ innerData.bcc }}</span>
              </template>
            </h5>
            <ejs-richtexteditor
              :height="bodyHeight"
              :toolbar-settings="toolbarData"
              :readonly="true"
              :inline-mode="inlineModeData"
              :value="richTextEditorContentRedacted"
              :enable-auto-url="false"
            />
            <v-btn v-if="isRedacted" small @click="handleUnredact"> Un-redact </v-btn>
          </div>

          <div v-if="innerData.attachments">
            <MessageAttachments
              :es-id="innerData.id"
              :attachments="innerData.attachments"
              :fingerprint-time="innerData.fingerprint_time"
            />
          </div>
        </div>
      </v-tab-item>

      <v-tab-item v-if="innerData.fingerprint_type === 'bbg.im'">
        <div class="modal-body">
          <BBGTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :conversation-id="innerData.conversationid"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :languages="languages"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>

      <v-tab-item v-if="innerData.fingerprint_type === 'slack'">
        <div class="modal-body">
          <SlackTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :conversation-id="innerData.conversationid"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :languages="languages"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>

      <v-tab-item v-if="innerData.fingerprint_type === 'whatsapp'">
        <div class="modal-body">
          <WhatsappTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :conversation-id="innerData.message_id_details"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :languages="languages"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>

      <v-tab-item
        v-if="
          innerData.fingerprint_type === 'teams.personal' ||
          innerData.fingerprint_type === 'teams.corporate'
        "
      >
        <div class="modal-body">
          <TeamsTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :conversation-id="innerData.conversationid"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :languages="languages"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>

      <v-tab-item v-if="innerData.fingerprint_type === 'voice'">
        <div class="modal-body">
          <VoiceTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>

      <v-tab-item
        v-if="
          innerData.fingerprint_type === 'zoom.call' || innerData.fingerprint_type === 'zoom.meet'
        "
      >
        <div class="modal-body">
          <ZoomTimeline
            v-if="tabSelected === 2"
            :es-id="innerData.id"
            :fingerprint-client="innerData.fingerprint_client"
            :fingerprint-time="innerData.fingerprint_time"
            :from-person="innerData.from_person"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "pinia";
import {
  RichTextEditorComponent,
  HtmlEditor,
  QuickToolbar,
} from "@syncfusion/ej2-vue-richtexteditor";
import Vue from "vue";
import TranslateSelect from "@/components/common/TranslateSelect.vue";
import MessageAttachments from "./MessageAttachments.vue";
import BBGTimeline from "./BBGTimeline.vue";
import SlackTimeline from "./SlackTimeline.vue";
import TeamsTimeline from "./TeamsTimeline.vue";
import VoiceTimeline from "./VoiceTimeline.vue";
import WhatsappTimeline from "./WhatsappTimeline.vue";
import ZoomTimeline from "./ZoomTimeline.vue";
import { logUnredactMessage } from "@/repositories/logs";
import { getDocument, translate } from "@/repositories/search";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";
import { fullDateTimeFormat, mediumDateTimeFormat, redactPII } from "@/filters";

export default {
  name: "MessageDetail",
  components: {
    "ejs-richtexteditor": RichTextEditorComponent,
    MessageAttachments,
    BBGTimeline,
    SlackTimeline,
    TeamsTimeline,
    VoiceTimeline,
    WhatsappTimeline,
    TranslateSelect,
    ZoomTimeline,
  },
  provide: {
    richtexteditor: [HtmlEditor, QuickToolbar],
  },
  props: {
    externalData: {
      type: Object || null,
      default: null,
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      toolbarData: {
        items: [
          "Bold",
          "Italic",
          "Underline",
          "Formats",
          "-",
          "Alignments",
          "OrderedList",
          "UnorderedList",
          "CreateLink",
        ],
      },
      inlineModeData: {
        enable: true,
        onSelection: true,
      },
      loading: true,
      bodyHeight: 0,
      tabSelected: 0,
      translated_body: null,
      translateOptions: {
        fromLang: "en",
        toLang: "de",
      },
      richTextEditorContent: "Loading content ...",
      unredact: undefined,
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      "canShowRedaction",
      "canShowTranslateBody",
      "canShowTranslateAttachments",
    ]),
    innerData() {
      return Object.entries(this.data).length > 0 ? this.data : this.externalData;
    },
    isRedacted() {
      return (
        this.canShowRedaction && this.unredact === undefined && this.body !== this.bodyRedacted
      );
    },
    body() {
      if (this.translated_body) {
        return this.translated_body;
      } else if (this.externalData?.translated_body) {
        return this.externalData.translated_body;
      } else if (this.innerData?.highlighted?.body) {
        return this.innerData.highlighted.body;
      }
      return this.innerData.body;
    },
    bodyRedacted() {
      const body = this.body;
      if (this.canShowRedaction && this.unredact === undefined) {
        return redactPII(body);
      }
      return body;
    },
    richTextEditorContentRedacted() {
      const retVal = this.richTextEditorContent;
      if (this.canShowRedaction && this.unredact === undefined) {
        return redactPII(retVal);
      }
      return retVal;
    },
    from_person() {
      if (this.innerData.from_person_display) {
        return this.innerData.from_person_display;
      }
      return this.innerData.from_person;
    },
  },
  mounted() {
    this.getDocumentFromServer();
  },
  methods: {
    fullDateTimeFormat,
    mediumDateTimeFormat,
    handleUnredact() {
      this.unredact = true;
      logUnredactMessage(this.innerData);
    },
    handleTabChange(tabNumber) {
      if ([0, 1].includes(tabNumber)) {
        this.getDocumentFromServer();
      }
    },
    async getDocumentFromServer() {
      const isRaw = this.tabSelected === 1;
      this.loading = true;
      try {
        const { data } = await getDocument(
          this.innerData.id,
          this.innerData.fingerprint_time,
          isRaw,
          this.innerData.django_id,
        );
        if (this.innerData.highlighted?.body) {
          data.body = this.innerData.highlighted.body;
        }
        if (this.innerData.body) {
          data.body = this.innerData.body;
        }
        this.data = data;
        if (data.body_html || data.body) {
          this.richTextEditorContent = data.body_html || data.body;
          this.bodyHeight = "auto";
        }
      } catch (error) {
        console.error("getDocument error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.loading = false;
      }
    },
    async _translate() {
      try {
        const { data } = await translate(
          "translation_body",
          this.innerData.body,
          this.translateOptions.fromLang,
          this.translateOptions.toLang,
        );
        this.translated_body = data.to_text;
        // alerting parents that the translation is completed
        const payload = {
          item: this.data,
          fromLang: this.translateOptions.fromLang,
          toLang: this.translateOptions.toLang,
        };
        this.$emit("translated", payload);
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    handleResetTranslation() {
      this.translated_body = null;
    },
    async translateAttachment(item) {
      try {
        const { data } = await translate(
          "translation_attachments",
          item.content,
          this.translateOptions.fromLang,
          this.translateOptions.toLang,
        );
        Vue.set(item, "translated_content", data.to_text);
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    handleResetAttachmentTranslation(item) {
      Vue.set(item, "translated_content", undefined);
    },
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-inputs/styles/material3.css";
@import "@syncfusion/ej2-lists/styles/material3.css";
@import "@syncfusion/ej2-popups/styles/material3.css";
@import "@syncfusion/ej2-buttons/styles/material3.css";
@import "@syncfusion/ej2-navigations/styles/material3.css";
@import "@syncfusion/ej2-splitbuttons/styles/material3.css";
@import "@syncfusion/ej2-vue-richtexteditor/styles/material3.css";
</style>

<style scoped>
::v-deep(span.highlighted) {
  color: #ff394b !important;
  font-weight: bold;
}

h3 {
  font-weight: normal;
  font-size: 1.3em;
}
.detail-row {
  text-align: left;
}
.body-text {
  padding: 10px;
  overflow: hidden;
}
.attach-text {
  padding: 10px;
}
.attach-detail {
  padding: 10px 0;
}
</style>
