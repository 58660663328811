import SearchList from "@/components/crud/SearchList.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import SearchConversationForm from "@/components/search/SearchConversationForm.vue";
import SearchDataTable from "@/components/datatable/SearchDataTable.vue";
import SearchResultsConversation from "@/components/search/SearchResultsConversation.vue";
import CaseSearchDataTable from "@/components/datatable/CaseSearchDataTable.vue";
import SuspiciousCategoryDataTable from "@/components/datatable/SuspiciousCategoryDataTable.vue";
import SuspiciousTermHistory from "@/components/crud/SuspiciousTermHistory.vue";

const routes = [
  {
    path: "/search/create/:caseId?/",
    name: "NewSearch",
    component: SearchForm,
    props: { randomSearch: false },
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/random-search/",
    name: "RandomSearch",
    component: SearchForm,
    props: { randomSearch: true },
    meta: {
      checkAccountFeatures: ({ canShowRandomSearch }) => canShowRandomSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/search/saved/",
    name: "SearchList",
    component: SearchList,
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
    },
  },
  {
    path: "/search/saved/:pk/update/",
    name: "SavedSearch",
    component: SearchForm,
    props: { randomSearch: false },
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/search/conversation/create/",
    name: "ConversationSearch",
    component: SearchConversationForm,
    meta: {
      checkAccountFeatures: ({ canShowConversationSearch }) => canShowConversationSearch,
    },
  },
  {
    path: "/search/conversation/results/",
    name: "SearchResultsConversation",
    component: SearchResultsConversation,
    meta: {
      checkAccountFeatures: ({ canShowConversationSearch }) => canShowConversationSearch,
      refreshTo: { name: "ConversationSearch" },
    },
  },
  {
    path: "/search/results/:pk?/",
    name: "SearchResults",
    component: SearchDataTable,
    props: (route) => ({ randomSearch: false, ...route.params }),
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/random-search/results/",
    name: "RandomSearchResults",
    component: SearchDataTable,
    props: { randomSearch: true },
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/search/case/:caseId/results/",
    name: "SearchResultsCase",
    component: CaseSearchDataTable,
    props: true,
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SearchList" },
    },
  },
  {
    path: "/search/suspiciousresults/category/:pk/",
    name: "CategorySuspiciousSearchResults",
    component: SuspiciousCategoryDataTable,
    props: true,
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SuspiciousTermList" },
    },
  },
  {
    path: "/search/suspiciousresults/history/:id/",
    name: "SuspiciousTermHistory",
    component: SuspiciousTermHistory,
    meta: {
      checkAccountFeatures: ({ canShowSearch }) => canShowSearch,
      refreshTo: { name: "SuspiciousTermList" },
    },
  },
];

export default routes;
