"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var router_1 = require("@/router");
var composables_1 = require("vue-router/composables");
var auth_1 = require("@/stores/auth");
exports.default = (0, vue_1.defineComponent)({
    __name: 'IpsNavigation',
    props: {
        value: null
    },
    setup: function (__props) {
        var authStore = (0, auth_1.useAuthStore)();
        var router = (0, composables_1.useRouter)();
        var _a = (0, pinia_1.storeToRefs)(authStore), firmGroups = _a.firmGroups, selectedFirmGroup = _a.selectedFirmGroup, canChangeRiskConfig = _a.canChangeRiskConfig, canShowCases = _a.canShowCases, canShowCaseCategories = _a.canShowCaseCategories, canShowConfigurationUser = _a.canShowConfigurationUser, canShowConfigurationFirmGroup = _a.canShowConfigurationFirmGroup, canShowConversationSearch = _a.canShowConversationSearch, canShowLogs = _a.canShowLogs, canShowRandomSearch = _a.canShowRandomSearch, canShowReports = _a.canShowReports, canShowReportDistributionList = _a.canShowReportDistributionList, canShowReportSchedule = _a.canShowReportSchedule, canShowReportSettings = _a.canShowReportSettings, canShowSearch = _a.canShowSearch, canShowStats = _a.canShowStats, canShowSuspiciousTerms = _a.canShowSuspiciousTerms, canShowSuspiciousTermCategory = _a.canShowSuspiciousTermCategory, canShowTriage = _a.canShowTriage, canShowTriageRules = _a.canShowTriageRules, canShowTriageActionReasonCategories = _a.canShowTriageActionReasonCategories, canShowWatch = _a.canShowWatch, canShowWorkflows = _a.canShowWorkflows, canShowPolicy = _a.canShowPolicy, canShowFaq = _a.canShowFaq, canShowRedactTerms = _a.canShowRedactTerms, firmLabelObject = _a.firmLabelObject, isStaffUser = _a.isStaffUser;
        var setSelectedFirmGroupId = authStore.setSelectedFirmGroupId;
        var items = (0, vue_2.computed)(function () {
            return [
                {
                    key: 1,
                    items: [
                        {
                            title: "Summary",
                            icon: "mdi-chart-bar",
                            routerLinkTo: canShowStats.value && { name: "Stats" },
                            show: true,
                            items: [
                                {
                                    title: "Your Notifications",
                                    icon: "mdi-file-document-multiple",
                                    routerLinkTo: { name: "CaseWorkflowList" },
                                    show: canShowWorkflows.value,
                                },
                                {
                                    title: "Metrics",
                                    icon: "mdi-chart-bar",
                                    routerLinkTo: { name: "Stats" },
                                    show: canShowStats.value,
                                },
                                {
                                    title: "Activity Log",
                                    icon: "mdi-table-large",
                                    routerLinkTo: { name: "LogList" },
                                    show: canShowLogs.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                        {
                            title: "Supervision",
                            icon: "mdi-check-circle-outline",
                            routerLinkTo: canShowTriage.value && { name: "TriageList" },
                            show: true,
                            items: [
                                {
                                    title: "Triage List",
                                    icon: "mdi-check-circle-outline",
                                    routerLinkTo: { name: "TriageList" },
                                    show: canShowTriage.value,
                                },
                                {
                                    title: "Risk Categories",
                                    icon: "mdi-format-list-group",
                                    routerLinkTo: { name: "SuspiciousTermCategoriesList" },
                                    show: canShowSuspiciousTermCategory.value,
                                },
                                {
                                    title: "Risk Terms",
                                    icon: "mdi-format-list-bulleted",
                                    routerLinkTo: { name: "SuspiciousTermList" },
                                    show: canShowSuspiciousTerms.value,
                                },
                                {
                                    title: "Risk Buckets",
                                    icon: "mdi-group",
                                    routerLinkTo: {
                                        name: "RiskBucketSettings",
                                    },
                                    show: canChangeRiskConfig.value,
                                },
                                {
                                    title: "Auto Ignore Rules",
                                    icon: "mdi-format-list-bulleted",
                                    routerLinkTo: { name: "TriageAutoIgnoreRules" },
                                    show: canShowTriageRules.value,
                                },
                                {
                                    title: "Triage Action Reasons",
                                    icon: "mdi-format-list-group",
                                    routerLinkTo: { name: "ActionReasonCategoryList", params: { type: 1 } },
                                    show: canShowTriageActionReasonCategories.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                        {
                            title: "Investigation",
                            icon: "mdi-folder-open-outline",
                            routerLinkTo: canShowCases.value && { name: "CaseList", params: { status: "1" } },
                            show: true,
                            items: [
                                {
                                    title: "Cases",
                                    icon: "mdi-folder-open-outline",
                                    routerLinkTo: { name: "CaseList", params: { status: "1" } },
                                    show: canShowCases.value,
                                },
                                {
                                    title: "Categories",
                                    icon: "mdi-format-list-bulleted",
                                    routerLinkTo: { name: "CaseCategoryList" },
                                    show: canShowCaseCategories.value,
                                },
                                {
                                    title: "Case Action Reasons",
                                    icon: "mdi-format-list-group",
                                    routerLinkTo: { name: "ActionReasonCategoryList", params: { type: 2 } },
                                    show: canShowTriageActionReasonCategories.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                        {
                            title: "Search",
                            icon: "mdi-magnify",
                            routerLinkTo: canShowSearch.value && { name: "NewSearch" },
                            show: true,
                            items: [
                                {
                                    title: "New Search",
                                    icon: "mdi-magnify",
                                    routerLinkTo: { name: "NewSearch" },
                                    show: canShowSearch.value,
                                },
                                {
                                    title: "Random Sample",
                                    icon: "mdi-database-search-outline",
                                    routerLinkTo: { name: "RandomSearch" },
                                    show: canShowRandomSearch.value,
                                },
                                {
                                    title: "Conversation Search",
                                    icon: "mdi-chat",
                                    routerLinkTo: { name: "ConversationSearch" },
                                    show: canShowConversationSearch.value,
                                },
                                {
                                    title: "Saved Searches",
                                    icon: "mdi-content-save-outline",
                                    routerLinkTo: { name: "SearchList" },
                                    show: canShowSearch.value,
                                },
                                {
                                    title: "Scheduled Searches",
                                    icon: "mdi-clock-outline",
                                    routerLinkTo: { name: "WatchList" },
                                    show: canShowWatch.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                        {
                            title: "Reporting",
                            icon: "mdi-file-chart",
                            routerLinkTo: canShowReports.value && { name: "ReportingReports" },
                            show: true,
                            items: [
                                {
                                    title: "Schedule",
                                    icon: "mdi-clock-outline",
                                    routerLinkTo: { name: "ReportingSchedule" },
                                    show: canShowReportSchedule.value,
                                },
                                {
                                    title: "Reports",
                                    icon: "mdi-file-plus",
                                    routerLinkTo: { name: "ReportingReports" },
                                    show: canShowReports.value,
                                },
                                {
                                    title: "Distribution Lists",
                                    icon: "mdi-list-box-outline",
                                    routerLinkTo: { name: "ReportingDistributionLists" },
                                    show: canShowReportDistributionList.value,
                                },
                                {
                                    title: "Format Report",
                                    icon: "mdi-file-cog",
                                    routerLinkTo: { name: "ReportingCustomise" },
                                    show: canShowReportSettings.value,
                                },
                                {
                                    title: "Exports",
                                    icon: "mdi-table-large",
                                    routerLinkTo: { name: "WatchReports" },
                                    show: canShowWatch.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                        {
                            title: "Learning Center",
                            icon: "mdi-school",
                            routerLinkTo: canShowFaq.value && { name: "FaqHome" },
                            show: canShowFaq.value,
                        },
                    ].filter(function (item) { return item.show && (item.items === undefined || item.items.length > 0); }),
                },
                {
                    key: 2,
                    items: [
                        {
                            title: "Settings",
                            icon: "mdi-wrench",
                            routerLinkTo: (canShowPolicy.value && { name: "PolicyDocument" }) ||
                                (canShowConfigurationFirmGroup.value && { name: "FirmGroupList" }),
                            show: true,
                            items: [
                                {
                                    title: "Fingerprint User",
                                    icon: "mdi-account",
                                    routerLinkTo: { name: "UserList" },
                                    show: canShowConfigurationUser.value,
                                },
                                {
                                    title: "".concat(firmLabelObject.value.singularUppercase, " Groups"),
                                    icon: "mdi-format-list-group",
                                    routerLinkTo: { name: "FirmGroupList" },
                                    show: canShowConfigurationFirmGroup.value,
                                },
                                {
                                    title: "Redact Custom Terms",
                                    icon: "mdi-sort-variant-lock-open",
                                    routerLinkTo: { name: "RedactTerms" },
                                    show: canShowRedactTerms.value,
                                },
                                {
                                    title: "Policy",
                                    icon: "mdi-file-document-check",
                                    routerLinkTo: { name: "PolicyDocument" },
                                    show: canShowPolicy.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                    ].filter(function (item) { return item.show && (item.items === undefined || item.items.length > 0); }),
                },
                {
                    key: 3,
                    items: [
                        {
                            title: "Administration Tools",
                            icon: "mdi-security",
                            routerLinkTo: { name: "AdminWebsockets" },
                            show: isStaffUser.value,
                            items: [
                                {
                                    title: "Websocket Messaging",
                                    icon: "mdi-webhook",
                                    routerLinkTo: { name: "AdminWebsockets" },
                                    show: isStaffUser.value,
                                },
                            ].filter(function (item) { return item.show; }),
                        },
                    ].filter(function (item) { return item.show && (item.items === undefined || item.items.length > 0); }),
                },
            ].filter(function (item) { return item.items.length > 0; });
        });
        function handleFirmGroupChange(firmGroupId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, setSelectedFirmGroupId(firmGroupId)];
                        case 1:
                            _a.sent();
                            (0, router_1.refreshRoute)();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleMenuChange(item) {
            if (!item.active && item.routerLinkTo) {
                router.push(item.routerLinkTo).catch(function () { });
            }
        }
        return { __sfc: true, authStore: authStore, router: router, firmGroups: firmGroups, selectedFirmGroup: selectedFirmGroup, canChangeRiskConfig: canChangeRiskConfig, canShowCases: canShowCases, canShowCaseCategories: canShowCaseCategories, canShowConfigurationUser: canShowConfigurationUser, canShowConfigurationFirmGroup: canShowConfigurationFirmGroup, canShowConversationSearch: canShowConversationSearch, canShowLogs: canShowLogs, canShowRandomSearch: canShowRandomSearch, canShowReports: canShowReports, canShowReportDistributionList: canShowReportDistributionList, canShowReportSchedule: canShowReportSchedule, canShowReportSettings: canShowReportSettings, canShowSearch: canShowSearch, canShowStats: canShowStats, canShowSuspiciousTerms: canShowSuspiciousTerms, canShowSuspiciousTermCategory: canShowSuspiciousTermCategory, canShowTriage: canShowTriage, canShowTriageRules: canShowTriageRules, canShowTriageActionReasonCategories: canShowTriageActionReasonCategories, canShowWatch: canShowWatch, canShowWorkflows: canShowWorkflows, canShowPolicy: canShowPolicy, canShowFaq: canShowFaq, canShowRedactTerms: canShowRedactTerms, firmLabelObject: firmLabelObject, isStaffUser: isStaffUser, setSelectedFirmGroupId: setSelectedFirmGroupId, items: items, handleFirmGroupChange: handleFirmGroupChange, handleMenuChange: handleMenuChange };
    }
});
