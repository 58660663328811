<script setup>
import { ref, computed, onMounted } from "vue";
import { getDataTypes } from "@/repositories/settings";
import TodoItemCard from "./TodoItemCard.vue";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  languages: {
    type: Array,
    required: true,
  },
  notes: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits([
  "toggleHideItem",
  "searchConversationsFromItem",
  "addItemNote",
  "showItemNotes",
  "removeItem",
]);

const store = useStore();
const authStore = useAuthStore();

const showHidden = ref(true);
const itemsWithNotes = computed(() =>
  props.items
    .filter(
      (item) =>
        (showHidden.value || !item.is_hidden) && mappedDataTypes.value[item.fingerprint_type],
    )
    .map((item) => ({
      ...item,
      notesCount: props.notes.filter((note) => note.todo_item === item.django_id).length,
    })),
);
function toggleShowHidden() {
  showHidden.value = !showHidden.value;
  authStore.updateTodoItemsTimelineShowHidden(showHidden.value);
}

const dataTypes = ref([]);
const dataTypesLoading = ref(true);
const mappedDataTypes = computed(() =>
  dataTypes.value.reduce(
    (acc, dataType) => ({
      ...acc,
      [dataType.id]: { ...dataType },
    }),
    {},
  ),
);

function handleOpenDocument(item) {
  store.showMessageDialog(item);
}

onMounted(async () => {
  showHidden.value = authStore.todoItemsTimelineShowHidden;
  dataTypesLoading.value = true;
  dataTypes.value = await getDataTypes();
  dataTypesLoading.value = false;
});
</script>

<template>
  <div>
    <div v-if="props.loading || dataTypesLoading" class="ma-3">Loading items...</div>
    <div v-else-if="!itemsWithNotes.length" class="ma-3">No items to display</div>
    <div v-else>
      <v-row>
        <v-col align="right">
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn class="ma-1" small v-bind="attrs" v-on="on" @click="toggleShowHidden">
                <v-icon left>
                  {{ showHidden ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
                {{ showHidden ? "Remove hidden items" : "Show hidden items" }}
              </v-btn>
            </template>
            <span v-if="showHidden">Remove hidden items from the timeline</span>
            <span v-else>Show hidden items in the timeline</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-timeline align-top dense>
        <v-timeline-item
          v-for="item in itemsWithNotes"
          :key="item.id"
          :color="mappedDataTypes[item.fingerprint_type]?.color"
          :icon="mappedDataTypes[item.fingerprint_type]?.icon"
          fill-dot
        >
          <TodoItemCard
            :item="item"
            :data-type="mappedDataTypes[item.fingerprint_type]"
            :can-show-conversation-search="authStore.canShowConversationSearch"
            :can-add-case-note="authStore.canAddCaseNote"
            :can-delete-todo-item="authStore.canDeleteTodoItem"
            @toggleHide="emit('toggleHideItem', item.django_id, $event)"
            @searchConversations="emit('searchConversationsFromItem', item)"
            @openDocument="handleOpenDocument(item)"
            @addNote="emit('addItemNote', item.django_id)"
            @showNotes="emit('showItemNotes', item.django_id)"
            @remove="emit('removeItem', item.django_id)"
          />
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>
