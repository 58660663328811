import { VCard } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mb-5"},[_c(VExpansionPanels,{attrs:{"flat":"","readonly":!_vm.enableExpansion}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{attrs:{"hide-actions":!_vm.enableExpansion}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.$slots.info)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"ml-2"},on),[_vm._v("mdi-information")])]}}],null,false,559429341)},[_c('div',[_vm._t("info")],2)]):_vm._e()],1)]),_c(VExpansionPanelContent,[_vm._t("metric-detail")],2)],1)],1),_vm._t("metric")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }