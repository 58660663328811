<template>
  <div>
    <v-app>
      <v-snackbar
        v-if="canShowAuthErrorMessage"
        :value="authErrorMessage"
        color="error"
        tile
        timeout="10000"
        top
        @input="handleToggleAuthErrorMessage"
      >
        <strong>{{ authErrorMessage }}</strong>
      </v-snackbar>

      <v-dialog v-model="showLicenses" scrollable width="90%">
        <LicenseList v-if="showLicenses" @close="showLicenses = false" />
      </v-dialog>
      <MessageDialog />

      <amplify-auth-container>
        <amplify-authenticator>
          <amplify-sign-in slot="sign-in" hide-sign-up="true" />
          <amplify-confirm-sign-in
            slot="confirm-sign-in"
            header-text="Enter code from your Authenticator App"
            :form-fields.prop="confirmSignInFormFields"
            :user.prop="awsAuthData"
          />
        </amplify-authenticator>
      </amplify-auth-container>

      <div v-if="isAuthenticated && userProfile">
        <IpsNavigation v-if="!showPrintingLayout" v-model="drawer" class="ips-main-nav" />
        <IpsHeader
          v-if="!showPrintingLayout"
          :key="updateKey"
          class="ips-main-header"
          @toggle-obfuscation="toggle_obfuscation"
          @toggle-drawer="drawer = !drawer"
        />
        <v-main>
          <v-container fluid :key="updateKey">
            <Notification />
            <WebsocketNotifications />
            <router-view />
            <v-btn
              v-if="canScrollToTop && !showPrintingLayout"
              class="d-print-none my-fab-button ma-5"
              color="transparent"
              fab
              bottom
              right
              @click="scrollToTop"
            >
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-chevron-up </v-icon>
                </template>
                <span>Back to top of the page</span>
              </v-tooltip>
            </v-btn>
          </v-container>
        </v-main>
      </div>
      <IpsFooter
        v-if="!showPrintingLayout"
        class="ips-main-footer"
        @licensesClick="showLicenses = true"
      />
    </v-app>
  </div>
</template>

<script>
import { onAuthUIStateChange } from "@aws-amplify/ui-components";
import { mapState, mapActions } from "pinia";

import IpsNavigation from "@/components/IpsNavigation.vue";
import IpsHeader from "@/components/IpsHeader.vue";
import IpsFooter from "@/components/IpsFooter.vue";
import LicenseList from "@/components/LicenseList.vue";
import Notification from "@/components/common/popups/Notification.vue";
import WebsocketNotifications from "@/components/common/popups/WebsocketNotifications.vue";
import MessageDialog from "@/components/MessageDialog.vue";
import { AccountStatus } from "@/models/enums";
import { obfuscate } from "@/plugins/obfuscate";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "App",
  components: {
    IpsNavigation,
    IpsHeader,
    IpsFooter,
    LicenseList,
    Notification,
    WebsocketNotifications,
    MessageDialog,
  },
  data() {
    return {
      AccountStatus,
      obfuscate,
      scrollY: 0,
      drawer: true,
      updateKey: 0,
      unsubscribeAuth: undefined,
      showLicenses: false,
      confirmSignInFormFields: [
        {
          type: "code",
          required: true,
          inputProps: {
            type: "text", // specify the actual html input type here
            inputmode: "numeric",
            pattern: "[0-9]*",
            autocomplete: "one-time-code",
            required: true,
          },
        },
      ],
    };
  },
  computed: {
    ...mapState(useStore, {
      showPrintingLayout: (state) => state.showPrintingLayout,
    }),
    ...mapState(useAuthStore, [
      "authErrorMessage",
      "awsAuthData",
      "isAuthenticated",
      "isTokenExpired",
      "userProfile",
    ]),
    canScrollToTop() {
      return this.scrollY > 100;
    },
    canShowAuthErrorMessage() {
      return !["RootPath", "TriageList"].includes(this.$route.name) && !this.userProfile;
    },
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.handleAwsUiStateChange({ authState, authData });
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    obfuscate.obfuscate();
  },
  methods: {
    ...mapActions(useAuthStore, ["setAuthErrorMessage", "handleAwsUiStateChange"]),
    handleToggleAuthErrorMessage(opened) {
      if (!opened) {
        this.setAuthErrorMessage(null);
      }
    },
    handleScroll() {
      this.scrollY = window.scrollY;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: window.scrollX,
        behavior: "smooth",
      });
    },
    async toggle_obfuscation() {
      await obfuscate.toggle();
      this.updateKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-fab-button {
  bottom: 25px;
  right: 20px;
  position: fixed;
  z-index: 2000;
}
</style>
