<template>
  <div>
    <PageFrame>
      <template #title>Fingerprint User List</template>

      <BasicList
        v-if="columns.length"
        :columns="columns"
        :data-source="columns && users"
        :grid-params="{
          toolbar: ['Edit', 'Print'],
          allowSorting: true,
          allowPaging: true,
          allowResizing: true,
          editSettings: {
            allowAdding: false,
            allowEditing: true,
            allowEditOnDblClick: true,
            allowDeleting: false,
            mode: 'Dialog',
            showDeleteConfirmDialog: true,
          },
          pageSettings: pageSettings,
          filterSettings: { type: 'Menu' },
          actionBegin: handleActionBegin,
          gridLines: 'Both',
          height: '600',
          autoFitColumns: true,
        }"
      />
    </PageFrame>
  </div>
</template>

<script>
import { DataManager } from "@syncfusion/ej2-data";
import { mapActions } from "pinia";

import BasicList from "@/components/common/BasicList.vue";
import PageFrame from "@/components/PageFrame.vue";
import { getObfuscationLevels } from "@/repositories/settings";
import { getUserList, saveUser } from "@/repositories/user";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "UserList",
  components: {
    BasicList,
    PageFrame,
  },
  data() {
    return {
      obfuscationLevelList: [],
      users: [],
      columns: [],
      pageSettings: {
        currentPage: 1,
        pageSize: 20,
        enableQueryString: false,
        pageSizes: [10, 20, 50, 100, "All"],
      },
    };
  },
  async mounted() {
    const authStore = useAuthStore();
    this.obfuscationLevelList = await getObfuscationLevels();

    this.columns = [
      {
        field: "id",
        headerText: "ID",
        isPrimaryKey: true,
        visible: false,
      },
      {
        field: "first_name",
        headerText: "First Name",
        validationRules: { required: true },
        obfuscate: true,
      },
      {
        field: "last_name",
        headerText: "Last Name",
        validationRules: { required: true },
        obfuscate: true,
      },
      {
        field: "username",
        headerText: "Username",
        allowEditing: false,
        obfuscate: true,
      },
      {
        field: "email",
        headerText: "Email",
        validationRules: { required: true },
        obfuscate: true,
      },
      {
        field: "max_results",
        headerText: "Max Search Results",
        obfuscate: true,
        editType: "numericedit",
        edit: { params: { format: "N", min: 1, max: 1000 } },
      },
      {
        field: "obfuscation_level",
        headerText: "Obfuscation Level",
        dataSource: new DataManager(this.obfuscationLevelList),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        obfuscate: true,
        editType: "dropdownedit",
        edit: { params: {} },
      },
      {
        field: "groups",
        headerText: "Groups",
        allowEditing: false,
        obfuscate: true,
        valueAccessor: (_, data) => data.groups.map((g) => g.name).join(", "),
      },
      {
        field: "firm_groups",
        headerText: `${authStore.firmLabelObject.singularUppercase} Groups`,
        allowEditing: false,
        obfuscate: true,
        valueAccessor: (_, data) => data.firm_groups.map((f) => f.group_name).join(", "),
      },
    ];
    this.loadUserList();
  },
  methods: {
    ...mapActions(useAuthStore, ["getUserProfile"]),
    handleActionBegin(e) {
      if (e.requestType === "save") {
        e.promise = this.saveFunction(e.data);
      }
      return e;
    },
    async loadUserList() {
      this.users = await getUserList({ source: "user" });
    },
    async saveFunction(data) {
      const payload = {
        account: data.account.id,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        max_results: data.max_results,
        obfuscation_level: data.obfuscation_level,
      };
      await saveUser(data.id, payload);
      this.loadUserList();
      this.getUserProfile();
    },
  },
};
</script>
