<template>
  <v-card>
    <v-card-title>
      <h1>User Settings</h1>
    </v-card-title>
    <v-card-text class="text--primary">
      <v-dialog v-model="dialogEnableOtp" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to switch your Multi-Factor-Authentication to an Authenticator App?
          </v-card-title>
          <v-card-text>
            You will be prompted with a QR code to be scanned with your Authenticator App.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialogEnableOtp = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="handleResetMFA"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEnableSms" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to switch your Multi-Factor-Authentication to SMS?
          </v-card-title>
          <v-card-text>
            You will receive an authentication code via SMS on your phone number
            {{ awsAuthData.attributes.phone_number }}.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialogEnableSms = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="handleEnableSms"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogChangeOtp" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            Do you want to switch your Multi-Factor-Authentication to a new device?
          </v-card-title>
          <v-card-text>
            You will be prompted with a new QR code to be scanned with your Authenticator App.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="dialogChangeOtp = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="handleResetMFA"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogConfirmChangeOtp" max-width="350">
        <v-card>
          <v-card-title> Multi-Factor-Authentication<br />switched to the new device </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogConfirmChangeOtp = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogTokenNotVerified" max-width="350">
        <v-card>
          <v-card-title> Token is not valid<br />please retry </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogTokenNotVerified = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSmsMfaNotEnabled" max-width="350">
        <v-card>
          <v-card-title> Disabled MFA method. </v-card-title>
          <v-card-text>
            This Multi-Factor-Authentication method is not enabled for your user.<br />
            Please contact the administrator for support.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogSmsMfaNotEnabled = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-alert outlined type="info" prominent border="left">
        <v-expansion-panels v-model="changePwdExpPanelIndexOpen" flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Change password</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="formChangePwdIs" v-model="formChangePwdIsValid" lazy-validation>
                <v-text-field
                  v-model="currentPassword"
                  type="password"
                  required
                  validate-on-blur
                  label="Current password"
                  :rules="formChangePwdRules.currentPassword"
                />
                <v-text-field
                  v-model="newPassword1"
                  type="password"
                  required
                  validate-on-blur
                  label="New password"
                  :rules="formChangePwdRules.newPassword1"
                />
                <v-text-field
                  v-model="newPassword2"
                  type="password"
                  required
                  validate-on-blur
                  label="New password (confirm)"
                  :rules="formChangePwdRules.newPassword2"
                />
                <v-btn type="submit" @click.prevent="handlePasswordChange"> Change password </v-btn>
                <span v-if="changePwdErrMsg" class="ml-2 error--text">
                  {{ changePwdErrMsg }}
                </span>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-alert>

      <v-alert outlined type="warning" prominent border="left">
        <v-expansion-panels v-model="changeOtpExpPanelIndexOpen" flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Manage Multi-Factor-Authentication</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3>
                Multi-Factor-Authentication
                <span v-if="preferredMFA === 'SOFTWARE_TOKEN_MFA'"
                  >enabled with Software Token</span
                >
                <span v-else-if="preferredMFA === 'SMS_MFA'">enabled via SMS</span>
                <span v-else>not enabled</span>
              </h3>
              <p v-if="!otpCode">
                <v-btn
                  v-if="preferredMFA !== 'SOFTWARE_TOKEN_MFA'"
                  class="warning"
                  @click="dialogEnableOtp = true"
                >
                  Switch Multi-Factor-Authentication to an Authenticator App
                </v-btn>
                <v-btn
                  v-if="preferredMFA === 'SOFTWARE_TOKEN_MFA'"
                  class="warning"
                  @click="dialogChangeOtp = true"
                >
                  Switch Multi-Factor-Authentication to a new device
                </v-btn>
              </p>
              <p>
                <v-btn
                  v-if="preferredMFA !== 'SMS_MFA'"
                  class="warning"
                  @click="dialogEnableSms = true"
                >
                  Switch Multi-Factor-Authentication to SMS
                </v-btn>
              </p>
              <p v-if="qrCodeURL">Scan the QR code with your Authenticator App.</p>
              <p v-if="qrCodeURL">
                <img :src="qrCodeURL" />
              </p>
              <p v-if="otpCode">Or enter this code in your Authenticator App: {{ otpCode }}</p>
              <p v-if="otpCode">
                Enter here the verification code from your Authenticator App
                <v-form>
                  <v-text-field v-model="tokenVerify" type="number" label="Verification code" />
                  <v-btn type="submit" @click.prevent="handleVerifyMFA"> Verify </v-btn>
                </v-form>
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { Auth } from "@aws-amplify/auth";
import { Translations } from "@aws-amplify/ui-components";
import { mapState } from "pinia";
import QRCode from "qrcode";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "UserSettings",
  data() {
    return {
      changePwdExpPanelIndexOpen: undefined, // set 0 to open
      changeOtpExpPanelIndexOpen: undefined, // set 0 to open
      dialogChangeOtp: false,
      dialogConfirmChangeOtp: false,
      dialogEnableOtp: false,
      dialogEnableSms: false,
      dialogSmsMfaNotEnabled: false,
      dialogTokenNotVerified: false,
      otpCode: "",
      qrCodeURL: "",
      tokenVerify: "",
      preferredMFA: "",
      currentPassword: "",
      newPassword1: "",
      newPassword2: "",
      formChangePwdIsValid: true,
      formChangePwdRules: {
        currentPassword: [(v) => !!v || "This field is required"],
        newPassword1: [(v) => !!v || "This field is required"],
        newPassword2: [
          (v) => !!v || "This field is required",
          (v) => v === this.newPassword1 || "Your password and confirmation password do not match.",
        ],
      },
      changePwdErrMsg: "",
    };
  },
  computed: {
    ...mapState(useAuthStore, {
      awsAuthData: (state) => state.awsAuthData,
      userProfile: (state) => state.userProfile,
    }),
  },
  async mounted() {
    await this.refreshPreferredMFA();
  },
  methods: {
    async refreshPreferredMFA() {
      this.preferredMFA = await Auth.getPreferredMFA(this.awsAuthData);
    },
    async handleEnableSms() {
      this.dialogEnableSms = false;
      try {
        await Auth.setPreferredMFA(this.awsAuthData, "SMS");
      } catch (error) {
        this.dialogSmsMfaNotEnabled = true;
      }
      await this.refreshPreferredMFA();
      this.changeOtpExpPanelIndexOpen = undefined; // set 0 to open
    },
    async handleResetMFA() {
      this.dialogChangeOtp = false;
      this.dialogEnableOtp = false;
      // To setup TOTP, first you need to get a `authorization code` from Amazon Cognito
      // `user` is the current Authenticated user
      this.otpCode = await Auth.setupTOTP(this.awsAuthData);
      // You can directly display the `code` to the user or convert it to a QR code to be scanned.
      const issuer = encodeURI(Translations.TOTP_ISSUER);
      const str = `otpauth://totp/${issuer}:${this.awsAuthData.username}?secret=${this.otpCode}&issuer=${issuer}`;
      this.qrCodeURL = await QRCode.toDataURL(str);
    },
    async handleVerifyMFA() {
      // Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
      // Use the generated one-time password to verify the setup
      try {
        await Auth.verifyTotpToken(this.awsAuthData, this.tokenVerify);
        // don't forget to set TOTP as the preferred MFA method
        await Auth.setPreferredMFA(this.awsAuthData, "TOTP");
        // ...
        await this.refreshPreferredMFA();
        this.changeOtpExpPanelIndexOpen = undefined; // set 0 to open
        this.otpCode = "";
        this.qrCodeURL = "";
        this.tokenVerify = "";
      } catch (error) {
        // Token is not verified
        console.error(error);
        this.dialogTokenNotVerified = true;
      }
    },
    async handlePasswordChange() {
      this.changePwdErrMsg = "";
      if (!this.$refs.formChangePwdIs.validate()) {
        this.changePwdErrMsg = "Check errors before proceeding";
        return;
      }
      this.formChangePwdIsValid = false;
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.currentPassword, this.newPassword1);
        this.changePwdExpPanelIndexOpen = undefined; // set 0 to open
        this.$refs.formChangePwdIs.reset();
      } catch (err) {
        this.changePwdErrMsg = err.message;
      }
    },
  },
};
</script>
