const arrSum = function (arr) {
  return arr.reduce((a, b) => a + b, 0);
};

function swap(data) {
  const ret = {};
  for (const key1 in data) {
    for (const key2 in data[key1]) {
      ret[key2] = ret[key2] || {};
      ret[key2][key1] = data[key1][key2] || {};
    }
  }
  return ret;
}

function selectColour(colourNum, colours) {
  if (colours < 1) colours = 1; // defaults to one colour - avoid divide by zero
  return `hsl(${(colourNum * (360 / colours)) % 360},100%,35%)`;
}

function fillTodoTimeData(
  results_todo_time_search,
  periods,
  valueLimit = 0,
  removeValueLimit = false,
) {
  const datasets = [];
  const labels = [...periods];
  const results_todo_time_search_swap = swap(results_todo_time_search);
  const colours_needed = Object.keys(results_todo_time_search_swap).length;
  let colour_index = 0;

  for (var search_term in results_todo_time_search_swap) {
    var points = [];
    const colour = selectColour(colour_index++, colours_needed);
    periods.forEach((time_period) => {
      const time_data = results_todo_time_search_swap[search_term][time_period] || {
        todoitem_total: 0,
      };
      points.push(time_data.todoitem_total);
    });

    if (arrSum(points) > valueLimit) {
      datasets.push({
        label: `${search_term} (${arrSum(points)})`,
        backgroundColor: colour,
        borderColor: colour,
        data: points,
        fill: false,
        lineTension: 0,
      });
    }
  }

  // checking and cleaning vertical elements equal to 0
  if (removeValueLimit) {
    let deletableIndexes = [];
    for (let i = 0; i < labels.length; i++) {
      const tot = datasets.reduce((acc, cur) => acc + cur.data[i], 0);
      if (tot <= valueLimit) {
        deletableIndexes.push(i);
      }
    }
    for (let i = deletableIndexes.length - 1; i >= 0; i--) {
      datasets.forEach((dataset) => {
        dataset.data.splice(deletableIndexes[i], 1);
      });
      labels.splice(deletableIndexes[i], 1);
    }
  }

  const line_chart_data = {
    labels,
    datasets: datasets.filter(
      (dataset) => dataset.data.reduce((acc, cur) => acc + cur, 0) > valueLimit,
    ),
  };

  const line_chart_options = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    // plugins: {
    //   legend: {
    //     display: false,
    //   },
    // },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return [line_chart_data, line_chart_options];
}

function fillCategoryPieData(data_case_timecreated_status_category) {
  if (!data_case_timecreated_status_category) return;
  const datasets = [];
  const categories = Object.keys(data_case_timecreated_status_category);
  const points = [];
  const colours = [];
  const colours_needed = categories.length;
  let colour_index = 0;

  for (const category in data_case_timecreated_status_category) {
    const cat_data = data_case_timecreated_status_category[category] || { case_total: 0 };
    const colour = selectColour(colour_index++, colours_needed);
    points.push(cat_data.case_total);
    colours.push(colour);
  }
  datasets.push({
    backgroundColor: colours,
    data: points,
  });

  return {
    labels: categories,
    datasets,
  };
}

function fillReasonPieData(results_todo_actionreason, valueLimit = 0) {
  if (!results_todo_actionreason) return;
  var datasets = [];
  var categories = Object.keys(results_todo_actionreason);
  var points = [];
  var colours = [];
  var colours_needed = categories.length;
  var colour_index = 0;

  for (var category in results_todo_actionreason) {
    var cat_data = results_todo_actionreason[category]["todo_total"] || 0;
    var colour = selectColour(colour_index++, colours_needed);
    points.push(cat_data);
    colours.push(colour);
  }
  datasets.push({
    backgroundColor: colours,
    data: points,
  });

  let deletableIndexes = [];
  for (let i = 0; i < categories.length; i++) {
    const tot = datasets.reduce((acc, cur) => acc + cur.data[i], 0);
    if (tot <= valueLimit) {
      deletableIndexes.push(i);
    }
  }
  for (let i = deletableIndexes.length - 1; i >= 0; i--) {
    datasets.forEach((dataset) => {
      dataset.data.splice(deletableIndexes[i], 1);
      dataset.backgroundColor.splice(deletableIndexes[i], 1);
    });
    categories.splice(deletableIndexes[i], 1);
  }

  return {
    labels: categories,
    datasets: datasets,
  };
}

export { fillTodoTimeData, fillCategoryPieData, fillReasonPieData, selectColour };
