<template>
  <div v-if="attachments.length > 0" class="mail-attachment">
    <p v-if="!hasPresignedUrl">
      <span>
        <v-icon>mdi-paperclip</v-icon>
        <span id="attach-count">{{ attachments.length }}</span> attachments -
      </span>
      <a
        id="attach-all"
        :href="`${host}document/${esId}/attachments/${
          fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
        }`"
        @click="
          handleDownload(
            $event,
            null,
            `${host}document/${esId}/attachments/${
              fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
            }`,
          )
        "
        >Download all</a
      >
    </p>
    <div class="attachment">
      <template v-if="Array.isArray(attachments)">
        <div v-for="item in attachments" :key="item.filename" class="file-box">
          <div v-if="item.presigned_url || item.filename" class="file">
            <a
              :href="
                item.presigned_url ||
                `${host}document/${esId}/attachments/${item.filename}/${
                  fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
                }`
              "
              @click="
                handleDownload(
                  $event,
                  item,
                  item.presigned_url ||
                    `${host}document/${esId}/attachments/${item.filename}/${
                      fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
                    }`,
                )
              "
            >
              <span class="corner" />
              <div class="icon">
                <v-icon>
                  {{ getIconFromFilename(item.filename) }}
                </v-icon>
              </div>
              <div v-obfuscate class="file-name">{{ item.filename }}</div>
            </a>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="attachments.presigned_url || attachments.filename" class="file">
          <a
            :href="
              attachments.presigned_url ||
              `${host}document/${esId}/attachments/${attachments.filename}/${
                fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
              }`
            "
            @click="
              handleDownload(
                $event,
                attachments,
                attachments.presigned_url ||
                  `${host}document/${esId}/attachments/${attachments.filename}/${
                    fingerprintTime ? `?fingerprint_time=${fingerprintTime}` : ''
                  }`,
              )
            "
          >
            <span class="corner" />
            <div class="icon">
              <v-icon>
                {{ getIconFromFilename(attachments.filename) }}
              </v-icon>
            </div>
            <div v-obfuscate class="file-name">{{ attachments.filename }}</div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import httpClient from "@/interceptors/axios";
import { getIconFromFilename } from "@/utils";

export default {
  name: "MessageAttachments",
  props: {
    esId: {
      type: String,
      default: null,
    },
    attachments: {
      type: [Array, Object],
      default: () => [],
    },
    fingerprintTime: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      host: `${process.env.VUE_APP_API}/api/v1/`,
    };
  },
  computed: {
    hasPresignedUrl() {
      return (
        this.attachments.filter((attachment) => attachment.presigned_url !== undefined).length > 0
      );
    },
  },
  methods: {
    getIconFromFilename,
    handleDownload(event, fileObj, url) {
      if (fileObj && fileObj.presigned_url) {
        return;
      }
      const filename = fileObj?.filename || "attachments.zip";
      event.preventDefault();
      return httpClient
        .get(url, {
          responseType: "blob", // important
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  },
};
</script>
