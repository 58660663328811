"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OFFSET_TYPE_FRONTEND_MAPPING = exports.OFFSET_TYPE_BACKEND_MAPPING = exports.PreselectedDates = exports.SELECTOR_RANGES = void 0;
exports.SELECTOR_RANGES = [
    { value: "minutes", text: "Minutes" },
    { value: "hours", text: "Hours" },
    { value: "days", text: "Days" },
    { value: "weeks", text: "Weeks" },
    { value: "months", text: "Months" },
    { value: "quarters", text: "Quarters" },
    { value: "years", text: "Years" },
];
var PreselectedDates;
(function (PreselectedDates) {
    PreselectedDates[PreselectedDates["TODAY"] = 0] = "TODAY";
    PreselectedDates[PreselectedDates["THIS_WEEK"] = 1] = "THIS_WEEK";
    PreselectedDates[PreselectedDates["THIS_MONTH"] = 2] = "THIS_MONTH";
    PreselectedDates[PreselectedDates["THIS_QUARTER"] = 3] = "THIS_QUARTER";
    PreselectedDates[PreselectedDates["THIS_YEAR"] = 4] = "THIS_YEAR";
    PreselectedDates[PreselectedDates["LAST_24_HOURS"] = 5] = "LAST_24_HOURS";
    PreselectedDates[PreselectedDates["LAST_7_DAYS"] = 6] = "LAST_7_DAYS";
    PreselectedDates[PreselectedDates["LAST_30_DAYS"] = 7] = "LAST_30_DAYS";
    PreselectedDates[PreselectedDates["LAST_90_DAYS"] = 8] = "LAST_90_DAYS";
    PreselectedDates[PreselectedDates["LAST_YEAR"] = 9] = "LAST_YEAR";
    PreselectedDates[PreselectedDates["LAST_DAY"] = 10] = "LAST_DAY";
    PreselectedDates[PreselectedDates["LAST_WEEK"] = 11] = "LAST_WEEK";
    PreselectedDates[PreselectedDates["LAST_MONTH"] = 12] = "LAST_MONTH";
    PreselectedDates[PreselectedDates["LAST_QUARTER"] = 13] = "LAST_QUARTER";
})(PreselectedDates || (exports.PreselectedDates = PreselectedDates = {}));
exports.OFFSET_TYPE_BACKEND_MAPPING = {
    minutes: "m",
    hours: "h",
    days: "d",
    weeks: "w",
    months: "M",
    quarters: "q",
    years: "y",
};
exports.OFFSET_TYPE_FRONTEND_MAPPING = {
    m: "minutes",
    h: "hours",
    d: "days",
    w: "weeks",
    M: "months",
    q: "quarters",
    y: "years",
};
