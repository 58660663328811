"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var BasicList_vue_1 = require("@/components/common/BasicList.vue");
var filters_1 = require("@/filters");
var utils_1 = require("./utils");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TimeBeforeItemReviewByPeriod',
    props: {
        data: {
            type: Object,
            required: true,
        },
        jsonData: {
            type: Object,
            default: null,
        },
        periodOptions: {
            type: Object,
            default: function () { return ({
                periodValue: null,
            }); },
        },
    },
    setup: function (__props) {
        var props = __props;
        var baseData = (0, vue_2.computed)(function () { var _a; return ((_a = props.jsonData) === null || _a === void 0 ? void 0 : _a.UserActionedCreatedItemsByPeriod) || {}; });
        var hasData = (0, vue_2.computed)(function () { return Object.keys(baseData.value).length > 0; });
        var labels = (0, vue_2.computed)(function () { return (0, utils_1.getFirstLevelKeys)(baseData.value); });
        var columns = ["actioned_avg"];
        var tableColumns = [
            { field: "label", headerText: "" },
            {
                field: "actioned_avg",
                headerText: "Time Before Item Review (days)",
                type: "number",
                textAlign: "Right",
                valueAccessor: function (field, data) {
                    return (0, filters_1.numberFormat)((data === null || data === void 0 ? void 0 : data[field]) > 10 ? Math.round(data === null || data === void 0 ? void 0 : data[field]) : data === null || data === void 0 ? void 0 : data[field]);
                },
            },
        ];
        var tableData = (0, vue_2.computed)(function () {
            return (0, utils_1.getTableData)(labels.value, columns, function (label, column) { var _a; return ((_a = baseData.value[label]) === null || _a === void 0 ? void 0 : _a[column]) || 0; });
        });
        return { __sfc: true, props: props, baseData: baseData, hasData: hasData, labels: labels, columns: columns, tableColumns: tableColumns, tableData: tableData, BasicList: BasicList_vue_1.default };
    }
});
