import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardText,{staticClass:"text--primary"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v("Set an absolute "+_vm._s(_vm.type)+" value")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto","align-self":"center"}},[_c(VBtn,{attrs:{"small":"","color":"accent"},on:{"click":_setup.setNow}},[_vm._v("Now")])],1),_c(VCol,{attrs:{"cols":"auto","align-self":"center"}},[_vm._v("or")]),_c(VCol,{attrs:{"align-self":"center"}},[_c(VInput,{attrs:{"hide-details":""}},[_c('input',{attrs:{"type":"datetime-local"},domProps:{"value":_setup.localizedFromValueStr},on:{"input":function($event){return _setup.updateDateTimeValue($event.target.valueAsNumber)}}})])],1),_c(VCol,{attrs:{"cols":"auto","align-self":"center"}},[_c(VBtn,{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Ok")])],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_vm._v("or select a relative value")])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VTextField,{staticClass:"value-input",attrs:{"type":"number","dense":"","hide-details":"","outlined":"","min":"0","width":"10px"},model:{value:(_setup.relativeInputNumber),callback:function ($$v) {_setup.relativeInputNumber=$$v},expression:"relativeInputNumber"}})],1),_c(VCol,[_c(VSelect,{staticClass:"range-input",attrs:{"dense":"","hide-details":"","outlined":"","items":_setup.selectorRanges,"item-value":"value","item-text":"text_custom"},model:{value:(_setup.relativeInputRangeType),callback:function ($$v) {_setup.relativeInputRangeType=$$v},expression:"relativeInputRangeType"}})],1),_c(VCol,{attrs:{"cols":"auto","align-self":"center"}},[_c(VBtn,{attrs:{"small":""},on:{"click":_setup.handleApplyRelative}},[_vm._v("Apply")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }