import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.attachments.length > 0)?_c('div',{staticClass:"mail-attachment"},[(!_vm.hasPresignedUrl)?_c('p',[_c('span',[_c(VIcon,[_vm._v("mdi-paperclip")]),_c('span',{attrs:{"id":"attach-count"}},[_vm._v(_vm._s(_vm.attachments.length))]),_vm._v(" attachments - ")],1),_c('a',{attrs:{"id":"attach-all","href":`${_vm.host}document/${_vm.esId}/attachments/${
        _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
      }`},on:{"click":function($event){return _vm.handleDownload(
          $event,
          null,
          `${_vm.host}document/${_vm.esId}/attachments/${
            _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
          }`,
        )}}},[_vm._v("Download all")])]):_vm._e(),_c('div',{staticClass:"attachment"},[(Array.isArray(_vm.attachments))?_vm._l((_vm.attachments),function(item){return _c('div',{key:item.filename,staticClass:"file-box"},[(item.presigned_url || item.filename)?_c('div',{staticClass:"file"},[_c('a',{attrs:{"href":item.presigned_url ||
              `${_vm.host}document/${_vm.esId}/attachments/${item.filename}/${
                _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
              }`},on:{"click":function($event){return _vm.handleDownload(
                $event,
                item,
                item.presigned_url ||
                  `${_vm.host}document/${_vm.esId}/attachments/${item.filename}/${
                    _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
                  }`,
              )}}},[_c('span',{staticClass:"corner"}),_c('div',{staticClass:"icon"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.getIconFromFilename(item.filename))+" ")])],1),_c('div',{directives:[{name:"obfuscate",rawName:"v-obfuscate"}],staticClass:"file-name"},[_vm._v(_vm._s(item.filename))])])]):_vm._e()])}):[(_vm.attachments.presigned_url || _vm.attachments.filename)?_c('div',{staticClass:"file"},[_c('a',{attrs:{"href":_vm.attachments.presigned_url ||
            `${_vm.host}document/${_vm.esId}/attachments/${_vm.attachments.filename}/${
              _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
            }`},on:{"click":function($event){return _vm.handleDownload(
              $event,
              _vm.attachments,
              _vm.attachments.presigned_url ||
                `${_vm.host}document/${_vm.esId}/attachments/${_vm.attachments.filename}/${
                  _vm.fingerprintTime ? `?fingerprint_time=${_vm.fingerprintTime}` : ''
                }`,
            )}}},[_c('span',{staticClass:"corner"}),_c('div',{staticClass:"icon"},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.getIconFromFilename(_vm.attachments.filename))+" ")])],1),_c('div',{directives:[{name:"obfuscate",rawName:"v-obfuscate"}],staticClass:"file-name"},[_vm._v(_vm._s(_vm.attachments.filename))])])]):_vm._e()]],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }