<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          small
          :to="{ name: 'ReportingStoredReportDetail', params: { id: data.id } }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-file-chart-outline</v-icon>
        </v-btn>
      </template>
      <span>Display report</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "StoredReportsActions",
  props: {
    innerData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  beforeMount() {
    if (this.innerData) {
      this.data = this.innerData;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
