<template>
  <div v-if="!data.is_deleted">
    <v-dialog v-model="displayCategDetails" width="600px">
      <SuspiciousTermDataTypes
        v-if="displayCategDetails"
        :id="data.id"
        :name="data.name"
        @close="displayCategDetails = false"
      />
    </v-dialog>

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          link
          small
          :to="{
            name: 'CategorySuspiciousSearchResults',
            params: { pk: data.id },
          }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <span>Preview</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          link
          small
          :to="{
            name: 'SuspiciousTermHistory',
            params: { id: data.id },
          }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-find-replace</v-icon>
        </v-btn>
      </template>
      <span>History of changes</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          link
          small
          @click="displayCategDetails = true"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </template>
      <span>Boost channels</span>
    </v-tooltip>
  </div>
</template>

<script>
import SuspiciousTermDataTypes from "../SuspiciousTermDataTypes.vue";

export default {
  name: "SuspiciousTermActions",
  components: { SuspiciousTermDataTypes },
  data() {
    return {
      displayCategDetails: false,
      data: {},
    };
  },
};
</script>
