"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRedactor = getRedactor;
exports.escapeRegExp = escapeRegExp;
exports.getRegexFromWordList = getRegexFromWordList;
exports.initRedactor = initRedactor;
var redact_pii_1 = require("redact-pii");
var redactor;
function getRedactor() {
    return redactor || new redact_pii_1.SyncRedactor();
}
function escapeRegExp(s) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
function getRegexFromWordList(words) {
    return new RegExp("\\b(?:".concat(words.map(escapeRegExp).join("|"), ")\\b"), "gi");
}
function initRedactor(redactTerms) {
    if (redactTerms === void 0) { redactTerms = []; }
    redactor = new redact_pii_1.SyncRedactor({
        customRedactors: {
            before: redactTerms.map(function (term) { return ({
                regexpPattern: getRegexFromWordList([term.term]),
                replaceWith: term.replace_with,
            }); }), // specialized patterns or functions
            // after: [], // more broad/general ones
        },
    });
    return redactor;
}
