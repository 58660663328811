import CaseWorkflowList from "@/components/workflow/CaseWorkflowList.vue";

const routes = [
  {
    path: "/workflows/",
    name: "CaseWorkflowList",
    component: CaseWorkflowList,
    meta: {
      checkAccountFeatures: ({ canShowWorkflows }) => canShowWorkflows,
    },
  },
];

export default routes;
