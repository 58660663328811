import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.dialogDisableSchedule),callback:function ($$v) {_vm.dialogDisableSchedule=$$v},expression:"dialogDisableSchedule"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" Do you really want to "+_vm._s(_vm.dialogDisableScheduleType)+" the selected schedule? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialogDisableSchedule = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.handleConfirmDisableSchedule}},[_vm._v(" Continue ")])],1)],1)],1),_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Report Schedules")]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"to":{ name: 'ReportingScheduleForm', params: { id: 'new' } }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add New ")],1)]},proxy:true}])},[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.schedules},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c('ScheduleActions',{attrs:{"data":item,"isLoadingTriggerButton":_vm.reportTriggerRunningId === item.id},on:{"disable-report-schedule":_vm.handleDisableScheduleClick,"enable-report-schedule":_vm.handleEnableScheduleClick,"schedule-trigger":_vm.handleReportScheduleTrigger}})]}},{key:"item.schedule",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getScheduleValue(item.schedule))+" ")]}},{key:"item.fingerprint_firm",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.firms.find((firm) => firm.id === item.fingerprint_firm)?.firm_name)+" ")]}},{key:"item.last_run",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(item.last_run))+" ")]}},{key:"item.modified_on",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(item.modified_on))+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }