<template>
  <v-container>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="periodData && Object.keys(periodData).length > 0">
      <h3 v-if="title">{{ title }}</h3>
      <div v-for="(item, search_name, index) in periodData" :key="index">
        <br v-if="index === Object.keys(periodData).length - 1" />
        <h4>{{ search_name }}</h4>

        <v-row dense>
          <v-col>Pending</v-col>
          <v-col cols="auto">
            <small>{{ todoPending(item) }} of {{ todoTotal(item) }}</small>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-progress-linear :value="percPending(item)" color="accent" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>Reviewed - No Action</v-col>
          <v-col cols="auto">
            <small>{{ todoIgnored(item) }} of {{ todoTotal(item) }}</small>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-progress-linear :value="percIgnored(item)" color="warning" />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>Assigned</v-col>
          <v-col cols="auto">
            <small>{{ todoAssigned(item) }} of {{ todoTotal(item) }}</small>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-progress-linear :value="percAssigned(item)" />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>No items found.</div>
  </v-container>
</template>

<script>
export default {
  name: "TodoMetricList",
  props: {
    periodData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    todoPending(item) {
      if (!item || !item.False) return 0;
      return item.False.todo_total || 0;
    },
    todoActioned(item) {
      if (!item || !item.True) return 0;
      return item.True.todo_total || 0;
    },
    todoAssigned(item) {
      if (!item || !item.True) return 0;
      return item.True.todoitem_total || 0;
    },
    todoIgnored(item) {
      return this.todoActioned(item) - this.todoAssigned(item);
    },
    todoTotal(item) {
      return this.todoPending(item) + this.todoActioned(item);
    },
    percPending(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoPending(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
    percIgnored(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoIgnored(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
    percAssigned(item) {
      if (!item) return 0;
      return parseFloat(((100 * this.todoAssigned(item)) / this.todoTotal(item)).toFixed(0)) || 0;
    },
  },
};
</script>
