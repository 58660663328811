<template>
  <div>
    <PageFrame>
      <template #title>Scheduled Searches</template>

      <template #actions>
        <v-btn
          v-if="permissions.add"
          :to="{ name: 'ReportingScheduleForm', params: { id: 'new' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Add New
        </v-btn>
      </template>

      <v-checkbox
        v-model="includeDeleted"
        label="Include disabled items"
        @change="handleTableUpdateOptions"
      />
      <v-data-table
        :loading="showLoading"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100, { text: 'All', value: -1 }],
        }"
        @update:options="handleTableUpdateOptions"
      >
        <template v-slot:item.is_deleted="{ item }">
          <v-tooltip bottom v-if="item.permissions.change">
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                icon
                small
                v-on="on"
                :to="{ name: 'WatchForm', params: { pk: item.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="item.is_deleted ? item.permissions.change : item.permissions.delete"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="showLoading"
                :color="item.is_deleted ? 'warning' : 'primary'"
                icon
                small
                v-on="on"
                @click="handleToggleItem(item)"
              >
                <v-icon>{{
                  item.is_deleted ? "mdi-toggle-switch-off" : "mdi-toggle-switch"
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.is_deleted ? "Enable" : "Disable" }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.schedule="{ item }">
          {{ getCronDescription(item.schedule) }}
        </template>
        <template v-slot:item.search_objs="{ item }">
          <ul>
            <li v-for="search in item.search_objs" :key="search.id">
              <router-link :to="{ name: 'SavedSearch', params: { pk: search.id } }">
                {{ search.name }}
              </router-link>
            </li>
          </ul>
        </template>
        <template v-slot:item.created_by="{ item }">
          {{ item.created_by.full_name }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import cronstrue from "cronstrue";
import PageFrame from "@/components/PageFrame.vue";
import watchRepository from "@/repositories/watch";
import { vuetifyGridOptionsToQueryParams } from "@/utils";

export default {
  components: { PageFrame },
  data() {
    return {
      permissions: {},
      lastFilterOptions: {},
      includeDeleted: false,
      showLoading: false,
      headers: [
        { value: "is_deleted", text: "Actions", width: "110px" },
        { value: "name", text: "Name" },
        { value: "description", text: "Description" },
        { value: "schedule", text: "Schedule" },
        { value: "search_objs", text: "Searches", sortable: false },
        { value: "created_by", text: "Owner" },
      ],
      items: [],
      itemsCount: 0,
    };
  },
  async mounted() {
    const { data } = await watchRepository.watchPermission();
    this.permissions = data;
  },
  methods: {
    getCronDescription(schedule) {
      return cronstrue.toString(schedule);
    },
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      if (options) {
        this.lastFilterOptions = options;
      }
      const state = vuetifyGridOptionsToQueryParams(this.lastFilterOptions);
      const { data } = await watchRepository.watchList({
        ...state,
        is_deleted: this.includeDeleted ? undefined : false,
        source: "user",
      });
      this.items = data.results;
      this.itemsCount = data.count;
      this.showLoading = false;
    },
    async handleToggleItem(item) {
      this.showLoading = true;
      const callFn = item.is_deleted ? watchRepository.watchRestore : watchRepository.watchDelete;
      try {
        await callFn(item.id);
      } finally {
        this.showLoading = false;
        this.handleTableUpdateOptions();
      }
    },
  },
};
</script>

<style scoped>
:deep(ul) {
  list-style-type: none;
  padding: 0;
}
</style>
