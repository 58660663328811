import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[(_vm.results_settings)?_c('MetricBox',{attrs:{"title":"Case Category V Stage","show-accordion":false},scopedSlots:_vm._u([{key:"metric",fn:function(){return [_c(VContainer,[_c(VSelect,{staticClass:"my-1",attrs:{"items":[
                {
                  value: '',
                  text: 'All',
                },
                {
                  value: '1',
                  text: 'Open',
                },
                {
                  value: '2',
                  text: 'Closed',
                },
              ],"dense":"","hide-details":"","label":"Case Status","outlined":""},on:{"change":_vm.handleChangeCaseStatus},model:{value:(_vm.caseStatus),callback:function ($$v) {_vm.caseStatus=$$v},expression:"caseStatus"}}),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.results_settings['category']),function(cat_name,cat_index){return _c('th',{key:cat_index},[_vm._v(" "+_vm._s(cat_name)+" ")])})],2)]),_c('tbody',_vm._l((_vm.results_settings['stage']),function(stg_name,stg_index){return _c('tr',{key:stg_index},[_c('td',[_vm._v(" "+_vm._s(stg_name)+" ")]),_vm._l((_vm.results_settings['category']),function(cat_name,cat_index){return _c('td',{key:cat_index},[(_vm.caseStatus)?[(
                          _vm.results_case_category_stage_status &&
                          _vm.results_case_category_stage_status[cat_name] &&
                          _vm.results_case_category_stage_status[cat_name][stg_name] &&
                          _vm.results_case_category_stage_status[cat_name][stg_name][_vm.caseStatus]
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.results_case_category_stage_status[cat_name][stg_name][_vm.caseStatus][ "case_total" ])+" ")]):_c('span',[_vm._v(" 0 ")])]:[(
                          _vm.results_case_category_stage &&
                          _vm.results_case_category_stage[cat_name] &&
                          _vm.results_case_category_stage[cat_name][stg_name]
                        )?_c('span',[_vm._v(" "+_vm._s(_vm.results_case_category_stage[cat_name][stg_name]["case_total"])+" ")]):_c('span',[_vm._v(" 0 ")])]],2)})],2)}),0)])],1)]},proxy:true}],null,false,840249920)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }