<template>
  <div>
    <v-dialog v-model="restoreDialog" max-width="500px">
      <v-card>
        <v-card-title>Confirm Restore</v-card-title>
        <v-card-text>Are you sure you wish to restore this case?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="restoreDialog = false">Cancel</v-btn>
          <v-btn color="warning" text @click="caseRestore">Restore</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip v-if="canChangeCase && data.is_deleted" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="warning" icon small @click="caseRestoreDialog" v-bind="attrs" v-on="on">
          <v-icon>mdi-restore</v-icon>
        </v-btn>
      </template>
      <span>Restore</span>
    </v-tooltip>

    <v-tooltip v-if="!data.is_deleted" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" icon small @click="redirectToCase" v-bind="attrs" v-on="on">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <span>Case details</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "pinia";
import caseRepository from "@/repositories/case";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";

export default {
  name: "CaseActions",
  data() {
    return {
      data: {},
      restoreDialog: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canChangeCase"]),
  },
  methods: {
    caseRestoreDialog() {
      this.restoreDialog = true;
    },
    async caseRestore() {
      this.restoreDialog = false;
      try {
        await caseRepository.caseRestore(this.data.id);
        await this.redirectToCase();
      } catch (error) {
        console.error("caseRestore error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async redirectToCase() {
      await this.$router.push({ name: "CaseDetail", params: { pk: this.data.id } });
    },
  },
};
</script>
