import AdminWebsockets from "@/views/admin/AdminWebsockets.vue";

const routes = [
  {
    path: "/admin/websockets/",
    name: "AdminWebsockets",
    component: AdminWebsockets,
    meta: {
      checkAccountFeatures: ({ isStaffUser }) => isStaffUser,
    },
  },
];

export default routes;
