<script setup>
import { ref, onMounted } from "vue";
import {
  adaptiveRelativeTimeFormat,
  composeRelativeDateTimeFormat,
  mediumDateTimeFormat,
} from "@/filters";

const props = defineProps({
  canChangeCase: {
    type: Boolean,
    required: true,
  },
  caseStatus: {
    type: Number,
    required: true,
  },
  caseWorkflow: {
    type: Object,
    required: true,
  },
  userId: {
    type: String,
    required: true,
  },
});

const selectedAction = ref(null);
const selectedTab = ref(null);
function nextButtonText(actionIndex) {
  return actionIndex == props.caseWorkflow.actions.length - 1 ? "Complete" : "Next stage";
}
function canShowButtons(action) {
  return (
    props.canChangeCase &&
    props.caseStatus === 1 &&
    props.caseWorkflow.running &&
    props.caseWorkflow.current_action &&
    props.caseWorkflow.current_action.id === action.id &&
    action.has_perms
  );
}

onMounted(() => {
  selectedAction.value =
    (props.caseWorkflow.actions &&
      props.caseWorkflow.actions.findIndex(
        (step) => step.id === props.caseWorkflow.current_action?.id,
      ) + 1) ||
    1;
  selectedTab.value =
    (props.caseWorkflow.actions &&
      props.caseWorkflow.actions.findIndex(
        (step) => step.id === props.caseWorkflow.current_action?.id,
      )) ||
    0;
});
</script>

<template>
  <div>
    <v-tabs v-model="selectedTab" class="elevation-2" background-color="background" grow>
      <v-tab
        v-for="(action, actionIndex) of caseWorkflow.actions"
        :key="`action-${action.id}`"
        :disabled="!action.started"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="canShowButtons(action) && actionIndex > 0"
              icon
              @click="$emit('action-revert', action)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Revert</span>
        </v-tooltip>
        <v-spacer />
        <v-icon left :color="action.completed ? 'success' : action.running ? 'secondary' : 'grey'">
          {{
            action.completed
              ? "mdi-check-circle"
              : action.running
                ? "mdi-dots-horizontal-circle-outline"
                : "mdi-check-circle-outline"
          }}
        </v-icon>
        {{ action.name }}
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-if="canShowButtons(action)"
              @click="
                actionIndex == caseWorkflow.actions.length - 1
                  ? $emit('action-complete-last', action)
                  : $emit('action-complete', action)
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>{{ nextButtonText(actionIndex) }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="elevation-2 px-2">
      <v-tab-item
        v-for="(action, actionIndex) of caseWorkflow.actions"
        :key="`${actionIndex}-content`"
        :step="actionIndex + 1"
      >
        <v-row>
          <v-col>
            <v-list>
              <v-list-item class="text-caption pa-0">
                <v-list-item-avatar class="mr-1">
                  <v-icon
                    :color="action.completed ? 'success' : action.running ? 'secondary' : 'grey'"
                  >
                    {{
                      action.completed
                        ? "mdi-checkbox-marked-circle"
                        : action.running
                          ? "mdi-circle-edit-outline"
                          : "mdi-checkbox-blank-circle-outline"
                    }}
                  </v-icon>
                </v-list-item-avatar>

                <v-row no-gutters align="center">
                  <!-- <v-col
                    cols="12"
                    md="auto"
                    class="pr-10"
                  >
                    <div
                      v-if="action.owner"
                      v-obfuscate
                      class="text-subtitle-2 text--secondary"
                    >
                      {{ action.owner.full_name }}
                    </div>
                  </v-col> -->
                  <v-col cols="12" md="auto" class="text-subtitle-2 text--secondary">
                    <div>
                      <span class="fixed-label-1">Step</span>
                      <span class="fixed-label-2">
                        {{ action.name }}
                      </span>
                      ({{ actionIndex + 1 }} of {{ caseWorkflow.actions.length }})
                    </div>
                    <div v-if="action.started">
                      <span class="fixed-label-1">Started</span>
                      <span class="fixed-label-2">
                        {{ mediumDateTimeFormat(action.started) }}
                      </span>
                      ({{ adaptiveRelativeTimeFormat(new Date(action.started) - new Date()) }})
                    </div>
                    <div v-if="action.finished">
                      <span class="fixed-label-1">Finished</span>
                      <span class="fixed-label-2">
                        {{ mediumDateTimeFormat(action.finished) }}
                      </span>
                      ({{ adaptiveRelativeTimeFormat(new Date(action.finished) - new Date()) }})
                    </div>
                    <div
                      v-if="
                        action.due_by && (action.running || (action.finished && action.overdue))
                      "
                    >
                      <span v-if="action.overdue" class="error--text">
                        <span class="fixed-label-1">Overdue</span>
                        <span class="fixed-label-2">
                          {{ mediumDateTimeFormat(action.due_by) }}
                        </span>
                        ({{
                          composeRelativeDateTimeFormat(
                            new Date(action.due_by) - new Date(action.finished || action.started),
                          )
                        }})
                      </span>
                      <span v-else>
                        <span class="fixed-label-1">Due</span>
                        <span class="fixed-label-2">
                          {{ mediumDateTimeFormat(action.due_by) }}
                        </span>
                        ({{ adaptiveRelativeTimeFormat(new Date(action.due_by) - new Date()) }})
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
.workflow-tab {
  border: 1px solid lightgrey;
}
.fixed-label-1 {
  display: inline-block;
  font-weight: bold;
  width: 60px;
}
.fixed-label-2 {
  display: inline-block;
  width: 160px;
}
</style>
