<template>
  <div>
    <v-row>
      <v-col>
        <MetricBox v-if="results_settings" title="Case Category V Stage" :show-accordion="false">
          <template #metric>
            <v-container>
              <v-select
                v-model="caseStatus"
                class="my-1"
                :items="[
                  {
                    value: '',
                    text: 'All',
                  },
                  {
                    value: '1',
                    text: 'Open',
                  },
                  {
                    value: '2',
                    text: 'Closed',
                  },
                ]"
                dense
                hide-details
                label="Case Status"
                outlined
                @change="handleChangeCaseStatus"
              />

              <v-simple-table>
                <thead>
                  <tr>
                    <th />
                    <th
                      v-for="(cat_name, cat_index) in results_settings['category']"
                      :key="cat_index"
                    >
                      {{ cat_name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(stg_name, stg_index) in results_settings['stage']" :key="stg_index">
                    <td>
                      {{ stg_name }}
                    </td>
                    <td
                      v-for="(cat_name, cat_index) in results_settings['category']"
                      :key="cat_index"
                    >
                      <template v-if="caseStatus">
                        <span
                          v-if="
                            results_case_category_stage_status &&
                            results_case_category_stage_status[cat_name] &&
                            results_case_category_stage_status[cat_name][stg_name] &&
                            results_case_category_stage_status[cat_name][stg_name][caseStatus]
                          "
                        >
                          {{
                            results_case_category_stage_status[cat_name][stg_name][caseStatus][
                              "case_total"
                            ]
                          }}
                        </span>
                        <span v-else> 0 </span>
                      </template>
                      <template v-else>
                        <span
                          v-if="
                            results_case_category_stage &&
                            results_case_category_stage[cat_name] &&
                            results_case_category_stage[cat_name][stg_name]
                          "
                        >
                          {{ results_case_category_stage[cat_name][stg_name]["case_total"] }}
                        </span>
                        <span v-else> 0 </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-container>
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";

export default {
  name: "CaseCategoryVStage",
  components: {
    MetricBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      caseStatus: "1",
    };
  },
  computed: {
    results_settings() {
      return this.jsonData?.UserSettings;
    },
    results_case_category_stage() {
      return this.jsonData?.CaseStatsByCategoryByStage?.all;
    },
    results_case_category_stage_status() {
      return this.jsonData?.CaseStatsByCategoryByStageStatus?.all;
    },
  },
  mounted() {
    this.caseStatus = this.data.parameters?.caseStatus || "1";
  },
  methods: {
    handleChangeCaseStatus(value) {
      this.$emit("change-report-item-parameters", {
        ...(this.data.parameters || {}),
        caseStatus: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.case-status label {
  margin-top: 6px;
}
.case-status .form-control {
  margin-bottom: 10px;
}
</style>
