export function removeItem(arr, val) {
  const index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function getDateString(d) {
  return (
    d.getFullYear() +
    "-" +
    appendLeadingZeroes(d.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(d.getDate()) +
    " " +
    appendLeadingZeroes(d.getHours()) +
    ":" +
    appendLeadingZeroes(d.getMinutes())
  );
}

export function formToJSON(f) {
  let output = {};
  new FormData(f).forEach((value, key) => {
    // Check if property already exist
    if (Object.prototype.hasOwnProperty.call(output, key)) {
      let current = output[key];
      if (!Array.isArray(current)) {
        // If it's not an array, convert it to an array.
        current = output[key] = [current];
      }
      current.push(value); // Add the new value to the array.
    } else {
      output[key] = value;
    }
  });
  return output;
}

export const getDataTypeIcon = (dataTypeId) => {
  switch (dataTypeId) {
    case "email":
      return "mdi-email-outline";
    case "bbg.msg":
      return "mdi-account-multiple-outline";
    case "voice":
      return "mdi-voicemail";
    case "slack":
      return "mdi-slack";
    case "teams.personal":
    case "teams.corporate":
      return "mdi-microsoft-teams";
    case "whatsapp":
      return "mdi-whatsapp";
    case "zoom.call":
      return "mdi-video";
    case "zoom.meet":
      return "mdi-video-box";
    case "bbg.im":
    default:
      return "mdi-chat-processing-outline";
  }
};

export const spacedColorsRgb = [
  "255, 0, 0",
  "0, 255, 0",
  "0, 0, 255",
  "255, 255, 0",
  "255, 0, 255",
  "0, 255, 255",
  "128, 0, 0",
  "0, 128, 0",
  "0, 0, 128",
  "128, 128, 0",
  "128, 0, 128",
  "0, 128, 128",
  "128, 128, 128",
  "0, 0, 0",
  "192, 0, 0",
  "0, 192, 0",
  "0, 0, 192",
  "192, 192, 0",
  "192, 0, 192",
  "0, 192, 192",
  "192, 192, 192",
  "64, 0, 0",
  "0, 64, 0",
  "0, 0, 64",
  "64, 64, 0",
  "64, 0, 64",
  "0, 64, 64",
  "64, 64, 64",
  "32, 0, 0",
  "0, 32, 0",
  "0, 0, 32",
  "32, 32, 0",
  "32, 0, 32",
  "0, 32, 32",
  "32, 32, 32",
  "96, 0, 0",
  "0, 96, 0",
  "0, 0, 96",
  "96, 96, 0",
  "96, 0, 96",
  "0, 96, 96",
  "96, 96, 96",
  "160, 0, 0",
  "0, 160, 0",
  "0, 0, 160",
  "160, 160, 0",
  "160, 0, 160",
  "0, 160, 160",
  "160, 160, 160",
  "224, 0, 0",
  "0, 224, 0",
  "0, 0, 224",
  "224, 224, 0",
  "224, 0, 224",
  "0, 224, 224",
  "224, 224, 224",
];

export const getDataTypeColor = (dataTypeId, opacity = 1) => {
  switch (dataTypeId) {
    case "voice":
      return `rgba(${spacedColorsRgb[0]}, ${opacity})`;
    case "whatsapp":
      return `rgba(${spacedColorsRgb[1]}, ${opacity})`;
    case "email":
      return `rgba(${spacedColorsRgb[2]}, ${opacity})`;
    case "bbg.msg":
      return `rgba(${spacedColorsRgb[3]}, ${opacity})`;
    case "bbg.im":
      return `rgba(${spacedColorsRgb[4]}, ${opacity})`;
    case "slack":
      return `rgba(${spacedColorsRgb[5]}, ${opacity})`;
    case "teams.corporate":
      return `rgba(${spacedColorsRgb[6]}, ${opacity})`;
    case "teams.personal":
      return `rgba(${spacedColorsRgb[7]}, ${opacity})`;
    case "zoom.call":
      return `rgba(${spacedColorsRgb[8]}, ${opacity})`;
    case "zoom.meet":
      return `rgba(${spacedColorsRgb[9]}, ${opacity})`;
    default:
      return `rgba(${spacedColorsRgb[10]}, ${opacity})`;
  }
};

export const fileIcons = {
  image: "mdi-file-image-outline", // 'fa-file-image-o',
  pdf: "mdi-file-pdf-box", // 'fa-file-pdf-o',
  word: "mdi-file-word-outline", // 'fa-file-word-o',
  powerpoint: "mdi-file-powerpoint-outline", // 'fa-file-powerpoint-o',
  excel: "mdi-file-excel-outline", // 'fa-file-excel-o',
  csv: "mdi-file-table-outline", // 'fa-file-text-o',
  audio: "mdi-file-music-outline", // 'fa-file-audio-o',
  video: "mdi-file-video-outline", // 'fa-file-video-o',
  archive: "mdi-folder-zip-outline", // 'fa-file-archive-o',
  code: "mdi-file-code-outline", // 'fa-file-code-o',
  text: "mdi-file-document-outline", // 'fa-file-text-o',
  file: "mdi-file-outline", // 'fa-file-o',
};

export const fileExtensions = {
  gif: fileIcons.image,
  jpeg: fileIcons.image,
  jpg: fileIcons.image,
  png: fileIcons.image,

  pdf: fileIcons.pdf,

  doc: fileIcons.word,
  docx: fileIcons.word,

  ppt: fileIcons.powerpoint,
  pptx: fileIcons.powerpoint,

  xls: fileIcons.excel,
  xlsx: fileIcons.excel,

  csv: fileIcons.csv,

  aac: fileIcons.audio,
  mp3: fileIcons.audio,
  ogg: fileIcons.audio,

  avi: fileIcons.video,
  flv: fileIcons.video,
  mkv: fileIcons.video,
  mp4: fileIcons.video,

  gz: fileIcons.archive,
  zip: fileIcons.archive,

  css: fileIcons.code,
  html: fileIcons.code,
  js: fileIcons.code,

  txt: fileIcons.text,
};

export const getIconFromFilename = (filename = "") => {
  const extension = filename.split(".").pop();
  return fileExtensions[extension.toLowerCase()] || fileIcons.file;
};

export const getSlackHtmlTemplate = (templateString) => {
  if (!templateString) {
    return templateString;
  }
  /**
   * replacing links
   * https://api.slack.com/reference/surfaces/formatting#linking-urls
   */
  let re = /<([^>| ]+[:]+[^>|]+)(\|([^>|]+))?>/gm;
  let template = templateString.replace(re, (match, group1, group2, group3) => {
    return `<a href="${group1}">${group3 || group1}</a>`;
  });
  return template;
};

export const getEmailAddressesFromEmailField = (baseString) => {
  if (!baseString) {
    return baseString;
  }
  let re = /<([^>]+)>/gm;
  const matches = [...baseString.matchAll(re)];
  if (!matches.length) {
    return baseString;
  }
  return matches.reduce((acc, elem) => acc.concat(elem[1]), []).join(", ");
};

/**
 * This function creates a matrix from a list of elements with `row` and `column`
 * properties
 * @param {Array} itemList array of items, sorted by row and column.
 * @returns matrix with rows and columns as objects in arrays
 */
export const createReportingTableFromList = (itemList) => {
  if (!itemList || !itemList.length) {
    return [];
  }
  const items = itemList
    // create rows and columns
    .reduce((acc, item) => {
      // when row not defined, create a row with a single column
      if (!item.row) {
        acc.push([item]);
        return acc;
      }
      // otherwise add the item as column in the specified row
      acc[item.row] = (acc[item.row] || []).concat(item);
      return acc;
    }, [])
    // removing empty rows
    .filter((r) => r != undefined)
    // // removing empty columns, not necessary
    // .map(r => r.filter(c => c != undefined))
    // sorting columns
    .map((r) => {
      r.sort((a, b) => {
        if (!a.col) {
          return 1;
        }
        if (!b.col) {
          return -1;
        }
        return a.col < b.col ? -1 : 1;
      });
      return r;
    });
  // sorting rows
  items.sort((a, b) => {
    if (!a[0].row) {
      return 1;
    }
    if (!b[0].row) {
      return -1;
    }
    return a[0].row < b[0].row ? -1 : 1;
  });
  return items;
};

export function ejsGridArgsToQueryParams(args = {}, defaultTakeNum = 20, defaultOrdering) {
  let ordering = undefined;
  if (args.sorted && args.sorted.length) {
    ordering = args.sorted
      .reverse()
      .map((e) => {
        const colName = e.name.replace("event.action", "event.action.keyword");
        return `${e.direction === "descending" ? "-" : ""}${colName}`;
      })
      .join(",");
  }
  return {
    take: args?.take || defaultTakeNum,
    page: args?.action?.currentPage || 1,
    ordering: ordering || defaultOrdering,
    filter_by: args?.where && JSON.stringify(args.where),
  };
}

export function vuetifyGridOptionsToQueryParams(args = {}, defaultTakeNum = 25, defaultOrdering) {
  let ordering = undefined;
  if (args.sortBy && args.sortBy.length) {
    ordering = args.sortBy
      .map((e, i) => {
        return `${args.sortDesc[i] ? "-" : ""}${e}`;
      })
      // TODO: use this with Vuefity 3
      // .map(
      //   (e: { key: string; order?: boolean | "asc" | "desc" }) =>
      //     `${e.order === "desc" ? "-" : ""}${e.key}`,
      // )
      .join(",");
  }
  return {
    take: args?.itemsPerPage || defaultTakeNum,
    page: args?.page || 1,
    ordering: ordering || defaultOrdering,
  };
}

export function isEmpty(value) {
  return value === null || value === undefined || Number.isNaN(value) || value === "";
}

export function* createFibonacciGenerator() {
  let a = 1;
  let b = 1;
  while (true) {
    yield a;
    const next = a + b;
    a = b;
    b = next;
  }
}
