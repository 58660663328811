"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstLevelKeys = getFirstLevelKeys;
exports.getSecondLevelKeys = getSecondLevelKeys;
exports.getTableColumns = getTableColumns;
exports.getTableData = getTableData;
exports.getChartDatasets = getChartDatasets;
function getFirstLevelKeys(obj) {
    if (obj === void 0) { obj = {}; }
    return Object.keys(obj);
}
function getSecondLevelKeys(obj) {
    if (obj === void 0) { obj = {}; }
    var keySet = new Set();
    Object.values(obj).forEach(function (_obj) {
        return Object.keys(_obj || {}).forEach(function (key) { return keySet.add(key); });
    });
    return Array.from(keySet);
}
function getTableColumns(columns, headerTextFn, valueAccessor, showTotals) {
    return [{ field: "label", headerText: "" }].concat(columns.map(function (column) { return ({
        field: column.replace(".", "_"),
        headerText: (headerTextFn && headerTextFn(column)) || column,
        type: "number",
        textAlign: "Right",
        valueAccessor: valueAccessor,
    }); }), showTotals
        ? [{ field: "totals", headerText: "Totals", type: "number", textAlign: "Right" }]
        : []);
}
function getTableData(labels, columns, columnValueFn) {
    return labels.map(function (label) {
        var rowObj = { label: label };
        var totals = 0;
        columns.forEach(function (column) {
            var columnValue = columnValueFn && columnValueFn(label, column);
            rowObj[column.replace(".", "_")] = columnValue;
            totals += columnValue || 0;
        });
        rowObj.totals = totals;
        return rowObj;
    });
}
function getChartDatasets(labels, columns, baseProps, labelFn, dataFn) {
    return columns.map(function (column) { return (__assign(__assign({}, baseProps), { label: (labelFn && labelFn(column)) || column, data: labels.map(function (label) { return dataFn && dataFn(label, column); }) })); });
}
