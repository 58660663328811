<template>
  <v-dialog v-model="dialogVisible" persistent max-width="500px">
    <form @submit.prevent="caseSubmit">
      <v-card>
        <v-card-title>
          Select Risk Bucket
          <v-spacer />
          <v-btn icon @click="dialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary">
          Select the risk bucket in which to move the selected items.
          <v-select
            v-model="selectedRiskBucket"
            :items="riskBucketList"
            autofocus
            hide-details
            item-value="value"
            item-text="name"
            @change="updateLastPreferredRiskBucket"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title class="text-left">
                    <v-chip :color="item.color">
                      {{ item.name }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <v-chip :color="item.color">{{ item.name }}</v-chip>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" type="submit" :disabled="formSubmitting">
            Select Risk Bucket
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "pinia";

import EventBus from "@/eventBus";
import { useAuthStore } from "@/stores/auth";
import { useCasesStore } from "@/stores/cases";

export default {
  data() {
    return {
      dialogVisible: false,
      selectedItems: [],
      selectedRiskBucket: 1,
      caseData: {
        name: "",
        reason_opened: "",
        category: null,
      },
      callbackFn: null,
      formSubmitting: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["lastPreferredRiskBucket"]),
    ...mapState(useCasesStore, ["riskBucketList"]),
  },
  async mounted() {
    EventBus.$on("riskBucketSelect", this.handleRiskBucketSelect);
    await this.getAccountRiskConfig();
    if (this.lastPreferredRiskBucket <= this.riskBucketList.length) {
      this.selectedRiskBucket = this.lastPreferredRiskBucket;
    }
  },
  beforeDestroy() {
    EventBus.$off("riskBucketSelect", this.handleRiskBucketSelect);
  },
  methods: {
    ...mapActions(useAuthStore, ["updateLastPreferredRiskBucket"]),
    ...mapActions(useCasesStore, ["getAccountRiskConfig"]),
    handleRiskBucketSelect({ detail: [items, callbackFn] }) {
      this.selectedItems = items;
      this.callbackFn = callbackFn;
      this.dialogVisible = true;
    },
    async caseSubmit() {
      this.formSubmitting = true;
      try {
        this.dialogVisible = false;
        if (typeof this.callbackFn === "function") await this.callbackFn(this.selectedRiskBucket);
      } catch (error) {
        console.error("caseSubmit error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.formSubmitting = false;
      }
    },
  },
};
</script>
