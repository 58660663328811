"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var CaseNote_vue_1 = require("./CaseNote.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'CaseNotes',
    props: {
        notes: null,
        canAddCaseNote: null,
        canChangeCaseNote: null,
        canDeleteCaseNote: null
    },
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var showDeletedNotes = (0, vue_2.ref)(false);
        var activeNotes = (0, vue_2.computed)(function () { return props.notes.filter(function (note) { return !note.is_deleted; }); });
        var deletedNotes = (0, vue_2.computed)(function () { return props.notes.filter(function (note) { return note.is_deleted; }); });
        return { __sfc: true, props: props, emit: emit, showDeletedNotes: showDeletedNotes, activeNotes: activeNotes, deletedNotes: deletedNotes, CaseNoteComponent: CaseNote_vue_1.default };
    }
});
