<script setup>
import { ref, computed, onMounted } from "vue";
import { mediumDateFormat } from "@/filters";
import { getFirms } from "@/repositories/firm";
import { getDataTypes } from "@/repositories/settings";
import Introduction from "./sections/PolicyIntroduction.vue";
import PolicySummary from "./sections/PolicySummary.vue";
import SupervisionCriteria from "./sections/PolicySupervisionCriteria.vue";
import SupervisionChannels from "./sections/PolicySupervisionChannels.vue";
import RiskIdentification from "./sections/PolicyRiskIdentification.vue";
import TriageWorkflow from "./sections/PolicyTriageWorkflow.vue";
import InvestigationWorkflow from "./sections/PolicyInvestigationWorkflow.vue";
import StatusUpdate from "./sections/PolicyStatusUpdate.vue";
import PolicyReview from "./sections/PolicyReview.vue";

const selectedTab = ref(0);
const firms = ref([]);
const dataTypes = ref([]);

const policyVersions = ref([]);
const lastVersion = computed(() => policyVersions.value[0]);
const policyObject = ref(null);
const policyObjectEdit = ref(null);

const showPrint = ref(true);
function togglePrint() {
  policyObjectEdit.value = {
    ...policyObject.value,
    version: { ...lastVersion.value },
  };
  showPrint.value = !showPrint.value;
}

onMounted(async () => {
  const [_firms, _dataTypes] = await Promise.all([getFirms(), getDataTypes()]);
  firms.value = _firms;
  dataTypes.value = _dataTypes;

  policyVersions.value = [
    {
      version: 0.2,
      approvedBy: "approved by",
      revisionDate: "2022-12-07T16:08:36.721Z",
      description: "description of change",
      author: "author",
    },
    {
      version: 0.1,
      approvedBy: "approved by",
      revisionDate: "2022-12-01T16:00:00.000Z",
      description: "description of change",
      author: "author",
    },
  ];
  policyObject.value = {
    firm: {
      id: firms.value[0].id,
      firm_name: firms.value[0].firm_name,
    },
    prepared_by: {
      name: "full_name",
      title: "title",
    },
    prepared_on: "2022-11-28T16:00:00.000Z",
    approved_by: {
      name: "full_name",
      title: "title",
    },
    approved_on: "2022-11-30T16:00:00.000Z",
    supervisionCriteria: {
      review_frequency: "quarter",
    },
    channels: ["email", "voice"],
    risk_score_calculated:
      "[The maximum individual lexicon score or the aggregate score of all matching lexicons]",
    risk_score_limit: 6.0,
    risk_score_limit_triage: 12.0,
    triage_review_frequency: "week", // '[Daily, Weekly, Monthly, Quarterly, X Day of the Month/Quarter]',
    triage_review_score: 12.0,
    triage_review_top_items: 20,
    triage_review_other_items: 20,
    triage_check_score_limit_72h: 20.0,
    triage_check_score_limit_48h: 40.0,
    triage_sample_frequency: "6months",
    triage_sample_items: 10,
    workflow_investigate_timescale: 5,
    workflow_review_timescale: 2,
    workflow_signoff_timescale: 1,
    reporting_frequency: "month",
    policy_review_frequency: "quarter",
    policy_review_figure: "[the chief compliance officer]",
  };

  togglePrint();
});
</script>

<template>
  <div v-if="policyObject" class="policy-body">
    <v-btn @click="togglePrint">
      {{ showPrint ? "Edit" : "Print" }}
    </v-btn>
    <h1>{{ policyObject.firm.firm_name }}</h1>
    <h1>Supervision Policy</h1>
    <h2 v-if="showPrint">Version {{ lastVersion.version }}</h2>
    <v-text-field
      v-else
      v-model="policyObjectEdit.version.version"
      label="Version"
      type="number"
      min="0"
      step="0.1"
      hide-details
    />

    <h2 v-if="showPrint">
      {{ mediumDateFormat(lastVersion.revisionDate) }}
    </h2>
    <h2 v-else>
      {{ mediumDateFormat(new Date()) }}
    </h2>

    <!-- <h4>Version History</h4>
    <v-simple-table class="my-2">
      <thead>
        <tr>
          <th>Version</th>
          <th>Approved By</th>
          <th>Revision Date</th>
          <th>Description of Change</th>
          <th>Author</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="version in policyVersions" :key="version.version">
          <td>{{ version.version }}</td>
          <td>{{ version.approvedBy }}</td>
          <td>{{ mediumDateFormat(version.revisionDate) }}</td>
          <td>{{ version.description }}</td>
          <td>{{ version.author }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-simple-table class="my-2">
      <tbody>
        <tr>
          <th>Prepared by</th>
          <td>{{ policyObject.prepared_by.name }}</td>
          <th>Title</th>
          <td>{{ policyObject.prepared_by.title }}</td>
          <th>Date</th>
          <td>{{ mediumDateFormat(policyObject.prepared_on) }}</td>
        </tr>
        <tr>
          <th>Approved by</th>
          <td>{{ policyObject.approved_by.name }}</td>
          <th>Title</th>
          <td>{{ policyObject.approved_by.title }}</td>
          <th>Date</th>
          <td>{{ mediumDateFormat(policyObject.approved_on) }}</td>
        </tr>
      </tbody>
    </v-simple-table> -->

    <v-tabs v-model="selectedTab" vertical>
      <v-tab>
        <v-icon left> mdi-check-circle </v-icon>
        Introduction
      </v-tab>
      <v-tab>
        <v-icon left> mdi-pencil </v-icon>
        Summary
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Supervision Criteria
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Supervision Channels
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Risk Identification
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Triage Workflow
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Investigation Workflow
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Status Update
      </v-tab>
      <v-tab>
        <v-icon left> mdi-check-circle-outline </v-icon>
        Policy Review
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Introduction />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <PolicySummary />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <SupervisionCriteria
              :is-print="showPrint"
              :review-frequency="policyObjectEdit.supervisionCriteria.review_frequency"
              @update:reviewFrequency="
                policyObjectEdit.supervisionCriteria.review_frequency = $event
              "
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <SupervisionChannels
              :is-print="showPrint"
              :data-types="dataTypes"
              :channels="policyObjectEdit.channels"
              @update:channels="policyObjectEdit.channels = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <RiskIdentification
              :is-print="showPrint"
              :risk-score-calculated="policyObject.risk_score_calculated"
              :risk-score-limit="policyObjectEdit.risk_score_limit"
              :risk-score-limit-triage="policyObjectEdit.risk_score_limit_triage"
              :review-frequency="policyObjectEdit.supervisionCriteria.review_frequency"
              @update:riskScoreLimit="policyObjectEdit.risk_score_limit = $event"
              @update:riskScoreLimitTriage="policyObjectEdit.risk_score_limit_triage = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <TriageWorkflow
              :is-print="showPrint"
              :triage-review-frequency="policyObjectEdit.triage_review_frequency"
              :triage-review-score="policyObjectEdit.triage_review_score"
              :triage-review-top-items="policyObjectEdit.triage_review_top_items"
              :triage-review-other-items="policyObjectEdit.triage_review_other_items"
              :triage-check-score-limit72h="policyObjectEdit.triage_check_score_limit_72h"
              :triage-check-score-limit48h="policyObjectEdit.triage_check_score_limit_48h"
              :triage-sample-frequency="policyObjectEdit.triage_sample_frequency"
              :triage-sample-items="policyObjectEdit.triage_sample_items"
              @update:triageReviewFrequency="policyObjectEdit.triage_review_frequency = $event"
              @update:triageReviewScore="policyObjectEdit.triage_review_score = $event"
              @update:triageReviewTopItems="policyObjectEdit.triage_review_top_items = $event"
              @update:triageReviewOtherItems="policyObjectEdit.triage_review_other_items = $event"
              @update:triageCheckScoreLimit72h="
                policyObjectEdit.triage_check_score_limit_72h = $event
              "
              @update:triageCheckScoreLimit48h="
                policyObjectEdit.triage_check_score_limit_48h = $event
              "
              @update:triageSampleFrequency="policyObjectEdit.triage_sample_frequency = $event"
              @update:triageSampleItems="policyObjectEdit.triage_sample_items = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <InvestigationWorkflow
              :is-print="showPrint"
              :workflow-investigate-timescale="policyObjectEdit.workflow_investigate_timescale"
              :workflow-review-timescale="policyObjectEdit.workflow_review_timescale"
              :workflow-signoff-timescale="policyObjectEdit.workflow_signoff_timescale"
              @update:workflowInvestigateTimescale="
                policyObjectEdit.workflow_investigate_timescale = $event
              "
              @update:workflowReviewTimescale="policyObjectEdit.workflow_review_timescale = $event"
              @update:workflowSignoffTimescale="
                policyObjectEdit.workflow_signoff_timescale = $event
              "
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <StatusUpdate
              :is-print="showPrint"
              :reporting-frequency="policyObjectEdit.reporting_frequency"
              :review-frequency="policyObjectEdit.supervisionCriteria.review_frequency"
              @update:reportingFrequency="policyObjectEdit.reporting_frequency = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <PolicyReview
              :is-print="showPrint"
              :policy-review-frequency="policyObjectEdit.policy_review_frequency"
              :policy-review-figure="policyObjectEdit.policy_review_figure"
              @update:policyReviewFrequency="policyObjectEdit.policy_review_frequency = $event"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedTab--"> Cancel </v-btn>
            <v-btn color="primary" @click="selectedTab++"> Continue </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style scoped lang="scss">
.v-tab {
  justify-content: left;
}

::v-deep(.v-select__selections) {
  > input {
    width: 15px;
    min-width: 15px;
  }
}

::v-deep(.v-autocomplete.v-select.v-input--is-focused input) {
  min-width: 15px;
}
</style>
