<!--
  Changes in this template must be reflected
  in `exportQueryCellInfo` of the `DataTable.vue` component
 -->

<template>
  <div>
    <span v-for="(item, index) in highlight" :key="index">
      <u>Matches for {{ index.split(".")[0] }}:</u>
      <ol>
        <li v-for="(match, index2) in item" :key="index2">
          <!-- eslint-disable vue/no-v-html -->
          <span v-obfuscate v-html="match" />
        </li>
      </ol>
    </span>
  </div>
</template>

<script>
import { getUsefulHighlights } from "@/components/datatable/utils";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    highlight() {
      return getUsefulHighlights(this.data.highlight || {});
    },
  },
};
</script>

<style scoped>
ol {
  padding-inline-start: 15px;
}
::v-deep(b),
::v-deep(.highlighted) {
  color: #ff394b;
  font-weight: bold;
}
</style>
