<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <h2>Metrics For All Time</h2>
        <MetricBox
          title="Triage: Pending"
          :show-accordion="!!Object.keys((results_todo_search || {}).False || {}).length"
        >
          <template #metric>
            <Metric
              :loading="loading_todo"
              :val="
                !isEmpty(results_todo?.False?.todo_total) ? results_todo.False.todo_total : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_todo_search"
              :period-data="results_todo_search ? results_todo_search.False : null"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Triage: Actioned"
          :show-accordion="!!Object.keys((results_todo_search || {}).True || {}).length"
        >
          <template #metric>
            <Metric
              :loading="loading_todo"
              :val="!isEmpty(results_todo?.True?.todo_total) ? results_todo.True.todo_total : 'N/A'"
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_todo_search"
              :period-data="results_todo_search ? results_todo_search.True : null"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Triage: Avg Time to Action"
          :show-accordion="!!Object.keys((results_todo_search || {}).True || {}).length"
        >
          <template #metric>
            <Metric
              :loading="loading_todo"
              :val="
                !isEmpty(results_todo?.True?.actioned_avg)
                  ? parseInt(results_todo.True.actioned_avg) + ' hours'
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :loading="loading_todo_search"
              :period-data="results_todo_search ? results_todo_search.True : null"
              :field-name="'actioned_avg'"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col cols="6">
        <h2>Case Totals</h2>
        <MetricBox
          title="Open Cases"
          :show-accordion="!!totalCasesByStatusAndCatList?.open?.length"
        >
          <template #metric>
            <Metric
              :loading="totalCasesByStatusLoading"
              :val="
                !isEmpty(totalCasesByStatusList?.open?.case_total)
                  ? totalCasesByStatusList.open.case_total
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :loading="totalCasesByStatusLoading"
              :period-data-list="totalCasesByStatusAndCatList?.open"
              key-name="cat"
              field-name="case_total"
            />
            <!-- <MetricList
              title="by Stage"
              :loading="totalOpenCasesByStageLoading"
              :period-data-list="totalOpenCasesByStageList"
              key-name="stage"
              field-name="case_total"
            /> -->
          </template>
        </MetricBox>

        <MetricBox
          title="Closed Cases"
          :show-accordion="!!totalCasesByStatusAndCatList?.closed?.length"
        >
          <template #metric>
            <Metric
              :loading="totalCasesByStatusLoading"
              :val="
                !isEmpty(totalCasesByStatusList?.closed?.case_total)
                  ? totalCasesByStatusList.closed.case_total
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :loading="totalCasesByStatusLoading"
              :period-data-list="totalCasesByStatusAndCatList?.closed"
              key-name="cat"
              field-name="case_total"
            />
          </template>
        </MetricBox>

        <MetricBox
          title="Avg Time to Close Case"
          :show-accordion="!!totalCasesByStatusAndCatList?.closed.length"
        >
          <template #metric>
            <Metric
              :loading="totalCasesByStatusLoading"
              :val="
                !isEmpty(totalCasesByStatusList?.closed?.duration_avg)
                  ? parseInt(totalCasesByStatusList.closed.duration_avg) + ' hours'
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Category"
              :loading="totalCasesByStatusLoading"
              :period-data-list="totalCasesByStatusAndCatList.closed"
              key-name="cat"
              field-name="duration_avg"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <MetricBox v-if="results_settings" title="Case Category V Stage" :show-accordion="false">
          <template #metric>
            <div class="ibox-content table-responsive">
              <v-select
                v-model="caseStatus"
                class="my-1"
                :items="[
                  {
                    value: '',
                    text: 'All',
                  },
                  {
                    value: '1',
                    text: 'Open',
                  },
                  {
                    value: '2',
                    text: 'Closed',
                  },
                ]"
                dense
                hide-details
                label="Case Status"
                outlined
              />

              <v-simple-table>
                <thead>
                  <tr>
                    <th />
                    <th
                      v-for="(cat_name, cat_index) in results_settings['category']"
                      :key="cat_index"
                    >
                      {{ cat_name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(stg_name, stg_index) in results_settings['stage']" :key="stg_index">
                    <td>
                      {{ stg_name }}
                    </td>
                    <td
                      v-for="(cat_name, cat_index) in results_settings['category']"
                      :key="cat_index"
                      class="table-data"
                    >
                      <template v-if="caseStatus">
                        <span
                          v-if="
                            results_case_category_stage_status &&
                            results_case_category_stage_status[cat_name] &&
                            results_case_category_stage_status[cat_name][stg_name] &&
                            results_case_category_stage_status[cat_name][stg_name][caseStatus]
                          "
                        >
                          {{
                            results_case_category_stage_status[cat_name][stg_name][caseStatus][
                              "case_total"
                            ]
                          }}
                        </span>
                        <span v-else>0</span>
                      </template>
                      <template v-else>
                        <span
                          v-if="
                            results_case_category_stage &&
                            results_case_category_stage[cat_name] &&
                            results_case_category_stage[cat_name][stg_name]
                          "
                        >
                          {{ results_case_category_stage[cat_name][stg_name]["case_total"] }}
                        </span>
                        <span v-else>0</span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </template>
        </MetricBox>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <v-card v-if="results_case_name_chart_data.data_todoitem_total.length">
          <v-card-title>
            <h5>Case Items by Case</h5>
          </v-card-title>
          <v-card-text>
            <ChartJs
              height="500px"
              type="bar"
              :data="{
                datasets: [
                  {
                    data: results_case_name_chart_data.data_todoitem_total,
                    backgroundColor: results_case_name_chart_data.backgroundColor,
                  },
                ],
                labels: results_case_name_chart_data.labels,
              }"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    padding: 2,
                  },
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card v-if="results_case_name_chart_data.data_casenote_total.length">
          <v-card-title>
            <h5>Case Notes by Case</h5>
          </v-card-title>
          <v-card-text>
            <ChartJs
              height="500px"
              type="bar"
              :data="{
                datasets: [
                  {
                    data: results_case_name_chart_data.data_casenote_total,
                    backgroundColor: results_case_name_chart_data.backgroundColor,
                  },
                ],
                labels: results_case_name_chart_data.labels,
              }"
              :options="{
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    text: '',
                  },
                },
                scales: {
                  x: {
                    padding: 2,
                  },
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MetricBox
          v-if="results_todo_type_actionreason"
          title="Channel V No Action Reason"
          :show-accordion="false"
        >
          <template #metric>
            <v-simple-table>
              <thead>
                <tr>
                  <th />
                  <th>Email</th>
                  <th>BBG Msg</th>
                  <th>BBG IM</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat_name, cat_index) in results_todo_type_actionreason"
                  :key="cat_index"
                >
                  <template v-if="cat_index !== 'None' && cat_index !== 'Added via Adhoc Search'">
                    <td>
                      {{ cat_index }}
                    </td>
                    <td>
                      {{ cat_name["email"] ? cat_name["email"]["todo_total"] : 0 }}
                    </td>
                    <td>
                      {{ cat_name["bbg.msg"] ? cat_name["bbg.msg"]["todo_total"] : 0 }}
                    </td>
                    <td>
                      {{ cat_name["bbg.im"] ? cat_name["bbg.im"]["todo_total"] : 0 }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </MetricBox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MetricBox
          v-if="results_todo_search_actionreason && results_todo_search['True']"
          title="Category V No Action Reason"
          class="scroll-box"
          :show-accordion="false"
        >
          <template #metric>
            <v-simple-table>
              <thead>
                <tr v-if="results_todo_search">
                  <th />
                  <th
                    v-for="(search_name, search_index) in results_todo_search.True"
                    :key="search_index"
                  >
                    {{ search_index }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat_name, cat_index) in results_todo_search_actionreason"
                  :key="cat_index"
                >
                  <template
                    v-if="
                      results_todo_search &&
                      cat_index !== 'None' &&
                      cat_index !== 'Added via Adhoc Search'
                    "
                  >
                    <td>
                      {{ cat_index }}
                    </td>
                    <td
                      v-for="(search_name, search_index) in results_todo_search.True"
                      :key="search_index"
                    >
                      {{ cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0 }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Category, ColumnSeries, ScrollBar, Tooltip, Zoom } from "@syncfusion/ej2-vue-charts";
import MetricBox from "./MetricBox.vue";
import Metric from "./Metric.vue";
import MetricList from "./MetricList.vue";
import ChartJs from "@/components/ChartJs.vue";
import { selectColour } from "./utils.js";
import { getStatsCaseTotals, getStatsTodoTotals } from "@/repositories/stats";
import EventBus from "@/eventBus";
import { isEmpty } from "@/utils";

const reduceCategories = (obj) =>
  Object.entries(obj).reduce(
    (acc, [k, v]) =>
      acc.concat({
        cat: k,
        ...v,
      }),
    [],
  );

const summarizeCategories = (list) => {
  let case_total = 0,
    todoitem_total = 0,
    casenote_total = 0;
  let total_duration = null;
  list.forEach((elem) => {
    case_total += elem.case_total;
    todoitem_total += elem.todoitem_total;
    casenote_total += elem.casenote_total;
    if (elem.duration_avg !== null) {
      total_duration = (total_duration || 0) + elem.duration_avg * elem.case_total;
    }
  });
  return {
    case_total,
    todoitem_total,
    casenote_total,
    duration_avg: total_duration && case_total && total_duration / case_total,
  };
};

export default {
  name: "AppMetrics",
  components: {
    MetricBox,
    Metric,
    MetricList,
    ChartJs,
  },
  provide: {
    chart: [Category, ColumnSeries, ScrollBar, Tooltip, Zoom],
  },
  data() {
    return {
      caseStatus: "1",

      loading: true,
      // loading_settings: true,
      loading_todo: true,
      loading_todo_search: true,
      loading_todo_search_actionreason: true,
      // loading_todo_actionreason: true,
      loading_todo_type_actionreason: true,
      loading_case_name: true,
      // loading_case_category_stage: true,
      // loading_case_category_stage_status: true,

      // results_settings: null,
      results_todo: {},
      results_todo_search: {},
      results_todo_search_actionreason: null,
      // results_todo_actionreason: null,
      results_todo_type_actionreason: null,
      results_case_name: null,
      results_case_name_chart_data: {
        backgroundColor: [],
        data_todoitem_total: [],
        data_casenote_total: [],
        labels: [],
      },
      // results_case_category_stage: null,
      // results_case_category_stage_status: null,

      // pie_todo_actionreason: {},
      // bar_todo_actionreason: [],
      totalCasesByStatus: { all: { 1: {}, 2: {} } },
      totalCasesByStatusLoading: false,
      // totalOpenCasesByStage: { all: {} },
      // totalOpenCasesByStageLoading: false,
    };
  },
  computed: {
    totalCasesByStatusAndCatList() {
      const keyMap = { 1: "open", 2: "closed" };
      return Object.entries(this.totalCasesByStatus?.all || {}).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [keyMap[k]]: reduceCategories(v),
        }),
        { open: [], closed: [] },
      );
    },
    totalCasesByStatusList() {
      return Object.entries(this.totalCasesByStatusAndCatList).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: summarizeCategories(v),
        }),
        { open: {}, closed: {} },
      );
    },
    totalOpenCasesByStageList() {
      return Object.entries(this.totalOpenCasesByStage?.all || {}).reduce(
        (acc, [k, v]) =>
          acc.concat({
            stage: k,
            ...v,
          }),
        [],
      );
    },
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    isEmpty,
    // async fetchSettings() {
    //   this.loading_settings = true;
    //   try {
    //     const r = await getStatsSettings();
    //     this.results_settings = r.data;
    //   } catch (error) {
    //     console.error("fetchSettings error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_settings = false;
    // },
    async fetchTodoStats(actioned = "False") {
      this.loading_todo = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: actioned.toLowerCase(),
          priority: "priority",
          auto_actioned: "false",
          exclude_assigned_items: "true",
          group_by: "actioned",
        });
        Vue.set(this.results_todo, actioned, r.data.all?.[actioned] || {});
      } catch (error) {
        console.error("fetchTodoStats error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo = false;
    },
    async fetchTodoStatsBySearch(actioned = "False") {
      this.loading_todo_search = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: actioned.toLowerCase(),
          priority: "priority",
          auto_actioned: "false",
          exclude_assigned_items: "true",
          group_by: "actioned,suspicious_term__suspicious_term_category__name",
        });
        Vue.set(this.results_todo_search, actioned, r.data.all?.[actioned]);
      } catch (error) {
        console.error("fetchTodoStatsBySearch error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_search = false;
    },
    // async fetchTodoStatsByActionReason() {
    //   this.loading_todo_actionreason = true;
    //   try {
    //     const r = await getStatsTodoTotals({
    //       'actioned': 'true',
    //       'auto_actioned': 'false',
    //       'exclude_assigned_items': 'false',
    //       'group_by': 'case,action_reason'
    //     });
    //     this.results_todo_actionreason = r.data?.all ? r.data.all['None'] : null;
    //     // this.pie_todo_actionreason = fillReasonPieData(this.results_todo_actionreason);
    //     this.bar_todo_actionreason = Object.entries(this.results_todo_actionreason || {}).map(
    //       ([key, value]) => ({
    //         label: key,
    //         value: value.todo_total,
    //       })
    //     );
    //   } catch (error) {
    //     console.error('fetchTodoStatsByActionReason error', error);
    //     EventBus.$emit('notify', 'warn', error);
    //   }
    //   this.loading_todo_actionreason = false;
    // },
    async fetchTodoStatsBySearchByActionReason() {
      this.loading_todo_search_actionreason = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: "true",
          auto_actioned: "false",
          exclude_assigned_items: "false",
          group_by: "case,action_reason,suspicious_term__suspicious_term_category__name",
        });
        this.results_todo_search_actionreason = r.data?.all ? r.data.all["None"] : null;
      } catch (error) {
        console.error("fetchTodoStatsBySearchByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_search_actionreason = false;
    },
    async fetchTodoStatsByTypeByActionReason() {
      this.loading_todo_type_actionreason = true;
      try {
        const r = await getStatsTodoTotals({
          actioned: "true",
          auto_actioned: "false",
          exclude_assigned_items: "false",
          group_by: "case,action_reason,document__fingerprint_type",
        });
        this.results_todo_type_actionreason = r.data?.all ? r.data.all["None"] : null;
      } catch (error) {
        console.error("fetchTodoStatsByTypeByActionReason error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_todo_type_actionreason = false;
    },
    async fetchCaseStatsByName() {
      this.loading_case_name = true;
      try {
        const r = await getStatsCaseTotals({ group_by: "name" });
        this.results_case_name = r.data.all;
        const entries = Object.entries(this.results_case_name || {});
        const labels = entries.map(([key]) => key);
        const data_todoitem_total = entries
          .map(([, value]) => value.todoitem_total)
          .filter((value) => value > 0);
        const data_casenote_total = entries
          .map(([, value]) => value.casenote_total)
          .filter((value) => value > 0);
        const backgroundColor = entries.map((_, i) => selectColour(i + 1, entries.length));
        this.results_case_name_chart_data = {
          backgroundColor,
          data_todoitem_total,
          data_casenote_total,
          labels,
        };
      } catch (error) {
        console.error("fetchCaseStatsByName error", error);
        EventBus.$emit("notify", "warn", error);
      }
      this.loading_case_name = false;
    },
    // async fetchCaseStatsByCategoryByStage() {
    //   this.loading_case_category_stage = true;
    //   try {
    //     const r = await getStatsCaseTotals({ group_by: "category__name,stage__name" });
    //     this.results_case_category_stage = r.data.all;
    //   } catch (error) {
    //     console.error("fetchCaseStatsByCategoryByStage error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_case_category_stage = false;
    // },
    // async fetchCaseStatsByCategoryByStageStatus() {
    //   this.loading_case_category_stage_status = true;
    //   try {
    //     const r = await getStatsCaseTotals({ group_by: "category__name,stage__name,case_status" });
    //     this.results_case_category_stage_status = r.data.all;
    //   } catch (error) {
    //     console.error("fetchCaseStatsByCategoryByStageStatus error", error);
    //     EventBus.$emit("notify", "warn", error);
    //   }
    //   this.loading_case_category_stage_status = false;
    // },
    async fetchTotalCasesByStatus() {
      this.totalCasesByStatusLoading = true;
      try {
        const { data } = await getStatsCaseTotals({
          period_type: "all",
          group_by: "case_status,category__name",
        });
        this.totalCasesByStatus = data;
      } finally {
        this.totalCasesByStatusLoading = false;
      }
    },
    // async fetchTotalOpenCasesByStage() {
    //   this.totalOpenCasesByStageLoading = true;
    //   try {
    //     const { data } = await getStatsCaseTotals({
    //       period_type: "all",
    //       case_status: 1,
    //       group_by: "stage__name",
    //     });
    //     this.totalOpenCasesByStage = data;
    //   } finally {
    //     this.totalOpenCasesByStageLoading = false;
    //   }
    // },
    fetchAll() {
      this.loading = true;
      Promise.all([
        // this.fetchSettings(),
        this.fetchTotalCasesByStatus(),
        // this.fetchTotalOpenCasesByStage(),
        this.fetchTodoStats("False"),
        this.fetchTodoStats("True"),
        // this.fetchTodoStatsByActionReason(),
        this.fetchTodoStatsBySearchByActionReason(),
        this.fetchTodoStatsBySearch("False"),
        this.fetchTodoStatsBySearch("True"),
        this.fetchTodoStatsByTypeByActionReason(),
        this.fetchCaseStatsByName(),
        // this.fetchCaseStatsByCategoryByStage(),
        // this.fetchCaseStatsByCategoryByStageStatus(),
      ]).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.case-status label {
  margin-top: 6px;
}
.case-status .form-control {
  margin-bottom: 10px;
}
.scroll-box {
  font-size: 0.8em;
  overflow-x: auto;
}
</style>
