<template>
  <div>
    <PageFrame>
      <template #title>{{ randomSearch ? "Random Sample" : "Search" }} Results</template>

      <template #crumb>
        <template v-if="pk">
          <router-link :to="{ name: 'SearchList' }"> Saved Search </router-link>
        </template>
      </template>

      <template #actions>
        <template v-if="searchData">
          <v-btn color="primary" :to="{ name: 'SavedSearch', params: { pk: searchData.id } }">
            Edit Search
          </v-btn>
        </template>
        <template v-else-if="randomSearch">
          <v-btn color="warning" :to="{ name: 'RandomSearch', query: $route.query }">
            New Search
          </v-btn>
        </template>
        <template v-else>
          <v-btn color="warning" :to="{ name: 'NewSearch', query: $route.query }">
            Modify Search
          </v-btn>
          &nbsp;
          <v-btn color="primary" @click="saveSearchDialog = true">Save Search</v-btn>
        </template>
      </template>

      <v-dialog v-model="saveSearchDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Save Search</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="saveFormIsValid">
              <v-text-field v-model.trim="searchName" label="Name" :rules="rules.name" required />
              <v-text-field
                v-model.trim="searchDescription"
                label="Description"
                :rules="rules.description"
                required
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveSearchDialog = false">Cancel</v-btn>
            <v-btn color="primary" @click="saveSearch" :disabled="!saveFormIsValid">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-alert v-if="randomSearch" type="info" text dismissible>
        These are random results. Refreshing the page may return different results.
      </v-alert>

      <DataTable
        grid-id="SearchDataTable"
        :data-function="dataFunction"
        :show-saved-cases-column="true"
        :hide-score-column="randomSearch"
        :hide-modified-columns="true"
      />
    </PageFrame>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import ActionsSearch from "./columns/ActionsSearch.vue";
import BatchActionsSearch from "./columns/BatchActionsSearch.vue";
import EventBus from "@/eventBus";
import searchRepository from "@/repositories/search";
import PageFrame from "../PageFrame.vue";

export default {
  name: "SearchDataTable",
  components: {
    PageFrame,
    DataTable,
  },
  provide: {
    actions: ActionsSearch,
    batchActions: BatchActionsSearch,
  },
  props: {
    pk: {
      type: String,
      default: "",
    },
    randomSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchData: null,
      saveSearchDialog: false,
      saveFormIsValid: false,
      searchName: "",
      searchDescription: "",
      rules: {
        name: [(v) => !!v.trim() || "Name is required"],
        description: [(v) => !!v.trim() || "Description is required"],
      },
    };
  },
  async mounted() {
    this.searchData = await this.getSearchData();
  },
  methods: {
    dataFunction(state) {
      return searchRepository.getSearchResults(this.pk, { ...state, source: "user" });
    },
    async getSearchData() {
      if (this.pk) {
        try {
          const r = await searchRepository.search(this.pk);
          return r.data;
        } catch (error) {
          console.error("getSearch error", error);
          EventBus.$emit("notify", "warn", error);
        }
      }
    },
    async saveSearch() {
      const data = this.$route.query;
      data.data_types = this.ensureList(data.data_types);
      data.restrict_to_firms = this.ensureList(data.restrict_to_firms);
      data.firm_groups = this.ensureList(data.firm_groups);
      data.name = this.searchName.trim();
      data.description = this.searchDescription.trim();
      try {
        const r = await searchRepository.searchSave(data);
        await this.$router.push({ name: "SearchResults", params: { pk: r.data.id } });
        this.searchData = r.data;
        this.saveSearchDialog = false;
      } catch (error) {
        console.error("searchSubmit error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    ensureList(x) {
      return typeof x === "string" ? [x] : x;
    },
  },
};
</script>
