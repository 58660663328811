import httpClient from "@/interceptors/axios";
import { withFirmGroup, withFirmGroups } from "./utils";

export default {
  // Search Data
  searchList(params) {
    return httpClient.get("searches/", { params: withFirmGroup(params) });
  },
  search(id) {
    return httpClient.get(`searches/${id}/`);
  },
  searchSave(payload, id = null) {
    if (id) {
      return httpClient.put(`searches/${id}/`, withFirmGroups(payload));
    } else {
      return httpClient.post("searches/", withFirmGroups(payload));
    }
  },
  searchDelete(id) {
    return httpClient.delete(`searches/${id}/`);
  },
  searchRestore(id) {
    return httpClient.patch(`searches/${id}/`, { is_deleted: false });
  },

  // Suspicious Term
  getTermHistory(id) {
    return httpClient.get(`terms/${id}/history/`, {
      params: { take: "all" },
    });
  },
  saveSuspiciousCategory(payload, id = null) {
    const _payload = { ...payload };
    if (_payload?.fields === null) {
      _payload.fields = [];
    }
    if (id) {
      return httpClient.put(`terms/${id}/`, _payload);
    } else {
      return httpClient.post("terms/", _payload);
    }
  },

  // migrate to the above
  patchSearch(id, field_name, field_value) {
    return httpClient.patch(`searches/${id}/`, { [field_name]: field_value });
  },
  searchPermission() {
    return httpClient.get("searches/permission/");
  },
  getSearchResults(id, params) {
    const _params = { ...params };
    if (_params?.fields) {
      if (typeof _params.fields === "string") {
        // check if it's a valid JSON value
        try {
          JSON.parse(_params.fields);
        } catch (e) {
          _params.fields = JSON.stringify([_params.fields]);
        }
      }
      // `fields` property must be a JSON field, not an array
      if (Array.isArray(_params.fields)) {
        _params.fields = JSON.stringify(_params.fields);
      }
    }
    const endpoint = id ? `searches/${id}/search/` : "search/adhoc/";
    return httpClient.get(endpoint, {
      params: _params,
      paramsSerializer: { indexes: null }, // https://github.com/axios/axios#request-config
    });
  },
  getSearchConversationResults(params) {
    return httpClient.get("search/conversation/", {
      params,
      paramsSerializer: { indexes: null }, // https://github.com/axios/axios#request-config
    });
  },
  getSuspiciousSearchPreview(id, params) {
    return httpClient.get(`termsearch${id ? `/${id}` : ""}/`, {
      params: withFirmGroup(params),
    });
  },
  getSuspiciousCategoryPreview(id, params) {
    return httpClient.get(`termsearch${id ? `/${id}` : ""}/category_detail/`, {
      params: withFirmGroup(params),
    });
  },
};

export function getChat(
  data_type,
  conversationId,
  fingerprint_client,
  fingerprint_time,
  page = 1,
  take = 20,
) {
  return httpClient.get(`chat/${conversationId}/`, {
    params: {
      data_type,
      fingerprint_client,
      fingerprint_time,
      page,
      take,
    },
  });
}

export function translate(translation_type, text, from_lang, to_lang) {
  return httpClient.post("textservice/translate/", {
    from_lang: from_lang,
    to_lang: to_lang,
    text: text,
    translation_type: translation_type,
  });
}

export function getStockTimeline(symbol, exchange, fromDate, toDate) {
  return httpClient.get(`stocks/${encodeURIComponent(encodeURIComponent(symbol))}/`, {
    params: {
      exchange,
      from_date: fromDate,
      to_date: toDate,
    },
  });
}

export function searchStock(searchString) {
  return httpClient.get(`stocks/`, {
    params: {
      keywords: searchString,
    },
  });
}

export function getSuspiciousCategoryBoosts(id) {
  return httpClient.get(`searchboost/`, {
    params: {
      search_data: id,
      take: "all",
    },
  });
}
export function saveSuspiciousCategoryBoosts(s) {
  const httpClientFn = s.id ? httpClient.patch : httpClient.post;
  return httpClientFn(`searchboost/${s.id ? `${s.id}/` : ""}`, s);
}
export function deleteSuspiciousCategoryBoosts(id) {
  return httpClient.delete(`searchboost/${id}/`);
}

export function getTerms(params) {
  return httpClient.get("terms/", {
    params: withFirmGroups(params),
    paramsSerializer: { indexes: null }, // https://github.com/axios/axios#request-config
  });
}

export function getTerm(id) {
  return httpClient.get(`terms/${id}/`);
}

/**
 * Retrieve a document from ElasticSearch (raw=false) or from AWS (raw=true)
 * @param {*} id document id
 * @param {*} fingerprint_time time of the document
 * @param {boolean?} raw call AWS to get the data and `body_html`
 * @param {string?} todoItemId used for highlighting of fields
 * @returns Document object
 */
export function getDocument(id, fingerprint_time, raw = false, todoItemId) {
  // if (raw) {
  //   return Promise.resolve({ data: mockRawDocument });
  // }
  // return Promise.resolve({ data: mockDocument });
  return httpClient.get(`document/${id}/remote/`, {
    params: {
      fingerprint_time: fingerprint_time,
      todo_item_id: todoItemId,
      body_html: !!raw || undefined,
    },
  });
}

// /**
//  * UNUSED!
//  * Retrieve a document from the database
//  * @param {string} id django id of the document
//  * @param {string?} todoItemId used for highlighting of fields
//  * @returns Document object
//  */
// export function getDjangoDocument(id, todoItemId) {
//   return httpClient.get(`document/${id}/`, {
//     params: { todo_item_id: todoItemId },
//   });
// }
