"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var PageFrame_vue_1 = require("@/components/PageFrame.vue");
var filters_1 = require("@/filters");
var firm_1 = require("@/repositories/firm");
var reporting_1 = require("@/repositories/reporting");
var auth_1 = require("@/stores/auth");
var DistributionListsActions_vue_1 = require("./columns/DistributionListsActions.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'DistributionLists',
    setup: function (__props) {
        var _this = this;
        var firms = (0, vue_2.ref)([]);
        var isLoading = (0, vue_2.ref)(false);
        var headers = (0, vue_2.ref)([]);
        var items = (0, vue_2.ref)([]);
        var dialogDelete = (0, vue_2.ref)(false);
        var dialogDeleteId = (0, vue_2.ref)("");
        var authStore = (0, auth_1.useAuthStore)();
        var firmLabelObject = (0, pinia_1.storeToRefs)(authStore).firmLabelObject;
        function loadData() {
            return __awaiter(this, void 0, void 0, function () {
                var data, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            isLoading.value = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, (0, reporting_1.getReportDistributionLists)()];
                        case 2:
                            data = (_b.sent()).data;
                            items.value = data;
                            return [3 /*break*/, 5];
                        case 3:
                            _a = _b.sent();
                            items.value = [];
                            return [3 /*break*/, 5];
                        case 4:
                            isLoading.value = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        function handleDeleteClick(id) {
            dialogDelete.value = true;
            dialogDeleteId.value = id;
        }
        function handleConfirmDelete() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, reporting_1.deleteReportDistributionLists)(dialogDeleteId.value)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, loadData()];
                        case 2:
                            _a.sent();
                            dialogDelete.value = false;
                            dialogDeleteId.value = "";
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        headers.value = [
                            {
                                value: "actions",
                                text: "Actions",
                                sortable: false,
                            },
                            {
                                value: "name",
                                text: "Name",
                            },
                            {
                                value: "fingerprint_firm__firm_name",
                                text: firmLabelObject.value.singularUppercase,
                            },
                            {
                                value: "external_email_recipients",
                                text: "Email Recipients",
                            },
                            {
                                value: "created_on",
                                text: "Created on",
                            },
                            {
                                value: "modified_on",
                                text: "Modified on",
                            },
                        ];
                        _a = firms;
                        return [4 /*yield*/, (0, firm_1.getFirms)()];
                    case 1:
                        _a.value = _b.sent();
                        loadData();
                        return [2 /*return*/];
                }
            });
        }); });
        return { __sfc: true, firms: firms, isLoading: isLoading, headers: headers, items: items, dialogDelete: dialogDelete, dialogDeleteId: dialogDeleteId, authStore: authStore, firmLabelObject: firmLabelObject, loadData: loadData, handleDeleteClick: handleDeleteClick, handleConfirmDelete: handleConfirmDelete, PageFrame: PageFrame_vue_1.default, mediumDateTimeFormat: filters_1.mediumDateTimeFormat, DistributionListsActions: DistributionListsActions_vue_1.default };
    }
});
