<template>
  <v-dialog
    :value="value"
    max-width="90%"
    data-html2canvas-ignore="true"
    scrollable
    @keydown.esc="$emit('input', false)"
  >
    <v-card>
      <v-card-title>Field history</v-card-title>
      <v-card-text>
        <BasicList
          :columns="columns"
          :data-source="dataSource"
          :grid-params="{
            allowPaging: true,
            pageSettings: pageSettings,
            selectionSettings: { type: 'Single' },
            rowSelected: handleRowSelected,
            dataStateChange: handleDataStateChange,
          }"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('input', false)"> Cancel </v-btn>
        <v-btn color="primary" text @click="handleSelectValue"> Use selected value </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BasicList from "@/components/common/BasicList.vue";
import { mediumDateTimeFormat } from "@/filters";
import { getFieldHistory } from "@/repositories/reporting";

export default {
  name: "FieldHistoryListDialog",
  components: {
    BasicList,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    modelId: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fieldHistory: {
        results: [],
        count: 0,
      },
      selectedValue: "",
      pageSettings: {
        pageSize: 10,
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          field: this.field,
          headerText: "Value",
        },
        {
          field: "num_items",
          headerText: "Times used",
        },
        {
          field: "last_created_on",
          headerText: "Last created on",
          valueAccessor: (field, data) => mediumDateTimeFormat(data[field]),
        },
        {
          field: "last_modified_on",
          headerText: "Last modified on",
          valueAccessor: (field, data) => mediumDateTimeFormat(data[field]),
        },
      ];
    },
    dataSource() {
      return {
        result: [...this.fieldHistory.results] || [],
        count: this.fieldHistory.count || 0,
      };
    },
  },
  watch: {
    async value(newValue) {
      if (newValue) {
        // loading elements here and not in `mounted` event,
        // because we need to wait for the dialog to be rendered
        this.handleDataStateChange();
      } else {
        this.fieldHistory = {
          results: [],
          count: 0,
        };
      }
    },
  },
  methods: {
    async handleDataStateChange(args = {}) {
      const { data } = await getFieldHistory(
        this.model,
        this.modelId,
        this.field,
        args?.take || 10,
        args?.action?.currentPage || 1,
      );
      this.fieldHistory = { ...data };
    },
    handleRowSelected(args) {
      this.selectedValue = args.data[this.field];
    },
    handleSelectValue() {
      this.$emit("selected-value", this.selectedValue);
      this.$emit("input", false);
    },
  },
};
</script>
