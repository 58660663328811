<template>
  <div v-if="computedPhrases.length > 0">
    <v-btn v-if="selected === null" small color="primary" @click="handleScrollPlayAudio(0, 0)">
      <v-icon left> mdi-play </v-icon>
      Play entire conversation
    </v-btn>
    &nbsp;
    <v-btn
      v-if="selected === null"
      small
      color="primary"
      @click="handleScrollPlayAudio(0, computedPhrases[0].start_time)"
    >
      <v-icon left> mdi-play </v-icon>
      Play from the first phrase
    </v-btn>
    <v-btn v-else small color="warning" @click="stopAudio">
      <v-icon left> mdi-stop </v-icon>
      Stop
    </v-btn>
    &nbsp;
    <v-btn small :href="data.url_audio" target="_blank">
      <v-icon left> mdi-download </v-icon>
      Download audio file
    </v-btn>
    <v-timeline class="my-timeline">
      <v-timeline-item
        v-for="(item, index) of computedPhrases"
        :key="index"
        :icon="item.position === 'RIGHT' ? 'mdi-chevron-left' : 'mdi-chevron-right'"
        :left="item.position === 'LEFT'"
        :right="item.position === 'RIGHT'"
        fill-dot
        large
      >
        <template #icon>
          <v-icon color="background" large>
            {{ item.position === "RIGHT" ? "mdi-chevron-left" : "mdi-chevron-right" }}
          </v-icon>
        </template>
        <template #opposite>
          <div :class="{ 'text-right': item.position === 'RIGHT' }">
            <div>{{ item.start_time }}s to {{ item.end_time }}s</div>
          </div>
        </template>
        <v-card :class="index === selected ? 'active-card' : ''">
          <v-card-title>
            <h4 v-obfuscate>
              {{ item.speaker }}
            </h4>
            <v-spacer />
            <v-btn
              v-if="index !== selected"
              class="mx-1"
              small
              color="primary"
              @click="playAudio(index, item.start_time, item.end_time)"
            >
              <v-icon left> mdi-play </v-icon>
              Play
            </v-btn>
            <v-btn
              v-if="index !== selected"
              small
              color="primary"
              @click="handleScrollPlayAudio(index, item.start_time)"
            >
              <v-icon left> mdi-play </v-icon>
              Play from here
            </v-btn>
            <v-btn v-else small color="warning" @click="stopAudio">
              <v-icon left> mdi-stop </v-icon>
              Stop
            </v-btn>
          </v-card-title>
          <v-card-text>
            <small>
              <!-- eslint-disable vue/no-v-html -->
              <p v-obfuscate class="black-text" v-html="item.text" />
            </small>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
  <div v-else-if="dataError">
    <v-alert icon="mdi-alert" type="warning">
      {{ dataError }}
    </v-alert>
  </div>
  <div v-else>Loading ...</div>
</template>

<script>
import { getDocument } from "@/repositories/search";

export default {
  name: "VoiceTimeline",
  props: {
    esId: {
      type: String,
      default: null,
    },
    fingerprintClient: {
      type: String,
      default: null,
    },
    fingerprintTime: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      data: {},
      dataError: null,
      from_person: null,
      audio: null,
      selected: null,
      timer: null,
      timers: [],
    };
  },
  computed: {
    computedPhrases() {
      return (this.data.phrases || []).map((item) => {
        return {
          ...item,
          position: item.speaker === this.from_person ? "LEFT" : "RIGHT",
        };
      });
    },
  },
  async mounted() {
    await this.getTimeline();
  },
  methods: {
    async getTimeline() {
      this.dataError = null;
      try {
        const r = await getDocument(this.esId, this.fingerprintTime, true);
        this.data = r.data;
        this.from_person = (this.data.phrases || [])[0]?.speaker;
        this.audio = new Audio(this.data.url_audio);
      } catch (error) {
        this.dataError =
          error?.response?.data?.detail || error?.response?.data || error?.message || error;
        console.error("getTimeline error", error);
      }
    },
    scrollPlayAudio(startIndex = 0, originalStart = 0) {
      for (let index = startIndex; index < this.computedPhrases.length; index++) {
        let start = this.computedPhrases[index].start_time;
        let timer = setTimeout(
          () => {
            this.selected = index;
            // this.$refs[`id_${index}`][0].$el.scrollIntoView();
          },
          1000 * (start - originalStart),
        );
        this.timers.push(timer);
      }
    },
    handleScrollPlayAudio(index, start) {
      this.stopAudio();
      this.selected = index;
      this.audio.currentTime = start;
      this.audio.play();
      this.scrollPlayAudio(index, start);
    },
    clearTimeouts() {
      for (let timer of this.timers) {
        clearTimeout(timer);
      }
      this.timers = [];
    },
    playAudio(index, start, stop) {
      this.stopAudio();
      this.selected = index;
      this.audio.currentTime = start;
      this.audio.play();
      this.timer = setTimeout(
        () => {
          this.stopAudio();
        },
        1000 * (stop - start) + 400,
      );
    },
    stopAudio() {
      clearTimeout(this.timer);
      this.clearTimeouts();
      this.audio.pause();
      this.audio.currentTime = 0;
      this.selected = null;
    },
  },
};
</script>

<style scoped>
.v-timeline:before {
  left: 50%;
}
.my-timeline {
  min-width: 950px; /* less than this value, the button "translate" is displayed on a new line */
}
.black-text {
  color: black;
}
.active-card {
  background-color: #f2dede;
}
</style>
