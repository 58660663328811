<template>
  <div>
    <PageFrame>
      <template #title>Event Log</template>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="selectedActions"
            chips
            clearable
            deletable-chips
            :items="actions"
            item-value="key"
            item-text="label"
            label="Filter Actions"
            multiple
            hide-details
            @change="handleTableUpdateOptions"
          />
        </v-col>
        <v-col>
          <v-autocomplete
            v-model="selectedUsers"
            chips
            clearable
            deletable-chips
            :items="users"
            item-value="id"
            item-text="name"
            label="Filter Users"
            multiple
            hide-details
            @change="handleTableUpdateOptions"
          />
        </v-col>
        <v-col v-if="firms.length > 1">
          <v-autocomplete
            v-model="selectedFirms"
            chips
            clearable
            deletable-chips
            :items="firms"
            item-value="id"
            item-text="firm_name"
            :label="`Filter ${firmLabelObject.pluralUppercase}`"
            multiple
            hide-details
            @change="handleTableUpdateOptions"
          />
        </v-col>
      </v-row>

      <v-data-table
        :loading="showLoading"
        :headers="headers"
        :items="items"
        :server-items-length="itemsCount"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        sort-by="event.created"
        :sort-desc="true"
        show-expand
        @update:options="handleTableUpdateOptions"
      >
        <template v-slot:item.event.action.keyword="{ item }">
          {{ item.event.action }}
        </template>
        <template v-slot:item.django.user_id="{ item }">
          {{ users.find((u) => u.id === item.django.user_id)?.full_name }}
        </template>
        <template v-slot:item.django.firm_id="{ item }">
          {{ firms.find((f) => f.id === item.django.firm_id)?.firm_name }}
        </template>
        <template v-slot:item.event.created="{ item }">
          {{ mediumDateTimeFormat(item.event.created) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <Log :data="item" />
            </v-container>
          </td>
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import PageFrame from "@/components/PageFrame.vue";
import Log from "@/components/datatable/details/Log.vue";
import { getFirms } from "@/repositories/firm";
import { getLogs } from "@/repositories/logs";
import { getActions } from "@/repositories/settings";
import { getUserList } from "@/repositories/user";
import { useAuthStore } from "@/stores/auth";
import { mediumDateTimeFormat } from "@/filters";
import { vuetifyGridOptionsToQueryParams } from "@/utils";

export default {
  name: "App",
  components: { PageFrame, Log },
  data() {
    return {
      actions: [],
      firms: [],
      users: [],
      selectedActions: [],
      selectedUsers: [],
      selectedFirms: [],
      showLoading: false,
      lastFilterOptions: {},
      headers: [],
      items: [],
      itemsCount: 0,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["firmLabelObject"]),
  },
  async mounted() {
    if (this.$route.params.action) {
      this.selectedActions = [this.$route.params.action];
    }
    const [actions, users, firms] = await Promise.all([
      getActions(),
      getUserList({ expand: undefined }),
      getFirms(),
    ]);
    this.actions = actions;
    this.users = users;
    this.firms = firms;
    this.headers = [
      { value: "event.action.keyword", text: "Action" },
      { value: "summary", text: "Summary", sortable: false },
      { value: "django.user_id", text: "Actioned By", sortable: false },
      { value: "django.firm_id", text: this.firmLabelObject.singularUppercase, sortable: false },
      { value: "event.created", text: "Date" },
    ];
  },
  methods: {
    mediumDateTimeFormat,
    async handleTableUpdateOptions(options) {
      this.showLoading = true;
      if (options) {
        this.lastFilterOptions = options;
      }
      const state = vuetifyGridOptionsToQueryParams(
        this.lastFilterOptions,
        undefined,
        "-event.created",
      );
      const { results = [], count = 0 } = await getLogs({
        ...state,
        actions: this.selectedActions.length ? JSON.stringify(this.selectedActions) : undefined,
        users: this.selectedUsers.length ? JSON.stringify(this.selectedUsers) : undefined,
        firms: this.selectedFirms.length ? JSON.stringify(this.selectedFirms) : undefined,
        source: "user",
      });
      this.items = results;
      this.itemsCount = count;
      this.showLoading = false;
    },
  },
};
</script>
