<template>
  <div>
    <h3 class="text-h6" :data-html2canvas-ignore="reportItem.title_display ? undefined : 'true'">
      {{ reportItem.title_display }}
      <v-tooltip v-if="!isPublished" data-html2canvas-ignore="true" right>
        <template #activator="{ on, attrs }">
          <v-btn
            data-html2canvas-ignore="true"
            icon
            v-bind="attrs"
            v-on="on"
            @click="$emit('change-item-values', 'commentary_pre')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit item data</span>
      </v-tooltip>
    </h3>
    <div
      class="text-caption"
      :data-html2canvas-ignore="reportItem.commentary_pre ? undefined : 'true'"
    >
      {{ reportItem.commentary_pre || "-- no commentary pre --" }}
    </div>
    <component
      :is="getSummaryComponent(reportItem.report_item_type)"
      :data="reportItem"
      :json-data="jsonData"
      :period-options="periodOptions"
      @change-report-item-parameters="$emit('change-report-item-parameters', $event)"
    />
    <div
      class="text-caption"
      :data-html2canvas-ignore="reportItem.commentary_post ? undefined : 'true'"
    >
      {{ reportItem.commentary_post || "-- no commentary post --" }}
    </div>
  </div>
</template>

<script>
import reports from "./reports";

export default {
  name: "StoredReportItem",
  props: {
    isPublished: {
      type: Boolean,
      default: false,
    },
    reportItem: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
    periodOptions: {
      type: Object,
      default: () => ({
        periodType: "",
        periodValue: null,
      }),
    },
  },
  methods: {
    getSummaryComponent(reportItemType) {
      return {
        CaseTotals: reports.CaseTotals,
        CaseItemsByCase: reports.CaseItemsByCase,
        CaseNotesByCase: reports.CaseNotesByCase,
        CaseCategoriesForPeriod: reports.CaseCategoriesForPeriod,
        CaseCategoryVStage: reports.CaseCategoryVStage, // TODO: remove this?
        CasesOpenedClosedByPeriod: reports.CasesOpenedClosedByPeriod,
        // ChannelVNoActionReason: reports.ChannelVNoActionReason,
        // ChannelVNoActionReasonByPeriod: reports.ChannelVNoActionReasonByPeriod,
        CategoryVNoActionReason: reports.CategoryVNoActionReason,
        // CategoryVNoActionReasonByPeriod: reports.CategoryVNoActionReasonByPeriod,
        // TodoByNoActionReason: reports.TodoByNoActionReason,
        // TodoByNoActionReasonByPeriod: reports.TodoByNoActionReasonByPeriod,
        TodosMetrics: reports.TodosMetrics,
        TodosMetricsByPeriod: reports.TodosMetricsByPeriod,
        ItemsByChannelByPeriod: reports.ItemsByChannelByPeriod,
        ItemsByFirmByPeriod: reports.ItemsByFirmByPeriod,
        ItemsBySuspiciousTermByPeriod: reports.ItemsBySuspiciousTermByPeriod,
        ItemsDistributionByPeriod: reports.ItemsDistributionByPeriod,
        ProcessedItemsByUserByPeriod: reports.ProcessedItemsByUserByPeriod,
        ItemsBurndownByPeriod: reports.ItemsBurndownByPeriod,
        SearchActivityByUserByPeriod: reports.SearchActivityByUserByPeriod,
        ItemProcessedOutcomeByPeriod: reports.ItemProcessedOutcomeByPeriod,
        TimeBeforeItemReviewByPeriod: reports.TimeBeforeItemReviewByPeriod,
        CaseWorkflowProgress: reports.CaseWorkflowProgress,
        SignatureSection: reports.SignatureSection,
      }[reportItemType];
    },
  },
};
</script>
