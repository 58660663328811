<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate />
    </div>
    <div v-else-if="periodData && Object.keys(periodData).length !== 0">
      <p v-if="title" class="my-title">
        {{ title }}
      </p>
      <ul v-for="(item, search_name, index) in periodData" :key="index" class="list-group">
        <li>
          <v-chip small color="primary" class="mr-2">
            {{ item[fieldName] ? parseInt(item[fieldName]) : "" }}
          </v-chip>
          {{ search_name }}
        </li>
      </ul>
    </div>
    <div v-else-if="periodDataList">
      <p v-if="title" class="my-title">
        {{ title }}
      </p>
      <ul v-for="elem in periodDataList" :key="elem[keyName]" class="list-group">
        <li>
          <v-chip small color="primary" class="mr-2">
            {{ elem[fieldName] ? parseInt(elem[fieldName]) : "" }}
          </v-chip>
          {{ elem[keyName] }}
        </li>
      </ul>
    </div>
    <div v-else>No items found.</div>
  </div>
</template>

<script>
export default {
  name: "MetricList",
  props: {
    periodData: {
      type: Object,
      required: false,
      default: null,
    },
    periodDataList: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    keyName: {
      type: String,
      required: false,
      default: "id",
    },
    fieldName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },
  },
};
</script>

<style scoped>
.list-group {
  margin-bottom: 5px;
}
.my-title {
  padding: 10px 0 0 0;
  font-style: italic;
}
</style>
