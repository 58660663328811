import { render, staticRenderFns } from "./CaseCategoryVStage.vue?vue&type=template&id=546de544&scoped=true"
import script from "./CaseCategoryVStage.vue?vue&type=script&lang=js"
export * from "./CaseCategoryVStage.vue?vue&type=script&lang=js"
import style0 from "./CaseCategoryVStage.vue?vue&type=style&index=0&id=546de544&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546de544",
  null
  
)

export default component.exports