"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var luxon_1 = require("luxon");
var vue_2 = require("vue");
var types_1 = require("./types");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MenuDateSelect',
    props: {
        value: null,
        type: { default: "end" }
    },
    emits: ["input", "close"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var typeLuxonRound = {
            start: "startOf",
            end: "endOf",
        };
        var selectorRanges = types_1.SELECTOR_RANGES.map(function (range) { return (__assign(__assign({}, range), { text_custom: "".concat(range.text, " ago") })); });
        var relativeInputNumber = (0, vue_2.ref)(2);
        var relativeInputRangeType = (0, vue_2.ref)("days");
        function handleApplyRelative() {
            var _a;
            var value = luxon_1.DateTime.now().minus((_a = {},
                _a[relativeInputRangeType.value] = relativeInputNumber.value,
                _a));
            if (relativeInputNumber.value > 0) {
                value = value[typeLuxonRound[props.type]]("minute");
            }
            emit("input", value);
            emit("close");
        }
        var timezoneOffset = luxon_1.DateTime.now().offset; //  * 60 * 1000;
        var localizedFromValueStr = (0, vue_2.computed)(function () {
            return props.value ? props.value.toISO().substring(0, 16) : "";
        });
        function updateDateTimeValue(newValue) {
            var valueToEmit;
            if (newValue) {
                valueToEmit = luxon_1.DateTime.fromMillis(newValue).minus({
                    minutes: timezoneOffset,
                });
            }
            else {
                valueToEmit = luxon_1.DateTime.now();
            }
            emit("input", valueToEmit);
        }
        function setNow() {
            updateDateTimeValue();
            emit("close");
        }
        return { __sfc: true, props: props, emit: emit, typeLuxonRound: typeLuxonRound, selectorRanges: selectorRanges, relativeInputNumber: relativeInputNumber, relativeInputRangeType: relativeInputRangeType, handleApplyRelative: handleApplyRelative, timezoneOffset: timezoneOffset, localizedFromValueStr: localizedFromValueStr, updateDateTimeValue: updateDateTimeValue, setNow: setNow };
    }
});
