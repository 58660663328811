<template>
  <div>
    <v-dialog v-model="removeItemFromCaseDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text>
          You are removing the item from case<br />
          {{ selectedCase.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="removeItemFromCaseDialog = false"> Cancel </v-btn>
          <v-btn color="error" text @click="removeItem"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip v-if="canDeleteTodoItem" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          color="error"
          v-bind="attrs"
          v-on="on"
          @click="removeItemFromCaseDialog = true"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <span>Remove this item from case {{ selectedCase.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "pinia";

import EventBus from "@/eventBus";
import { resetTodoItems } from "@/repositories/todo";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "ActionsCaseDetail",
  data() {
    return {
      removeItemFromCaseDialog: false,
      data: {},
    };
  },
  computed: {
    ...mapState(useStore, {
      selectedCase: (state) => state.case,
    }),
    ...mapState(useAuthStore, ["canShowConversationSearch", "canDeleteTodoItem"]),
  },
  methods: {
    async removeItem() {
      try {
        await resetTodoItems([this.data.django_id]);
      } catch (error) {
        console.error("removeItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
