import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.PageFrame,{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_setup.isNew ? "Add" : "Edit")+" Auto Ignore Rule ")]},proxy:true}])},[(!_setup.formObj)?_c('h1',[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" Loading data... ")],1):_c(VForm,{model:{value:(_setup.formValid),callback:function ($$v) {_setup.formValid=$$v},expression:"formValid"}},[_c(VCard,[_c(VCardText,[_c(VSelect,{attrs:{"rules":_setup.rules.field_name,"items":_setup.fieldNames,"item-text":"name","item-value":"id","label":"Field Name","required":""},model:{value:(_setup.formObj.field_name),callback:function ($$v) {_vm.$set(_setup.formObj, "field_name", $$v)},expression:"formObj.field_name"}}),_c(VTextField,{attrs:{"rules":_setup.rules.field_values,"label":"Field Values","hint":"Comma separated list of values","persistent-hint":"","required":""},model:{value:(_setup.formObj.field_values),callback:function ($$v) {_vm.$set(_setup.formObj, "field_values", $$v)},expression:"formObj.field_values"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _setup.router.back()}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_setup.formValid},on:{"click":_setup.handleSave}},[_vm._v("Save")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }