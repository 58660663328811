<template>
  <div>
    <PageFrame v-if="caseObj">
      <template #title> Adhoc Search Results </template>

      <template #crumb>
        <router-link :to="{ name: 'CaseDetail', params: { pk: caseObj.id } }">
          {{ caseObj.name }}
        </router-link>
      </template>

      <template #actions>
        <v-btn
          color="warning"
          :to="{ name: 'NewSearch', params: { caseId: caseObj.id }, query: $route.query }"
        >
          Modify Search
        </v-btn>
        &nbsp;
        <v-btn color="primary" :to="{ name: 'CaseDetail', params: { pk: caseObj.id } }">
          Back To Case
        </v-btn>
      </template>

      <DataTable grid-id="CaseSearchDataTable" :data-function="dataFunction" />
    </PageFrame>
  </div>
</template>

<script>
import DataTable from "./DataTable.vue";
import ActionsCaseSearch from "./columns/ActionsCaseSearch.vue";
import BatchActionsCaseSearch from "./columns/BatchActionsCaseSearch.vue";
import searchRepository from "@/repositories/search";
import { getCase } from "@/repositories/case";
import EventBus from "@/eventBus";
import { useStore } from "@/stores/main";
import PageFrame from "../PageFrame.vue";

export default {
  name: "CaseSearchDataTable",
  components: {
    PageFrame,
    DataTable,
  },
  provide: {
    // add case_id to payload so we can record adhoc search against the case in context
    actions: ActionsCaseSearch,
    batchActions: BatchActionsCaseSearch,
  },
  props: {
    caseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      caseObj: null,
    };
  },
  async created() {
    this.caseObj = await this.getCase();
    const mainStore = useStore();
    mainStore.setCase(this.caseObj);
  },
  methods: {
    dataFunction(state) {
      const mainStore = useStore();
      state.case_id = mainStore.case.id;
      state.restrict_to_firms = [mainStore.case.fingerprint_firm];
      return searchRepository.getSearchResults(null, { ...state, source: "user" });
    },
    async getCase() {
      try {
        const r = await getCase(this.caseId);
        return r.data;
      } catch (error) {
        console.error("getCase error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
  },
};
</script>
