import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.restoreDialog),callback:function ($$v) {_vm.restoreDialog=$$v},expression:"restoreDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Confirm Restore")]),_c(VCardText,[_vm._v("Are you sure you wish to restore this case?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.restoreDialog = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"warning","text":""},on:{"click":_vm.caseRestore}},[_vm._v("Restore")])],1)],1)],1),(_vm.canChangeCase && _vm.data.is_deleted)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"warning","icon":"","small":""},on:{"click":_vm.caseRestoreDialog}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-restore")])],1)]}}],null,false,1255200820)},[_c('span',[_vm._v("Restore")])]):_vm._e(),(!_vm.data.is_deleted)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":""},on:{"click":_vm.redirectToCase}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-eye")])],1)]}}],null,false,4216500162)},[_c('span',[_vm._v("Case details")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }