<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="500">
      <v-form v-model="valid">
        <v-card>
          <v-card-title> Enter a Reason for Reviewing Without Action </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="actionCategoryId"
              label="Reason Category"
              :items="actionReasonCategories"
              item-value="id"
              item-text="name"
              autofocus
              auto-select-first
              :rules="actionCategoryIdRules"
            />
            <v-checkbox
              :disabled="!actionCategoryId"
              v-model="useAsDefault"
              label="Use this category as the default for future reviews"
            />
            <v-textarea v-model="actionReason" label="Explanation" :rules="actionReasonRules" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="showDialog = false"> Cancel </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="handleSubmit"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import { CategoryType } from "@/models/enums";
import { getActionReasonCategories } from "@/repositories/todo";

export default {
  data() {
    return {
      showDialog: false,
      valid: false,
      callbackFn: null,
      actionReasonCategories: [],
      actionCategoryId: null,
      actionCategoryIdRules: [
        /* istanbul ignore next */
        (v) => !!v || v === "" || "Selecting a Category is required",
      ],
      actionReason: "",
      useAsDefault: false,
    };
  },
  computed: {
    actionReasonRules() {
      const category = this.actionReasonCategories.filter((c) => c.id === this.actionCategoryId)[0];
      if (category && !category.id) {
        return [(v) => !!v || "An explanation is required for the selected category"];
      }
      return [];
    },
  },
  mounted() {
    this.loadActionReasonCategories();
    EventBus.$on("setIgnoreReason", this.handleSetIgnoreReason);
  },
  beforeDestroy() {
    EventBus.$off("setIgnoreReason", this.handleSetIgnoreReason);
  },
  methods: {
    handleSetIgnoreReason({ detail: [callbackFn] }) {
      this.callbackFn = callbackFn;
      this.showDialog = true;
      this.useAsDefault = false;
    },
    async loadActionReasonCategories() {
      const actionReasonCategories = await getActionReasonCategories(CategoryType.TODO, false);
      this.actionReasonCategories = actionReasonCategories.concat([
        {
          id: "",
          name: "Other...",
        },
      ]);
    },
    async handleSubmit() {
      try {
        if (typeof this.callbackFn === "function") {
          await this.callbackFn(this.actionCategoryId, this.actionReason, this.useAsDefault);
        }
        this.showDialog = false;
        this.actionCategoryId = null;
        this.actionReason = "";
      } catch (error) {
        console.error("handleSubmit error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
  },
};
</script>
