import CaseCategoriesForPeriod from "./CaseCategoriesForPeriod.vue";
import CaseCategoryVStage from "./CaseCategoryVStage.vue";
import CaseItemsByCase from "./CaseItemsByCase.vue";
import CaseNotesByCase from "./CaseNotesByCase.vue";
import CasesOpenedClosedByPeriod from "./CasesOpenedClosedByPeriod.vue";
import CaseTotals from "./CaseTotals.vue";
import CategoryVNoActionReason from "./CategoryVNoActionReason.vue";
// import CategoryVNoActionReasonByPeriod from "./CategoryVNoActionReasonByPeriod.vue";
// import ChannelVNoActionReason from "./ChannelVNoActionReason.vue";
// import ChannelVNoActionReasonByPeriod from "./ChannelVNoActionReasonByPeriod.vue";
// import TodoByNoActionReason from "./TodoByNoActionReason.vue";
// import TodoByNoActionReasonByPeriod from "./TodoByNoActionReasonByPeriod.vue";
import TodosMetrics from "./TodosMetrics.vue";
import TodosMetricsByPeriod from "./TodosMetricsByPeriod.vue";
import ItemsByChannelByPeriod from "./ItemsByChannelByPeriod.vue";
import ItemsByFirmByPeriod from "./ItemsByFirmByPeriod.vue";
import ItemsBySuspiciousTermByPeriod from "./ItemsBySuspiciousTermByPeriod.vue";
import ItemsDistributionByPeriod from "./ItemsDistributionByPeriod.vue";
import ProcessedItemsByUserByPeriod from "./ProcessedItemsByUserByPeriod.vue";
import ItemsBurndownByPeriod from "./ItemsBurndownByPeriod.vue";
import SearchActivityByUserByPeriod from "./SearchActivityByUserByPeriod.vue";
import ItemProcessedOutcomeByPeriod from "./ItemProcessedOutcomeByPeriod.vue";
import TimeBeforeItemReviewByPeriod from "./TimeBeforeItemReviewByPeriod.vue";
import CaseWorkflowProgress from "./CaseWorkflowProgress.vue";
import SignatureSection from "./SignatureSection.vue";

export default {
  CaseCategoriesForPeriod,
  CaseCategoryVStage,
  CaseItemsByCase,
  CaseNotesByCase,
  CasesOpenedClosedByPeriod,
  CaseTotals,
  CategoryVNoActionReason,
  // CategoryVNoActionReasonByPeriod,
  // ChannelVNoActionReason,
  // ChannelVNoActionReasonByPeriod,
  // TodoByNoActionReason,
  // TodoByNoActionReasonByPeriod,
  TodosMetrics,
  TodosMetricsByPeriod,
  ItemsByChannelByPeriod,
  ItemsByFirmByPeriod,
  ItemsBySuspiciousTermByPeriod,
  ItemsDistributionByPeriod,
  ProcessedItemsByUserByPeriod,
  ItemsBurndownByPeriod,
  SearchActivityByUserByPeriod,
  ItemProcessedOutcomeByPeriod,
  TimeBeforeItemReviewByPeriod,
  CaseWorkflowProgress,
  SignatureSection,
};
