<template>
  <div>
    <v-row no-gutters>
      <h3>{{ data.title_display }}</h3>
    </v-row>
    <v-row>
      <v-col>
        <MetricBox
          v-for="dataType in dataTypeList"
          :key="dataType.value"
          :title="dataType.title"
          :show-accordion="
            !!Object.keys((data_todo_time_type_search || {})[dataType.value] || {}).length
          "
        >
          <template #info>
            <ul>
              <li>
                {{ countTriagedItems(data_todo_time_type, dataType.value) }} items added to the
                triage list
              </li>
              <li>
                {{ countIngestedItems(data_totals, dataType.value) }} items ingested into our
                elastic search cluster<br />
              </li>
              <li>
                This represents {{ percTodos(data_todo_time_type, data_totals, dataType.value) }}%
                of the ingested items ({{
                  countTriagedItems(data_todo_time_type, dataType.value)
                }}/{{ countIngestedItems(data_totals, dataType.value) }}).
              </li>
            </ul>
          </template>
          <template #metric>
            <Metric
              :val="`${countTriagedItems(data_todo_time_type, dataType.value)} of ${countIngestedItems(data_totals, dataType.value)}`"
              :perc="percTodos(data_todo_time_type, data_totals, dataType.value)"
            />
          </template>
          <template #metric-detail>
            <MetricList
              v-if="data_todo_time_type_search"
              title="by Risk Categ."
              :period-data="data_todo_time_type_search[dataType.value]"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <MetricBox
          title="Triage by Status"
          :show-accordion="!!Object.keys(data_todo_time_search_action || {}).length"
        >
          <template #info>
            <ul>
              <li>Pending: triage items still in the pending list</li>
              <li>
                Reviewed: items actioned by a user (or auto rule) by moving them to the no action
                list
              </li>
              <li>Assigned: items actioned by a user by adding them to a case</li>
            </ul>
          </template>
          <template #metric>
            <TodoMetric :period-data="data_todo_time_action" />
          </template>
          <template #metric-detail>
            <TodoMetricList title="by Risk Categ." :period-data="data_todo_time_search_action" />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Triage: Avg Time to Action"
          :show-accordion="!!Object.keys(data_todo_time_search || {}).length"
        >
          <template #info>
            Hours elapsed on average between a triage item being added to the triage list and it
            being actioned
          </template>
          <template #metric>
            <Metric
              :val="
                data_todo_time && data_todo_time['actioned_avg']
                  ? parseInt(data_todo_time['actioned_avg']) + ' hours'
                  : 'N/A'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="data_todo_time_search"
              :field-name="'actioned_avg'"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";
import Metric from "@/components/metrics/Metric.vue";
import MetricList from "@/components/metrics/MetricList.vue";
import TodoMetric from "@/components/metrics/TodoMetric.vue";
import TodoMetricList from "@/components/metrics/TodoMetricList.vue";
import { getDataTypes } from "@/repositories/settings";

export default {
  name: "TodosMetricsByPeriod",
  components: {
    Metric,
    MetricBox,
    MetricList,
    TodoMetric,
    TodoMetricList,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
    periodOptions: {
      type: Object,
      default: () => ({
        periodValue: null,
      }),
    },
  },
  data() {
    return {
      dataTypes: [],
    };
  },
  computed: {
    canShowBbgIm() {
      return this.dataTypes.some((dt) => dt.id === "bbg.im");
    },
    canShowBbgMsg() {
      return this.dataTypes.some((dt) => dt.id === "bbg.msg");
    },
    canShowEmail() {
      return this.dataTypes.some((dt) => dt.id === "email");
    },
    canShowVoice() {
      return this.dataTypes.some((dt) => dt.id === "voice");
    },
    canShowSlack() {
      return this.dataTypes.some((dt) => dt.id === "slack");
    },
    canShowTeamsPersonal() {
      return this.dataTypes.some((dt) => dt.id === "teams.personal");
    },
    canShowTeamsCorporate() {
      return this.dataTypes.some((dt) => dt.id === "teams.corporate");
    },
    canShowWhatsapp() {
      return this.dataTypes.some((dt) => dt.id === "whatsapp");
    },
    canShowZoomCall() {
      return this.dataTypes.some((dt) => dt.id === "zoom.call");
    },
    canShowZoomMeet() {
      return this.dataTypes.some((dt) => dt.id === "zoom.meet");
    },
    dataTypeList() {
      return [
        {
          canShow: this.canShowBbgIm,
          value: "bbg.im",
          title: "Triage: Bloomberg IM",
        },
        {
          canShow: this.canShowBbgMsg,
          value: "bbg.msg",
          title: "Triage: Bloomberg Msg",
        },
        {
          canShow: this.canShowEmail,
          value: "email",
          title: "Triage: Email",
        },
        {
          canShow: this.canShowVoice,
          value: "voice",
          title: "Triage: Voice",
        },
        {
          canShow: this.canShowSlack,
          value: "slack",
          title: "Triage: Slack",
        },
        {
          canShow: this.canShowTeamsPersonal,
          value: "teams.personal",
          title: "Triage: Teams Personal",
        },
        {
          canShow: this.canShowTeamsCorporate,
          value: "teams.corporate",
          title: "Triage: Teams Corporate",
        },
        {
          canShow: this.canShowWhatsapp,
          value: "whatsapp",
          title: "Triage: Whatsapp",
        },
        {
          canShow: this.canShowZoomCall,
          value: "zoom.call",
          title: "Triage: Zoom Call",
        },
        {
          canShow: this.canShowZoomMeet,
          value: "zoom.meet",
          title: "Triage: Zoom Meet",
        },
      ].filter((item) => item.canShow);
    },
    data_todo_time_type() {
      return this.jsonData?.TodoStatsByTimeByType?.[this.periodOptions.periodValue];
    },
    data_todo_time_type_search() {
      return this.jsonData?.TodoStatsByTimeByTypeBySearch?.[this.periodOptions.periodValue];
    },
    data_totals() {
      return this.jsonData?.AllTotalsByTime?.[this.periodOptions.periodValue];
    },
    data_todo_time_search_action() {
      return this.jsonData?.TodoStatsByTimeBySearchByAction?.[this.periodOptions.periodValue];
    },
    data_todo_time_action() {
      return this.jsonData?.TodoStatsByTimeAction?.[this.periodOptions.periodValue];
    },
    data_todo_time_search() {
      return this.jsonData?.TodoStatsByTimeBySearch?.[this.periodOptions.periodValue];
    },
    data_todo_time() {
      return this.jsonData?.TodoStatsByTime?.[this.periodOptions.periodValue];
    },
  },
  methods: {
    countTodos(periodData) {
      if (!periodData) return 0;
      return (periodData.todo_total || 0).toLocaleString();
    },
    percTodos(periodData, periodDataTotal, dataType) {
      const data = periodData?.[dataType];
      const total = periodDataTotal?.[dataType]?.total || 0;
      if (data) {
        if (total) {
          return `${parseFloat(((100 * data) / total).toFixed(4)) || 0}`;
        }
        return null;
      }
      return "0";
    },
    countTriagedItems(periodData, dataType) {
      return periodData?.[dataType]?.todo_total || 0;
    },
    countIngestedItems(periodDataTotal, dataType) {
      return periodDataTotal?.[dataType]?.total || 0;
    },
  },
  async mounted() {
    getDataTypes().then((dataTypes) => {
      this.dataTypes = dataTypes;
    });
  },
};
</script>
