import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageFrame',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Pending workflows")]},proxy:true},{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.loadData}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)]},proxy:true}])},[_c(VList,{attrs:{"subheader":"","two-line":""}},[(_vm.loadingNotifications)?_c(VListItem,[_c(VListItemAvatar,[_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar"}})],1),_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line"}})],1):_vm._e(),_c(VSubheader,[_vm._v("Cases waiting for activity")]),_vm._l((_vm.notifications),function(notification){return _c(VListItem,{key:notification.id,attrs:{"to":{ name: 'CaseDetail', params: { pk: notification.case.id } }}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-file-document-multiple")])],1),_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{staticClass:"col-6 col-md-3"},[_vm._v("Action:"),_c('br'),_vm._v(_vm._s(notification.name))]),_c(VCol,{staticClass:"col-6 col-md-3"},[_vm._v("Case:"),_c('br'),_vm._v(_vm._s(notification.case.name))]),_c(VCol,{staticClass:"col-12 col-md-3"},[_vm._v(" Assigned to:"),_c('br'),_vm._v(" "+_vm._s(notification.assign_to.map(_vm.getUserFullName).join(", "))+" ")]),_c(VCol,{staticClass:"col-12 col-md-3 text-right"},[_vm._v(" Started "+_vm._s(_vm.adaptiveRelativeTimeFormat(new Date(notification.started) - new Date()))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.mediumDateTimeFormat(notification.started))+" ")])],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }