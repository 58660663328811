import { Auth, Hub } from "aws-amplify";

import router from "@/router";
import { logLogin } from "@/repositories/logs";
import { REDIRECT_KEY_NAME } from "@/stores/auth";

export const authEventHandler = async ({ payload: { event, data } }) => {
  if (event === "parsingCallbackUrl") {
    localStorage.setItem(REDIRECT_KEY_NAME, data.url);
  } else if (event === "signIn") {
    logLogin().catch(() => {});
    const redirectTo = localStorage.getItem(REDIRECT_KEY_NAME);
    localStorage.removeItem(REDIRECT_KEY_NAME);
    if (redirectTo && router.currentRoute.path !== redirectTo) {
      router.push(redirectTo);
    }
  } else if (event === "customOAuthState") {
    if (data === REDIRECT_KEY_NAME) {
      const redirectTo = localStorage.getItem(REDIRECT_KEY_NAME);
      localStorage.removeItem(REDIRECT_KEY_NAME);
      if (router.currentRoute.path !== redirectTo) {
        router.push(redirectTo);
      }
    }
  } else if (event === "signOut") {
    // DO NOT CALL `Auth.signOut` from this event, it will cause a logout loop!
    // 2021-08-18 Denny: handling this from pinia auth store (awsLogout action)
    // authStore.awsLogout();
  } else if (event === "signIn_failure") {
    // DO NOT CALL `Auth.signOut` from this event, it will hide the error message!
    // Auth.signOut();
  } else if (event === "tokenRefresh") {
    // TODO: change wss token connection here?
  } else if (event === "tokenRefresh_failure") {
    Auth.signOut();
  }
};

Hub.listen("auth", authEventHandler);
