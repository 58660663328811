import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":"Cut-off value","type":"number","min":"0"},on:{"change":_vm.handleChangeCutoffValue},model:{value:(_vm.cutoffValue),callback:function ($$v) {_vm.cutoffValue=$$v},expression:"cutoffValue"}}),(_vm.chartData.data.length)?_c('ChartJs',{attrs:{"height":"500px","type":"bar","data":{
          datasets: [
            {
              data: _vm.chartData.data,
              backgroundColor: _vm.chartData.backgroundColor,
            },
          ],
          labels: _vm.chartData.labels,
        },"options":{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              padding: 2,
            },
            y: {
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}}):_c('span',[_vm._v("No data available for this period")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }