<template>
  <div>
    <v-row>
      <v-col>
        <MetricBox
          title="Case Opened"
          :show-accordion="!!Object.keys(data_case_timecreated_status_category || {}).length"
        >
          <template #info>
            Cases created within the time period and currently in the opened state
          </template>
          <template #metric>
            <Metric
              :val="
                data_case_timecreated_status ? '' + data_case_timecreated_status['case_total'] : '0'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="data_case_timecreated_status_category"
              :field-name="'case_total'"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Case Closed"
          :show-accordion="!!Object.keys(data_case_timeclosed_status_category || {}).length"
        >
          <template #info>
            Cases created within the time period and currently in the closed state
          </template>
          <template #metric>
            <Metric
              :val="
                data_case_timeclosed_status ? '' + data_case_timeclosed_status['case_total'] : '0'
              "
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="data_case_timeclosed_status_category"
              :field-name="'case_total'"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";
import Metric from "@/components/metrics/Metric.vue";
import MetricList from "@/components/metrics/MetricList.vue";

export default {
  name: "CasesOpenedClosedByPeriod",
  components: {
    Metric,
    MetricBox,
    MetricList,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
    periodOptions: {
      type: Object,
      default: () => ({
        periodValue: null,
      }),
    },
  },
  computed: {
    results_case_timecreated_status() {
      return this.jsonData?.CaseStatsByTimeCreatedByStatus;
    },
    results_case_timecreated_status_category() {
      return this.jsonData?.CaseStatsByTimeCreatedByStatusByCategory;
    },
    results_case_timeclosed_status() {
      return this.jsonData?.CaseStatsByTimeClosedByStatus;
    },
    results_case_timeclosed_status_category() {
      return this.jsonData?.CaseStatsByTimeClosedByStatusByCategory;
    },
    data_case_timecreated_status() {
      return this.results_case_timecreated_status?.[this.periodOptions.periodValue];
    },
    data_case_timecreated_status_category() {
      return this.results_case_timecreated_status_category?.[this.periodOptions.periodValue];
    },
    data_case_timeclosed_status() {
      return this.results_case_timeclosed_status?.[this.periodOptions.periodValue];
    },
    data_case_timeclosed_status_category() {
      return this.results_case_timeclosed_status_category?.[this.periodOptions.periodValue];
    },
  },
};
</script>
