import httpClient from "@/interceptors/axios";
import { defineStore } from "pinia";

export const defaultState = {
  accounts: [],
  accountsLoading: false,
  accountsLoadingError: null,
};

export const actions = {
  async getAccounts(params = {}) {
    this.accountsLoading = true;
    this.accountsLoadingError = null;
    try {
      const { data } = await httpClient.get("accounts/", {
        params: {
          ...params,
          take: "all",
        },
      });
      this.accounts = data;
    } catch (error) {
      this.accountsLoadingError = error;
      this.accounts = defaultState.accounts;
    } finally {
      this.accountsLoading = false;
    }
  },
};

export const useAccountsStore = defineStore("accounts", {
  state: () => defaultState,
  actions,
});
