import SuspiciousTermList from "@/components/crud/SuspiciousTermList.vue";
import SuspiciousCategoryList from "@/views/suspisious-terms/SuspiciousCategoryList.vue";

const routes = [
  {
    path: "/risk-terms/",
    name: "SuspiciousTermList",
    component: SuspiciousTermList,
    meta: {
      checkAccountFeatures: ({ canShowSuspiciousTerms }) => canShowSuspiciousTerms,
    },
  },
  {
    path: "/risk-categories/",
    name: "SuspiciousTermCategoriesList",
    component: SuspiciousCategoryList,
    meta: {
      checkAccountFeatures: ({ canShowSuspiciousTermCategory }) => canShowSuspiciousTermCategory,
    },
  },
];

export default routes;
