import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"500px","persistent":"","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c(VCard,[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_c(VCardText,[_c(VTextarea,{attrs:{"label":_vm.inputLabel,"hint":_vm.inputHint,"persistent-hint":"","rows":"3","required":"","autofocus":""},model:{value:(_setup.reason),callback:function ($$v) {_setup.reason=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"reason"}})],1),_c(VCardActions,[_vm._t("actions",function(){return [_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_setup.emitCancel}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"secondary","text":"","disabled":!_setup.reason},on:{"click":_setup.emitOk}},[_vm._v("Ok")])]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }