import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"app":"","dark":"","color":"#140452","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('div',{staticClass:"image-header"},[_c('img',{attrs:{"src":"img/fingerprint-logo-red-rgb.svg"}})]),_c(VList,{attrs:{"nav":"","dense":""}},[(_setup.firmGroups.length > 1)?_c(VListItem,[_c(VSelect,{attrs:{"label":`${_setup.firmLabelObject.singularUppercase} Group`,"value":_setup.selectedFirmGroup.id,"items":_setup.firmGroups,"item-value":"id","item-text":"group_name"},on:{"change":_setup.handleFirmGroupChange}})],1):_vm._e(),_vm._l((_setup.items),function(parentItem,index){return _c('div',{key:parentItem.key},[_vm._l((parentItem.items),function(item){return _c('div',{key:item.title},[(item.items)?_c(VListGroup,{attrs:{"no-action":"","color":"white"},on:{"change":function($event){return _setup.handleMenuChange(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.items),function(child){return _c(VListItem,{key:child.title,attrs:{"link":"","to":child.routerLinkTo}},[_c(VListItemTitle,{attrs:{"dark":""}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(child.icon))]),_vm._v(" "+_vm._s(child.title)+" ")],1)],1)}),1):_c(VListItem,{attrs:{"link":"","to":item.routerLinkTo}},[_c(VListItemTitle,{attrs:{"dark":""}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)}),(index < _setup.items.length - 1)?_c(VDivider,{staticClass:"my-5"}):_vm._e()],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }