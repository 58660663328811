import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[(_vm.noteId)?_c('span',[_vm._v("Edit Case Note")]):_c('span',[_vm._v("Add Case Note")])]),_c(VCardText,[(_vm.loadingData)?_c('div',[_vm._v("Loading data...")]):_vm._e(),_c(VForm,{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingData),expression:"!loadingData"}]},[_c(VTextField,{attrs:{"label":"Summary","rules":[(v) => !!v || 'Please enter a summary.']},model:{value:(_vm.params.summary),callback:function ($$v) {_vm.$set(_vm.params, "summary", $$v)},expression:"params.summary"}}),_c('label',{staticClass:"control-label"},[_vm._v("Note")]),_c('div',[_c('ejs-richtexteditor',{ref:"noteObj",attrs:{"id":"note","name":"note","height":350,"toolbar-settings":_vm.toolbarSettings,"enable-auto-url":false},model:{value:(_vm.params.note),callback:function ($$v) {_vm.$set(_vm.params, "note", $$v)},expression:"params.note"}})],1),(_vm.params.file_obj && _vm.params.file_obj.url)?_c('div',{},[_c('a',{attrs:{"href":_vm.params.file_obj.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.params.file_obj.name))]),_c(VBtn,{staticClass:"mx-2 mt-2",attrs:{"small":"","color":"primary"},on:{"click":_vm.clearFile}},[_vm._v(" Clear ")])],1):_c(VFileInput,{attrs:{"label":"Upload File","show-size":""},model:{value:(_vm.params.file_obj),callback:function ($$v) {_vm.$set(_vm.params, "file_obj", $$v)},expression:"params.file_obj"}}),_c(VTextField,{attrs:{"type":"url","label":"URL Link","name":"link","maxlength":"200"},model:{value:(_vm.params.link),callback:function ($$v) {_vm.$set(_vm.params, "link", $$v)},expression:"params.link"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close-dialog', false)}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"color":"warning","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.noteId ? "Update" : "Save")+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }