<template>
  <v-dialog v-model="dialogVisible" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span>
          <v-tabs v-model="tab">
            <v-tab :disabled="caseList.length === 0">Choose Case</v-tab>
            <v-tab :disabled="!canAddCase">Create a new case</v-tab>
          </v-tabs>
        </span>
        <v-spacer />
        <v-btn icon @click="dialogVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text--primary">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <form v-if="caseList.length > 0" id="caseform" role="form" @submit.prevent="caseSubmit">
              <v-autocomplete
                v-model="selectedCase"
                auto-select-first
                :items="caseList"
                item-value="case_id"
                item-text="case_name"
              />
              <div>
                <v-btn color="primary" small type="submit" :disabled="formSubmitting">
                  Select Case
                </v-btn>
              </div>
            </form>
          </v-tab-item>
          <v-tab-item>
            <div v-if="canAddCase && categoryList.length > 0">
              <form role="form" @submit.prevent="caseAdd">
                <div class="form-group">
                  <div class="e-float-input">
                    <input
                      id="name"
                      v-model="caseData.name"
                      type="text"
                      name="name"
                      required="required"
                      data-msg-containerid="nameError"
                      aria-invalid="true"
                      aria-describedby="name-info"
                      class="e-error"
                      aria-required="true"
                    />
                    <span class="e-float-line" />
                    <label for="name" class="e-float-text">Name</label>
                  </div>

                  <div class="e-float-input">
                    <input
                      id="reason_opened"
                      v-model="caseData.reason_opened"
                      type="text"
                      name="reason_opened"
                      required="required"
                      data-msg-containerid="reasonError"
                      aria-invalid="true"
                      aria-describedby="reason-info"
                      class="e-error"
                      aria-required="true"
                    />
                    <span class="e-float-line" />
                    <label for="reason_opened" class="e-float-text">Reason Opened</label>
                  </div>

                  <div>
                    <v-autocomplete
                      v-model="caseData.category"
                      auto-select-first
                      label="Category"
                      :items="categoryList"
                      item-value="id"
                      item-text="name"
                      required="required"
                    />
                  </div>
                </div>
                <div>
                  <v-btn color="warning" small type="submit" :disabled="formSubmitting">
                    Add Case
                  </v-btn>
                </div>
              </form>
            </div>
            <div v-else>
              <div v-if="categoryList.length > 0" />
              <v-alert v-else class="mb-0" dense text type="error">
                Your account doesn't have any case categories defined yet.<br />To create a new
                case, please <a href="/case/category/list/">add a case category</a> first.
              </v-alert>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <div v-if="caseList.length === 0 && !canAddCase">
          <p>
            You can't perform this action. There are no cases available to add this item to, and you
            don't have permission to add new cases.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "pinia";

import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { useAuthStore } from "@/stores/auth";

export default {
  name: "ChooseCase",
  data() {
    return {
      dialogVisible: false,
      tab: 0,
      caseList: [],
      categoryList: [],
      selectedCase: {},
      selectedItems: [],
      caseData: {
        name: "",
        reason_opened: "",
        category: null,
      },
      callbackFn: null,
      formSubmitting: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canAddCase"]),
  },
  mounted() {
    EventBus.$on("caseSelect", this.handleCaseSelect);
  },
  beforeDestroy() {
    EventBus.$off("caseSelect", this.handleCaseSelect);
  },
  methods: {
    handleCaseSelect({ detail: [items, callbackFn] }) {
      this.selectedItems = items;
      this.callbackFn = callbackFn;
      this.getCaseList();
      this.getCategoryList();
    },
    async getCaseList() {
      try {
        const r = await caseRepository.caseList({
          take: "all",
          fingerprint_firm: this.selectedItems?.[0]?.fingerprint_firm,
        });
        this.caseList = r.data
          .filter((obj) => !obj.is_deleted && obj.case_status === 1)
          .map((obj) => ({
            case_id: obj.id,
            case_name: obj.name,
          }));
        if (this.caseList.length) {
          this.selectedCase = this.caseList[0].case_id;
          this.tab = 0;
        } else {
          this.tab = 1;
        }
        this.dialogVisible = true;
      } catch (error) {
        console.error("getCaseList error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async getCategoryList() {
      try {
        const r = await caseRepository.caseCategoryList({
          take: "all",
        });
        this.categoryList = r.data.filter((obj) => !obj.is_deleted);
      } catch (error) {
        console.error("getCategoryList error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async caseSubmit() {
      this.formSubmitting = true;
      try {
        this.dialogVisible = false;
        const selectedCaseObj = this.caseList.find((obj) => obj.case_id === this.selectedCase);
        if (typeof this.callbackFn === "function") await this.callbackFn(selectedCaseObj);
      } catch (error) {
        console.error("caseSubmit error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.formSubmitting = false;
      }
    },
    async caseAdd() {
      this.formSubmitting = true;
      try {
        const newCase = await caseRepository.caseSave({
          ...this.caseData,
          fingerprint_firm: this.selectedItems?.[0]?.fingerprint_firm,
        });
        this.caseData = {};
        this.dialogVisible = false;
        if (typeof this.callbackFn === "function")
          await this.callbackFn({ case_id: newCase.data.id, case_name: newCase.data.name });
      } catch (error) {
        console.error("caseAdd error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.formSubmitting = false;
      }
    },
  },
};
</script>
