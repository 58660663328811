<template>
  <div class="mb-2">
    <v-tooltip bottom v-if="canAddCaseItem && canShowAddButton">
      <template #activator="{ on, attrs }">
        <v-btn class="ma-1" color="secondary" v-bind="attrs" v-on="on" @click="addItemsDialog">
          <v-icon left>mdi-plus</v-icon>
          Batch Add
        </v-btn>
      </template>
      <span>Add selected items to a case</span>
    </v-tooltip>
    <v-tooltip bottom v-if="canAddTriageItem">
      <template #activator="{ on, attrs }">
        <v-btn class="ma-1" color="warning" v-bind="attrs" v-on="on" @click="addTriageItemsDialog">
          <v-icon left>mdi-content-copy</v-icon>
          Batch Copy
        </v-btn>
      </template>
      <span>Copy selected items to the Triage List</span>
    </v-tooltip>
    <span v-if="!canShowAddButton" class="mx-2">
      Only items with the same Client can be added to a case.
    </span>
  </div>
</template>

<script>
import { mapState } from "pinia";
import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { useAuthStore } from "@/stores/auth";
import { getPayload } from "../utils";

export default {
  name: "BatchActionsSearch",
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canAddCaseItem", "canAddTriageItem"]),
    canShowAddButton() {
      const firstRowClient = this.selectedRows[0]?.fingerprint_client;
      return this.selectedRows.every(
        (selectedRow) => selectedRow.fingerprint_client === firstRowClient,
      );
    },
  },
  methods: {
    async addItemsDialog() {
      EventBus.$emit("caseSelect", this.selectedRows, (caseObj) => this.addTriageItems(caseObj));
    },
    addTriageItemsDialog() {
      EventBus.$emit("riskBucketSelect", [this.data], (riskBucket) =>
        this.addTriageItems(null, riskBucket),
      );
    },
    async addTriageItems(caseObj, riskBucket) {
      for (const row of this.selectedRows) {
        const payload = getPayload(row, caseObj);
        payload.search_params = this.$route.query;
        if (riskBucket) {
          payload.target_bucket = riskBucket;
        }
        try {
          await caseRepository.todoItemAdd(payload);
          EventBus.$emit("notify", "success", "Triage Item Added");
        } catch (error) {
          console.error("addTriageItems error", error);
          EventBus.$emit("notify", "warn", error);
        }
      }
      EventBus.$emit("loadData");
    },
  },
};
</script>
