<template>
  <div>
    <PageFrame>
      <template #title>Cases</template>

      <v-row no-gutters align="center" class="mb-2">
        <v-btn text :to="{ name: 'CaseList', params: { status: '1' } }">
          <v-icon small left>mdi-folder-open-outline</v-icon>
          Open Cases
        </v-btn>
        <v-btn text :to="{ name: 'CaseList', params: { status: '2' } }">
          <v-icon small left>mdi-folder-outline</v-icon>
          Closed Cases
        </v-btn>

        <v-checkbox
          v-model="includeDeleted"
          class="mx-2 mt-0 pt-0"
          label="Include deleted items"
          @change="handleDataStateChange()"
          hide-details
        />
        <v-progress-circular v-if="showLoading" indeterminate size="24" />
      </v-row>

      <BasicList
        v-if="columns.length"
        :columns="columns"
        :data-source="dataSource"
        :grid-params="{
          allowPaging: true,
          pageSettings: pageSettings,
          allowSorting: true,
          filterSettings: {
            type: 'Menu',
          },
          allowResizing: true,
          autoFitColumns: true,
          selectionSettings: { enableToggle: false },
          allowEditing: true,
          editSettings: editSettings,
          toolbar: ['Add', 'Edit', 'ExcelExport', 'CsvExport', 'Print'],
          gridLines: 'Both',
          dataStateChange: handleDataStateChange,
          actionBegin: handleActionBegin, // used for saving data
          actionComplete: handleActionComplete, // used for resizing edit dialog
          loadingIndicator: {
            indicatorType: 'Shimmer',
          },
        }"
      />
    </PageFrame>
  </div>
</template>

<script>
import { DataManager } from "@syncfusion/ej2-data";
import BasicList from "@/components/common/BasicList.vue";
import PageFrame from "@/components/PageFrame.vue";
import { mediumDateTimeFormat } from "@/filters";
import caseRepository from "@/repositories/case";
import { getFirms } from "@/repositories/firm";
import { useAuthStore } from "@/stores/auth";
import EventBus from "@/eventBus";
import { ejsGridArgsToQueryParams } from "@/utils";
import CaseActions from "./columns/CaseActions.vue";

export default {
  name: "SuspiciousTermList",
  components: { BasicList, PageFrame },
  props: {
    status: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      showLoading: false,
      lastFilterArgs: {},
      includeDeleted: false,
      columns: [],
      lastState: {},
      dataSource: { result: [], count: 0 },
      pageSettings: {
        currentPage: 1,
        pageSize: 20,
        enableQueryString: false,
        pageSizes: [10, 20, 50, 100, "All"],
      },
      editSettings: {
        allowAdding: true,
        allowEditing: true,
        allowDeleting: false,
        mode: "Dialog",
        showDeleteConfirmDialog: true,
      },
    };
  },
  watch: {
    status() {
      this.handleDataStateChange();
    },
  },
  async mounted() {
    let authStore = useAuthStore();
    let caseCategories = [];
    let firms = [];
    try {
      const { data } = await caseRepository.caseCategoryList({ take: "all" });
      caseCategories = data;
      firms = await getFirms();
    } catch (error) {
      EventBus.$emit("notify", "warn", error);
    }
    this.columns = [
      {
        field: "actions",
        headerText: "",
        allowEditing: false,
        allowSorting: false,
        template: () => ({
          template: CaseActions,
        }),
      },
      {
        field: "id",
        headerText: "ID",
        isPrimaryKey: true,
        visible: false,
      },
      {
        field: "name",
        headerText: "Case Name",
        validationRules: { required: true },
      },
      {
        field: "fingerprint_firm",
        headerText: "Firm",
        dataSource: new DataManager(firms),
        foreignKeyField: "id",
        foreignKeyValue: "firm_name",
        validationRules: { required: true },
      },
      {
        visible: false,
        field: "reason_opened",
        headerText: "Reason Opened",
        validationRules: { required: true },
      },
      {
        field: "open_on",
        headerText: "Date Open",
        valueAccessor: (_, data) => mediumDateTimeFormat(data.open_on),
        allowEditing: false,
      },
      {
        field: "category",
        headerText: "Category",
        dataSource: new DataManager(caseCategories),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        validationRules: { required: true },
      },
      {
        field: "created_by.full_name",
        headerText: "Created By",
        allowEditing: false,
        allowSorting: false,
      },
      {
        field: "current_action.started",
        headerText: "Last Action",
        valueAccessor: (_, data) => mediumDateTimeFormat(data.open_on),
        allowEditing: false,
        allowSorting: false,
      },
      {
        field: "open_for",
        headerText: "Open For",
        allowEditing: false,
        allowSorting: false,
      },
    ];
    this.editSettings = {
      ...this.editSettings,
      allowAdding: authStore.canAddCase,
    };
    this.handleDataStateChange();
  },
  methods: {
    async handleDataStateChange(args) {
      if (args) {
        this.lastFilterArgs = args;
      }
      const state = ejsGridArgsToQueryParams(this.lastFilterArgs);
      const { data } = await caseRepository.caseList({
        ...state,
        case_status: parseInt(this.status, 10) || 1,
        is_deleted: this.includeDeleted ? undefined : false,
        source: "user",
      });
      this.dataSource = { count: data.count, result: data.results };
    },
    async handleActionBegin(args) {
      // show/hide columns in edit dialog
      if (args.requestType == "beginEdit" || args.requestType == "add") {
        args._innerGrid.getColumns()[0].visible = false; // actions
        args._innerGrid.getColumns()[3].visible = args.requestType == "add"; // fingerprint_firm
        args._innerGrid.getColumns()[4].visible = true; // reason_opened
        args._innerGrid.getColumns()[5].visible = false; // opened_on
        args._innerGrid.getColumns()[7].visible = false; // created_by.full_name
        args._innerGrid.getColumns()[8].visible = false; // current_action.started
        args._innerGrid.getColumns()[9].visible = false; // open_for
      }
      if (args.requestType == "save" || args.requestType == "cancel") {
        args._innerGrid.getColumns()[0].visible = true; // actions
        args._innerGrid.getColumns()[4].visible = false; // reason_opened
        args._innerGrid.getColumns()[5].visible = true; // opened_on
        args._innerGrid.getColumns()[7].visible = true; // created_by.full_name
        args._innerGrid.getColumns()[8].visible = true; // current_action.started
        args._innerGrid.getColumns()[9].visible = true; // open_for
      }

      if (args.requestType === "save" && ["add", "edit"].includes(args.action)) {
        args.cancel = true;
        await caseRepository.caseSave(
          {
            name: args.data.name,
            fingerprint_firm: args.data.fingerprint_firm,
            category: args.data.category,
            reason_opened: args.data.reason_opened,
          },
          args.data.id,
        );
        args.dialog && args.dialog.close();
        await this.handleDataStateChange(args.state);
      }
    },
    handleActionComplete(args) {
      // check for add/edit action and resizes the dialog width
      if (["add", "beginEdit"].includes(args.requestType) && args.dialog) {
        args.dialog.width = "85%";
      }
    },
  },
};
</script>
