<template>
  <div>
    <v-row no-gutters>
      <h3>Triage Totals</h3>
    </v-row>
    <v-row>
      <v-col>
        <MetricBox
          title="Triage: Pending"
          :show-accordion="!!Object.keys(todoStatsBySearchNotActioned || {}).length"
        >
          <template #metric>
            <Metric :val="todoStatsNotActioned ? todoStatsNotActioned.todo_total : 'N/A'" />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="todoStatsBySearchNotActioned || null"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Triage: Actioned"
          :show-accordion="!!Object.keys(todoStatsBySearchActioned || {}).length"
        >
          <template #metric>
            <Metric :val="todoStatsActioned ? todoStatsActioned.todo_total : 'N/A'" />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="todoStatsBySearchActioned || null"
              :field-name="'todo_total'"
            />
          </template>
        </MetricBox>
      </v-col>

      <v-col>
        <MetricBox
          title="Avg Time to Action"
          :show-accordion="!!Object.keys(todoStatsBySearchActioned || {}).length"
        >
          <template #metric>
            <Metric
              :val="todoStatsActioned ? parseInt(todoStatsActioned.actioned_avg) + ' hours' : 'N/A'"
            />
          </template>
          <template #metric-detail>
            <MetricList
              title="by Risk Categ."
              :period-data="todoStatsBySearchActioned || null"
              :field-name="'actioned_avg'"
            />
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";
import Metric from "@/components/metrics/Metric.vue";
import MetricList from "@/components/metrics/MetricList.vue";

export default {
  name: "TodosMetrics",
  components: {
    Metric,
    MetricBox,
    MetricList,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    todoStatsActioned() {
      return this.jsonData?.TodoStatsActioned?.all?.True;
    },
    todoStatsNotActioned() {
      return this.jsonData?.TodoStatsNotActioned?.all?.False;
    },
    todoStatsBySearchActioned() {
      return this.jsonData?.TodoStatsBySearchActioned?.all?.True;
    },
    todoStatsBySearchNotActioned() {
      return this.jsonData?.TodoStatsBySearchNotActioned?.all?.False;
    },
  },
};
</script>
