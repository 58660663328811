<script setup>
import { computed } from "vue";
import { getUsefulHighlights } from "@/components/datatable/utils";
import { fullDateTimeFormat } from "@/filters";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  dataType: {
    type: Object,
    required: true,
  },
  canShowConversationSearch: {
    type: Boolean,
  },
  canAddCaseNote: {
    type: Boolean,
    default: false,
  },
  canDeleteTodoItem: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  "openDocument",
  "toggleHide",
  "searchConversations",
  "addNote",
  "showNotes",
  "remove",
]);

const highlight = computed(() => {
  return getUsefulHighlights(props.item.highlight);
});

function truncate(str, n, useWordBoundary) {
  if (!str || str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return (
    (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) +
    " ... (hover to see more) ..."
  );
}
</script>

<template>
  <v-card :color="item.is_hidden ? 'grey lighten-2' : undefined">
    <v-card-text class="text--primary">
      <v-row v-if="item.is_hidden" align="center">
        <v-col cols="5" md="5" sm="12" class="font-weight-bold">
          {{ fullDateTimeFormat(props.item.fingerprint_time) }}
        </v-col>
        <v-col cols="5" md="5" sm="12">
          <div v-if="props.item.fingerprint_type">
            <span class="font-weight-bold"> Type: </span>
            <span>
              {{ dataType.name }}
            </span>
          </div>
        </v-col>
        <v-col cols="2" md="2" sm="12" class="text-right">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                class="ma-1"
                small
                v-bind="attrs"
                v-on="on"
                @click="emit('toggleHide', !item.is_hidden)"
              >
                <v-icon left>
                  {{ item.is_hidden ? "mdi-eye" : "mdi-eye-off" }}
                </v-icon>
                {{ item.is_hidden ? "Show" : "Hide" }}
              </v-btn>
            </template>
            <span v-if="item.is_hidden">Show this item in the timeline</span>
            <span v-else>Hide this item from the timeline</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="6" md="6" sm="12">
          <h4>
            {{ fullDateTimeFormat(props.item.fingerprint_time) }}
          </h4>

          <div v-if="props.item.current_risk_bucket">
            <span class="font-weight-bold"> Risk Bucket: </span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ props.item.current_risk_bucket }}</span>
              </template>
              <span>
                <h3>Risk rank score calculation</h3>
                <ul>
                  <li v-for="exp of props.item.risk_rank_explain" :key="exp">
                    {{ exp }}
                  </li>
                </ul>
              </span>
            </v-tooltip>
          </div>
          <div v-if="props.item.id">
            <span class="font-weight-bold"> ID: </span>
            <span>
              {{ props.item.id }}
            </span>
          </div>
          <div v-if="props.item.fingerprint_type">
            <span class="font-weight-bold"> Type: </span>
            <span>
              {{ dataType.name }}
            </span>
          </div>
          <div v-if="props.item.to">
            <span class="font-weight-bold"> To: </span>
            <span v-obfuscate :title="props.item.to">
              {{ truncate(props.item.to, 100, true) }}
            </span>
          </div>
          <div v-if="props.item.from_person">
            <span class="font-weight-bold"> From: </span>
            <span v-obfuscate :title="props.item.from_person">
              {{ truncate(props.item.from_person, 100, true) }}
            </span>
          </div>
          <div v-if="props.item.cc">
            <span class="font-weight-bold"> CC: </span>
            <span v-obfuscate :title="props.item.cc">
              {{ truncate(props.item.cc, 100, true) }}
            </span>
          </div>
          <div v-if="props.item.bcc">
            <span class="font-weight-bold"> BCC: </span>
            <span v-obfuscate :title="props.item.bcc">
              {{ truncate(props.item.bcc, 100, true) }}
            </span>
          </div>
          <div v-if="props.item.subject">
            <span class="font-weight-bold"> Subject: </span>
            <span v-obfuscate :title="props.item.subject">
              {{ truncate(props.item.subject, 100, true) }}
            </span>
          </div>
          <div v-if="props.item.score">
            <span class="font-weight-bold"> Score: </span>
            <span>
              {{ props.item.score.toFixed(2) }}
            </span>
          </div>
          <div v-if="props.item.search_params_names">
            <span class="font-weight-bold"> Searches: </span>
            <ul>
              <li
                v-for="(search, index) in props.item.search_params_names.split('|||')"
                :key="index"
              >
                {{ search }}
              </li>
            </ul>
          </div>
        </v-col>

        <v-col cols="6" md="6" sm="12">
          <span v-for="(d, index) in highlight" :key="index">
            <u>Matches for {{ index.split(".")[0] }}</u
            >:
            <ol>
              <li v-for="(match, index2) in d" :key="index2">
                <!-- eslint-disable vue/no-v-html -->
                <span v-obfuscate v-html="match" />
              </li>
            </ol>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!item.is_hidden">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
            @click="emit('openDocument')"
          >
            <v-icon left> mdi-file-document-outline </v-icon>
            Open document
          </v-btn>
        </template>
        <span>Open this document for preview</span>
      </v-tooltip>
      <v-tooltip v-if="props.canAddCaseNote && item.notesCount" bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="ma-1" small v-bind="attrs" v-on="on" @click="emit('showNotes')">
            <v-icon left> mdi-comment-processing-outline </v-icon>
            {{ item.notesCount }} note{{ item.notesCount > 1 ? "s" : "" }}
          </v-btn>
        </template>
        <span>Show/manage notes for this item</span>
      </v-tooltip>
      <v-tooltip v-if="props.canAddCaseNote && !item.notesCount" bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="ma-1" small v-bind="attrs" v-on="on" @click="emit('addNote')">
            <v-icon left> mdi-comment-plus-outline </v-icon>
            Add note
          </v-btn>
        </template>
        <span>Add a note to this item</span>
      </v-tooltip>

      <v-spacer />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            small
            v-bind="attrs"
            v-on="on"
            @click="emit('toggleHide', !item.is_hidden)"
          >
            <v-icon left>
              {{ item.is_hidden ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
            {{ item.is_hidden ? "Show" : "Hide" }}
          </v-btn>
        </template>
        <span v-if="item.is_hidden">Show this item in the timeline</span>
        <span v-else>Hide this item from the timeline</span>
      </v-tooltip>

      <v-tooltip v-if="canDeleteTodoItem" bottom>
        <template #activator="{ on, attrs }">
          <v-btn class="ma-1" color="error" small v-bind="attrs" v-on="on" @click="emit('remove')">
            <v-icon left>mdi-minus</v-icon>
            Remove
          </v-btn>
        </template>
        <span>Remove this item from the case</span>
      </v-tooltip>

      <v-tooltip v-if="props.canShowConversationSearch" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            color="secondary"
            small
            v-bind="attrs"
            v-on="on"
            @click="emit('searchConversations')"
          >
            <v-icon left> mdi-comment-search-outline </v-icon>
            Search conversations
          </v-btn>
        </template>
        <span>Search for conversations related to this item</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
