"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPayload = getPayload;
exports.getUsefulHighlights = getUsefulHighlights;
exports.replaceSpecialUnicodeChars = replaceSpecialUnicodeChars;
exports.getColumnsVisibility = getColumnsVisibility;
exports.restoreColumnsVisibility = restoreColumnsVisibility;
function getPayload(data, selectedCase) {
    if (selectedCase === void 0) { selectedCase = null; }
    return {
        document: {
            id: data.id,
            fingerprint_client: data.fingerprint_client,
            fingerprint_type: data.fingerprint_type,
            fingerprint_time: data.fingerprint_time,
            subject: data.subject,
            from_person: data.from_person,
            from_orig: data.from_orig,
            to: data.to,
            cc: data.cc,
            bcc: data.bcc,
            greeting: data.greeting,
            highlight: data.highlight,
            fingerprint_schema: data.fingerprint_schema,
            schema_valid: data.schema_valid,
        },
        es_id: data.id,
        score: data.score,
        highlight: data.highlight,
        case: (selectedCase === null || selectedCase === void 0 ? void 0 : selectedCase.case_id) || (selectedCase === null || selectedCase === void 0 ? void 0 : selectedCase.id) || "",
        case_name: (selectedCase === null || selectedCase === void 0 ? void 0 : selectedCase.case_name) || (selectedCase === null || selectedCase === void 0 ? void 0 : selectedCase.name) || "",
        todo_item: data.django_id,
    };
}
function getUsefulHighlights(highlightsObject) {
    return Object.entries(highlightsObject || {})
        .filter(function (_a) {
        var key = _a[0];
        return key.indexOf("fingerprint") === -1 && key.indexOf("Thread-Topic") === -1;
    })
        .reduce(function (acc, _a) {
        var key = _a[0], value = _a[1];
        if (key.indexOf("body") !== -1) {
            if (key === "body" || !("body" in acc)) {
                acc.body = value;
            }
        }
        else {
            acc[key] = value;
        }
        return acc;
    }, {});
}
// replace special unicode characters with ascii equivalents
function replaceSpecialUnicodeChars(str) {
    return str
        .replace(/\u200B/g, " ")
        .replace(/\u2013/g, "-")
        .replace(/\u2014/g, "-")
        .replace(/\u2018/g, "'")
        .replace(/\u2019/g, "'")
        .replace(/\u201C/g, '"')
        .replace(/\u201D/g, '"');
}
function getColumnsVisibility(columns) {
    return columns.map(function (column, index) { return ({ index: index, visible: column.visible }); });
}
function restoreColumnsVisibility(columns, oldColumnsVisibility) {
    if (columns.length !== oldColumnsVisibility.length) {
        return columns;
    }
    columns.forEach(function (column, index) {
        column.visible = oldColumnsVisibility[index].visible;
    });
    return columns;
}
