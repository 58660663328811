"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var PageFrame_vue_1 = require("@/components/PageFrame.vue");
var administration_1 = require("@/repositories/administration");
exports.default = (0, vue_1.defineComponent)({
    __name: 'AdminWebsockets',
    setup: function (__props) {
        var testData = {
            source_name: "workflow",
            message: "Hello there",
            source_details: { case_id: "<case_id_here>" },
        };
        var testMessage = (0, vue_2.ref)(JSON.stringify(testData, null, 2));
        var sendSocketError = (0, vue_2.ref)("");
        function sendSocketMessage() {
            sendSocketError.value = "";
            try {
                var jsonMessage = JSON.parse(testMessage.value);
                (0, administration_1.sendMessage)(jsonMessage);
            }
            catch (error) {
                sendSocketError.value = "Error sending message: ".concat(error);
            }
        }
        return { __sfc: true, testData: testData, testMessage: testMessage, sendSocketError: sendSocketError, sendSocketMessage: sendSocketMessage, PageFrame: PageFrame_vue_1.default };
    }
});
