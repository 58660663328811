import httpClient from "@/interceptors/axios";
import { withFirmGroup } from "./utils";

export function getFieldHistory(model, id, field, take = 10, page = 1) {
  const modelPath = {
    report: "report",
    item: "item",
  }[model];
  return httpClient.get(`reporting/${modelPath}/${id}/field-history/${field}/`, {
    params: { take, page },
  });
}

export async function getReportSettingsLogoBase64(reportSettingsId) {
  try {
    const { data } = await httpClient.get(`reporting/config/${reportSettingsId}/logo_base64/`);
    return data;
  } catch {
    return null;
  }
}

export function publishReport(reportId, file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return httpClient.post(`reporting/report/${reportId}/publish/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function unpublishReport(reportId) {
  return httpClient.get(`reporting/report/${reportId}/unpublish/`);
}

export function triggerSchedule(id) {
  return httpClient.post(`reporting/schedule/${id}/create_report/`);
}

export function createReportingSchedule(schedule) {
  return httpClient.post("reporting/schedule/", withFirmGroup(schedule));
}

export function updateReportingSchedule(schedule) {
  return httpClient.put(`reporting/schedule/${schedule.id}/`, schedule);
}

export function deleteReportingSchedule(id) {
  return httpClient.delete(`reporting/schedule/${id}/`);
}

export function patchReportingSchedule(schedule) {
  return httpClient.patch(`reporting/schedule/${schedule.id}/`, schedule);
}

export function patchReportingReport(report) {
  return httpClient.patch(`reporting/report/${report.id}/`, report);
}

export function patchReportingReportItem(reportItem) {
  return httpClient.patch(`reporting/item/${reportItem.id}/`, reportItem);
}

export function getReportingReport(id) {
  return httpClient.get(`reporting/report/${id}/`, {
    params: {
      expand: "items",
    },
  });
}

export function getReportingReports(params = {}) {
  return httpClient.get("reporting/report/", { params: withFirmGroup(params) });
}

export function getReportingSchedule(id) {
  return httpClient.get(`reporting/schedule/${id}/`);
}

export function getReportingSchedules(params = {}) {
  return httpClient.get("reporting/schedule/", {
    params: {
      ...withFirmGroup(params),
      take: "all",
    },
  });
}

export function getReportingSettings(params) {
  return httpClient.get("reporting/config/", { params: withFirmGroup({ ...params, take: "all" }) });
}

export function saveReportingSettings(settings) {
  const httpClientFn = settings.id ? httpClient.patch : httpClient.post;
  const url = "reporting/config/" + (settings.id ? `${settings.id}/` : "");
  const formData = new FormData();
  Object.entries(withFirmGroup(settings))
    .filter(([key, value]) => key != "logo" && value !== undefined)
    .forEach(([key, value]) => formData.append(key, value));
  if ("logo" in settings) {
    if ([null, ""].includes(settings.logo)) {
      formData.append("logo", ""); // empty strings to remove logo
    } else if (settings.logo) {
      formData.append("logo", settings.logo);
    }
  }
  return httpClientFn(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getReportingTemplates(params = {}) {
  return httpClient.get("reporting/template/", {
    params: {
      ...withFirmGroup(params),
      take: "all",
    },
  });
}

export function getReportDistributionLists(params = {}) {
  return httpClient.get("reporting/distribution/", {
    params: {
      ...withFirmGroup(params),
      take: "all",
    },
  });
}

export function getReportDistributionList(id) {
  return httpClient.get(`reporting/distribution/${id}/`);
}

export function saveReportDistributionList(distributionList) {
  const httpClientFn = distributionList.id ? httpClient.patch : httpClient.post;
  const url = "reporting/distribution/" + (distributionList.id ? `${distributionList.id}/` : "");
  return httpClientFn(url, distributionList);
}

export function deleteReportDistributionLists(id) {
  return httpClient.delete(`reporting/distribution/${id}/`);
}
