<template>
  <div>
    <ChooseCase />
    <ChooseRiskBucket />
    <SetIgnoreReason />
    <AskForReason
      v-model="showAskForReason"
      @ok="handleAskForReasonSubmit"
      @cancel="showAskForReason = false"
    />

    <div v-if="selectedRows.length > 0 && batchActions">
      <component :is="batchActions" :selected-rows="selectedRows" :show-count="showCountColumn" />
    </div>

    <v-alert
      v-if="dataCapped.is_capped"
      class="mb-0"
      dense
      dismissible
      icon="mdi-alert"
      text
      type="warning"
    >
      Note: {{ dataCapped.hits }} records were returned but capped at {{ dataCapped.limit }}.
    </v-alert>

    <div v-if="loadingOK">
      <ejs-grid
        :id="gridId"
        ref="dataGrid"
        :loading-indicator="{ indicatorType: 'Shimmer' }"
        :data-source="innerDataSource"
        :data-state-change="loadData"
        grid-lines="Both"
        :allow-text-wrap="true"
        :allow-paging="true"
        :allow-sorting="!disableSort"
        :allow-excel-export="true"
        :toolbar="showToolbar && toolbar"
        :toolbar-click="toolbarClick"
        :allow-selection="true"
        :selection-settings="selectionOptions"
        :row-selected="rowSelected"
        :row-deselected="rowSelected"
        :page-settings="pageSettings"
        :query-cell-info="customiseCell"
        :excel-query-cell-info="exportQueryCellInfo"
        :show-column-chooser="true"
        :action-complete="handleActionComplete"
      >
        <e-columns>
          <e-column v-for="(column, key) in columns" :key="key" v-bind="column" />
        </e-columns>
        <template v-slot:matchesColumnTemplate="{ data }">
          <MatchesColumn :data="data" />
        </template>
        <template v-slot:riskBucketColumnTemplate="{ data }">
          <RiskBucket :data="data" />
        </template>
      </ejs-grid>
      <slot name="footer-count" :count="innerDataSource.count"></slot>
    </div>
    <div v-else class="text-center pa-5">
      Loading data...<br />
      <v-progress-circular class="ma-5" indeterminate color="primary" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { L10n } from "@syncfusion/ej2-base";
import { DataManager } from "@syncfusion/ej2-data";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  ColumnChooser,
  ForeignKey,
  ExcelExport,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-grids";
import AskForReason from "@/components/common/popups/AskForReason.vue";
import { getUsefulHighlights } from "@/components/datatable/utils";
import { composeRelativeDateTimeFormat, fullDateTimeFormat } from "@/filters";
import { obfuscate } from "@/plugins/obfuscate";
import { getFirms } from "@/repositories/firm";
import { logExportTable } from "@/repositories/logs";
import { getDataTypes, getLanguages } from "@/repositories/settings";
import { useAuthStore } from "@/stores/auth";
import { useCasesStore } from "@/stores/cases";
import Summary from "./columns/Summary.vue";
import RiskBucket from "./columns/RiskBucket.vue";
import MatchesColumn from "./columns/MatchesColumn.vue";
import SavedCases from "./columns/SavedCases.vue";
import ChooseCase from "./popups/ChooseCase.vue";
import ChooseRiskBucket from "./popups/ChooseRiskBucket.vue";
import SetIgnoreReason from "./popups/SetIgnoreReason.vue";
import EventBus from "@/eventBus";
import { shortDateTimeFormat } from "@/filters";
import {
  getColumnsVisibility,
  restoreColumnsVisibility,
  replaceSpecialUnicodeChars,
} from "./utils";

const regexB = /(<([/?b^>]+)>)/gi;
const regex = /(<([^>]+)>)/gi;

export default {
  name: "DataTable",
  components: {
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
    ChooseCase,
    ChooseRiskBucket,
    SetIgnoreReason,
    AskForReason,
    MatchesColumn,
    RiskBucket,
  },
  inject: ["actions", "batchActions"],
  provide: {
    grid: [ColumnChooser, ForeignKey, ExcelExport, Page, Sort, Toolbar],
  },
  props: {
    dataFunction: {
      type: Function,
    },
    gridId: {
      type: String,
      default: "DataTable",
      required: true,
    },
    dataSource: {
      type: Object,
      default: undefined,
    },
    hideCheckboxSelection: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showCreated: Boolean,
    showRiskBucket: {
      type: Boolean,
      default: false,
    },
    hideScoreColumn: {
      type: Boolean,
      default: false,
    },
    showActionReason: {
      type: Boolean,
      default: false,
    },
    actionReasonCategDataSource: {
      type: Array,
      default: () => [],
    },
    showModifiedOnColumn: {
      type: Boolean,
      default: false,
    },
    showSuspTermColumns: {
      type: Boolean,
      default: false,
    },
    suspTermCategDataSource: {
      type: Array,
      default: () => [],
    },
    showSavedCasesColumn: {
      type: Boolean,
      default: false,
    },
    defaultPageSize: {
      type: [Number, String],
      default: 20,
    },
    defaultPageSizes: {
      type: Array,
      default: () => ["10", "20", "50", "100", "200"],
    },
    defaultGetParams: {
      type: Object,
      default: () => ({}),
    },
    defaultOrdering: {
      type: String,
      default: "current_risk_bucket,-score",
    },
    firmsDataSource: {
      type: Array,
      default: () => null,
    },
    firmField: {
      type: String,
      default: "fingerprint_firm",
    },
    firmForeighKeyField: {
      type: String,
      default: "id",
    },
    hideId2Column: {
      type: Boolean,
      default: false,
    },
    hideSearchesColumn: {
      type: Boolean,
      default: false,
    },
    hideModifiedColumns: {
      type: Boolean,
      default: false,
    },
    enableFingerprintTimeSort: {
      type: Boolean,
      default: false,
    },
    showCountColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAskForReason: false,
      askForReasonType: false,
      handleAskForReasonSubmitInnerFn: async () => {},
      languages: [],
      dataTypes: [],
      gridState: {},
      loadingOK: false,
      pageTitle: null,
      searchUrl: null,
      backUrl: null,
      pageSettings: {
        currentPage: 1,
        pageSize: 20,
        enableQueryString: false,
        pageSizes: ["10", "20", "50", "100"],
      },
      toolbar: ["Print", "ExcelExport", "CsvExport", "ColumnChooser"],
      selectionOptions: { checkboxOnly: true, enableSimpleMultiRowSelection: true },
      selectedRows: [],
      dataReturned: {},
      innerDataSource: {},
      dataCapped: { is_capped: false },
      columns: [],
      lastPageState: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["firmLabelObject", "dataTableVisibleColumns"]),
    ...mapState(useCasesStore, ["riskBucketList"]),
  },
  async mounted() {
    this.gridState = { ordering: this.defaultOrdering };
    this.pageSettings = {
      ...this.pageSettings,
      pageSize: this.defaultPageSize,
      pageSizes: this.defaultPageSizes,
    };

    const [languages, dataTypes] = await Promise.all([getLanguages(), getDataTypes()]);
    this.languages = languages;
    this.dataTypes = dataTypes;
    EventBus.$on("gridUpdate", this.refreshDataSource);
    EventBus.$on("loadData", this.handleLoadDataEmitter);
    EventBus.$on("setGridParam", this.handleSetGridParam);

    this.setTableMessage("Searching ...");

    const storedVisibleColumns = this.dataTableVisibleColumns[this.gridId] || {};
    function visibleSetOrDefault(visibleSetting, defaultIfNotSet) {
      return visibleSetting === undefined ? defaultIfNotSet : visibleSetting;
    }
    this.columns = [
      {
        type: "checkbox",
        width: 50,
        visible: !this.hideCheckboxSelection,
      },
      {
        field: "actions",
        headerText: "Actions",
        width: 150,
        textAlign: "Center",
        visible: !!this.actions,
        allowSorting: false,
        template:
          this.actions &&
          (() => ({
            template: {
              extends: this.actions,
              propsData: { showCount: this.showCountColumn },
            },
          })),
        type: "string",
        format: undefined,
        customAttributes: { class: "column-actions" },
        showInColumnChooser: false,
      },
      {
        field: "id",
        headerText: "ID",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.id, false),
        allowSorting: true,
        type: "string",
      },
      {
        field: this.firmField,
        headerText: this.firmLabelObject.singularUppercase,
        width: 125,
        visible: visibleSetOrDefault(storedVisibleColumns.fingerprint_firm, false),
        allowSorting: false,
        type: "string",
        dataSource: new DataManager(this.firmsDataSource || (await getFirms())),
        foreignKeyField: this.firmForeighKeyField,
        foreignKeyValue: "firm_name",
      },
      {
        field: "fingerprint_type",
        headerText: "Type",
        width: 125,
        visible: visibleSetOrDefault(storedVisibleColumns.fingerprint_type, false),
        allowSorting: true,
        type: "string",
        dataSource: new DataManager(this.dataTypes),
        foreignKeyField: "id",
        foreignKeyValue: "name",
      },
      {
        field: "summary",
        headerText: "Summary",
        width: 300,
        visible: visibleSetOrDefault(storedVisibleColumns.summary, true),
        allowSorting: false,
        template: () => ({ template: Summary }),
        type: "string",
      },
      {
        field: "highlight",
        headerText: "Matches",
        width: 350,
        visible: visibleSetOrDefault(storedVisibleColumns.highlight, true),
        allowSorting: false,
        template: "matchesColumnTemplate",
      },
      {
        _custom_hide: !this.showRiskBucket,
        field: "current_risk_bucket",
        headerText: "Risk Bucket",
        width: "150",
        visible: visibleSetOrDefault(storedVisibleColumns.current_risk_bucket, this.showRiskBucket),
        allowSorting: true,
        template: "riskBucketColumnTemplate",
        type: "number",
      },
      {
        _custom_hide: this.hideScoreColumn,
        field: "score",
        headerText: "Score",
        width: 125,
        visible: visibleSetOrDefault(
          storedVisibleColumns.score,
          !this.showRiskBucket && !this.hideScoreColumn,
        ),
        allowSorting: true,
        valueAccessor: (field, data) => data[field] && data[field].toFixed(2),
        type: "number",
      },
      {
        _custom_hide: !this.showSuspTermColumns,
        field: "suspicious_term.suspicious_term_category_id",
        headerText: "Risk Category",
        width: 175,
        visible: visibleSetOrDefault(
          storedVisibleColumns["suspicious_term.suspicious_term_category_id"],
          true,
        ),
        allowSorting: false,
        dataSource: new DataManager(this.suspTermCategDataSource),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        type: "string",
      },
      {
        _custom_hide: !this.showSuspTermColumns,
        field: "suspicious_term.name",
        headerText: "Risk Term",
        width: 175,
        visible: visibleSetOrDefault(storedVisibleColumns["suspicious_term.name"], true),
        allowSorting: false,
        type: "string",
      },
      {
        field: "fingerprint_time",
        headerText: "Document Date",
        width: 175,
        visible: visibleSetOrDefault(storedVisibleColumns.fingerprint_time, false),
        allowSorting: this.enableFingerprintTimeSort,
        valueAccessor: (field, data) => shortDateTimeFormat(data[field]),
      },
      {
        _custom_hide: this.hideModifiedColumns,
        field: "modified_on",
        headerText: "Modified on",
        width: 175,
        visible: visibleSetOrDefault(storedVisibleColumns.modified_on, this.showModifiedOnColumn),
        allowSorting: false,
        valueAccessor: (field, data) => shortDateTimeFormat(data[field]),
      },
      {
        _custom_hide: this.hideModifiedColumns,
        field: "modified_by.full_name",
        headerText: "Modified by",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.full_name, this.showModifiedOnColumn),
        allowSorting: false,
        type: "string",
      },
      {
        _custom_hide: !this.showCreated,
        field: "created_on",
        headerText: "Created on",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.created_on, false),
        allowSorting: true,
        valueAccessor: (field, data) => shortDateTimeFormat(data[field]),
      },
      {
        _custom_hide: !this.showCreated,
        field: "created_by.full_name",
        headerText: "Created by",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns["created_by.full_name"], false),
        allowSorting: false,
        type: "string",
      },
      {
        _custom_hide: !this.showCreated,
        field: "created_on ",
        headerText: "Created Ago",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns["time_since_created"], false),
        allowSorting: true,
        valueAccessor: (_, data) =>
          data.created_on && composeRelativeDateTimeFormat(new Date() - new Date(data.created_on)),
      },
      {
        _custom_hide: !this.showSavedCasesColumn,
        field: "saved_cases",
        headerText: "Saved To Cases",
        width: 125,
        visible: visibleSetOrDefault(storedVisibleColumns.saved_cases, this.showSavedCasesColumn),
        allowSorting: false,
        template: () => ({ template: SavedCases }),
      },
      {
        _custom_hide: !this.showActionReason,
        field: "action_category",
        headerText: "Ignored Category",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.action_category, this.showActionReason),
        allowSorting: false,
        dataSource: new DataManager(this.actionReasonCategDataSource),
        foreignKeyField: "id",
        foreignKeyValue: "name",
        type: "string",
      },
      {
        _custom_hide: !this.showActionReason,
        field: "action_reason",
        headerText: "Reason Ignored",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.action_reason, false),
        allowSorting: false,
        type: "string",
      },
      {
        _custom_hide: this.hideId2Column,
        field: "django_id",
        headerText: "ID2",
        width: 225,
        visible: visibleSetOrDefault(storedVisibleColumns.django_id, false),
        allowSorting: false,
        type: "string",
      },
      {
        _custom_hide: this.hideSearchesColumn,
        field: "search_params_names",
        headerText: "Searches",
        width: 125,
        visible: visibleSetOrDefault(storedVisibleColumns.search_params_names, false),
        allowSorting: false,
        valueAccessor: (field, data) => data[field] && data[field].split("|||").join(", \n"),
        type: "string",
      },
    ].filter((e) => !e._custom_hide);

    await this.loadData();
    setTimeout(() => {
      this.loadingOK = true;
    }, 200);
  },
  beforeDestroy() {
    EventBus.$off("gridUpdate", this.refreshDataSource);
    EventBus.$off("loadData", this.handleLoadDataEmitter);
    EventBus.$off("setGridParam", this.handleSetGridParam);
  },
  methods: {
    ...mapActions(useAuthStore, ["updateDataTableVisibleColumns"]),
    handleSetGridParam({ detail: [dict] }) {
      const autoTriggerRefresh = this.pageSettings.currentPage !== 1;
      this.pageSettings.currentPage = 1;
      const oldGridState = JSON.stringify({ ...this.gridState });
      this.gridState = {
        ...this.gridState,
        ...dict,
      };
      const newGridState = JSON.stringify({ ...this.gridState });
      const manualTriggerRefresh = !autoTriggerRefresh && oldGridState !== newGridState;
      if (manualTriggerRefresh && this.$refs.dataGrid) {
        this.$refs.dataGrid.refresh();
      }
    },
    setCase() {
      EventBus.$emit("caseSelect");
    },
    handleLoadDataEmitter() {
      this.loadData(this.lastPageState, true);
    },
    async loadData(pageState, forceReload = false) {
      if (!forceReload && pageState && pageState?.action?.requestType === "stringfilterrequest") {
        return;
      }
      if (
        !forceReload &&
        pageState &&
        pageState?.action?.requestType === "refresh" &&
        pageState?.action?.name === "beforeFragAppend"
      ) {
        return;
      }
      let state = { ...this.$route.query, ...this.gridState };

      state.page = state.page ? state.page : 1;
      state.take = state.take ? state.take : this.defaultPageSize;
      state.ordering = state.ordering ? state.ordering : "";

      if (pageState) {
        if (pageState.action) {
          if (pageState.action.currentPage) {
            state.page = pageState.action.currentPage;
          }
          if (pageState.action.columnName) {
            let operator = "";
            if (
              pageState.action.direction &&
              pageState.action.direction.toLowerCase() === "descending"
            ) {
              operator = "-";
            }
            state.ordering = operator + pageState.action.columnName;
          } else {
            state.ordering = this.defaultOrdering;
          }
        }
        if (pageState.take) {
          state.take = pageState.take;
        }
      }

      try {
        if (this.dataSource !== undefined) {
          this.dataReturned = this.dataSource;
        } else {
          this.lastPageState = pageState;
          const r = await this.dataFunction({ ...this.defaultGetParams, ...state });
          let es_header = r.headers?.["elastic-search"];
          if (es_header) {
            es_header = es_header.split(";").map((s) => s.trim().split("="));
            this.dataCapped = {
              hits: es_header[0][1],
              limit: es_header[1][1],
              is_capped: es_header[2][1] === "True",
            };
          }
          this.dataReturned = r.data;
        }
        if (this.dataReturned.length > 0) {
          // do nothing
        } else {
          this.setTableMessage("No records found.");
        }
        this.refreshDataSource();
        // this.$refs.dataGrid.sortColumn('score', 'Descending');
      } catch (error) {
        console.error("loadData error", error);
        if (error.response && error.response.data.indexOf("ConnectionTimeout") !== -1) {
          error.response.data = [
            "The amount of results your current query would generate is large.  This means the system cannot return them in a timely manner.  Please refine your query and search again.",
          ];
        }
        EventBus.$emit("notify", "warn", error);
      }
    },
    refreshDataSource() {
      const results = this.dataReturned.result || this.dataReturned.results || this.dataReturned;
      const count = "count" in this.dataReturned ? this.dataReturned.count : results.length;
      const hasPagination = "result" in this.dataReturned || "results" in this.dataReturned;
      // fixing `results` to `result` for sh*tfusion grid
      this.innerDataSource = hasPagination ? { result: results, count } : results;
    },
    customiseCell(args) {
      if (
        [
          "id",
          "fingerprint_type",
          "summary",
          "fingerprint_time",
          "score",
          "current_risk_bucket",
          "highlight",
          "actions",
          "created_on",
          "time_since_created",
        ].indexOf(args.column.field) === -1
      ) {
        obfuscate.obfuscate(args.cell);
      }
      args.cell.classList.add("cell-format");

      if (args.column.field === "fingerprint_client") {
        args.cell.id = `id_${args.data.django_id}`;
      }
    },
    exportQueryCellInfo(args) {
      let matches = [];
      let highlight = {};
      switch (args.column.field) {
        case "summary":
          if (args.data?.fingerprint_type) {
            matches.push(`Type: ${args.data.fingerprint_type}`);
          }
          if (args.data?.to) {
            matches.push(`To: ${args.data.to}`);
          }
          if (args.data?.bcc) {
            matches.push(`BCC: ${args.data.bcc}`);
          }
          if (args.data?.from_person_display) {
            matches.push(`From: ${args.data.from_person_display}`);
          }
          if (args.data?.cc) {
            matches.push(`CC: ${args.data.cc}`);
          }
          if (args.data?.subject) {
            matches.push(`Subject: ${args.data.subject}`);
          }
          if (args.data?.fingerprint_time) {
            matches.push(`Date: ${fullDateTimeFormat(args.data.fingerprint_time)}`);
          }
          args.value = matches.join("\n\n");
          break;
        case "highlight":
          highlight = getUsefulHighlights(args.data?.highlight);
          Object.entries(highlight).forEach(([key, value]) => {
            let matchItems = [];
            let count = 1;
            for (let match of value) {
              matchItems.push(`\t${count++}. ${match.replace(/\s+/g, " ").split("\n").join(" ")}`);
            }
            matches.push(`Matches for ${key.split(".")[0]}:\n${matchItems.join(" ")}`);
          });
          args.value = replaceSpecialUnicodeChars(
            matches.join("\n\n").replace(regexB, "*").replace(regex, ""),
          );
          break;
        case "current_risk_bucket":
          args.value = this.riskBucketList[args.data?.current_risk_bucket - 1].name;
          break;
        case "search_params_names":
          if (args.data.search_params_names) {
            args.data.search_params_names.split("|||").forEach((item) => {
              matches.push(item);
            });
            args.value = matches.join("; ");
          }
          break;
        case "saved_cases":
          args.data.saved_cases.forEach((item) => {
            matches.push(item.case_name);
          });
          args.value = matches.join("; ");
          break;
      }
    },
    toggleExportSpecialColumnsVisibility(gridColumns, value = false) {
      gridColumns[0].visible = value; // checkbox column
      gridColumns[1].visible = value; // actions column
    },
    async toolbarClick(args) {
      const gridColumns = this.$refs.dataGrid.getColumns();
      const oldVisibility = getColumnsVisibility(gridColumns);
      switch (args.item.text) {
        case "Excel Export":
          this.askForReasonType = "Excel Export";
          this.handleAskForReasonSubmitInnerFn = async () => {
            try {
              gridColumns.forEach((col) => {
                col.visible = true;
              });
              this.toggleExportSpecialColumnsVisibility(gridColumns, false);
              await this.$refs.dataGrid.excelExport();
            } finally {
              restoreColumnsVisibility(gridColumns, oldVisibility);
              this.toggleExportSpecialColumnsVisibility(gridColumns, true);
            }
          };
          this.showAskForReason = true;
          break;
        case "CSV Export":
          this.askForReasonType = "CSV Export";
          this.handleAskForReasonSubmitInnerFn = async () => {
            try {
              gridColumns.forEach((col) => {
                col.visible = true;
              });
              this.toggleExportSpecialColumnsVisibility(gridColumns, false);
              await this.$refs.dataGrid.csvExport();
            } finally {
              restoreColumnsVisibility(gridColumns, oldVisibility);
              this.toggleExportSpecialColumnsVisibility(gridColumns, true);
            }
          };
          this.showAskForReason = true;
          break;
      }
    },
    rowSelected() {
      this.selectedRows = this.$refs.dataGrid.getSelectedRecords();
    },
    setTableMessage(msg) {
      L10n.load({
        "en-GB": {
          grid: {
            EmptyRecord: msg,
          },
        },
      });
    },
    getTimeNow() {
      const date = new Date();
      const year = date.getFullYear();
      const month = `${date.getMonth() + 1}`.padStart(2, "0");
      const day = `${date.getDate()}`.padStart(2, "0");
      const hour = `${date.getHours()}`.padStart(2, "0");
      const min = `${date.getMinutes()}`.padStart(2, "0");
      const sec = `${date.getSeconds()}`.padStart(2, "0");
      return `${year}${month}${day}_${hour}${min}${sec}`;
    },
    async handleAskForReasonSubmit(reason) {
      this.showAskForReason = false;
      await logExportTable(`DataTable ${this.askForReasonType}: ${reason}`).catch(() => {});
      this.handleAskForReasonSubmitInnerFn && (await this.handleAskForReasonSubmitInnerFn());
    },
    handleActionComplete(args) {
      if (args.requestType === "columnstate") {
        // store visible columns
        const columnsVisibilityObj = this.$refs.dataGrid
          .getColumns()
          .filter((col) => col.field)
          .reduce((acc, col) => {
            acc[col.field] = col.visible;
            return acc;
          }, {});
        this.updateDataTableVisibleColumns(this.gridId, columnsVisibilityObj);
      }
    },
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-buttons/styles/material3.css";
@import "@syncfusion/ej2-calendars/styles/material3.css";
@import "@syncfusion/ej2-dropdowns/styles/material3.css";
@import "@syncfusion/ej2-inputs/styles/material3.css";
@import "@syncfusion/ej2-navigations/styles/material3.css";
@import "@syncfusion/ej2-popups/styles/material3.css";
@import "@syncfusion/ej2-splitbuttons/styles/material3.css";
@import "@syncfusion/ej2-vue-grids/styles/material3.css";
</style>

<style lang="scss" scoped>
.v-input.v-select {
  flex: unset;
}

::v-deep(.e-rowcell.column-actions) {
  padding: 2px;
}

/*::v-deep(a) {*/
/*    color: #42b983;*/
/*}*/

::v-deep(.cell-format) {
  vertical-align: top !important;
  /*color: #2c3e50 !important;*/
}

::v-deep(.e-ghidden) {
  opacity: 0.35;
}

::v-deep(.e-view::before) {
  content: "\e509";
}

::v-deep(.highlight1) {
  background: #eee;
}
</style>
