"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'PeriodSelector',
    props: {
        label: null,
        value: null
    },
    emits: ["input"],
    setup: function (__props, _a) {
        var emits = _a.emit;
        var props = __props;
        var timeUnits = [
            {
                value: "days",
                text: "Days",
                days: 1,
            },
            {
                value: "weeks",
                text: "Weeks",
                days: 7,
            },
            {
                value: "months",
                text: "Months",
                days: 30,
            },
        ];
        var selectedPeriod = (0, vue_2.computed)(function () {
            var value = props.value || 0;
            var unit = timeUnits[0];
            if (value) {
                for (var i = timeUnits.length - 1; i > 0; i--) {
                    if (value % timeUnits[i].days === 0) {
                        value = value / timeUnits[i].days;
                        unit = timeUnits[i];
                        break;
                    }
                }
            }
            return {
                value: value,
                unit: unit,
            };
        });
        function updateValue(value) {
            emits("input", {
                value: value,
                unit: selectedPeriod.value.unit,
                days: value * selectedPeriod.value.unit.days,
            });
        }
        function updateUnit(unit) {
            emits("input", {
                value: selectedPeriod.value.value,
                unit: unit,
                days: selectedPeriod.value.value * unit.days,
            });
        }
        return { __sfc: true, props: props, emits: emits, timeUnits: timeUnits, selectedPeriod: selectedPeriod, updateValue: updateValue, updateUnit: updateUnit };
    }
});
