import { defineStore } from "pinia";

export const defaultState = {
  lastWorkflowNotification: null,
};

export const actions = {
  newWorkflowNotification(title, content, routerLinkObj) {
    this.lastWorkflowNotification = new Object({ title, content, routerLinkObj });
  },
};

export const useNotificationsStore = defineStore("notifications", {
  state: () => defaultState,
  actions,
});
