<template>
  <div>
    <PageFrame>
      <template #title>Exports</template>

      <v-alert type="info" class="my-2" dense>
        These files are archived using the winzip AES standard. Unfortunately the default archive
        managers on Windows, Mac and Linux can not decrypt these files.<br />
        To extract them you need a 7z-compatible software, like
        <a href="https://cleanerone.trendmicro.com/unzip-one/" target="_blank">Unzip One</a>
        (for Windows and MacOS),
        <a href="https://theunarchiver.com/" target="_blank">The Unarchiver</a>
        (for MacOS),
        <a href="https://www.7-zip.org/" target="_blank">7-Zip</a>
        (for Windows, MacOS command line, Linux command line)
      </v-alert>

      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item.file_name="{ item }">
          <v-btn v-if="item.url" :href="item.url" target="_blank" small text>
            <v-icon left>{{ getIconFromFilename(item.file_name) }}</v-icon>
            {{ item.file_name }}
          </v-btn>
        </template>
        <template v-slot:item.last_modified="{ item }">
          {{ mediumDateTimeFormat(item.last_modified) }}
        </template>
      </v-data-table>
    </PageFrame>
  </div>
</template>

<script>
import PageFrame from "@/components/PageFrame.vue";
import { mediumDateTimeFormat } from "@/filters";
import watchRepository from "@/repositories/watch";
import { getIconFromFilename } from "@/utils";

export default {
  name: "App",
  components: { PageFrame },
  data() {
    return {
      isLoading: false,
      headers: [
        { value: "file_name", text: "File (click to open)" },
        { value: "last_modified", text: "Modified on", align: "end" },
      ],
      items: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getIconFromFilename,
    mediumDateTimeFormat,
    async loadData(state) {
      this.isLoading = true;
      try {
        const { data } = await watchRepository.reportList({ ...state, source: "user" });
        this.items = data;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
