"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FaqBlock',
    props: {
        block: null
    },
    setup: function (__props) {
        var props = __props;
        var fileType = (0, vue_2.computed)(function () { var _a, _b; return (_b = (_a = props.block.file_meta) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.split("/")[0]; });
        var isVimeoLink = (0, vue_2.computed)(function () { var _a; return (_a = props.block.link) === null || _a === void 0 ? void 0 : _a.includes("vimeo.com"); });
        function getVimeoId(link) {
            var _a;
            return (_a = link.split("/").pop()) !== null && _a !== void 0 ? _a : "";
        }
        return { __sfc: true, props: props, fileType: fileType, isVimeoLink: isVimeoLink, getVimeoId: getVimeoId };
    }
});
