import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-subtitle-1 mb-2",domProps:{"innerHTML":_vm._s(_vm.data.summary)}}),(_vm.data.event.action === 'UNREDACT' && _vm.extraJson.es_id)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.showMessageDialog({
            id: _vm.extraJson.es_id,
            fingerprint_client: _vm.extraJson.fingerprint_client,
            fingerprint_time: _vm.extraJson.fingerprint_time,
          })}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-file-document-outline")])],1)]}}],null,false,2659644106)},[_c('span',[_vm._v("Open this document for preview")])]):_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.cleanedExtraJson)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }