"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var PeriodSelector_vue_1 = require("@/components/period-selector/PeriodSelector.vue");
var PageFrame_vue_1 = require("@/components/PageFrame.vue");
var eventBus_1 = require("@/eventBus");
var case_1 = require("@/models/case");
var case_2 = require("@/repositories/case");
exports.default = (0, vue_1.defineComponent)({
    __name: 'RiskBucketSettings',
    setup: function (__props) {
        function getSavedData() {
            return __awaiter(this, void 0, Promise, function () {
                var _a, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            _a = riskConfig;
                            return [4 /*yield*/, (0, case_2.getAccountRiskConfig)(true)];
                        case 1:
                            _a.value = _b.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _b.sent();
                            console.error("getSavedData error", error_1);
                            eventBus_1.default.$emit("notify", "warn", error_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        function save() {
            return __awaiter(this, void 0, void 0, function () {
                var error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            isSaving.value = true;
                            return [4 /*yield*/, (0, case_2.updateAccountRiskConfig)(riskConfig.value)];
                        case 1:
                            _a.sent();
                            eventBus_1.default.$emit("notify", "success", "Risk buckets saved successfully.");
                            setTimeout(function () {
                                isSaving.value = false;
                            }, 60000);
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.error("save error", error_2);
                            eventBus_1.default.$emit("notify", "warn", error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        var isSaving = (0, vue_2.ref)(false);
        var riskConfig = (0, vue_2.ref)(null);
        var isValid = (0, vue_2.ref)(false);
        var rules = {
            name: [function (value) { return (!!value && !!value.trim()) || "Name is required"; }],
            scoreThreshold: [function (value) { return value > 0 || "Score Threshold must be greater than 0"; }],
        };
        var validationErrors = (0, vue_2.computed)(function () {
            var errors = [];
            if (riskConfig.value) {
                if (riskConfig.value.bucket_names.some(function (name) { return !name || !name.trim(); })) {
                    errors.push("Bucket names are required");
                }
                if (riskConfig.value.score_thresholds.some(function (day) { return day <= 0; })) {
                    errors.push("Score threshold must be greater than 0");
                }
                for (var i = 0; i < riskConfig.value.score_thresholds.length - 1; i++) {
                    if (riskConfig.value.score_thresholds[i] < riskConfig.value.score_thresholds[i + 1]) {
                        errors.push("Score Thresholds must be in descending order");
                        break;
                    }
                    else if (riskConfig.value.score_thresholds[i] === riskConfig.value.score_thresholds[i + 1]) {
                        errors.push("Two or more of your buckets have the same Score Threshold");
                        break;
                    }
                }
            }
            return errors;
        });
        function resetBucketNumber(value) {
            riskConfig.value.bucket_names = case_1.RISK_BUCKET_SETTINGS[value].map(function (bucket) { return bucket.name; });
            var arrayOfZeroes = Array.from({ length: value - 1 }, function () { return 0; });
            var arrayOfTwoWeeks = Array.from({ length: value - 1 }, function () { return 14; });
            riskConfig.value.score_thresholds = riskConfig.value.score_thresholds
                .concat(arrayOfZeroes)
                .slice(0, value - 1);
            riskConfig.value.decay_days = riskConfig.value.decay_days
                .concat(arrayOfTwoWeeks)
                .slice(0, value - 1);
        }
        function defaultThresholds() {
            return __awaiter(this, arguments, void 0, function (generate) {
                var thresholds;
                if (generate === void 0) { generate = false; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, case_2.getDefaultRiskConfigThresholds)(riskConfig.value.num_buckets, generate)];
                        case 1:
                            thresholds = _a.sent();
                            riskConfig.value.score_thresholds = thresholds;
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () {
            getSavedData();
        });
        return { __sfc: true, getSavedData: getSavedData, save: save, isSaving: isSaving, riskConfig: riskConfig, isValid: isValid, rules: rules, validationErrors: validationErrors, resetBucketNumber: resetBucketNumber, defaultThresholds: defaultThresholds, PeriodSelector: PeriodSelector_vue_1.default, PageFrame: PageFrame_vue_1.default, getBucketsDefaultConfig: case_1.getBucketsDefaultConfig };
    }
});
