<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<template>
  <div>
    <v-tooltip v-if="data?.permissions?.change" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          small
          :to="{ name: 'ReportingDistributionListDetail', params: { id: data.id } }"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit</span>
    </v-tooltip>
    <v-tooltip v-if="data?.permissions?.delete" bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          fab
          small
          color="error"
          v-bind="attrs"
          v-on="on"
          @click="$emit('delete', data.id)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <span>Delete</span>
    </v-tooltip>
  </div>
</template>
