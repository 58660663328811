import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-vue";
import Vue from "vue";
import App from "./App.vue";
import awsExports from "./aws-exports";
import pinia from "./stores";
import router from "./router";
import "./interceptors/aws-cognito";
import { disposeErrorsInterceptor, initErrorsInterceptor } from "./interceptors/errors";
import "./plugins/aws-amplify";
import { obfuscateDirective } from "./plugins/obfuscate";
import { registerLicense } from "@syncfusion/ej2-base";
import vuetify from "./plugins/vuetify";
import "./styles/style.scss";

initErrorsInterceptor();
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE);

// configuring Amplify AWS Cognito authentication
Amplify.configure(awsExports);

Vue.config.ignoredElements = [/amplify-\w*/];

const main = async () => {
  Vue.directive("obfuscate", obfuscateDirective);
  Vue.config.productionTip = false;

  return new Vue({
    pinia,
    router,
    vuetify,
    unmounted() {
      disposeErrorsInterceptor();
    },
    render: (h) => h(App),
  }).$mount("#app");
};

main();
