import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.removeItemsFromCaseDialog),callback:function ($$v) {_vm.removeItemsFromCaseDialog=$$v},expression:"removeItemsFromCaseDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Are you sure? ")]),_c(VCardText,[_vm._v(" You are removing selected items from case"),_c('br'),_vm._v(" "+_vm._s(_vm.selectedCase.name)+" ")]),_c(VCardActions,[_vm._v(" "+_vm._s(_vm.apiLoadingMessage)+" "),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.apiLoadingMessage !== '',"text":""},on:{"click":function($event){_vm.removeItemsFromCaseDialog = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"disabled":_vm.apiLoadingMessage !== '',"color":"error","text":""},on:{"click":_vm.removeItems}},[_vm._v(" Agree ")])],1)],1)],1),(_vm.canDeleteTodoItem)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"error"},on:{"click":function($event){_vm.removeItemsFromCaseDialog = true}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-minus")]),_vm._v(" Batch Remove ")],1)]}}],null,false,398933751)},[_c('span',[_vm._v("Remove selected items from case "+_vm._s(_vm.selectedCase.name))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }