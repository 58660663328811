import Customise from "@/components/reporting/Customise.vue";
import DistributionLists from "@/components/reporting/DistributionLists.vue";
import DistributionListForm from "@/components/reporting/DistributionListForm.vue";
import Schedule from "@/components/reporting/Schedule.vue";
import ScheduleForm from "@/components/reporting/ScheduleForm.vue";
import StoredReports from "@/components/reporting/StoredReports.vue";
import StoredReportDetail from "@/components/reporting/StoredReportDetail.vue";

// // old handling of a component with printing enabled
// const reportingRoute = {
//   path: '/reporting',
//   name: 'Reporting',
//   component: ReportingPage,
//   children: [
//     {
//       path: 'summary',
//       name: 'ReportingSummary',
//       component: Summary,
//     },
//   ],
// };

const routes = [
  {
    path: "/reporting/reports/:status?/",
    name: "ReportingReports",
    component: StoredReports,
    props: (route) => ({
      published: route.params?.status === "published",
    }),
    meta: {
      checkAccountFeatures: ({ canShowReports }) => canShowReports,
    },
  },
  {
    path: "/reporting/stored-reports/:id",
    name: "ReportingStoredReportDetail",
    component: StoredReportDetail,
    meta: {
      checkAccountFeatures: ({ canShowReports }) => canShowReports,
      refreshTo: { name: "ReportingReports" },
    },
  },
  {
    path: "/reporting/distribution-lists",
    name: "ReportingDistributionLists",
    component: DistributionLists,
    meta: {
      checkAccountFeatures: ({ canShowReportDistributionList }) => canShowReportDistributionList,
    },
  },
  {
    path: "/reporting/distribution-lists/:id",
    name: "ReportingDistributionListDetail",
    component: DistributionListForm,
    props: true,
    meta: {
      checkAccountFeatures: ({ canShowReportDistributionList }) => canShowReportDistributionList,
      refreshTo: { name: "ReportingDistributionLists" },
    },
  },
  {
    path: "/reporting/customise",
    name: "ReportingCustomise",
    component: Customise,
    meta: {
      checkAccountFeatures: ({ canShowReportSettings }) => canShowReportSettings,
    },
  },
  {
    path: "/reporting/schedule",
    name: "ReportingSchedule",
    component: Schedule,
    meta: {
      checkAccountFeatures: ({ canShowReportSchedule }) => canShowReportSchedule,
    },
  },
  {
    path: "/reporting/schedule/:id",
    name: "ReportingScheduleForm",
    component: ScheduleForm,
    props: true,
    meta: {
      checkAccountFeatures: ({ canShowReportSchedule }) => canShowReportSchedule,
      refreshTo: { name: "ReportingSchedule" },
    },
  },

  // // old handling of a component with printing enabled
  // reportingRoute,
  // {
  //   path: '/print/reporting',
  //   name: `Print${reportingRoute.name}`,
  //   component: reportingRoute.component,
  //   meta: { shouldHavePrintLayout: true },
  //   children: reportingRoute.children.map(child => ({
  //     ...child,
  //     name: `Print${child.name}`,
  //   })),
  // },
];

export default routes;
