<template>
  <div>
    <v-tooltip bottom v-if="canAddCaseItem">
      <template #activator="{ on, attrs }">
        <v-btn class="ma-1" color="secondary" v-bind="attrs" v-on="on" @click="addItemDialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Add this item to a case</span>
    </v-tooltip>
    <v-tooltip bottom v-if="canAddTriageItem">
      <template #activator="{ on, attrs }">
        <v-btn class="ma-1" color="warning" v-bind="attrs" v-on="on" @click="addTriageItemDialog">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span>Copy this item to the Triage List</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "pinia";
import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { useAuthStore } from "@/stores/auth";
import { getPayload } from "../utils";

export default {
  name: "ActionsSearch",
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useAuthStore, ["userProfile", "canAddCaseItem", "canAddTriageItem"]),
  },
  methods: {
    addItemDialog() {
      EventBus.$emit("caseSelect", [this.data], (caseObj) => this.addTriageItem(caseObj));
    },
    addTriageItemDialog() {
      EventBus.$emit("riskBucketSelect", [this.data], (riskBucket) =>
        this.addTriageItem(null, riskBucket),
      );
    },
    checkThreshold() {
      let riskRank,
        score,
        threshold,
        below = false;
      if (this.data.score) {
        try {
          score = this.data.score.toFixed(1);
          riskRank = score * 3;
          threshold = this.userProfile.account.score_threshold;
          below = riskRank < threshold;
        } catch (err) {
          console.error(err);
        }
      }
      return { below, riskRank, score, threshold };
    },
    getMessage() {
      let msg = "Triage Item Added";
      let { below, riskRank, threshold } = this.checkThreshold();
      if (below) {
        msg += "<br><br>";
        msg += `Note: this item has a risk rank of ${riskRank}, which is below the account threshold of ${threshold}. `;
        msg += 'You can find it by changing the Priority drop down on the Triage List to "Remain".';
      }
      return msg;
    },
    async addTriageItem(caseObj, riskBucket) {
      const payload = getPayload(this.data, caseObj);
      payload.search_params = this.$route.query;
      if (riskBucket) {
        payload.target_bucket = riskBucket;
      }
      try {
        await caseRepository.todoItemAdd(payload);
        if (caseObj) {
          EventBus.$emit("notify", "success", "Case Item Added");
        } else {
          EventBus.$emit("notify", "success", this.getMessage());
        }
      } catch (error) {
        console.error("addTriageItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
      EventBus.$emit("loadData");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--fab.v-size--small {
  height: 28px;
  width: 28px;

  .v-icon {
    height: 17px;
    font-size: 17px;
    width: 17px;
    font-weight: bold;
  }
}
</style>
