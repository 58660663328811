<script setup>
// https://xrutayisire.github.io/react-js-cron/?path=/docs/reactjs-cron--demo
// https://karoletrych.github.io/vue-cron-editor/
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import cronstrue from "cronstrue";
import { DateTime } from "luxon";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: "Schedule every",
  },
  value: {
    type: String,
    required: false,
    default: "* * * * *",
  },
  periods: {
    type: Array,
    required: false,
    default: () => ["year", "month", "week", "day", "hour", "minute"],
  },
});

const emit = defineEmits(["input"]);

function getPropsPeriods() {
  return props.periods;
}
const period = ref(getPropsPeriods()[0]);

const utcTime = ref(DateTime.utc().toLocaleString(DateTime.TIME_24_SIMPLE));
let timerUTCTime;
onMounted(() => {
  timerUTCTime = setInterval(() => {
    utcTime.value = DateTime.utc().toLocaleString(DateTime.TIME_24_SIMPLE);
  }, 1000);
});

onUnmounted(() => {
  clearInterval(timerUTCTime);
});

const daysOfWeekItems = [
  { text: "every day of the week", value: "*" },
  { text: "sunday", value: "0" },
  { text: "monday", value: "1" },
  { text: "tuesday", value: "2" },
  { text: "wednesday", value: "3" },
  { text: "thursday", value: "4" },
  { text: "friday", value: "5" },
  { text: "saturday", value: "6" },
];
const daysOfWeek = ref(["*"]);

const monthsItems = [
  { text: "every month", value: "*" },
  { text: "january", value: "1" },
  { text: "february", value: "2" },
  { text: "march", value: "3" },
  { text: "april", value: "4" },
  { text: "may", value: "5" },
  { text: "june", value: "6" },
  { text: "july", value: "7" },
  { text: "august", value: "8" },
  { text: "september", value: "9" },
  { text: "october", value: "10" },
  { text: "november", value: "11" },
  { text: "december", value: "12" },
];
const months = ref(["*"]);

const daysItems = [{ text: "every day of the month", value: "*" }];
for (let i = 1; i <= 31; i++) {
  daysItems.push({ text: i.toString(), value: i.toString() });
}
const days = ref(["*"]);

const hoursItems = [];
if (getPropsPeriods().includes("hour")) {
  hoursItems.push({ text: "every hour", value: "*" });
}
for (let i = 0; i <= 23; i++) {
  let text = ("00" + (i % 12).toString()).slice(-2);
  if (i === 0) {
    text = "12 AM";
  } else if (i === 12) {
    text = "12 PM";
  } else {
    text += " " + (i < 12 ? "AM" : "PM");
  }
  hoursItems.push({
    text,
    value: i.toString(),
  });
}
const hours = ref(hoursItems[0].value);

const minutesItems = [];
if (getPropsPeriods().includes("minute")) {
  minutesItems.push({ text: "every minute", value: "*" });
}
for (let i = 0; i < 60; i += 5) {
  minutesItems.push({
    text: ("00" + i.toString()).slice(-2),
    value: i.toString(),
  });
}
const minutes = ref([minutesItems[0].value]);

const cronValue = computed(() => {
  let retVal = minutes.value.join(",");
  retVal += ` ${hours.value.join(",")}`;
  retVal += ` ${days.value.join(",")}`;
  retVal += ` ${months.value.length ? months.value.join(",") : "*"}`;
  retVal += ` ${daysOfWeek.value.join(",")}`;
  return retVal;
});
const computedValue = computed(() => {
  return cronstrue.toString(cronValue.value);
});

function handleChangeAllItems(elems, availableElems) {
  const len = elems.length;
  if (!len) {
    elems.push(availableElems[0].value);
  } else if (len >= 1 && elems.includes("*")) {
    if (elems[len - 1] === "*") {
      elems.splice(0, len - 1);
    } else {
      elems.splice(elems.indexOf("*"), 1);
    }
  }
  emit("input", cronValue.value);
}

function handleChangePeriod(newPeriod) {
  period.value = newPeriod;
  let changed = false;
  switch (newPeriod) {
    case "minute":
      if (minutes.value[0] !== "*") {
        minutes.value = ["*"];
        changed = true;
      }
      break;
    case "hour":
      if (hours.value[0] !== "*") {
        hours.value = ["*"];
        changed = true;
      }
      break;
    case "day":
      if (daysOfWeek.value[0] !== "*") {
        daysOfWeek.value = ["*"];
        changed = true;
      }
      break;
    case "week":
      if (days.value[0] !== "*") {
        days.value = ["*"];
        changed = true;
      }
      break;
    case "month":
      if (months.value[0] !== "*") {
        months.value = ["*"];
        changed = true;
      }
      break;
  }
  if (changed) {
    emit("input", cronValue.value);
  }
}

function checkInvalidValues(values, validValues) {
  return values.some((item) => !validValues.find((i) => i.value === item));
}

function parseValue(value) {
  if (!value) {
    return;
  }
  const cronParts = value.split(" ");
  if (cronParts.length < 5) {
    return;
  }
  minutes.value = cronParts[0].split(",");
  if (checkInvalidValues(minutes.value, minutesItems)) {
    minutes.value = [minutesItems[0].value];
  }
  hours.value = cronParts[1].split(",");
  if (checkInvalidValues(hours.value, hoursItems)) {
    hours.value = [hoursItems[0].value];
  }
  days.value = cronParts[2].split(",");
  if (checkInvalidValues(days.value, daysItems)) {
    days.value = ["*"];
  }
  months.value = cronParts[3].split(",");
  if (checkInvalidValues(months.value, monthsItems)) {
    months.value = ["*"];
  }
  daysOfWeek.value = cronParts[4].split(",");
  if (checkInvalidValues(daysOfWeek.value, daysOfWeekItems)) {
    daysOfWeek.value = ["*"];
  }

  // let periodToDisplay = 'year';
  // if (months.value.length === 1 && months.value[0] === '*') {
  //   periodToDisplay = 'month';
  // }
  // if (days.value.length === 1 && days.value[0] === '*') {
  //   periodToDisplay = 'week';
  // }
  // if (daysOfWeek.value.length === 1 && daysOfWeek.value[0] === '*') {
  //   periodToDisplay = 'day';
  // }
  // period.value = periodToDisplay;
}

function getPropsValue() {
  return props.value;
}
watch(() => props.value, parseValue);
parseValue(getPropsValue());
</script>

<template>
  <div>
    <v-input
      :messages="`Executes: ${computedValue} UTC time, please note that the current UTC time is ${utcTime}`"
    >
      <v-row dense align="baseline">
        <span>
          <label>{{ label }}</label>
        </span>
        <span class="ml-2">
          <v-autocomplete
            :value="period"
            :items="periods"
            style="width: 95px"
            dense
            hide-details
            @change="handleChangePeriod"
          />
        </span>
        <span v-if="period === 'year'" class="ml-2">
          <label>in</label>
        </span>
        <span v-if="period === 'year'" class="ml-2">
          <v-autocomplete
            v-model="months"
            :items="monthsItems"
            auto-select-first
            :clearable="months[0] !== '*'"
            dense
            hide-details
            multiple
            @change="handleChangeAllItems(months, monthsItems)"
          />
        </span>
        <span v-if="['year', 'month', 'week'].includes(period)" class="ml-2">
          <label>on</label>
        </span>
        <span v-if="['year', 'month'].includes(period)" class="ml-2">
          <v-autocomplete
            v-model="days"
            :items="daysItems"
            auto-select-first
            :clearable="days[0] !== '*'"
            dense
            hide-details
            multiple
            @change="handleChangeAllItems(days, daysItems)"
          />
        </span>
        <span v-if="days[0] === '*' && ['year', 'month'].includes(period)" class="ml-2">
          <label>and</label>
        </span>
        <span v-if="days[0] === '*' && ['year', 'month', 'week'].includes(period)" class="ml-2">
          <v-autocomplete
            v-model="daysOfWeek"
            :items="daysOfWeekItems"
            auto-select-first
            :clearable="daysOfWeek[0] !== '*'"
            dense
            hide-details
            multiple
            @change="handleChangeAllItems(daysOfWeek, daysOfWeekItems)"
          />
        </span>
        <span v-if="['year', 'month', 'week', 'day', 'hour'].includes(period)" class="ml-2">
          <label>at</label>
        </span>
        <span v-if="['year', 'month', 'week', 'day'].includes(period)" class="ml-2">
          <v-autocomplete
            v-model="hours"
            :items="hoursItems"
            auto-select-first
            :clearable="hours[0] !== '*'"
            dense
            hide-details
            multiple
            @change="handleChangeAllItems(hours, hoursItems)"
          />
        </span>
        <span v-if="['year', 'month', 'week', 'day'].includes(period)" class="ml-2">
          <label>:</label>
        </span>
        <span v-if="['year', 'month', 'week', 'day', 'hour'].includes(period)" class="ml-2">
          <v-autocomplete
            v-model="minutes"
            :items="minutesItems"
            auto-select-first
            :clearable="minutes[0] !== '*'"
            dense
            hide-details
            multiple
            @change="handleChangeAllItems(minutes, minutesItems)"
          />
        </span>
      </v-row>
    </v-input>
  </div>
</template>

<style lang="scss" scoped>
::v-deep(.v-select__selections) {
  > input {
    width: 15px;
    min-width: 15px;
  }
}

::v-deep(.v-autocomplete.v-select.v-input--is-focused input) {
  min-width: 15px;
}
</style>
