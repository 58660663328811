import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VFooter,{attrs:{"app":"","absolute":"","color":"white","inset":"","light":""}},[_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('h3',[_vm._v("For Support")])]),_c(VCardText,[_c('h3',{staticClass:"ma-5"},[_c(VIcon,{attrs:{"color":"primary","left":""}},[_vm._v("mdi-web")]),_c('a',{attrs:{"href":"https://support.fingerprint-supervision.com","target":"_blank"}},[_vm._v("support.fingerprint-supervision.com")])],1),_c('h3',{staticClass:"ma-5"},[_c(VIcon,{attrs:{"color":"primary","left":""}},[_vm._v("mdi-email-outline")]),_c('a',{attrs:{"href":"mailto:support@fingerprint-supervision.com"},on:{"click":function($event){$event.preventDefault();return _setup.handleSupportEmail.apply(null, arguments)}}},[_vm._v("support@fingerprint-supervision.com")])],1),_c('h3',{staticClass:"ma-5"},[_c(VIcon,{attrs:{"color":"primary","left":""}},[_vm._v("mdi-phone")]),_c('a',{attrs:{"href":"tel:+44 203 011 4150"}},[_vm._v("+44 203 011 4150")])],1)])],1)],1),_c(VBtn,{attrs:{"small":"","text":""},on:{"click":function($event){_setup.dialog = true}}},[_c(VIcon,{attrs:{"color":"primary","left":""}},[_vm._v("mdi-lifebuoy")]),_vm._v(" Need help? Click here ")],1),_c(VSpacer),_vm._v(" © Fingerprint Compliance Services Ltd "+_vm._s(_setup.currentYear)+". "),_c(VSpacer),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Release "+_vm._s(_setup.version)+" ")])]}}])},[_c('span',[_vm._v("Build "+_vm._s(_setup.build))])]),_c('a',{staticClass:"ml-5 text-decoration-underline",on:{"click":function($event){return _setup.emit('licensesClick')}}},[_vm._v("Licenses")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }