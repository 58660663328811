<script>
import PageFrame from "@/components/PageFrame.vue";
import { adaptiveRelativeTimeFormat, mediumDateTimeFormat } from "@/filters";
import { getCaseNotifications } from "@/repositories/case";
import { getUserList } from "@/repositories/user";

export default {
  components: { PageFrame },
  data() {
    return {
      loadingNotifications: false,
      notifications: [],
      users: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    adaptiveRelativeTimeFormat,
    mediumDateTimeFormat,
    async loadData() {
      this.loadingNotifications = true;
      try {
        this.users = await getUserList();
        const { data } = await getCaseNotifications({ take: "all" });
        this.notifications = data;
      } catch (error) {
        this.notifications = [];
      } finally {
        this.loadingNotifications = false;
      }
    },
    getUserFullName(id) {
      const user = this.users.find((u) => u.id === id);
      return user ? user.full_name : "";
    },
  },
};
</script>

<template>
  <div>
    <PageFrame>
      <template #title>Pending workflows</template>

      <template #actions>
        <v-btn icon @click="loadData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>

      <v-list subheader two-line>
        <v-list-item v-if="loadingNotifications">
          <v-list-item-avatar>
            <v-skeleton-loader type="list-item-avatar" />
          </v-list-item-avatar>

          <v-skeleton-loader type="list-item-two-line" />
        </v-list-item>

        <v-subheader>Cases waiting for activity</v-subheader>

        <v-list-item
          v-for="notification in notifications"
          :key="notification.id"
          :to="{ name: 'CaseDetail', params: { pk: notification.case.id } }"
        >
          <v-list-item-avatar>
            <v-icon>mdi-file-document-multiple</v-icon>
          </v-list-item-avatar>

          <v-row no-gutters align="center">
            <v-col class="col-6 col-md-3">Action:<br />{{ notification.name }}</v-col>

            <v-col class="col-6 col-md-3">Case:<br />{{ notification.case.name }}</v-col>

            <v-col class="col-12 col-md-3">
              Assigned to:<br />
              {{ notification.assign_to.map(getUserFullName).join(", ") }}
            </v-col>

            <v-col class="col-12 col-md-3 text-right">
              Started
              {{ adaptiveRelativeTimeFormat(new Date(notification.started) - new Date()) }}
              <br />
              {{ mediumDateTimeFormat(notification.started) }}
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </PageFrame>
  </div>
</template>
