import UserList from "@/components/crud/UserList.vue";
import RedactTerms from "@/views/configuration/RedactTerms.vue";
import RiskBucketSettings from "@/views/configuration/RiskBucketSettings.vue";
import UserSettings from "@/components/account/UserSettings.vue";
import FirmGroupList from "@/components/firm-groups/FirmGroupList.vue";

const routes = [
  {
    path: "/configuration/user/",
    name: "UserSettings",
    component: UserSettings,
    meta: { checkAccountFeatures: (authStore) => authStore.canShowConfiguration },
  },
  {
    path: "/configuration/fingerprintuser/",
    name: "UserList",
    component: UserList,
    meta: {
      checkAccountFeatures: ({ canShowConfigurationUser }) => canShowConfigurationUser,
    },
  },
  {
    path: "/configuration/firm-groups/",
    name: "FirmGroupList",
    component: FirmGroupList,
    meta: {
      checkAccountFeatures: ({ canShowConfigurationFirmGroup }) => canShowConfigurationFirmGroup,
    },
  },
  {
    path: "/configuration/risk-buckets/",
    name: "RiskBucketSettings",
    component: RiskBucketSettings,
    meta: {
      checkAccountFeatures: ({ canChangeRiskConfig }) => canChangeRiskConfig,
    },
  },
  {
    path: "/configuration/redact-terms/",
    name: "RedactTerms",
    component: RedactTerms,
    meta: {
      checkAccountFeatures: ({ canShowRedactTerms }) => canShowRedactTerms,
    },
  },
];

export default routes;
