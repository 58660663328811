"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var auth_1 = require("@/stores/auth");
var enums_1 = require("@/models/enums");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ServiceUnavailable',
    setup: function (__props) {
        var authStore = (0, auth_1.useAuthStore)();
        var userProfile = (0, pinia_1.storeToRefs)(authStore).userProfile;
        var serviceStatusMessage = (0, vue_2.computed)(function () {
            var _a, _b;
            switch ((_b = (_a = userProfile.value) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.status) {
                case enums_1.AccountStatus.OFFLINE:
                    return "The service is not ready yet. Please try again later.";
                case enums_1.AccountStatus.SUSPENDED:
                    return "The service has been suspended. Please contact support.";
                default:
                    return "The service should be ready now. Please try again.";
            }
        });
        return { __sfc: true, authStore: authStore, userProfile: userProfile, serviceStatusMessage: serviceStatusMessage, AccountStatus: enums_1.AccountStatus };
    }
});
