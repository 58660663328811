<template>
  <div v-if="caseObjLoading">Loading case data...</div>
  <div v-else-if="caseObj">
    <v-dialog v-model="dialogWorkflowDisplay" max-width="300" persistent>
      <v-card>
        <v-card-title>
          {{ dialogWorkflowTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogWorkflowText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogWorkflowDisplay = false"> Cancel </v-btn>
          <v-btn
            :color="dialogWorkflowOkButtonColor"
            text
            @click="dialogWorkflowFunctionOk && dialogWorkflowFunctionOk()"
          >
            {{ dialogWorkflowOkButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCaseNoteDisplay" persistent>
      <CaseNoteForm
        v-if="dialogCaseNoteDisplay"
        :note-id="dialogCaseNoteId"
        :case-id="caseObj.id"
        :todo-item-id="dialogCaseNoteTodoItemId"
        @close-dialog="handleCloseDialogCaseNote"
      />
    </v-dialog>
    <v-dialog v-model="dialogCaseNoteHistoryDisplay" persistent scrollable>
      <v-card>
        <v-card-title> Case Note History </v-card-title>
        <v-card-text>
          <CaseNoteHistory v-if="dialogCaseNoteHistoryDisplay" :note-id="dialogCaseNoteHistoryId" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogCaseNoteHistoryDisplay = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTodoItemNotesDisplay" scrollable>
      <v-card>
        <v-card-title> Todo Item Notes </v-card-title>
        <v-card-text>
          <CaseNotes
            v-if="dialogTodoItemNotesDisplay"
            :notes="dialogTodoItemNotesList"
            :can-add-case-note="canAddCaseNote"
            :can-change-case-note="canChangeCaseNote"
            :can-delete-case-note="canDeleteCaseNote"
            @note-update="handleCaseNoteUpdate($event, dialogTodoItemNotesId)"
            @note-history="handleCaseNoteHistory"
            @note-delete="deleteCaseNoteDialog"
            @note-undelete="undeleteCaseNoteDialog"
            @note-add="handleCaseNoteAdd(dialogTodoItemNotesId)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogTodoItemNotesDisplay = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeItemFromCaseDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Are you sure?</v-card-title>
        <v-card-text>
          You are removing the item from case<br />
          {{ caseObj.name }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="removeItemFromCaseDialog = false"> Cancel </v-btn>
          <v-btn color="error" text @click="handleRemoveItem"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCaseNoteDelete" max-width="500px">
      <v-card>
        <v-card-title>Confirm {{ dialogCaseNoteDeleteAction }}</v-card-title>
        <v-card-text>
          Are you sure you wish to {{ dialogCaseNoteDeleteAction.toLowerCase() }} this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogCaseNoteDelete = false">Cancel</v-btn>
          <v-btn
            color="warning"
            text
            @click="dialogCaseNoteDeleteFunctionOk && dialogCaseNoteDeleteFunctionOk()"
          >
            {{ dialogCaseNoteDeleteAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CloseCase
      v-if="canChangeCase"
      :show-dialog="caseCloseDialog"
      @cancel="caseCloseDialog = false"
      @confirm="caseCloseHandleFn"
    />
    <OpenCase
      v-if="canChangeCase"
      :show-dialog="caseReopenDialog"
      @cancel="caseReopenDialog = false"
      @confirm="handleCaseReopen"
    />

    <v-card flat>
      <v-card-title>
        <span v-obfuscate>{{ caseObj.name }}</span>
        <v-spacer />
        <v-btn v-if="canShowSearch" :to="{ name: 'NewSearch', params: { caseId: caseObj.id } }">
          New Search
        </v-btn>
        &nbsp;
        <v-btn @click="handlePrint"> Print / PDF </v-btn>
      </v-card-title>
      <v-card-subtitle>
        <v-row dense>
          <v-col>
            <span v-if="caseObj.reason_closed">
              Reason closed: <span v-obfuscate>{{ caseObj.reason_closed }}</span>
            </span>
            <span v-else-if="caseObj.reason_opened">
              Reason opened: <span v-obfuscate>{{ caseObj.reason_opened }}</span>
            </span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" lg="4"> State: {{ caseObj.case_status_display }} </v-col>
          <v-col cols="12" md="6" lg="4">
            Category:
            <span v-if="caseObj.category && caseObj.category.name" v-obfuscate>{{
              caseObj.category.name
            }}</span>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            Created by <span v-obfuscate>{{ caseObj.created_by.full_name }}</span> on
            {{ mediumDateTimeFormat(caseObj.created_on) }}
          </v-col>
        </v-row>
        <v-row v-if="!caseWorkflow && caseObj.stage && caseObj.stage.name" dense>
          <v-col cols="12"> Stage: {{ caseObj.stage.name }} </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text class="text--primary">
        <div v-if="caseObj.case_status === 1">
          <span v-if="caseWorkflowLoading"> Loading case workflow structure... </span>
          <WorkflowCard
            v-if="canShowWorkflows && !caseWorkflowLoading && caseWorkflow && caseWorkflow.actions"
            :can-change-case="canChangeCase"
            :case-workflow="caseWorkflow"
            :case-status="caseObj.case_status"
            :user-id="userProfile.id"
            @action-revert="askPreviousStage(caseObj.id, $event)"
            @action-complete="askNextStage(caseObj.id, $event)"
            @action-complete-last="askLastStage(caseObj.id, $event)"
          />
        </div>

        <div v-if="caseObj.case_status === 1 && !caseObj.current_action">
          <v-btn class="mr-2 mb-2" color="error" small @click="closeCaseDialog"> Close Case </v-btn>
        </div>
        <div v-else-if="caseObj.case_status !== 1 && !caseWorkflow">
          <v-btn class="mr-2 mb-2" color="error" small @click="openCaseDialog"> Reopen Case </v-btn>
        </div>

        <v-expansion-panels v-if="canShowCaseNote" v-model="expandCaseNotesIndex" class="my-2">
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              Case Notes
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <div v-if="caseNotesLoading">Loading case notes...</div>
              <div v-else-if="caseNotesLoadingError">
                {{ caseNotesLoadingError }}
              </div>
              <div v-else>
                <CaseNotes
                  :notes="caseNotesGeneric"
                  :can-add-case-note="canAddCaseNote"
                  :can-change-case-note="canChangeCaseNote"
                  :can-delete-case-note="canDeleteCaseNote"
                  @note-update="handleCaseNoteUpdate($event)"
                  @note-history="handleCaseNoteHistory"
                  @note-delete="deleteCaseNoteDialog"
                  @note-undelete="undeleteCaseNoteDialog"
                  @note-add="handleCaseNoteAdd()"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-model="expandTodoItemsIndex" class="my-2">
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              Case Items
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="todoItemsTab === null" class="ma-3">
                Select your preferred display type (can be changed at any time)
              </div>
              <v-tabs v-model="todoItemsTab" :optional="todoItemsTab === null">
                <v-tab>
                  <v-icon left small> mdi-timeline-text-outline </v-icon>
                  Timeline
                </v-tab>
                <v-tab>
                  <v-icon left small> mdi-table </v-icon>
                  Data Table
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="todoItemsTab">
                <v-tab-item>
                  <TodoItemsTimeline
                    :loading="todoItemsLoading"
                    :items="todoItems"
                    :languages="languages"
                    :notes="caseNotesItems"
                    @changeStatus="loadTodoItems"
                    @searchConversationsFromItem="handleSearchConversationFromItem"
                    @toggleHideItem="handleToggleHideItem"
                    @addItemNote="handleCaseNoteAdd($event)"
                    @showItemNotes="handleShowItemNotes"
                    @removeItem="handleAskRemoveItem"
                  />
                </v-tab-item>
                <v-tab-item class="mt-2">
                  <DataTable
                    v-if="todoItemsTab === 1"
                    grid-id="CaseDataTable"
                    :data-source="{ results: todoItems, count: todoItems.length }"
                    :disable-sort="true"
                    :show-created="true"
                    :show-risk-bucket="true"
                    :show-modified-on-column="true"
                    default-ordering="-modified_on"
                    default-page-size="All"
                    :default-page-sizes="['All']"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels
          v-if="canShowCaseActivity"
          v-model="expandCaseActivityIndex"
          class="my-2"
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="secondary">
              Case Activity
              <template #actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <span v-if="caseActivityLoading"> Loading case activity... </span>
              <v-simple-table v-else-if="caseActivity.length" class="col-md-12">
                <tr v-for="activity in caseActivity" :key="activity.id">
                  <td>
                    <p v-obfuscate>
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="activity.summary" />
                      <template
                        v-if="
                          activity.django.object_type_id === 'TodoItem' &&
                          activity.event.action === 'CREATE'
                        "
                      >
                        [<a href="#" @click.prevent="scrollToID(activity.django.object_id)"
                          >View Item</a
                        >]
                      </template>
                    </p>
                    <p>
                      <small
                        ><em
                          >On {{ mediumDateTimeFormat(activity.event.created) }} by
                          <span v-obfuscate>{{
                            getUserFullName(activity.django.user_id)
                          }}</span></em
                        ></small
                      >
                    </p>
                    <hr />
                  </td>
                </tr>
              </v-simple-table>
              <span v-else-if="caseActivityLoadingError">
                {{ caseActivityLoadingError }}
              </span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="my-2">
          <v-card-text class="text--primary">
            <v-btn color="success" small @click="showExport = !showExport">
              Export Case for Compliance
            </v-btn>

            <div v-if="showExport">
              <p>&nbsp;</p>
              <p>
                In order to export the raw data for this case, please enter a password below. This
                will be used to password protect the final zip file.
              </p>
              <p>
                Your report will appear on the
                <router-link :to="{ name: 'WatchReports' }">Reporting > Exports</router-link>
                page when ready.
              </p>
              <form id="caseExportForm" role="form" @submit.prevent="caseExport">
                <div class="form-group">
                  <v-text-field
                    v-model="exportPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="rulesPassword"
                    :type="showPassword ? 'text' : 'password'"
                    label="Enter a password for this zip file"
                    hint="At least 8 characters"
                    counter
                    @click:append="showPassword = !showPassword"
                  />
                  <p v-if="exportError" class="text-danger">
                    {{ exportError }}
                  </p>
                </div>
                <div>
                  <v-btn small class="mx-1" @click="showExport = false"> Cancel </v-btn>
                  <v-btn color="success" small class="mx-1" @click="caseExport"> Export </v-btn>
                </div>
              </form>
              <v-alert type="info" class="my-2" dense text>
                These files are archived using the winzip AES standard. Unfortunately the default
                archive managers on Windows, Mac and Linux can not decrypt these files.<br />
                To extract them you need a 7z-compatible software, like
                <a href="https://cleanerone.trendmicro.com/unzip-one/" target="_blank">Unzip One</a>
                (for Windows and MacOS),
                <a href="https://theunarchiver.com/" target="_blank">The Unarchiver</a>
                (for MacOS),
                <a href="https://www.7-zip.org/" target="_blank">7-Zip</a>
                (for Windows, MacOS command line, Linux command line)
              </v-alert>
            </div>
          </v-card-text>
        </v-card>

        <v-card v-if="!caseObj.is_deleted && canDeleteCase" class="my-2">
          <v-card-text class="text--primary">
            <v-btn color="error" small @click="showDelete = !showDelete"> Delete Case </v-btn>

            <div v-if="showDelete">
              <p>&nbsp;</p>
              <p>
                Are you sure you want to delete the "<strong>{{ caseObj.name }}</strong
                >" case?
              </p>
              <p>
                You will lose all your case notes and activity. Case items will be returned to the
                Triage List.
              </p>
              <p>
                To confirm this action, please type "<strong>{{ caseObj.name }}</strong
                >".
              </p>

              <form id="caseform" role="form" @submit.prevent="caseDelete">
                <div class="form-group">
                  <v-text-field
                    v-model="deleteName"
                    :label="`Type &quot;${caseObj.name}&quot; here.`"
                  />
                  <p v-if="deleteError" class="text-danger">
                    {{ deleteError }}
                  </p>
                </div>
                <div>
                  <v-btn small class="mx-1" @click="showDelete = false"> Cancel </v-btn>
                  <v-btn color="error" small class="mx-1" @click="caseDelete"> Delete </v-btn>
                </div>
              </form>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
  <div v-else-if="caseObjLoadingError">Errors loading case...</div>
</template>

<script>
import { mapState, mapActions } from "pinia";

import TodoItemsTimeline from "./TodoItemsTimeline.vue";
import CaseNotes from "./CaseNotes.vue";
import DataTable from "../datatable/DataTable.vue";
import ActionsCaseDetail from "../datatable/columns/ActionsCaseDetail.vue";
import BatchActionsCaseDetail from "../datatable/columns/BatchActionsCaseDetail.vue";
import CloseCase from "../datatable/popups/CloseCase.vue";
import OpenCase from "../datatable/popups/OpenCase.vue";
import CaseNoteForm from "@/components/case/CaseNoteForm.vue";
import CaseNoteHistory from "@/components/crud/CaseNoteHistory.vue";
import WorkflowCard from "@/components/workflow/WorkflowCard.vue";
import caseRepository, {
  caseClose,
  caseReopen,
  getCase,
  getCaseActivity,
  getCaseNotes,
  getCaseWorkflow,
  todoItemsToGridElement,
} from "@/repositories/case";
import { hideTodoItem, resetTodoItems, showTodoItem } from "@/repositories/todo";
import EventBus from "@/eventBus";
import { adaptiveRelativeTimeFormat, mediumDateTimeFormat } from "@/filters";
import { getLanguages } from "@/repositories/settings";
import { getUserList } from "@/repositories/user";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";
import { useCasesStore } from "@/stores/cases";

export default {
  name: "CaseDataTable",
  components: {
    OpenCase,
    CloseCase,
    DataTable,
    WorkflowCard,
    CaseNoteForm,
    CaseNoteHistory,
    TodoItemsTimeline,
    CaseNotes,
  },
  provide: {
    actions: ActionsCaseDetail,
    batchActions: BatchActionsCaseDetail,
  },
  data() {
    return {
      todoItemsTab: null,
      caseObj: null,
      caseObjLoadingError: null,
      caseActivity: [],
      caseActivityLoadingError: null,
      caseNotes: [],
      caseNotesLoadingError: null,
      caseWorkflow: null,
      caseWorkflowLoadingError: null,
      todoItems: [],
      todoItemsLoading: false,
      users: [],

      dialogWorkflowDisplay: false,
      dialogWorkflowFunctionOk: null,
      dialogWorkflowTitle: "",
      dialogWorkflowText: "Are you sure you wish to proceed?",
      dialogWorkflowOkButtonText: "Confirm",
      dialogWorkflowOkButtonColor: "primary",
      showDelete: false,
      deleteName: null,
      deleteError: null,
      showExport: false,
      showPassword: null,
      rulesPassword: [(v) => !!v || "Required", (v) => v.length >= 8 || "Min 8 characters"],
      exportPassword: "",
      exportError: null,
      expandCaseNotesIndex: null,
      expandTodoItemsIndex: 0,
      expandCaseActivityIndex: null,
      selectedStep: null,
      dialogCaseNoteDisplay: false,
      dialogCaseNoteId: null,
      dialogCaseNoteTodoItemId: null,
      dialogCaseNoteHistoryDisplay: false,
      dialogCaseNoteHistoryId: null,

      dialogCaseNoteDelete: false,
      dialogCaseNoteDeleteAction: "Delete", // || "Restore"
      dialogCaseNoteDeleteFunctionOk: null,

      caseCloseDialog: false,
      caseCloseHandleFn: () => {},
      caseReopenDialog: false,

      dialogTodoItemNotesDisplay: false,
      dialogTodoItemNotesId: null,

      removeItemFromCaseDialog: false,
      removeItemFromCaseId: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, {
      canChangeCase: (state) => state.canChangeCase,
      canDeleteCase: (state) => state.canDeleteCase,
      canAddCaseNote: (state) => state.canAddCaseNote,
      canChangeCaseNote: (state) => state.canChangeCaseNote,
      canDeleteCaseNote: (state) => state.canDeleteCaseNote,
      canShowCaseNote: (state) => state.canShowCaseNote,
      canShowCaseActivity: (state) => state.canShowCaseActivity,
      canShowSearch: (state) => state.canShowSearch,
      canShowWorkflows: (state) => state.canShowWorkflows,
      caseDataTableTodoItemsTab: (state) => state.caseDataTableTodoItemsTab,
      userProfile: (state) => state.userProfile,
    }),
    ...mapState(useCasesStore, {
      caseActivityLoading: (state) => state.caseActivityLoading,
      caseNotesLoading: (state) => state.caseNotesLoading,
      caseObjLoading: (state) => state.caseObjLoading,
      caseWorkflowLoading: (state) => state.caseWorkflowLoading,
    }),
    pk() {
      return this.$route.params.pk;
    },
    caseNotesGeneric() {
      return this.caseNotes.filter((caseNote) => !caseNote.todo_item);
    },
    caseNotesItems() {
      return this.caseNotes.filter((caseNote) => caseNote.todo_item);
    },
    dialogTodoItemNotesList() {
      return this.caseNotesItems.filter(
        (caseNote) => caseNote.todo_item === this.dialogTodoItemNotesId,
      );
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
    todoItemsTab(newValue) {
      this.updateCaseDataTableTodoItemsTab(newValue);
      if (newValue === 0) {
        this.loadTodoItems();
      }
    },
  },
  async mounted() {
    EventBus.$on("loadData", this.loadData);
    const todoItemsTab = this.caseDataTableTodoItemsTab;
    this.todoItemsTab = [0, 1].includes(todoItemsTab) ? todoItemsTab : null;
    this.languages = await getLanguages();
    this.users = await getUserList();
    await this.getAccountRiskConfig();
    await this.loadData();
  },
  beforeDestroy() {
    EventBus.$off("loadData", this.loadData);
  },

  methods: {
    ...mapActions(useAuthStore, ["updateCaseDataTableTodoItemsTab"]),
    ...mapActions(useCasesStore, ["getAccountRiskConfig"]),
    adaptiveRelativeTimeFormat,
    mediumDateTimeFormat,
    async loadData() {
      const casesStore = useCasesStore();
      casesStore.caseObjLoading = true;
      if (!this.pk) {
        return;
      }
      try {
        const { data } = await getCase(this.pk, [
          "todo_items",
          "todo_items.created_by",
          "todo_items.modified_by",
        ]);
        this.caseObj = data;
        this.loadTodoItems();
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
        this.caseObjLoadingError = error;
      } finally {
        casesStore.caseObjLoading = false;
      }

      // set caseObj in store for DataTable usage (???)
      const mainStore = useStore();
      mainStore.setCase(this.caseObj);

      if (this.canShowWorkflows) {
        this.loadCaseWorkflow(this.pk);
      }
      this.loadCaseActivity(this.pk);
      this.loadCaseNotes(this.pk);
    },
    async loadTodoItems() {
      this.todoItemsLoading = true;
      try {
        this.todoItems = todoItemsToGridElement(this.caseObj.todo_items).sort((a, b) =>
          a.fingerprint_time > b.fingerprint_time ? 1 : -1,
        );
      } catch (error) {
        this.todoItems = [];
      } finally {
        this.todoItemsLoading = false;
      }
    },
    async loadCaseActivity(caseId) {
      const casesStore = useCasesStore();
      casesStore.caseActivityLoading = true;
      try {
        const { data } = await getCaseActivity(caseId);
        this.caseActivity = data;
      } catch (error) {
        this.caseActivityLoadingError = error;
        this.caseActivity = [];
      } finally {
        casesStore.caseActivityLoading = false;
      }
    },
    async loadCaseNotes(caseId) {
      const casesStore = useCasesStore();
      casesStore.caseNotesLoading = true;
      try {
        const { data } = await getCaseNotes(caseId);
        this.caseNotes = data.results;
      } catch (error) {
        this.caseNotesLoadingError = error;
        this.caseNotes = [];
      } finally {
        casesStore.caseNotesLoading = false;
      }
    },
    async loadCaseWorkflow(caseId) {
      const casesStore = useCasesStore();
      casesStore.caseWorkflowLoading = true;
      try {
        const { data } = await getCaseWorkflow(caseId);
        this.caseWorkflow = data;
      } catch (error) {
        this.caseWorkflowLoadingError = error;
        this.caseWorkflow = null;
      } finally {
        casesStore.caseWorkflowLoading = false;
      }
      this.selectedStep =
        this.caseWorkflow?.steps && this.caseWorkflow.steps.findIndex((step) => step.running) + 1;
    },
    deleteCaseNoteDialog(note_id) {
      this.dialogCaseNoteDelete = true;
      this.dialogCaseNoteDeleteAction = "Delete";
      this.dialogCaseNoteDeleteFunctionOk = async () => {
        await this.deleteCaseNoteOK(note_id);
        this.dialogCaseNoteDelete = false;
      };
    },
    async deleteCaseNoteOK(note_id) {
      try {
        await caseRepository.noteDelete(note_id);
        await this.loadCaseNotes(this.pk);
      } catch (error) {
        console.error("deleteCaseNote error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    undeleteCaseNoteDialog(note_id) {
      this.dialogCaseNoteDelete = true;
      this.dialogCaseNoteDeleteAction = "Restore";
      this.dialogCaseNoteDeleteFunctionOk = async () => {
        await this.undeleteCaseNoteOK(note_id);
        this.dialogCaseNoteDelete = false;
      };
    },
    async undeleteCaseNoteOK(note_id) {
      try {
        await caseRepository.noteRestore(note_id);
        await this.loadCaseNotes(this.pk);
      } catch (error) {
        console.error("undeleteCaseNoteOK error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    scrollToID(id) {
      setTimeout(() => {
        const container = this.$el.querySelector(`#id_${id}`);
        if (container) {
          this.expandTodoItemsIndex = 0;
          container.scrollIntoView();
        }
      }, 500);
    },
    askLastStage(caseId, currentAction) {
      this.caseCloseDialog = true;
      this.caseCloseHandleFn = ({ categoryId, actionReason }) =>
        this.lastStage(caseId, currentAction, categoryId, actionReason);
    },
    async lastStage(caseId, currentAction, categoryId, actionReason) {
      this.dialogWorkflowDisplay = false;
      try {
        if (!currentAction.started) {
          await caseRepository.caseStartAction(caseId, currentAction.id);
        }
        await caseRepository.caseFinishAction(caseId, currentAction.id, actionReason);
        // closing case
        await caseClose(this.caseObj.id, categoryId, actionReason);
        this.caseCloseDialog = false;
        // reloading case data
        this.loadData();
      } catch (error) {
        console.error("lastStage error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    askNextStage(caseId, currentAction) {
      this.dialogWorkflowDisplay = true;
      this.dialogWorkflowFunctionOk = () => this.nextStage(caseId, currentAction);
      this.dialogWorkflowTitle = "Agree and Complete";
      this.dialogWorkflowOkButtonColor = "primary";
    },
    async nextStage(caseId, currentAction) {
      this.dialogWorkflowDisplay = false;
      try {
        if (!currentAction.started) {
          await caseRepository.caseStartAction(caseId, currentAction.id);
        }
        await caseRepository.caseFinishAction(caseId, currentAction.id);
        // asyncronous reloading case workflow and case activity
        this.loadCaseWorkflow(this.pk);
        this.loadCaseActivity(this.pk);
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    async askPreviousStage(caseId, currentAction) {
      this.dialogWorkflowDisplay = true;
      this.dialogWorkflowFunctionOk = () => this.previousStage(caseId, currentAction);
      this.dialogWorkflowTitle = "Revert";
      this.dialogWorkflowOkButtonColor = "warning";
    },
    async previousStage(caseId, currentAction) {
      this.dialogWorkflowDisplay = false;
      try {
        await caseRepository.caseRevertAction(caseId, currentAction.id);
        // asyncronous reloading case workflow and case activity
        this.loadCaseWorkflow(this.pk);
        this.loadCaseActivity(this.pk);
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    closeCaseDialog() {
      this.caseCloseDialog = true;
      this.caseCloseHandleFn = this.handleCaseClose;
    },
    async handleCaseClose({ categoryId, actionReason }) {
      try {
        await caseClose(this.caseObj.id, categoryId, actionReason);
        this.caseCloseDialog = false;
        this.loadData();
      } catch (error) {
        console.error("caseClose error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    openCaseDialog() {
      this.caseReopenDialog = true;
    },
    async handleCaseReopen({ actionReason }) {
      try {
        await caseReopen(this.caseObj.id, actionReason);
        this.caseReopenDialog = false;
        this.loadData();
      } catch (error) {
        console.error("caseOpen error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async caseExport() {
      try {
        if (this.exportPassword && this.exportPassword.length >= 8) {
          await caseRepository.caseExport(this.caseObj.id, this.exportPassword);
          EventBus.$emit(
            "notify",
            "success",
            "Case export requested. Your export will appear on the Reports page.",
          );
          this.exportError = null;
          this.showExport = false;
        } else {
          this.exportError = "Please enter a password of at least 8 characters.";
        }
      } catch (error) {
        console.error("caseExport error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async caseDelete() {
      try {
        if (this.deleteName === this.caseObj.name) {
          await caseRepository.caseDelete(this.caseObj.id);
          await this.$router.push({ name: "CaseList", params: { status: "1" } });
        } else if (this.deleteName) {
          this.deleteError = "Name does not match";
        }
      } catch (error) {
        console.error("caseDelete error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    async handlePrint() {
      this.expandCaseNotesIndex = 0;
      this.expandTodoItemsIndex = 0;
      this.expandCaseActivityIndex = 0;
      await this.$nextTick();
      setTimeout(() => {
        window.print();
      }, 500);
    },
    handleCaseNoteAdd(todoItemId = null) {
      this.dialogCaseNoteDisplay = true;
      this.dialogCaseNoteTodoItemId = todoItemId;
    },
    handleCaseNoteUpdate(id, todoItemId = null) {
      this.dialogCaseNoteId = id;
      this.dialogCaseNoteDisplay = true;
      this.dialogCaseNoteTodoItemId = todoItemId;
    },
    handleCloseDialogCaseNote(needsRefresh) {
      this.dialogCaseNoteId = null;
      this.dialogCaseNoteTodoItemId = null;
      this.dialogCaseNoteDisplay = false;
      if (needsRefresh) {
        this.loadCaseNotes(this.pk);
      }
    },
    handleCaseNoteHistory(id) {
      this.dialogCaseNoteHistoryId = id;
      this.dialogCaseNoteHistoryDisplay = true;
    },
    handleSearchConversationFromItem(item) {
      this.$router.push({
        name: "ConversationSearch",
        query: {
          data_types: [item.fingerprint_type],
          person1: item.from_person,
          person2: item.to,
          dt_from: item.fingerprint_time,
          dt_to: item.fingerprint_time,
          restrict_to_firms: [item.fingerprint_firm.id],
        },
      });
    },
    async handleToggleHideItem(djangoId, hide) {
      try {
        if (hide) {
          await hideTodoItem(djangoId);
        } else {
          await showTodoItem(djangoId);
        }
        this.todoItems = this.todoItems.map((todoItem) => ({
          ...todoItem,
          is_hidden: todoItem.django_id === djangoId ? hide : todoItem.is_hidden,
        }));
      } catch (error) {
        console.error("hideItem error", error);
        EventBus.$emit("notify", "warn", error);
      }
    },
    handleShowItemNotes(djangoId) {
      this.dialogTodoItemNotesDisplay = true;
      this.dialogTodoItemNotesId = djangoId;
    },
    handleHideItemNotes() {
      this.dialogTodoItemNotesDisplay = false;
      this.dialogTodoItemNotesId = null;
    },
    handleAskRemoveItem(djangoId) {
      this.removeItemFromCaseDialog = true;
      this.removeItemFromCaseId = djangoId;
    },
    async handleRemoveItem() {
      this.removeItemFromCaseDialog = false;
      try {
        await resetTodoItems([this.removeItemFromCaseId]);
      } catch (error) {
        console.error("removeItemFromCase error", error);
        EventBus.$emit("notify", "warn", error);
      } finally {
        this.loadData();
      }
    },
    getUserFullName(id) {
      const user = this.users.find((u) => u.id === id);
      return user ? user.full_name : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header.secondary {
  color: white;
}

.v-expansion-panel-content::v-deep(.v-expansion-panel-content__wrap) {
  padding: 15px;
}
</style>
