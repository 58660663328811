import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardTitle,[_c('h5',[_vm._v("Case Categories for "+_vm._s(_vm.periodOptions.periodValue))]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VIcon,_vm._g({staticClass:"ml-2"},on),[_vm._v("mdi-information")])]}}])},[_c('div',[_vm._v("Cases created within the time period, by category")])])],1),_c(VCardText,[(_vm.data_case_timecreated_status_category !== undefined)?_c('ChartJs',{attrs:{"height":"300px","type":"pie","data":_vm.pie_chart_data,"options":_vm.pie_chart_options}}):_c('span',[_vm._v("No data available for this period")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }