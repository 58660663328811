import { Auth } from "aws-amplify";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import Vue from "vue";
import VueRouter from "vue-router";
import { useStore } from "@/stores/main";
import { useAuthStore, REDIRECT_KEY_NAME } from "@/stores/auth";
import AdministrationRoutes from "./administration";
import ConfigRoutes from "./configuration";
import CaseRoutes from "./case";
import SearchRoutes from "./search";
import WatchRoutes from "./watch";
import TermRoutes from "./term";
import ReportingRoutes from "./reporting";
import StatsRoutes from "./stats";
import LogsRoutes from "./logs";
import WorkflowRoutes from "./workflow";
import PolicyRoutes from "./policy";
import FaqRoutes from "./faq";
import PageNotFound from "@/components/PageNotFound.vue";
import ServiceUnavailable from "@/components/ServiceUnavailable.vue";
import { AccountStatus } from "@/models/enums";

Vue.use(VueRouter);

let routes = [];
routes = routes.concat(
  AdministrationRoutes,
  ConfigRoutes,
  CaseRoutes,
  SearchRoutes,
  WatchRoutes,
  TermRoutes,
  StatsRoutes,
  LogsRoutes,
  ReportingRoutes,
  WorkflowRoutes,
  PolicyRoutes,
  FaqRoutes,
);
routes.push({ name: "RootPath", path: "/", redirect: { name: "TriageList" } });
routes.push({ name: "PageNotFound", path: "/page-not-found", component: PageNotFound });
routes.push({
  name: "ServiceUnavailable",
  path: "/service-unavailable",
  component: ServiceUnavailable,
});
routes.push({ path: "*", redirect: { name: "PageNotFound" } });

const router = new VueRouter({
  mode: "history", // enable history mode
  routes,
});

if (process.env.VUE_APP_ES_APM_SERVICE_NAME) {
  // https://www.elastic.co/guide/en/apm/agent/rum-js/current/intro.html
  // TODO: move to vue-integration after migrating to Vue 3.x
  // https://www.elastic.co/guide/en/apm/agent/rum-js/current/vue-integration.html
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
      serviceName: process.env.VUE_APP_ES_APM_SERVICE_NAME,

      // Set custom APM Server URL (default: http://localhost:8200)
      serverUrl: process.env.VUE_APP_ES_APM_SERVER_URL,

      // Set service version (required for sourcemap feature)
      serviceVersion: process.env.VUE_APP_VERSION,
      environment: process.env.VUE_APP_ES_APM_ENVIRONMENT,

      distributedTracingOrigins: [process.env.VUE_APP_API],
      propagateTracestate: true,
    },
  });
}

export const handleBeforeEachAwsCognito = async (to, from, next) => {
  try {
    await Auth.currentSession();
  } catch {
    to.fullPath && localStorage.setItem(REDIRECT_KEY_NAME, to.fullPath);
    // Auth.federatedSignIn({
    //   customState: REDIRECT_KEY_NAME,
    // });
  }
  next();
};

export const handleBeforeEachCheckAccountStatus = async (to, from, next) => {
  /**
   * getting userProfile here because when refereshing a page,
   * auth getters ar not loaded yet.
   * Double loading of userProfile, but the only way to be sure it's correct
   */
  const authStore = useAuthStore();
  await authStore.getUserProfile({ onlyAfter60Seconds: true });
  if (
    authStore.userProfile &&
    to.meta.checkAccountFeatures &&
    authStore.userProfile.account?.status !== AccountStatus.ONLINE
  ) {
    return next({ name: "ServiceUnavailable", query: { fullPath: to.fullPath } });
  }
  return next();
};

export const handleBeforeEachCheckAccountFeatures = async (to, from, next) => {
  /**
   * getting userProfile here because when refereshing a page,
   * auth getters ar not loaded yet.
   * Double loading of userProfile, but the only way to be sure it's correct
   */
  const authStore = useAuthStore();
  await authStore.getUserProfile({ onlyAfter60Seconds: true });
  if (
    authStore.userProfile &&
    to.meta.checkAccountFeatures &&
    !to.meta.checkAccountFeatures(authStore)
  ) {
    return next({ name: "PageNotFound", query: { fullPath: to.fullPath } });
  }
  return next();
};

export const handleBeforeEachCheckPrintableRoute = (to, from, next) => {
  const shouldHavePrintLayout = to.matched.some((record) => record.meta.shouldHavePrintLayout);
  const mainStore = useStore();
  mainStore.setShowPrintingLayout(shouldHavePrintLayout);
  next();
};

router.beforeEach(handleBeforeEachAwsCognito);

router.beforeEach(handleBeforeEachCheckAccountStatus);
router.beforeEach(handleBeforeEachCheckAccountFeatures);
router.beforeEach(handleBeforeEachCheckPrintableRoute);

export function refreshRoute() {
  router.currentRoute;
  if (router.currentRoute.meta.refreshTo) {
    router.push(router.currentRoute.meta.refreshTo);
  } else {
    router.go(0);
  }
  // const route = useRoute();
  // const router = useRouter();
  // if (route.name !== "CaseWorkflowList") {
  //   router.push({ name: "CaseWorkflowList" });
  // } else {
  //   router.go();
  // }
}

export default router;
