"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var luxon_1 = require("luxon");
var vue_2 = require("vue");
var types_1 = require("./types");
exports.default = (0, vue_1.defineComponent)({
    __name: 'MenuQuickSelect',
    props: {
        value: null,
        limitEndNow: { type: Boolean }
    },
    emits: ["input"],
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        var commonlyUsed = [
            { value: types_1.PreselectedDates.TODAY, text: "Today" },
            { value: types_1.PreselectedDates.THIS_WEEK, text: "This week" },
            { value: types_1.PreselectedDates.THIS_MONTH, text: "This month" },
            { value: types_1.PreselectedDates.THIS_QUARTER, text: "This quarter" },
            { value: types_1.PreselectedDates.THIS_YEAR, text: "This year" },
            { value: types_1.PreselectedDates.LAST_24_HOURS, text: "Last 24 hours" },
            { value: types_1.PreselectedDates.LAST_7_DAYS, text: "Last 7 days" },
            { value: types_1.PreselectedDates.LAST_30_DAYS, text: "Last 30 days" },
            { value: types_1.PreselectedDates.LAST_90_DAYS, text: "Last 90 days" },
            { value: types_1.PreselectedDates.LAST_YEAR, text: "Last year" },
            { value: types_1.PreselectedDates.LAST_DAY, text: "Last day" },
            { value: types_1.PreselectedDates.LAST_WEEK, text: "Last week" },
            { value: types_1.PreselectedDates.LAST_MONTH, text: "Last month" },
            { value: types_1.PreselectedDates.LAST_QUARTER, text: "Last quarter" },
            { value: types_1.PreselectedDates.LAST_YEAR, text: "Last year" },
        ];
        var commonlyUsedLeftList = commonlyUsed.slice(0, 5);
        var commonlyUsedRightList = commonlyUsed.slice(5, 10);
        var commonlyUsedRelativeLeftList = commonlyUsed.slice(10, 13);
        var commonlyUsedRelativeRightList = commonlyUsed.slice(13, 15);
        var quickSelectInputNumber = (0, vue_2.ref)(2);
        var quickSelectInputRangeType = (0, vue_2.ref)("days");
        function preselectDates(format) {
            var now = luxon_1.DateTime.now();
            switch (format) {
                case types_1.PreselectedDates.TODAY:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.startOf("day"),
                        toValue: props.limitEndNow ? now : now.endOf("day"),
                    });
                    break;
                case types_1.PreselectedDates.THIS_WEEK:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.startOf("week"),
                        toValue: props.limitEndNow ? now : now.endOf("week"),
                    });
                    break;
                case types_1.PreselectedDates.THIS_MONTH:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.startOf("month"),
                        toValue: props.limitEndNow ? now : now.endOf("month"),
                    });
                    break;
                case types_1.PreselectedDates.THIS_QUARTER:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.startOf("quarter"),
                        toValue: props.limitEndNow ? now : now.endOf("quarter"),
                    });
                    break;
                case types_1.PreselectedDates.THIS_YEAR:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.startOf("year"),
                        toValue: props.limitEndNow ? now : now.endOf("year"),
                    });
                    break;
                case types_1.PreselectedDates.LAST_24_HOURS:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ hours: 24 }),
                        toValue: now,
                    });
                    break;
                case types_1.PreselectedDates.LAST_7_DAYS:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ days: 7 }),
                        toValue: now,
                    });
                    break;
                case types_1.PreselectedDates.LAST_30_DAYS:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ days: 30 }),
                        toValue: now,
                    });
                    break;
                case types_1.PreselectedDates.LAST_90_DAYS:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ days: 90 }),
                        toValue: now,
                    });
                    break;
                case types_1.PreselectedDates.LAST_YEAR:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ years: 1 }),
                        toValue: now,
                        offsetType: "years",
                        offsetValue: 1,
                    });
                    break;
                case types_1.PreselectedDates.LAST_DAY:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ days: 1 }),
                        toValue: now,
                        offsetType: "days",
                        offsetValue: 1,
                    });
                    break;
                case types_1.PreselectedDates.LAST_WEEK:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ weeks: 1 }),
                        toValue: now,
                        offsetType: "weeks",
                        offsetValue: 1,
                    });
                    break;
                case types_1.PreselectedDates.LAST_MONTH:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ months: 1 }),
                        toValue: now,
                        offsetType: "months",
                        offsetValue: 1,
                    });
                    break;
                case types_1.PreselectedDates.LAST_QUARTER:
                    emitInputEvent({
                        rangeType: props.value.rangeType,
                        fromValue: now.minus({ months: 3 }),
                        toValue: now,
                        offsetType: "months",
                        offsetValue: 3,
                    });
                    break;
            }
        }
        function handleApplyQuickSelect() {
            var _a;
            emitInputEvent({
                rangeType: props.value.rangeType,
                fromValue: luxon_1.DateTime.now().minus((_a = {},
                    _a[quickSelectInputRangeType.value] = quickSelectInputNumber.value,
                    _a)),
                toValue: luxon_1.DateTime.now(),
                offsetValue: quickSelectInputNumber.value,
                offsetType: quickSelectInputRangeType.value,
            });
        }
        function emitInputEvent(rangeSelection) {
            emit("input", {
                rangeType: rangeSelection.rangeType,
                fromValue: rangeSelection.rangeType === "absolute"
                    ? rangeSelection.fromValue && rangeSelection.fromValue
                    : null,
                toValue: rangeSelection.rangeType === "absolute"
                    ? rangeSelection.toValue && rangeSelection.toValue
                    : null,
                offsetType: rangeSelection.rangeType === "relative" ? rangeSelection.offsetType : null,
                offsetValue: rangeSelection.rangeType === "relative" ? rangeSelection.offsetValue : null,
            });
        }
        return { __sfc: true, props: props, emit: emit, commonlyUsed: commonlyUsed, commonlyUsedLeftList: commonlyUsedLeftList, commonlyUsedRightList: commonlyUsedRightList, commonlyUsedRelativeLeftList: commonlyUsedRelativeLeftList, commonlyUsedRelativeRightList: commonlyUsedRelativeRightList, quickSelectInputNumber: quickSelectInputNumber, quickSelectInputRangeType: quickSelectInputRangeType, preselectDates: preselectDates, handleApplyQuickSelect: handleApplyQuickSelect, emitInputEvent: emitInputEvent, SELECTOR_RANGES: types_1.SELECTOR_RANGES };
    }
});
