"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var filters_1 = require("@/filters");
exports.default = (0, vue_1.defineComponent)({
    __name: 'CaseNote',
    props: {
        note: null,
        canChangeCaseNote: null,
        canDeleteCaseNote: null
    },
    setup: function (__props, _a) {
        var emit = _a.emit;
        var props = __props;
        return { __sfc: true, props: props, emit: emit, mediumDateTimeFormat: filters_1.mediumDateTimeFormat };
    }
});
