<template>
  <div>
    <ejs-stockchart
      id="stockChart"
      :title="title"
      :primary-x-axis="{
        valueType: 'DateTime',
        majorGridLines: { color: 'transparent' },
        crosshairTooltip: { enable: true },
      }"
      :primary-y-axis="{
        lineStyle: { color: 'transparent' },
        majorTickLines: { color: 'transparent', width: 0 },
      }"
      :tooltip="{
        enable: true,
        shared: true,
      }"
      :crosshair="{
        enable: true,
      }"
      :border="{ width: 0 }"
      :enable-period-selector="true"
      :zoom-settings="{
        enableSelectionZooming: true,
        mode: 'X',
      }"
      :axes="[
        {
          name: 'stockAxis',
          lineStyle: { color: 'transparent' },
          majorTickLines: { color: 'transparent', width: 0 },
        },
      ]"
      @stockChartMouseClick="handleStockChartMouseClick"
    >
      <e-stockchart-series-collection>
        <e-stockchart-series
          :data-source="dataSource"
          type="Candle"
          volume="volume"
          x-name="date"
          low="low"
          high="high"
          open="open"
          close="close"
        />
      </e-stockchart-series-collection>
      <e-stockchart-stockevents v-if="eventsA && eventsA.length">
        <e-stockchart-stockevent
          v-for="annotation of annotations"
          :key="annotation.key"
          :date="annotation.date"
          :text="annotation.text"
          :text-style="{ color: 'white' }"
          :description="annotation.description"
          type="Pin"
          :background="annotation.background"
          :series-indexes="[0]"
          place-at="high"
        />
      </e-stockchart-stockevents>
    </ejs-stockchart>
  </div>
</template>
<script>
import {
  StockChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  StockEventsDirective,
  StockEventDirective,
  DateTime,
  CandleSeries,
  Tooltip,
  Crosshair,
  RangeTooltip,
  LineSeries,
  SplineSeries,
  HiloOpenCloseSeries,
  HiloSeries,
  RangeAreaSeries,
  Trendlines,
  EmaIndicator,
  RsiIndicator,
  BollingerBands,
  TmaIndicator,
  MomentumIndicator,
  SmaIndicator,
  AtrIndicator,
  AccumulationDistributionIndicator,
  MacdIndicator,
  StochasticIndicator,
  Export,
  ColumnSeries,
  Zoom,
  StackingColumnSeries,
} from "@syncfusion/ej2-vue-charts";
export default {
  name: "StockChartSyncfusion",
  components: {
    "ejs-stockchart": StockChartComponent,
    "e-stockchart-series-collection": SeriesCollectionDirective,
    "e-stockchart-series": SeriesDirective,
    "e-stockchart-stockevents": StockEventsDirective,
    "e-stockchart-stockevent": StockEventDirective,
  },
  provide: {
    stockChart: [
      DateTime,
      Tooltip,
      Crosshair,
      RangeTooltip,
      LineSeries,
      SplineSeries,
      CandleSeries,
      HiloOpenCloseSeries,
      HiloSeries,
      RangeAreaSeries,
      Trendlines,
      EmaIndicator,
      RsiIndicator,
      BollingerBands,
      TmaIndicator,
      MomentumIndicator,
      SmaIndicator,
      AtrIndicator,
      AccumulationDistributionIndicator,
      MacdIndicator,
      StochasticIndicator,
      Export,
      ColumnSeries,
      Zoom,
      StackingColumnSeries,
    ],
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    dataSource: {
      required: true,
      type: Array,
    },
    eventsA: {
      type: Array,
      default: () => [],
    },
    eventsB: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    annotations() {
      return [].concat(
        this.eventsA.map((e) => ({
          key: e.date.getTime(),
          date: e.date,
          text: "A",
          description: e.description,
          background: "#2B547E",
        })),
        this.eventsB.map((e) => ({
          key: e.date.getTime(),
          date: e.date,
          text: "B",
          description: e.description,
          background: "#800517",
        })),
      );
    },
  },
  methods: {
    handleStockChartMouseClick(args) {
      const target = args.target || "";
      const re = /_StockEvents_(\d+)_(Text|Shape)$/;
      const match = target.match(re);
      if (match) {
        const annotationIndex = parseInt(match[1]) || 0;
        this.$emit("event-marker-click", this.annotations[annotationIndex]);
      }
    },
  },
};
</script>
