<template>
  <div>
    <PageFrame>
      <template #title>
        Suspicious Search Preview (all {{ firmLabelObject.pluralLowercase }}, Category: &quot;{{
          category_name
        }}&quot;, Term: &quot;{{ term_name }}&quot;)
      </template>

      <template #crumb>
        <router-link :to="{ name: 'SuspiciousTermList' }">Risk Terms</router-link>
      </template>

      <DataTable
        grid-id="SuspiciousCategoryDataTable"
        :data-function="dataFunction"
        :hide-checkbox-selection="true"
        firm-field="fingerprint_client"
        firm-foreigh-key-field="firm_identifier"
        :hide-id2-column="true"
        :hide-searches-column="true"
        :hide-modified-columns="true"
      />
    </PageFrame>
  </div>
</template>

<script>
import { mapState } from "pinia";
import DataTable from "./DataTable.vue";
import searchRepository, { getTerm } from "@/repositories/search";
import { getSuspiciousTermCategory } from "@/repositories/todo";
import { useAuthStore } from "@/stores/auth";
import PageFrame from "../PageFrame.vue";

export default {
  name: "SuspiciousCategoryDataTable",
  components: {
    PageFrame,
    DataTable,
  },
  props: {
    pk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      category_name: "",
      term_name: "",
    };
  },
  provide: {
    actions: null,
    batchActions: null,
  },
  computed: {
    ...mapState(useAuthStore, ["firmLabelObject"]),
  },
  async mounted() {
    let response = await getTerm(this.pk);
    this.term_name = response.data.name;
    response = await getSuspiciousTermCategory(response.data.suspicious_term_category);
    this.category_name = response.data.name;
  },
  methods: {
    dataFunction() {
      return searchRepository.getSuspiciousCategoryPreview(this.pk, {
        source: "user",
      });
    },
  },
};
</script>
