import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VTabs,{staticClass:"elevation-2",attrs:{"background-color":"background","grow":""},model:{value:(_setup.selectedTab),callback:function ($$v) {_setup.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.caseWorkflow.actions),function(action,actionIndex){return _c(VTab,{key:`action-${action.id}`,attrs:{"disabled":!action.started}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_setup.canShowButtons(action) && actionIndex > 0)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('action-revert', action)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-arrow-left")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Revert")])]),_c(VSpacer),_c(VIcon,{attrs:{"left":"","color":action.completed ? 'success' : action.running ? 'secondary' : 'grey'}},[_vm._v(" "+_vm._s(action.completed ? "mdi-check-circle" : action.running ? "mdi-dots-horizontal-circle-outline" : "mdi-check-circle-outline")+" ")]),_vm._v(" "+_vm._s(action.name)+" "),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_setup.canShowButtons(action))?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){actionIndex == _vm.caseWorkflow.actions.length - 1
                ? _vm.$emit('action-complete-last', action)
                : _vm.$emit('action-complete', action)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-arrow-right")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_setup.nextButtonText(actionIndex)))])])],1)}),1),_c(VTabsItems,{staticClass:"elevation-2 px-2",model:{value:(_setup.selectedTab),callback:function ($$v) {_setup.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.caseWorkflow.actions),function(action,actionIndex){return _c(VTabItem,{key:`${actionIndex}-content`,attrs:{"step":actionIndex + 1}},[_c(VRow,[_c(VCol,[_c(VList,[_c(VListItem,{staticClass:"text-caption pa-0"},[_c(VListItemAvatar,{staticClass:"mr-1"},[_c(VIcon,{attrs:{"color":action.completed ? 'success' : action.running ? 'secondary' : 'grey'}},[_vm._v(" "+_vm._s(action.completed ? "mdi-checkbox-marked-circle" : action.running ? "mdi-circle-edit-outline" : "mdi-checkbox-blank-circle-outline")+" ")])],1),_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{staticClass:"text-subtitle-2 text--secondary",attrs:{"cols":"12","md":"auto"}},[_c('div',[_c('span',{staticClass:"fixed-label-1"},[_vm._v("Step")]),_c('span',{staticClass:"fixed-label-2"},[_vm._v(" "+_vm._s(action.name)+" ")]),_vm._v(" ("+_vm._s(actionIndex + 1)+" of "+_vm._s(_vm.caseWorkflow.actions.length)+") ")]),(action.started)?_c('div',[_c('span',{staticClass:"fixed-label-1"},[_vm._v("Started")]),_c('span',{staticClass:"fixed-label-2"},[_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(action.started))+" ")]),_vm._v(" ("+_vm._s(_setup.adaptiveRelativeTimeFormat(new Date(action.started) - new Date()))+") ")]):_vm._e(),(action.finished)?_c('div',[_c('span',{staticClass:"fixed-label-1"},[_vm._v("Finished")]),_c('span',{staticClass:"fixed-label-2"},[_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(action.finished))+" ")]),_vm._v(" ("+_vm._s(_setup.adaptiveRelativeTimeFormat(new Date(action.finished) - new Date()))+") ")]):_vm._e(),(
                      action.due_by && (action.running || (action.finished && action.overdue))
                    )?_c('div',[(action.overdue)?_c('span',{staticClass:"error--text"},[_c('span',{staticClass:"fixed-label-1"},[_vm._v("Overdue")]),_c('span',{staticClass:"fixed-label-2"},[_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(action.due_by))+" ")]),_vm._v(" ("+_vm._s(_setup.composeRelativeDateTimeFormat( new Date(action.due_by) - new Date(action.finished || action.started), ))+") ")]):_c('span',[_c('span',{staticClass:"fixed-label-1"},[_vm._v("Due")]),_c('span',{staticClass:"fixed-label-2"},[_vm._v(" "+_vm._s(_setup.mediumDateTimeFormat(action.due_by))+" ")]),_vm._v(" ("+_vm._s(_setup.adaptiveRelativeTimeFormat(new Date(action.due_by) - new Date()))+") ")])]):_vm._e()])],1)],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }