"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    $on: function (eventType, handlerFn) {
        document.addEventListener(eventType, handlerFn);
    },
    $off: function (eventType, handlerFn) {
        document.removeEventListener(eventType, handlerFn);
    },
    $emit: function (eventType) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var e = new CustomEvent(eventType, {
            cancelable: false,
            detail: args,
        });
        document.dispatchEvent(e);
    },
};
