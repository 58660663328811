var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ejs-stockchart',{attrs:{"id":"stockChart","title":_vm.title,"primary-x-axis":{
      valueType: 'DateTime',
      majorGridLines: { color: 'transparent' },
      crosshairTooltip: { enable: true },
    },"primary-y-axis":{
      lineStyle: { color: 'transparent' },
      majorTickLines: { color: 'transparent', width: 0 },
    },"tooltip":{
      enable: true,
      shared: true,
    },"crosshair":{
      enable: true,
    },"border":{ width: 0 },"enable-period-selector":true,"zoom-settings":{
      enableSelectionZooming: true,
      mode: 'X',
    },"axes":[
      {
        name: 'stockAxis',
        lineStyle: { color: 'transparent' },
        majorTickLines: { color: 'transparent', width: 0 },
      },
    ]},on:{"stockChartMouseClick":_vm.handleStockChartMouseClick}},[_c('e-stockchart-series-collection',[_c('e-stockchart-series',{attrs:{"data-source":_vm.dataSource,"type":"Candle","volume":"volume","x-name":"date","low":"low","high":"high","open":"open","close":"close"}})],1),(_vm.eventsA && _vm.eventsA.length)?_c('e-stockchart-stockevents',_vm._l((_vm.annotations),function(annotation){return _c('e-stockchart-stockevent',{key:annotation.key,attrs:{"date":annotation.date,"text":annotation.text,"text-style":{ color: 'white' },"description":annotation.description,"type":"Pin","background":annotation.background,"series-indexes":[0],"place-at":"high"}})}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }