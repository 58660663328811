<template>
  <div>
    <ejs-toast
      id="websocket-notification-toast"
      ref="websocketNotify"
      :time-out="10000"
      :position="{ X: 'Right', Y: 'Top' }"
      :show-close-button="true"
      type="info"
    />
  </div>
</template>

<script>
import { ToastComponent } from "@syncfusion/ej2-vue-notifications";
import { useNotificationsStore } from "@/stores/notifications";

export default {
  components: {
    "ejs-toast": ToastComponent,
  },
  mounted() {
    const notificationsStore = useNotificationsStore();
    let lastNotification = null;

    notificationsStore.$subscribe((_, state) => {
      if (lastNotification !== state.lastWorkflowNotification) {
        lastNotification = state.lastWorkflowNotification;
        try {
          this.$refs.websocketNotify.show({
            title: lastNotification.title,
            content: lastNotification.content,
            buttons: lastNotification.routerLinkObj && [
              {
                model: {
                  content: "Open",
                },
                click: () => {
                  this.$router.push(lastNotification.routerLinkObj).catch(() => {});
                },
              },
            ],
          });
        } catch {
          /* empty */
        }
      }
    });
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-vue-notifications/styles/material3.css";
</style>
