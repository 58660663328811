<template>
  <div>
    <ejs-toast
      id="element"
      ref="notifyObj"
      :time-out="timeout"
      :position="position"
      :show-close-button="true"
    />
  </div>
</template>

<script>
import { ToastComponent } from "@syncfusion/ej2-vue-notifications";
import EventBus from "@/eventBus";

export default {
  name: "NotificationPopup",
  components: {
    "ejs-toast": ToastComponent,
  },
  data() {
    return {
      position: { X: "Right", Y: "Top" },
      timeout: 10000,
    };
  },
  mounted() {
    this.toasts = {
      warn: { title: "Warning", cssClass: "e-toast-warning" },
      success: { title: "Success", cssClass: "e-toast-success" },
      error: { title: "Error", cssClass: "e-toast-danger" },
      info: { title: "Information", cssClass: "e-toast-info" },
    };
    EventBus.$on("notify", this.notifyListener);
  },
  beforeDestroy() {
    EventBus.$off("notify", this.notifyListener);
  },
  methods: {
    notifyListener({ detail: [type, msg] }) {
      return this.handleNotification(type, msg);
    },
    handleNotification(type, msg) {
      const toast = this.toasts[type];
      let message = "";

      if (typeof msg === "string" || msg instanceof String) {
        message = msg;
      } else if (msg.response) {
        let responseData = msg.response.data;
        const responseStatus = msg.response.statusText;
        if (responseData) {
          if (typeof responseData === "object") {
            responseData = Object.keys(responseData).map((k) => responseData[k]);
          }
          if (Array.isArray(responseData)) {
            for (const key in responseData) {
              let val = responseData[key];
              if (Array.isArray(val)) {
                val = val[0];
              }
              message += `${val}. `;
            }
          } else {
            message += responseData;
          }
        } else if (responseStatus) {
          message = responseStatus;
        }
      } else if (msg.message) {
        message = msg.message;
      } else {
        message = "An unknown error occurred.";
      }
      toast.content = message;
      this.timeout = Math.max(10000, message.length * 100);
      if (this.$refs.notifyObj) {
        this.$refs.notifyObj.show(toast);
      }
    },
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-vue-notifications/styles/material3.css";
</style>
