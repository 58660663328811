import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_c('div',[_vm._v("Licenses")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VTabs,{model:{value:(_setup.tab),callback:function ($$v) {_setup.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("Frontend")]),_c(VTab,[_vm._v("Backend")])],1)],1),_c(VCardText,{staticClass:"text--primary"},[_c(VTabsItems,{model:{value:(_setup.tab),callback:function ($$v) {_setup.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c(VRow,[_c(VCol,{staticClass:"bold"},[_vm._v("Name")]),_c(VCol,{staticClass:"bold"},[_vm._v("Author")]),_c(VCol,{staticClass:"bold"},[_vm._v("License Type")])],1),_vm._l((_setup.filteredFrontendLicenses),function(license){return _c(VRow,{key:license.name,attrs:{"dense":""}},[_c(VCol,[_vm._v(" "+_vm._s(license.name)+" "),_c('a',{attrs:{"href":_setup.cleanLicenseLink(license.link),"target":"_blank","title":"Repository link"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-source-repository")])],1)]),_c(VCol,[_vm._v(_vm._s(license.author))]),_c(VCol,[_vm._v(_vm._s(license.licenseType))])],1)})],2),_c(VTabItem,[_c(VRow,[_c(VCol,{staticClass:"bold"},[_vm._v("Name")]),_c(VCol,{staticClass:"bold"},[_vm._v("Author")]),_c(VCol,{staticClass:"bold"},[_vm._v("License Type")])],1),_vm._l((_setup.backendLicenses),function(license){return _c(VRow,{key:license.Name,attrs:{"dense":""}},[_c(VCol,[_vm._v(" "+_vm._s(license.Name)+" "),_c('a',{attrs:{"href":_setup.cleanLicenseLink(license.URL),"target":"_blank","title":"Repository link"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-source-repository")])],1)]),_c(VCol,[_vm._v(_vm._s(license.Author))]),_c(VCol,[_vm._v(_vm._s(license.License))])],1)})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }