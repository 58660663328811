import CaseList from "@/components/crud/CaseList.vue";
import CaseDataTable from "@/components/case/CaseDataTable.vue";
import TriageDataTable from "@/components/datatable/TriageDataTable.vue";
import ActionReasonCategoryList from "@/components/crud/ActionReasonCategoryList.vue";
import CaseCategoryList from "@/components/crud/CaseCategoryList.vue";
import TodoRuleForm from "@/components/crud/TodoRuleForm.vue";
import TodoRuleList from "@/components/crud/TodoRuleList.vue";

const routes = [
  {
    path: "/case/list/:status?/",
    name: "CaseList",
    component: CaseList,
    props: true,
    meta: {
      checkAccountFeatures: ({ canShowCases }) => canShowCases,
    },
  },
  {
    path: "/case/:pk?/",
    name: "CaseDetail",
    component: CaseDataTable,
    meta: {
      checkAccountFeatures: ({ canShowCases }) => canShowCases,
      refreshTo: { name: "CaseList" },
    },
  },
  {
    path: "/case/item/todo/",
    redirect: { name: "TriageList" },
  },
  {
    path: "/todo-list/",
    redirect: { name: "TriageList" },
  },
  {
    path: "/triage-list/",
    name: "TriageList",
    component: TriageDataTable,
    meta: {
      checkAccountFeatures: ({ canShowTriage }) => canShowTriage,
    },
  },
  {
    path: "/case/category/list/",
    name: "CaseCategoryList",
    component: CaseCategoryList,
    meta: {
      checkAccountFeatures: ({ canShowCaseCategories }) => canShowCaseCategories,
    },
  },
  {
    path: "/triage/auto-ignore-rules/",
    name: "TriageAutoIgnoreRules",
    component: TodoRuleList,
    meta: {
      checkAccountFeatures: ({ canShowTriageRules }) => canShowTriageRules,
    },
  },
  {
    path: "/triage/auto-ignore-rules/edit/:id?/",
    name: "TriageAutoIgnoreRulesEdit",
    component: TodoRuleForm,
    props: true,
    meta: {
      checkAccountFeatures: ({ canAddTriageRules, canChangeTriageRules }) =>
        canAddTriageRules || canChangeTriageRules,
    },
  },
  {
    path: "/todo/action-reason-categories/:type",
    name: "ActionReasonCategoryList",
    component: ActionReasonCategoryList,
    props: (route) => ({
      ...route.params,
      type: parseInt(route.params.type, 10),
    }),
    meta: {
      checkAccountFeatures: ({ canShowTriageActionReasonCategories }) =>
        canShowTriageActionReasonCategories,
    },
  },
];

export default routes;
