<template>
  <div>
    <v-row>
      <v-col>
        <MetricBox
          v-if="results_todo_search_actionreason"
          title="Category V No Action Reason"
          class="scroll-box"
          :show-accordion="false"
        >
          <template #metric>
            <v-simple-table>
              <thead>
                <tr v-if="results_todo_search">
                  <th />
                  <th
                    v-for="(search_name, search_index) in results_todo_search"
                    :key="search_index"
                  >
                    {{ search_index }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cat_name, cat_index) in results_todo_search_actionreason"
                  :key="cat_index"
                >
                  <template
                    v-if="
                      results_todo_search &&
                      cat_index !== 'None' &&
                      cat_index !== 'Added via Adhoc Search'
                    "
                  >
                    <td>
                      {{ cat_index || "-- empty --" }}
                    </td>
                    <td
                      v-for="(search_name, search_index) in results_todo_search"
                      :key="search_index"
                    >
                      {{ cat_name[search_index] ? cat_name[search_index]["todo_total"] : 0 }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </MetricBox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MetricBox from "@/components/metrics/MetricBox.vue";

export default {
  name: "CategoryVNoActionReason",
  components: {
    MetricBox,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    jsonData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    results_todo_search() {
      return this.jsonData?.TodoStatsBySearchActioned?.all?.True;
    },
    results_todo_search_actionreason() {
      return this.jsonData?.TodoStatsBySearchByActionReason?.all?.None;
    },
  },
};
</script>
