import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.canShowCount)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VChip,{staticClass:"my-1",attrs:{"color":"warning"}},[_vm._v("Group of "+_vm._s(_vm.data.group_total)+" items")])],1)],1):_vm._e(),(_vm.canChangeTriageItem && _vm.data.actioned)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"error"},on:{"click":_vm.unignoreItem}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-undo")])],1)]}}],null,false,1276010936)},[_c('span',[_vm._v("Move "+_vm._s(_vm.canShowCount ? `${_vm.data.group_total} items` : "this item")+" back into the 'Pending' list")])])],1)],1):_vm._e(),(_vm.canAddCaseItem && !_vm.data.actioned)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"error"},on:{"click":_vm.ignoreItemDialog}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-redo")])],1)]}}],null,false,72757575)},[_c('span',[_vm._v(" Move "+_vm._s(_vm.canShowCount ? `${_vm.data.group_total} items` : "this item")+" to the 'Reviewed - No Action' list ")])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"secondary"},on:{"click":_vm.addItemDialog}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,2416291898)},[_c('span',[_vm._v("Add "+_vm._s(_vm.canShowCount ? `${_vm.data.group_total} items` : "this item")+" to a case")])])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }