<template>
  <div>
    <v-tabs v-model="selectedTab">
      <v-tab> All-Time Metrics </v-tab>
      <v-tab> Metrics Over Time </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <AppMetricsTotals />
      </v-tab-item>
      <v-tab-item>
        <AppMetricsPeriods />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AppMetricsPeriods from "./AppMetricsPeriods.vue";
import AppMetricsTotals from "./AppMetricsTotals.vue";

export default {
  components: {
    AppMetricsPeriods,
    AppMetricsTotals,
  },
  data() {
    return {
      selectedTab: null,
    };
  },
};
</script>
