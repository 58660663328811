import AppMetrics from "@/components/metrics/AppMetrics.vue";
import AppMetricsPDF from "@/components/metrics/AppMetricsPDF.vue";

const routes = [
  {
    path: "/stats/metrics/",
    name: "Stats",
    component: AppMetrics,
    meta: { checkAccountFeatures: ({ canShowStats }) => canShowStats },
  },
  {
    path: "/stats/metrics/pdf/:periodType/:periodValue/view/",
    name: "StatsReport",
    component: AppMetricsPDF,
    props: true,
    meta: { checkAccountFeatures: ({ canShowStats }) => canShowStats },
  },
];

export default routes;
