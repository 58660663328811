import httpClient from "@/interceptors/axios";

export default {
  // Watch Data
  watchList(params) {
    return httpClient.get("watches/", { params });
  },
  watch(id) {
    return httpClient.get(`watches/${id}/`);
  },
  watchSave(payload, id = null) {
    if (id) {
      return httpClient.put(`watches/${id}/`, payload);
    } else {
      return httpClient.post("watches/", payload);
    }
  },
  watchDelete(id) {
    return httpClient.delete(`watches/${id}/`);
  },
  watchRestore(id) {
    return httpClient.patch(`watches/${id}/`, { is_deleted: false });
  },
  watchPermission() {
    return httpClient.get("watches/permission/");
  },

  // Report Data
  reportList(params) {
    return httpClient.get("reports/", { params });
  },
};
