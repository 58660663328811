"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var FaqBlock_vue_1 = require("./FaqBlock.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FaqPage',
    props: {
        page: null
    },
    setup: function (__props) {
        return { __sfc: true, FaqBlock: FaqBlock_vue_1.default };
    }
});
