import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.data.is_deleted)?_c('div',[_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.displayCategDetails),callback:function ($$v) {_vm.displayCategDetails=$$v},expression:"displayCategDetails"}},[(_vm.displayCategDetails)?_c('SuspiciousTermDataTypes',{attrs:{"id":_vm.data.id,"name":_vm.data.name},on:{"close":function($event){_vm.displayCategDetails = false}}}):_vm._e()],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","link":"","small":"","to":{
          name: 'CategorySuspiciousSearchResults',
          params: { pk: _vm.data.id },
        }}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-magnify")])],1)]}}],null,false,551713899)},[_c('span',[_vm._v("Preview")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","link":"","small":"","to":{
          name: 'SuspiciousTermHistory',
          params: { id: _vm.data.id },
        }}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-find-replace")])],1)]}}],null,false,4063006914)},[_c('span',[_vm._v("History of changes")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","icon":"","link":"","small":""},on:{"click":function($event){_vm.displayCategDetails = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,false,1670226004)},[_c('span',[_vm._v("Boost channels")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }