<template>
  <div v-if="selectedCase" class="mb-2">
    <v-tooltip bottom v-if="canAddTriageItem">
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          color="secondary"
          :disabled="checkRowAdded"
          v-bind="attrs"
          v-on="on"
          @click="addItems"
        >
          <v-icon left>mdi-plus</v-icon>
          Batch Add
        </v-btn>
      </template>
      <span>Add selected items to case {{ selectedCase.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from "pinia";
import EventBus from "@/eventBus";
import caseRepository from "@/repositories/case";
import { getPayload } from "../utils";
import { useAuthStore } from "@/stores/auth";
import { useStore } from "@/stores/main";

export default {
  name: "BatchActionsCaseSearch",
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    ...mapState(useStore, {
      selectedCase: (state) => state.case,
    }),
    ...mapState(useAuthStore, ["canAddTriageItem"]),
    checkRowAdded() {
      return this.selectedRows.some((row) => row.innerIsAdded || this.isChecked(row.saved_cases));
    },
  },
  methods: {
    async addItems() {
      for (const row of this.selectedRows) {
        const payload = getPayload(row, this.selectedCase);
        payload.search_params = this.$route.query;
        try {
          await caseRepository.todoItemAdd(payload);
        } catch (error) {
          console.error("addItems error", error);
          EventBus.$emit("notify", "warn", error);
        }
      }
      EventBus.$emit("loadData");
    },
    isChecked(arr) {
      return arr.some((item) => this.selectedCase.id && this.selectedCase.id === item.id);
    },
  },
};
</script>
