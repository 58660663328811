<template>
  <div>
    <PageFrame>
      <template #title>
        <template v-if="pk">
          {{ params.name }}
        </template>
        <template v-else> New Watch Schedule </template>
      </template>

      <template #crumb>
        <template v-if="pk">
          <router-link :to="{ name: 'WatchList' }">Scheduled Searches</router-link>
        </template>
      </template>

      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="params.name"
          auto-grow
          rows="1"
          label="Name"
          hint="Enter a name for this watch"
          prepend-icon="mdi-text-short"
          :rules="rules.name"
          name="name"
        >
          <template #prepend>
            <v-icon small> mdi-text-short </v-icon>
          </template>
        </v-text-field>

        <v-text-field
          v-model="params.description"
          auto-grow
          rows="1"
          label="Description"
          hint="Enter a description for this watch"
          prepend-icon="mdi-text-short"
          :rules="rules.description"
          name="description"
        >
          <template #prepend>
            <v-icon small> mdi-text-short </v-icon>
          </template>
        </v-text-field>

        <v-input
          prepend-icon="mdi-history"
          hint="Enter a schedule for this watch"
          persistent-hint
          name="schedule"
        >
          <template #prepend>
            <v-icon small> mdi-history </v-icon>
          </template>
          <template #default>
            <v-label for="schedule"> Schedule </v-label>
            <CronEditor v-model="params.schedule" :periods="['year', 'month', 'week', 'day']" />
          </template>
        </v-input>

        <v-select
          v-model="params.searches"
          :items="searchList"
          multiple
          label="Select Searches"
          hint="Assign one or more searches"
          small-chips
          deletable-chips
          item-value="id"
          item-text="name"
          prepend-icon="mdi-layers-search-outline"
          :rules="rules.searches"
          class="tour-searches"
        >
          <template #prepend>
            <v-icon small> mdi-layers-search-outline </v-icon>
          </template>
          <template #prepend-item>
            <v-list-item ripple @click="toggleSelectAllSearches">
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>

        <div v-if="pk">
          <v-btn
            v-if="unsavedChanges"
            :disabled="!valid"
            class="mt-2 ml-5"
            color="warning"
            @click="save"
          >
            Save
          </v-btn>
          <v-btn
            v-else
            :disabled="!valid"
            class="mt-2 ml-5"
            color="primary"
            :to="{ name: 'WatchList' }"
          >
            Save
          </v-btn>
        </div>
        <v-btn v-else :disabled="!valid" class="mt-2 ml-5" color="primary" @click="save">
          Save
        </v-btn>
      </v-form>
    </PageFrame>
  </div>
</template>

<script>
import CronEditor from "@/components/common/CronEditor.vue";
import EventBus from "@/eventBus";
import watchRepository from "@/repositories/watch";
import searchRepository from "@/repositories/search";
import PageFrame from "../PageFrame.vue";

export default {
  name: "WatchForm",
  components: {
    PageFrame,
    CronEditor,
  },
  data() {
    return {
      formObj: null,
      searchList: [{}],
      searchData: null,
      savedData: null,
      params: {},
      unsavedChanges: false,
      disableWatch: false,
      valid: true,
      rules: {
        name: [(v) => !!v || "Please enter a name."],
        description: [(v) => !!v || "Please enter a description."],
        notificationType: [
          (v) => (v !== null && v !== undefined) || "Please select a notification type",
        ],
        searches: [(v) => !!v.length || "Please select at least one search"],
      },
    };
  },
  computed: {
    pk() {
      return this.$route.params.pk;
    },
    search_id() {
      return this.$route.params.search_id;
    },
    searchChoices() {
      const searches = this.params.searches || [];
      return this.searchList.filter((item) => !searches.includes(item.id));
    },
    searchSelected() {
      const searches = this.params.searches || [];
      return this.searchList.filter((item) => searches.includes(item.id));
    },
  },
  async mounted() {
    const [searchList, searchData, savedData] = await Promise.all([
      this.getSearchList(),
      this.getSearchData(),
      this.getSavedData(),
    ]);
    this.searchList = searchList;
    this.searchData = searchData;
    this.savedData = savedData;
    this.params = await this.getParams();
    this.watchUnsavedChanges();
  },
  methods: {
    async getSearchList() {
      try {
        const { data } = await searchRepository.searchList({ take: "all" });
        return data;
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    async getSearchData() {
      if (this.search_id) {
        try {
          const r = await searchRepository.search(this.search_id);
          return r.data;
        } catch (error) {
          EventBus.$emit("notify", "warn", error);
        }
      }
    },
    async getSavedData() {
      if (this.pk) {
        try {
          const r = await watchRepository.watch(this.pk);
          return r.data;
        } catch (error) {
          EventBus.$emit("notify", "warn", error);
        }
      }
    },
    async getParams() {
      let params = {};
      const savedData = await this.savedData;
      if (savedData) {
        params = {
          name: savedData.name,
          description: savedData.description,
          schedule: savedData.schedule,
          notify_by: savedData.notify_by,
          searches: savedData.searches,
        };
      } else if (this.search_id) {
        params = {
          name: null,
          description: null,
          schedule: "0 0 */1 * *",
          notify_by: [],
          searches: [this.search_id],
        };
      } else {
        params = {
          name: null,
          description: null,
          schedule: "0 0 */1 * *",
          notify_by: [],
          searches: [],
        };
      }
      return params;
    },
    async save() {
      try {
        // wrap with try/catch otherwise unit tests fail
        if (!this.formObj.validate()) return;
      } catch (e) {
        // error
      }
      try {
        const r = await watchRepository.watchSave(this.params, this.pk);
        if (this.pk) {
          this.savedData = r.data;
          this.params = await this.getParams();
          this.disableWatch = true;
          this.unsavedChanges = false;
        } else {
          await this.$router.push({ name: "WatchList" });
        }
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
    toggleSelectAllSearches() {
      if (this.params.searches.length === this.searchList.length) {
        this.params.searches = [];
      } else {
        this.params.searches = this.searchList.map((f) => f.id);
      }
    },
    watchUnsavedChanges() {
      setTimeout(() => {
        for (const p of ["params"]) {
          this.$watch(
            p,
            function () {
              if (this.disableWatch) {
                this.disableWatch = false;
              } else {
                this.unsavedChanges = true;
              }
            },
            { deep: true },
          );
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
::v-deep(.v-input__slot) {
  display: unset;
}
</style>
