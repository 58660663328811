"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldList = getFieldList;
exports.getSearchWeightList = getSearchWeightList;
exports.getActions = getActions;
exports.getDataTypes = getDataTypes;
exports.getLanguages = getLanguages;
exports.getObfuscationLevels = getObfuscationLevels;
exports.getReportTimePeriods = getReportTimePeriods;
exports.getSearchableFields = getSearchableFields;
exports.getSearchAnalyzers = getSearchAnalyzers;
exports.getSearchWeightingMethods = getSearchWeightingMethods;
exports.getReportNotifyTypes = getReportNotifyTypes;
exports.getWorkflowNotifyTypes = getWorkflowNotifyTypes;
exports.getFirmGroups = getFirmGroups;
exports.updateFirmGroup = updateFirmGroup;
exports.deleteFirmGroup = deleteFirmGroup;
exports.getRedactTerms = getRedactTerms;
exports.saveRedactTerm = saveRedactTerm;
exports.deleteRedactTerm = deleteRedactTerm;
var cache_1 = require("@/cache");
var axios_1 = require("@/interceptors/axios");
var utils_1 = require("@/utils");
var utils_2 = require("./utils");
function getFieldList() {
    var _this = this;
    return (0, cache_1.getCachedOrFetch)("fieldList", function () { return __awaiter(_this, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("settings/fields/")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    err_1 = _a.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ });
}
function getSearchWeightList() {
    var _this = this;
    return (0, cache_1.getCachedOrFetch)("searchWeightList", function () { return __awaiter(_this, void 0, void 0, function () {
        var data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("settings/search_weights/")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    err_2 = _a.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ });
}
function getActions() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("actions", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, err_3;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/actions/")];
                            case 1:
                                data = (_a.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                err_3 = _a.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getDataTypes() {
    return __awaiter(this, arguments, void 0, function (iconColorOpacity) {
        var firmGroups, dataTypesObj;
        var _this = this;
        if (iconColorOpacity === void 0) { iconColorOpacity = 0.85; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, cache_1.getCachedOrFetch)("dataTypes", function () { return __awaiter(_this, void 0, void 0, function () {
                        var data, err_4;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, axios_1.default.get("settings/data_types/", { params: (0, utils_2.withFirmGroup)() })];
                                case 1:
                                    data = (_a.sent()).data;
                                    return [2 /*return*/, data];
                                case 2:
                                    err_4 = _a.sent();
                                    return [2 /*return*/, []];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
                case 1:
                    firmGroups = _a.sent();
                    dataTypesObj = {};
                    firmGroups.forEach(function (firmGroup) {
                        firmGroup.firms.forEach(function (firm) {
                            firm.data_types.forEach(function (dataType) {
                                dataTypesObj[dataType.id] = dataType.name;
                            });
                        });
                    });
                    return [2 /*return*/, Object.entries(dataTypesObj).map(function (_a) {
                            var dataTypeId = _a[0], dataTypeName = _a[1];
                            return ({
                                id: dataTypeId,
                                name: dataTypeName,
                                color: (0, utils_1.getDataTypeColor)(dataTypeId, iconColorOpacity),
                                icon: (0, utils_1.getDataTypeIcon)(dataTypeId),
                            });
                        })];
            }
        });
    });
}
function getLanguages() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("languages", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, err_5;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/languages/")];
                            case 1:
                                data = (_a.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                err_5 = _a.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getObfuscationLevels() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("obfuscationLevels", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, err_6;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/obfuscation_levels/")];
                            case 1:
                                data = (_a.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                err_6 = _a.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getReportTimePeriods() {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("reportTimePeriods", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/report-time-periods/")];
                            case 1:
                                data = (_b.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getSearchableFields() {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    return resolve([
                        { key: "subject", label: "Subject" },
                        { key: "body", label: "Body" },
                        { key: "from_", label: "From" },
                        { key: "to", label: "To" },
                        { key: "cc", label: "Cc" },
                        { key: "bcc", label: "Bcc" },
                        { key: "attachments.content", label: "Attachment" },
                    ]);
                })];
        });
    });
}
// https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-analyzers.html
function getSearchAnalyzers() {
    return __awaiter(this, void 0, Promise, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("searchAnalyzers", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/search_analysers/")];
                            case 1:
                                data = (_b.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#query-string-multi-field-parms
function getSearchWeightingMethods() {
    return __awaiter(this, void 0, Promise, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("searchWeightingMethods", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/search_weighting_methods/")];
                            case 1:
                                data = (_b.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getReportNotifyTypes() {
    return __awaiter(this, void 0, Promise, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("reportNotifyTypes", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/report_notify_types/")];
                            case 1:
                                data = (_b.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getWorkflowNotifyTypes() {
    return __awaiter(this, void 0, Promise, function () {
        var _this = this;
        return __generator(this, function (_a) {
            return [2 /*return*/, (0, cache_1.getCachedOrFetch)("workflowNotifyTypes", function () { return __awaiter(_this, void 0, void 0, function () {
                    var data, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, axios_1.default.get("settings/workflow_notify_types/")];
                            case 1:
                                data = (_b.sent()).data;
                                return [2 /*return*/, data];
                            case 2:
                                _a = _b.sent();
                                return [2 /*return*/, []];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }, { ttl: 1000 * 60 * 60 /* 1 hour */ })];
        });
    });
}
function getFirmGroups() {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("firmgroups/", { params: { take: "all" } })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function updateFirmGroup(firmGroup) {
    return __awaiter(this, void 0, Promise, function () {
        var httpClientMethod, httpClientUrl, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    httpClientMethod = axios_1.default.post;
                    httpClientUrl = "firmgroups/";
                    if (firmGroup.id) {
                        httpClientMethod = axios_1.default.patch;
                        httpClientUrl = "firmgroups/".concat(firmGroup.id, "/");
                    }
                    return [4 /*yield*/, httpClientMethod(httpClientUrl, firmGroup)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
function deleteFirmGroup(firmGroupId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios_1.default.delete("firmgroups/".concat(firmGroupId, "/"))];
        });
    });
}
function getRedactTerms() {
    return __awaiter(this, void 0, Promise, function () {
        var data, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios_1.default.get("redactterms/", {
                            params: { ordering: "term", take: "all" },
                        })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function saveRedactTerm(redactTerm) {
    return __awaiter(this, void 0, Promise, function () {
        var httpClientMethod, httpClientUrl, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    httpClientMethod = axios_1.default.post;
                    httpClientUrl = "redactterms/";
                    if (redactTerm.id) {
                        httpClientMethod = axios_1.default.patch;
                        httpClientUrl = "redactterms/".concat(redactTerm.id, "/");
                    }
                    return [4 /*yield*/, httpClientMethod(httpClientUrl, redactTerm)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
function deleteRedactTerm(redactTermId) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios_1.default.delete("redactterms/".concat(redactTermId, "/"))];
        });
    });
}
