import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v("Risk Terms")]),_c(VCardSubtitle,[_c(VCheckbox,{attrs:{"label":"Include disabled items"},on:{"change":function($event){return _vm.handleDataStateChange()}},model:{value:(_vm.includeDisabled),callback:function ($$v) {_vm.includeDisabled=$$v},expression:"includeDisabled"}})],1)],1),(_vm.columns.length)?_c('BasicList',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"grid-params":{
      allowPaging: true,
      pageSettings: _vm.pageSettings,
      allowSorting: true,
      allowResizing: true,
      autoFitColumns: true,
      selectionSettings: { enableToggle: false },
      allowEditing: true,
      editSettings: _vm.editSettings,
      toolbar: ['Add', 'Edit', 'ExcelExport', 'CsvExport', 'Print'],
      gridLines: 'Both',
      dataStateChange: _vm.handleDataStateChange,
      rowSelected: _vm.handleRowSelected,
      actionBegin: _vm.handleActionBegin, // used for saving data
      actionComplete: _vm.handleActionComplete, // used for resizing edit dialog
      loadingIndicator: {
        indicatorType: 'Shimmer',
      },
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }