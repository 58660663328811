"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addConsoleEvent = addConsoleEvent;
exports.hookLogType = hookLogType;
exports.errorListenerFn = errorListenerFn;
exports.unhandledRejectionListenerFn = unhandledRejectionListenerFn;
exports.initErrorsInterceptor = initErrorsInterceptor;
exports.disposeErrorsInterceptor = disposeErrorsInterceptor;
var original = {
    warn: console.warn,
    error: console.error,
    log: console.log,
    debug: console.debug,
};
function addConsoleEvent(type, value) {
    if (console.everything) {
        console.everything.push({
            timestamp: new Date(),
            type: type,
            value: value,
        });
        console.everything = console.everything.slice(-2);
    }
}
function hookLogType(logType) {
    original[logType] = console[logType].bind(console);
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        addConsoleEvent(logType, args);
        original[logType].apply(console, args);
    };
}
function errorListenerFn(e) {
    addConsoleEvent("exception", {
        error_message: e.error.message,
        filename: e.filename,
        lineno: e.lineno,
    });
    return false;
}
function unhandledRejectionListenerFn(e) {
    addConsoleEvent("promiseRejection", e.reason);
    return false;
}
function initErrorsInterceptor() {
    if (console.everything === undefined) {
        console.everything = [];
    }
    addEventListener("error", errorListenerFn);
    addEventListener("unhandledrejection", unhandledRejectionListenerFn);
    console.error = hookLogType("error");
    console.warn = hookLogType("warn");
}
function disposeErrorsInterceptor() {
    delete console.everything;
    removeEventListener("error", errorListenerFn);
    removeEventListener("unhandledrejection", unhandledRejectionListenerFn);
    console.error = original.error;
    console.warn = original.warn;
}
