import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.PageFrame,{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Websocket Messaging")]},proxy:true}])},[_c(VCard,[_c(VCardTitle,[_vm._v("Send test message to echo service")]),_c(VCardText,[_c(VTextarea,{attrs:{"label":"Message","outlined":"","rows":"7","hide-details":""},model:{value:(_setup.testMessage),callback:function ($$v) {_setup.testMessage=$$v},expression:"testMessage"}}),(_setup.sendSocketError)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(_vm._s(_setup.sendSocketError))]):_vm._e()],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"accent"},on:{"click":_setup.sendSocketMessage}},[_vm._v("Send Socket Message")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }