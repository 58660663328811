"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var search_1 = require("@/repositories/search");
var settings_1 = require("@/repositories/settings");
var eventBus_1 = require("@/eventBus");
exports.default = (0, vue_1.defineComponent)({
    __name: 'SuspiciousTermDataTypes',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
    emits: ["close"],
    setup: function (__props, _a) {
        var _this = this;
        var emit = _a.emit;
        var props = __props;
        var boostData = (0, vue_2.ref)([]);
        var isSaving = (0, vue_2.ref)(false);
        function getBoostData() {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _dataTypes, _suspiciousCategoryBoosts, _boostsByDataType, _boostArray;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, Promise.all([(0, settings_1.getDataTypes)(), (0, search_1.getSuspiciousCategoryBoosts)(props.id)])];
                        case 1:
                            _a = _b.sent(), _dataTypes = _a[0], _suspiciousCategoryBoosts = _a[1].data;
                            _boostsByDataType = _suspiciousCategoryBoosts.reduce(function (acc, e) {
                                var _a;
                                return (__assign(__assign({}, acc), (_a = {}, _a[e.data_type] = { id: e.id, boost: e.boost }, _a)));
                            }, {});
                            _boostArray = _dataTypes.map(function (dt) {
                                var _a, _b;
                                return ({
                                    id: (_a = _boostsByDataType[dt.id]) === null || _a === void 0 ? void 0 : _a.id,
                                    data_type: dt.id,
                                    dataTypeName: dt.name,
                                    dataTypeIcon: dt.icon,
                                    search_data: props.id,
                                    boost: ((_b = _boostsByDataType[dt.id]) === null || _b === void 0 ? void 0 : _b.boost) || 0,
                                });
                            });
                            return [2 /*return*/, _boostArray];
                    }
                });
            });
        }
        function handleBoostUpdate(suspiciousCategoryBoost, forceValue) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, error_1, _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            isSaving.value = true;
                            if (forceValue !== undefined) {
                                suspiciousCategoryBoost.boost = forceValue;
                            }
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 7, 8, 10]);
                            if (!(suspiciousCategoryBoost.boost === 0)) return [3 /*break*/, 4];
                            _a = suspiciousCategoryBoost.id;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, (0, search_1.deleteSuspiciousCategoryBoosts)(suspiciousCategoryBoost.id)];
                        case 2:
                            _a = (_c.sent());
                            _c.label = 3;
                        case 3:
                            _a;
                            return [3 /*break*/, 6];
                        case 4: return [4 /*yield*/, (0, search_1.saveSuspiciousCategoryBoosts)(suspiciousCategoryBoost)];
                        case 5:
                            _c.sent();
                            _c.label = 6;
                        case 6: return [3 /*break*/, 10];
                        case 7:
                            error_1 = _c.sent();
                            console.error(error_1);
                            eventBus_1.default.$emit("notify", "warn", error_1);
                            return [3 /*break*/, 10];
                        case 8:
                            _b = boostData;
                            return [4 /*yield*/, getBoostData()];
                        case 9:
                            _b.value = _c.sent();
                            isSaving.value = false;
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = boostData;
                        return [4 /*yield*/, getBoostData()];
                    case 1:
                        _a.value = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _b.sent();
                        console.error(error_2);
                        eventBus_1.default.$emit("notify", "warn", error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
        return { __sfc: true, props: props, emit: emit, boostData: boostData, isSaving: isSaving, getBoostData: getBoostData, handleBoostUpdate: handleBoostUpdate };
    }
});
