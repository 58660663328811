<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { getCaseCategoriesByReasonClosed, updateCaseCategory } from "@/repositories/case";
import {
  createActionReasonCategory,
  deleteActionReasonCategory,
  getActionReasonCategories,
  getTodoCategoriesByActionReason,
  getCategoryTypes,
  updateActionReasonCategory,
  updateTodoItemsCategory,
} from "@/repositories/todo";
import { CategoryType } from "@/models/enums";

const props = defineProps({
  type: {
    type: Number,
    required: true,
  },
});

watch(
  () => props.type,
  () => {
    loadData();
  },
);

const isDataLoading = ref(false);
const categories = ref([]);
const types = ref([]);
const categoryReasons = ref([]);
const dialogEditCategory = ref(false);
const editingCategory = ref(null);
const formValid = ref(false);
const rules = {
  name: [
    (v) => !!v || "Category name is required",
    (v) =>
      !categories.value.some(
        (c) =>
          c.type === editingCategory.value.type &&
          c.id !== editingCategory.value.id &&
          c.name === v.trim(),
      ) || "This category name already exists",
  ],
};
const dialogEditReason = ref(false);
const editingReasonStruct = ref(null);
const formReasonValid = ref(false);
const dialogDeleteCategory = ref(false);

const typesCategories = computed(() => {
  const retVal = types.value
    .filter((type) => type.key === props.type)
    .map((type) => ({
      ...type,
      categories: categories.value
        .filter((category) => category.type === type.key)
        .concat({ id: null, name: "Other...", type: type.key })
        .map((c) => ({
          ...c,
          reasons: categoryReasons.value.filter(
            (c1) => c.type === c1.action_category.type && c.id === c1.action_category.id,
          )[0]?.reasons,
        })),
    }));
  return retVal;
});
const editingReasonCategories = computed(() => {
  if (!editingReasonStruct.value) {
    return [];
  }
  return typesCategories.value.filter((t) => t.key === editingReasonStruct.value.typeKey)[0]
    .categories;
});

async function loadData() {
  isDataLoading.value = true;
  let promiseReasons = () => Promise.resolve([]);
  if (props.type === 1) {
    promiseReasons = getTodoCategoriesByActionReason;
  } else if (props.type === 2) {
    promiseReasons = getCaseCategoriesByReasonClosed;
  }
  const [_types, _categories, _reasons] = await Promise.all([
    getCategoryTypes(),
    getActionReasonCategories(props.type, false, true),
    promiseReasons(),
  ]);
  types.value = _types;
  categories.value = _categories;
  categoryReasons.value = [].concat(_reasons);
  isDataLoading.value = false;
}

function handleAddNewCategory(type) {
  editingCategory.value = {
    name: "",
    type,
  };
  dialogEditCategory.value = true;
}

function handleCategoryEdit(category) {
  editingCategory.value = { ...category };
  dialogEditCategory.value = true;
}

function handleCategoryDelete(category) {
  editingCategory.value = { ...category };
  dialogDeleteCategory.value = true;
}

async function handleSaveCategory() {
  if (editingCategory.value.id) {
    await updateActionReasonCategory(editingCategory.value);
  } else {
    await createActionReasonCategory(editingCategory.value.name, editingCategory.value.type);
  }
  editingCategory.value = null;
  dialogEditCategory.value = false;
  loadData();
}

async function handleConfirmDeleteCategory() {
  await deleteActionReasonCategory(editingCategory.value.id);
  editingCategory.value = null;
  dialogDeleteCategory.value = false;
  loadData();
}

function handleBadgeReasonClick(typeKey, category, reason) {
  editingReasonStruct.value = {
    typeKey,
    fromCategoryId: category.id,
    category: { ...category },
    reason: { ...reason },
  };
  dialogEditReason.value = true;
}

async function handleSaveReason() {
  switch (editingReasonStruct.value.typeKey) {
    case CategoryType.TODO:
      await updateTodoItemsCategory(
        editingReasonStruct.value.fromCategoryId,
        editingReasonStruct.value.category.id,
        editingReasonStruct.value.reason.reason,
      );
      break;
    case CategoryType.CASE:
      await updateCaseCategory(
        editingReasonStruct.value.fromCategoryId,
        editingReasonStruct.value.category.id,
        editingReasonStruct.value.reason.reason,
      );
      break;
  }
  editingReasonStruct.value = null;
  dialogEditReason.value = false;
  loadData();
}

onMounted(() => {
  loadData();
});
</script>

<template>
  <div>
    <v-dialog v-if="editingCategory" v-model="dialogEditCategory" persistent max-width="600px">
      <v-form v-model="formValid" @submit.prevent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Category {{ editingCategory.id ? "edit" : "add" }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editingCategory.name"
                    :rules="rules.name"
                    label="Category name"
                    required
                    autofocus
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialogEditCategory = false"> Close </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!formValid"
              @click.stop="handleSaveCategory"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="editingCategory" v-model="dialogDeleteCategory" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Deleting Category &quot;{{ editingCategory.name }}&quot;</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the category &quot;{{
            editingCategory.name
          }}&quot;?<br /><br />
          This operation cannot be canceled.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogDeleteCategory = false"> Cancel </v-btn>
          <v-btn color="error" type="submit" @click.stop="handleConfirmDeleteCategory">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="editingReasonStruct" v-model="dialogEditReason" persistent max-width="600px">
      <v-form v-model="formReasonValid" @submit.prevent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Change reason category</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="editingReasonStruct.category.id"
                    :items="editingReasonCategories"
                    item-value="id"
                    item-text="name"
                    label="Category"
                    autofocus
                  />
                  <v-text-field
                    v-model="editingReasonStruct.reason.reason"
                    label="Reason description"
                    disabled
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  This change will be reflected on
                  {{ editingReasonStruct.reason.count !== 1 ? "all" : "" }}
                  {{ editingReasonStruct.reason.count }} item{{
                    editingReasonStruct.reason.count !== 1 ? "s" : ""
                  }}<br />
                  with category &quot;{{ editingReasonStruct.category.name }}&quot;<br />
                  and reason &quot;{{ editingReasonStruct.reason.reason }}&quot;<br />
                  <br />
                  Are you sure?
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialogEditReason = false"> Cancel </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!formReasonValid"
              @click.stop="handleSaveReason"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card>
      <v-card-title> Action Reason Categories </v-card-title>
      <v-card-text>
        <v-skeleton-loader v-if="isDataLoading" type="list-item" />
        <div v-for="catType of typesCategories" :key="catType.key" class="mb-2">
          <v-row dense>
            <v-col>
              <h3>{{ catType.label }}</h3>
            </v-col>
            <v-col cols="auto">
              <v-btn small color="primary" @click="handleAddNewCategory(catType.key)">
                <v-icon left> mdi-plus-box-outline </v-icon>
                New Category
              </v-btn>
            </v-col>
          </v-row>
          <v-expansion-panels>
            <v-expansion-panel v-for="category of catType.categories" :key="category.id">
              <v-expansion-panel-header>
                <div>
                  {{ category.name }}
                </div>
                <div class="my-panel-buttons">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="handleCategoryEdit(category)"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Category name</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="category.id && (!category.reasons || category.reasons.length === 0)"
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="handleCategoryDelete(category)"
                      >
                        <v-icon> mdi-trash-can </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Category</span>
                  </v-tooltip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.reasons && category.reasons.length >= 0">Reasons provided</div>
                <div v-else>No reasons provided</div>
                <v-badge
                  v-for="reason of category.reasons"
                  :key="reason.reason"
                  :content="reason.count"
                  overlap
                  class="m-2"
                >
                  <v-chip
                    close
                    close-icon="mdi-pencil"
                    link
                    @click="handleBadgeReasonClick(catType.key, category, reason)"
                    @click:close="handleBadgeReasonClick(catType.key, category, reason)"
                  >
                    {{ reason.reason }}
                  </v-chip>
                </v-badge>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.my-panel-buttons {
  flex-grow: 0;
  margin: 0 10px;
}
</style>
