"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withFirmGroup = withFirmGroup;
exports.withFirmGroups = withFirmGroups;
var auth_1 = require("@/stores/auth");
function withFirmGroup(obj) {
    var _a;
    var authStore = (0, auth_1.useAuthStore)();
    return __assign({ firm_group: (_a = authStore.selectedFirmGroup) === null || _a === void 0 ? void 0 : _a.id }, obj);
}
function withFirmGroups(obj) {
    var _a, _b;
    var authStore = (0, auth_1.useAuthStore)();
    return __assign({ firm_groups: ((_a = authStore.selectedFirmGroup) === null || _a === void 0 ? void 0 : _a.id) && [(_b = authStore.selectedFirmGroup) === null || _b === void 0 ? void 0 : _b.id] }, obj);
}
