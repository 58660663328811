<template>
  <ejs-grid
    ref="ejsGrid"
    id="ejsGrid"
    :data-source="dataSource"
    :data-bound="handleDataBound"
    :toolbar-click="handleToolbarClick"
    :allow-excel-export="true"
    :allow-csv-export="true"
    v-bind="gridParams"
    :action-begin="handleActionBegin"
    :action-complete="handleActionComplete"
    :edit-settings="computedEditSettings"
    :query-cell-info="handleQueryCellInfo"
    :row-selected="handleRowSelected"
  >
    <e-columns>
      <e-column v-for="col in columns" :key="col.id" v-bind="col" />
    </e-columns>
    <e-aggregates v-if="aggregateColumns.length > 0">
      <e-aggregate>
        <e-columns>
          <e-column v-for="col in aggregateColumns" :key="col.id" v-bind="col"></e-column>
        </e-columns>
      </e-aggregate>
    </e-aggregates>
  </ejs-grid>
</template>

<script>
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  AggregatesDirective,
  AggregateDirective,
  Aggregate,
  DetailRow,
  Edit,
  ExcelExport,
  Filter,
  ForeignKey,
  // Group,
  Page,
  // Reorder,
  Resize,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-vue-grids";
import { obfuscate } from "@/plugins/obfuscate";
import { getColumnsVisibility, restoreColumnsVisibility } from "@/components/datatable/utils";

export default {
  name: "BasicList",
  components: {
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
    "e-aggregates": AggregatesDirective,
    "e-aggregate": AggregateDirective,
  },
  provide: {
    grid: [
      Aggregate,
      DetailRow,
      Edit,
      ExcelExport,
      Filter,
      ForeignKey,
      // Group,
      Page,
      // Reorder,
      Resize,
      Sort,
      Toolbar,
    ],
  },
  props: {
    aggregateColumns: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: [Object, Array],
      default: () => undefined,
    },
    gridParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      innerEditSettings: {
        allowEditing: false,
        allowEditOnDblClick: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Dialog",
        showDeleteConfirmDialog: true,
      },
    };
  },
  computed: {
    computedEditSettings() {
      return {
        ...this.innerEditSettings,
        ...this.gridParams?.editSettings,
      };
    },
  },
  methods: {
    handleActionBegin(e) {
      if (this.gridParams.actionBegin) {
        e._innerGrid = this.$refs.ejsGrid;
        this.gridParams.actionBegin(e);
      }
    },
    handleActionComplete(e) {
      /* istanbul ignore else */
      if (e.requestType === "add") {
        e.dialog.header = "New Item";
      } else if (e.requestType === "beginEdit") {
        e.dialog.header = "Edit Item";
      }
      if (this.gridParams.actionComplete) {
        e._innerGrid = this.$refs.ejsGrid;
        this.gridParams.actionComplete(e);
      }
    },
    handleDataBound() {
      if (this.gridParams.autoFitColumns) {
        this.$refs.ejsGrid.autoFitColumns();
      }
    },
    handleQueryCellInfo(e) {
      if (e.column.obfuscate) {
        obfuscate.obfuscate(e.cell);
      }
      if (this.gridParams.queryCellInfo) {
        this.gridParams.queryCellInfo(e);
      }
    },
    handleRowSelected(e) {
      if (e.data.id) {
        this.innerEditSettings = {
          ...this.innerEditSettings,
          allowEditing: !!(
            this.gridParams.editSettings?.allowEditing && e.data?.permissions?.change
          ),
          allowEditOnDblClick: !!(
            this.gridParams.editSettings?.allowEditOnDblClick && e.data?.permissions?.change
          ),
          allowDeleting: !!(
            this.gridParams.editSettings?.allowDeleting && e.data?.permissions?.delete
          ),
        };
      }
      if (this.gridParams.rowSelected) {
        this.gridParams.rowSelected(e);
      }
    },
    toggleExportSpecialColumnsVisibility(gridColumns, value = false) {
      const index = gridColumns.findIndex((col) => col.field === "actions");
      gridColumns[index].visible = value; // actions column
    },
    async handleToolbarClick(args) {
      const gridColumns = this.$refs.ejsGrid.getColumns();
      const oldVisibility = getColumnsVisibility(gridColumns);
      if (args.item.text === "Excel Export") {
        try {
          gridColumns.forEach((col) => {
            col.visible = true;
          });
          this.toggleExportSpecialColumnsVisibility(gridColumns, false);
          await this.$refs.ejsGrid.excelExport();
        } finally {
          restoreColumnsVisibility(gridColumns, oldVisibility);
          this.toggleExportSpecialColumnsVisibility(gridColumns, true);
        }
      } else if (args.item.text === "CSV Export") {
        try {
          gridColumns.forEach((col) => {
            col.visible = true;
          });
          this.toggleExportSpecialColumnsVisibility(gridColumns, false);
          await this.$refs.ejsGrid.csvExport();
        } finally {
          restoreColumnsVisibility(gridColumns, oldVisibility);
          this.toggleExportSpecialColumnsVisibility(gridColumns, true);
        }
      }
    },
  },
};
</script>

<style>
@import "@syncfusion/ej2-base/styles/material3.css";
@import "@syncfusion/ej2-buttons/styles/material3.css";
@import "@syncfusion/ej2-calendars/styles/material3.css";
@import "@syncfusion/ej2-dropdowns/styles/material3.css";
@import "@syncfusion/ej2-inputs/styles/material3.css";
@import "@syncfusion/ej2-navigations/styles/material3.css";
@import "@syncfusion/ej2-popups/styles/material3.css";
@import "@syncfusion/ej2-splitbuttons/styles/material3.css";
@import "@syncfusion/ej2-vue-grids/styles/material3.css";
</style>
