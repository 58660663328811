<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <ChooseCase />
    <PageFrame>
      <template #title>
        Messages between {{ person1 }} (A) and {{ person2 }} (B)
        <v-progress-circular
          v-if="showLoading"
          class="mx-3"
          color="warning"
          indeterminate
          size="24"
        />
      </template>

      <template #actions>
        <v-btn color="warning" :to="{ name: 'ConversationSearch', query: $route.query }">
          Modify Search
        </v-btn>
      </template>

      <div ref="mainElement">
        <StockChartSyncfusion
          v-if="canShowConversationStockSearch && stockTimeline"
          :title="chartTitle"
          :data-source="stockTimeline.values"
          :events-a="dataAnnotationsA"
          :events-b="dataAnnotationsB"
          @event-marker-click="handleEventMarkerClick"
        />

        Subject: {{ subject }}
        <v-alert
          v-if="!isDataLoading && timelineData.length === 0"
          class="m-2"
          color="purple"
          dense
          outlined
          text
          type="success"
        >
          No results found
        </v-alert>
        <v-expansion-panels v-model="expandedPanels" accordion multiple ripple>
          <v-expansion-panel
            v-for="dataItem in timelineDataGrouped"
            :id="`expansion-panel.${dataItem.key}`"
            :key="dataItem.key"
          >
            <v-expansion-panel-header v-obfuscate ripple expand>
              <span>
                {{ fullDateFormat(new Date(dataItem.key)) }}
              </span>
              <span class="text-right mr-1">
                {{ `${dataItem.items.length} message${dataItem.items.length !== 1 ? "s" : ""}` }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-timeline>
                <v-timeline-item
                  v-for="item in dataItem.items"
                  :key="item.id"
                  fill-dot
                  large
                  v-bind="item.timelineProps"
                >
                  <template #icon>
                    <v-icon color="background" large>
                      {{ item.timelineProps.left ? "mdi-chevron-right" : "mdi-chevron-left" }}
                    </v-icon>
                  </template>
                  <template #opposite>
                    <div :class="{ 'text-right': item.timelineProps.right }">
                      <div>{{ fullDateTimeFormat(item.fingerprint_time) }}</div>
                      <div>
                        {{ item.fingerprint_type }}
                        <v-icon small>{{ getDataTypeIcon(item.fingerprint_type) }}</v-icon>
                      </div>
                    </div>
                  </template>
                  <v-card>
                    <v-card-title>
                      <h4 v-obfuscate>
                        {{ item.from_person }}
                      </h4>
                    </v-card-title>
                    <v-card-text v-obfuscate>
                      {{ item.subject }}
                    </v-card-text>
                    <v-card-actions>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            color="primary"
                            fab
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="addItemDialog(item)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add this item to a case</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="ma-1"
                            color="primary"
                            fab
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="showMessageDialog(item)"
                          >
                            <v-icon>mdi-file-document-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Open this document for preview</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </PageFrame>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapState, mapActions } from "pinia";
import ChooseCase from "@/components/datatable/popups/ChooseCase.vue";
import { getPayload } from "@/components/datatable/utils";
import StockChartSyncfusion from "@/components/search/StockChartSyncfusion.vue";
import caseRepository from "@/repositories/case";
import searchRepository, { getStockTimeline } from "@/repositories/search";
import { getLanguages } from "@/repositories/settings";
import { useStore } from "@/stores/main";
import { useAuthStore } from "@/stores/auth";
import PageFrame from "../PageFrame.vue";
import EventBus from "@/eventBus";
import { fullDateFormat, fullDateTimeFormat } from "@/filters";
import { getDataTypeIcon } from "@/utils";

export default {
  name: "SearchResultsConversation",
  components: { ChooseCase, PageFrame, StockChartSyncfusion },
  data() {
    return {
      languages: [],
      showLoading: false,
      stockTimeline: null,
      chartTitle: "",
      isDataLoading: true,
      timelineData: [],
      expandedPanels: [],
      person1: null,
      person2: null,
      subject: null,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["canShowConversationStockSearch"]),
    dataAnnotations() {
      return this.timelineDataGrouped.map((e) => {
        const leftElems = e.items.filter((i) => i.timelineProps.left).length;
        return {
          date: new Date(e.key),
          leftElems,
          rightElems: e.items.length - leftElems,
        };
      });
    },
    dataAnnotationsA() {
      return this.dataAnnotations
        .filter((e) => e.leftElems > 0)
        .map((e) => ({
          date: e.date,
          text: e.leftElems,
          description: `${e.leftElems} message${e.leftElems !== 1 ? "s" : ""}`,
        }));
    },
    dataAnnotationsB() {
      return this.dataAnnotations
        .filter((e) => e.rightElems > 0)
        .map((e) => ({
          date: e.date,
          text: e.rightElems,
          description: `${e.rightElems} message${e.rightElems !== 1 ? "s" : ""}`,
        }));
    },
    timelineDataWithSides() {
      return this.timelineData.map((elem) => ({
        ...elem,
        timelineProps: this.getTimelineItemProps(elem),
      }));
    },
    timelineDataGrouped() {
      const groupedData = this.timelineDataWithSides.reduce(
        (acc, i) => ({
          ...acc,
          [i.fingerprint_time.substring(0, 10)]: (
            acc[i.fingerprint_time.substring(0, 10)] || []
          ).concat(i),
        }),
        {},
      );
      return Object.entries(groupedData).map((i) => ({ key: i[0], items: i[1] }));
    },
  },
  async mounted() {
    getLanguages().then((languages) => {
      this.languages = languages;
    });
    this.setParams();
    this.loadData();
    if (this.canShowConversationStockSearch && this.$route.query.stockSymbol) {
      this.chartTitle = `${this.$route.query.stockName} (${this.$route.query.stockSymbol}) - ${this.$route.query.stockExchange}`;
      let fromDate = this.$route.query.dt_from;
      let toDate = this.$route.query.dt_to;
      if (this.$route.query.searchType === "relative") {
        fromDate = DateTime.now()
          .plus({
            [{
              m: "minutes",
              h: "hours",
              d: "days",
              w: "weeks",
              M: "months",
              y: "years",
            }[this.$route.query.dt_offset_type]]: -this.$route.query.dt_offset_count,
          })
          .toUTC()
          .toISO();
        toDate = DateTime.now().toUTC().toISO();
      }
      try {
        const { data } = await getStockTimeline(
          this.$route.query.stockSymbol,
          this.$route.query.stockExchange,
          fromDate,
          toDate,
        );
        this.stockTimeline = data;
      } catch {
        this.stockTimeline = null;
      }
    }
  },
  beforeUnmount() {
    this.showLoading = false;
  },
  methods: {
    ...mapActions(useStore, ["showMessageDialog"]),
    fullDateFormat,
    fullDateTimeFormat,
    getDataTypeIcon,
    setParams() {
      this.person1 = (this.$route.query.person1 || "").replace(/['"]+/g, "");
      this.person2 = (this.$route.query.person2 || "").replace(/['"]+/g, "");
      this.subject = (this.$route.query.subject || "").replace(/['"]+/g, "");
    },
    async loadData() {
      this.showLoading = true;
      let state = { ...this.$route.query };
      try {
        state.sort = "fingerprint.time"; // single field sort only (for now)
        const r = await searchRepository.getSearchConversationResults({
          ...state,
          source: "user",
          take: "all",
        });
        this.timelineData = r.data;
        if (!this.$route.query.stockSymbol) {
          this.expandedPanels = this.timelineDataGrouped.map((e, i) => i);
        }
        this.isDataLoading = false;
      } catch (error) {
        if (error.response && error.response.data.indexOf("ConnectionTimeout") !== -1) {
          error.response.data = [
            "The amount of results your current query would generate is large.  This means the system cannot return them in a timely manner.  Please refine your query and search again.",
          ];
        }
        EventBus.$emit("notify", "warn", error);
      }
      this.showLoading = false;
    },
    getTimelineItemProps(item) {
      const isFromPerson1 = item.from_person.toLowerCase().indexOf(this.person1.toLowerCase()) > -1;
      return {
        left: isFromPerson1,
        right: !isFromPerson1,
      };
    },
    async handleEventMarkerClick(eventMarker) {
      const targetDate = DateTime.fromJSDate(eventMarker.date).toFormat("yyyy-MM-dd");
      this.expandedPanels = this.timelineDataGrouped
        .map((e, i) => (e.key === targetDate ? i : -1))
        .filter((i) => i > -1);
      setTimeout(() => {
        document
          .getElementById(`expansion-panel.${targetDate}`)
          .scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    async addItemDialog(item) {
      EventBus.$emit("caseSelect", [item], (caseObj) => this.addItem(item, caseObj));
    },
    async addItem(item, caseObj) {
      const payload = getPayload({ ...item, score: 1 }, caseObj);
      payload.search_params = this.$route.query;
      try {
        await caseRepository.todoItemAdd(payload);
        if (caseObj) {
          EventBus.$emit("notify", "success", "Case Item Added");
        } else {
          EventBus.$emit("notify", "success", this.getMessage());
        }
      } catch (error) {
        EventBus.$emit("notify", "warn", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-timeline:before {
  left: 50%;
}

.v-card__title,
.v-card__text {
  padding: 10px;
  padding-bottom: 0;
}

.v-expansion-panel-header {
  text-align: left;
}

.inner-panel {
  .v-expansion-panel-header {
    padding: 2px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>
