<template>
  <div class="pa-4">
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <h3>
        {{ val }}
        <div v-if="perc" class="stat-percent text-navy">{{ perc }}%</div>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "IpsMetric",
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    val: {
      type: [String, Number],
      required: true,
    },
    perc: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
