<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="text-subtitle-1 mb-2" v-html="data.summary" />
    <v-tooltip bottom v-if="data.event.action === 'UNREDACT' && extraJson.es_id">
      <template #activator="{ on, attrs }">
        <v-btn
          class="ma-1"
          color="primary"
          fab
          small
          v-bind="attrs"
          v-on="on"
          @click="
            showMessageDialog({
              id: extraJson.es_id,
              fingerprint_client: extraJson.fingerprint_client,
              fingerprint_time: extraJson.fingerprint_time,
            })
          "
        >
          <v-icon>mdi-file-document-outline</v-icon>
        </v-btn>
      </template>
      <span>Open this document for preview</span>
    </v-tooltip>
    <span v-else class="text-body-2">
      {{ cleanedExtraJson }}
    </span>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useStore } from "@/stores/main";

export default {
  name: "LogDetail",
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    extraJson() {
      try {
        return this.data?.event?.extra || {};
      } catch (error) {
        return {};
      }
    },
    cleanedExtraJson() {
      // hotfix/workaround to remove all firm idendifiers from elasticsearch wrong logs
      if (
        this.data.event.action === "SEARCH" &&
        this.extraJson?.full_query?.query?.bool?.filter?.[0]?.bool?.must?.[0]?.terms[
          "fingerprint.client"
        ]
      ) {
        const retVal = { ...this.extraJson };
        retVal.full_query.query.bool.filter[0].bool.must[0].terms["fingerprint.client"] = [];
        return retVal;
      }
      return this.extraJson;
    },
  },
  methods: {
    ...mapActions(useStore, ["showMessageDialog"]),
  },
};
</script>
