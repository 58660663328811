import { defineStore } from "pinia";

export const defaultState = {
  case: {},
  canShowMessageDialog: false,
  messageDialogItem: null,
  showPrintingLayout: false,
};

export const actions = {
  setCase(selectedCase) {
    this.case = selectedCase ? selectedCase : {};
  },
  setShowPrintingLayout(value = true) {
    this.showPrintingLayout = value;
  },
  showMessageDialog(messageDialogItem) {
    this.messageDialogItem = messageDialogItem;
    this.canShowMessageDialog = true;
  },
};

export const useStore = defineStore("main", {
  state: () => defaultState,
  actions,
});
