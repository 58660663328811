"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var composables_1 = require("vue-router/composables");
var PageFrame_vue_1 = require("@/components/PageFrame.vue");
var case_1 = require("@/repositories/case");
var settings_1 = require("@/repositories/settings");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TodoRuleForm',
    props: {
        id: null
    },
    setup: function (__props) {
        var _this = this;
        var props = __props;
        var router = (0, composables_1.useRouter)();
        var route = (0, composables_1.useRoute)();
        var isNew = (0, vue_2.computed)(function () { return !props.id; });
        var formValid = (0, vue_2.ref)(false);
        var formObj = (0, vue_2.ref)();
        var fieldNames = (0, vue_2.ref)([]);
        var rules = {
            field_name: [function (v) { return !!v || "This field is mandatory"; }],
            field_values: [function (v) { return !!v || "This field is mandatory"; }],
        };
        function handleSave() {
            return __awaiter(this, void 0, void 0, function () {
                var payload;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!formValid.value) {
                                return [2 /*return*/];
                            }
                            payload = {
                                id: (_a = formObj.value) === null || _a === void 0 ? void 0 : _a.id,
                                field_name: (_b = formObj.value) === null || _b === void 0 ? void 0 : _b.field_name,
                                field_values: (_c = formObj.value) === null || _c === void 0 ? void 0 : _c.field_values.split(",").map(function (v) { return v.trim(); }),
                            };
                            return [4 /*yield*/, case_1.default.todoRuleSave(payload)];
                        case 1:
                            _d.sent();
                            router.back();
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = fieldNames;
                        return [4 /*yield*/, (0, settings_1.getFieldList)()];
                    case 1:
                        _a.value = _b.sent();
                        if (!!isNew.value) return [3 /*break*/, 3];
                        return [4 /*yield*/, case_1.default.todoRule(props.id)];
                    case 2:
                        data = (_b.sent()).data;
                        formObj.value = {
                            id: data.id,
                            field_name: data.field_name,
                            field_values: data.field_values.join(", "),
                        };
                        return [3 /*break*/, 4];
                    case 3:
                        formObj.value = {
                            field_name: route.query.field_name || "",
                            field_values: route.query.field_values || "",
                        };
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
        return { __sfc: true, props: props, router: router, route: route, isNew: isNew, formValid: formValid, formObj: formObj, fieldNames: fieldNames, rules: rules, handleSave: handleSave, PageFrame: PageFrame_vue_1.default };
    }
});
