import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"value":_vm.showDialog,"persistent":"","max-width":"500"}},[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Enter a Reason for Closing the Case ")]),_c(VCardText,[_c(VAutocomplete,{attrs:{"label":"Reason Category","items":_vm.actionReasonCategories,"item-value":"id","item-text":"name","autofocus":"","auto-select-first":"","rules":_vm.actionCategoryIdRules},model:{value:(_vm.actionCategoryId),callback:function ($$v) {_vm.actionCategoryId=$$v},expression:"actionCategoryId"}}),_c(VTextarea,{attrs:{"label":"Explanation","rules":_vm.actionReasonRules},model:{value:(_vm.actionReason),callback:function ($$v) {_vm.actionReason=$$v},expression:"actionReason"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }