import httpClient from "@/interceptors/axios";
import { withFirmGroup } from "./utils";

export async function getLogs(params) {
  try {
    const { data } = await httpClient.get("log/", {
      params: withFirmGroup(params),
      paramsSerializer: { indexes: null }, // https://github.com/axios/axios#request-config
    });
    return data;
  } catch {
    return { results: [], count: 0 };
  }
}

export async function getLogPermissions() {
  try {
    const { data } = await httpClient.get("log/permission/");
    return data;
  } catch {
    return {};
  }
}

/**
 * log the export of the DataTable
 * @param {string} reason the reason for the export
 */
export function logExportTable(reason) {
  return httpClient.post("log/event/", {
    action: "EXPORT_DATATABLE",
    extra: { reason },
  });
}

export function logLogin() {
  return httpClient.post("log/login/");
}

export function logLogout() {
  return httpClient.post("log/event/", { action: "LOGOUT" });
}

/**
 *
 * @param {object} message the object to unredact
 * @param {string} [field] field unredacted
 * @param {string} [reason] reason for the unredacton
 * @returns
 */
export async function logUnredactMessage(message, field, reason) {
  const { data } = await httpClient.post("log/event/", {
    action: "UNREDACT",
    extra: {
      es_id: message.id,
      fingerprint_client: message.fingerprint_client,
      fingerprint_time: message.fingerprint_time,
      field,
      reason,
    },
  });
  return data;
}
