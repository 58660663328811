"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var pinia_1 = require("pinia");
var vue_2 = require("vue");
var PageFrame_vue_1 = require("@/components/PageFrame.vue");
var firm_1 = require("@/repositories/firm");
var search_1 = require("@/repositories/search");
var settings_1 = require("@/repositories/settings");
var user_1 = require("@/repositories/user");
var auth_1 = require("@/stores/auth");
var cases_1 = require("@/stores/cases");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FirmGroupList',
    setup: function (__props) {
        var _this = this;
        var authStore = (0, auth_1.useAuthStore)();
        var _a = (0, pinia_1.storeToRefs)(authStore), canAddFirmGroups = _a.canAddFirmGroups, canChangeFirmGroups = _a.canChangeFirmGroups, canDeleteFirmGroups = _a.canDeleteFirmGroups, firmLabelObject = _a.firmLabelObject;
        var getUserProfile = authStore.getUserProfile;
        var casesStore = (0, cases_1.useCasesStore)();
        var getCaseNotifications = casesStore.getCaseNotifications;
        var firms = (0, vue_2.ref)([]);
        var users = (0, vue_2.ref)([]);
        var terms = (0, vue_2.ref)([]);
        var firmGroups = (0, vue_2.ref)([]);
        var newFirmName = (0, vue_2.ref)("");
        function firmNameIsValid(firmName) {
            return firmName && !!firmName.trim();
        }
        var addingFirmGroup = (0, vue_2.ref)(false);
        function resetNewFirmName() {
            newFirmName.value = "";
        }
        function addFirmGroup() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            addingFirmGroup.value = true;
                            return [4 /*yield*/, (0, settings_1.updateFirmGroup)({
                                    group_name: newFirmName.value.trim(),
                                })];
                        case 1:
                            _a.sent();
                            resetNewFirmName();
                            addingFirmGroup.value = false;
                            refreshFirmGroupsGlobally();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function removeFirmGroup(firmGroup) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!firmGroup.id) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, settings_1.deleteFirmGroup)(firmGroup.id)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            refreshFirmGroupsGlobally();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function startEditFirmGroup(firmGroup) {
            vue_2.default.set(firmGroup, "newGroupName", firmGroup.group_name);
            vue_2.default.set(firmGroup, "isEditing", true);
        }
        function resetEditFirmGroup(firmGroup) {
            vue_2.default.set(firmGroup, "newGroupName", undefined);
            vue_2.default.set(firmGroup, "isEditing", false);
        }
        function saveFirmGroup(firmGroup) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!firmGroup.newGroupName) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, settings_1.updateFirmGroup)({
                                    id: firmGroup.id,
                                    group_name: firmGroup.newGroupName.trim(),
                                    firms: firmGroup.newFirms,
                                    users: firmGroup.newUsers,
                                    terms: firmGroup.newTerms,
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            refreshFirmGroupsGlobally();
                            return [2 /*return*/];
                    }
                });
            });
        }
        var isSaving = (0, vue_2.ref)(false);
        function getAndProcessFirmGroups() {
            return __awaiter(this, void 0, void 0, function () {
                var _firmGroups;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, (0, settings_1.getFirmGroups)()];
                        case 1:
                            _firmGroups = _a.sent();
                            firmGroups.value = _firmGroups.map(function (firmGroup) { return (__assign(__assign({}, firmGroup), { newFirms: firmGroup.firms.map(function (firm) { return firm.id; }), newUsers: firmGroup.users.map(function (user) { return user.id; }), newTerms: firmGroup.terms.map(function (term) { return term.id; }) })); });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function refreshFirmGroupsGlobally() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getAndProcessFirmGroups()];
                        case 1:
                            _a.sent();
                            getUserProfile();
                            getCaseNotifications();
                            return [2 /*return*/];
                    }
                });
            });
        }
        (0, vue_2.onMounted)(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _firms, _users, _terms;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            (0, firm_1.getFirms)({ firm_groups: undefined }),
                            (0, user_1.getUserList)(),
                            (0, search_1.getTerms)({
                                take: "all",
                                firm_groups: undefined,
                                // is_deleted: false,
                            }),
                        ])];
                    case 1:
                        _a = _b.sent(), _firms = _a[0], _users = _a[1], _terms = _a[2].data;
                        firms.value = _firms;
                        users.value = _users;
                        // this works without further changes, because the `v-select` component uses
                        // `v-model="firmGroup.newTerms"` without additional checks
                        // on items not in the list of `:items="terms"`
                        // TODO: check if after migrating to Vue3 this still works in the same way
                        terms.value = _terms.filter(function (term) { return term.is_deleted === false; });
                        return [4 /*yield*/, getAndProcessFirmGroups()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        return { __sfc: true, authStore: authStore, canAddFirmGroups: canAddFirmGroups, canChangeFirmGroups: canChangeFirmGroups, canDeleteFirmGroups: canDeleteFirmGroups, firmLabelObject: firmLabelObject, getUserProfile: getUserProfile, casesStore: casesStore, getCaseNotifications: getCaseNotifications, firms: firms, users: users, terms: terms, firmGroups: firmGroups, newFirmName: newFirmName, firmNameIsValid: firmNameIsValid, addingFirmGroup: addingFirmGroup, resetNewFirmName: resetNewFirmName, addFirmGroup: addFirmGroup, removeFirmGroup: removeFirmGroup, startEditFirmGroup: startEditFirmGroup, resetEditFirmGroup: resetEditFirmGroup, saveFirmGroup: saveFirmGroup, isSaving: isSaving, getAndProcessFirmGroups: getAndProcessFirmGroups, refreshFirmGroupsGlobally: refreshFirmGroupsGlobally, PageFrame: PageFrame_vue_1.default };
    }
});
